import { connect } from 'react-redux';
import { get, groupBy, isEmpty, sortBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

import { CATEGORY_TYPES_OBJECT, getPropertyNoneValue } from '@commons/constants/categoryTypes';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { Dropdown, ToggleButton } from '@commons/utils/styledLibraryComponents';
import { ExportButton } from '@commons/ExportButton';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { LeftRightSplitter } from '@commons/LeftRightSplitter';
import { mapDataForExport } from '@commons/utils/format';
import { PeriodDatePicker } from '@commons/DatePickers/PeriodDatePicker';
import EmptyState from '@commons/EmptyState';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';
import utilsXLS from '@commons/utils/makeXLS';

import { usePeriodDatePickerState } from '@hooks/usePeriodDatePickerState';

import { getCentralKitchenStores } from '@selectors/stores';
import { getClientInfo } from '@selectors/client';
import { getPrevHorizon } from '@selectors/featureProps';

import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { getStoresMappedToSupplierOfClient } from '@services/client';
import centralService from '@services/central';

import { getEntityTypesChoices, ENTITY_TYPES_TO_CATEGORY_TYPES } from '../utils/commons';

import {
  Container,
  ContentContainer,
  RecommendationFormContainer,
  RecommendationsContainer,
  SettingContainer,
} from './styledComponents';
import {
  getInformationsColumnsSettings,
  getIngredientsColumnsSettings,
  getRecipeColumnsSettings,
} from './utils/getColumns';

import LoadingState from '../../orders/OrderList/components/OrderForm/components/LoadingState/LoadingState';
import ProductionCategorySumup from '../CentralKitchenProductionOperations/components/ProductionCategorySumup';
import RecommendationCategoryContent from './components/RecommendationCategoryContent';

const FILTER_KEY = 'category';
const EMPTY_VALUE = '';
const EXPORT_SORT_COLUMNS = ['category', 'name'];

const CentralKitchenProductionRecommendations = (props) => {
  const {
    client: { clientId, storeName, defaultTimezone },
    centralKitchens,
    prevHorizon: { future: MAX_FUTURE_DAY },
    pageLoading,
    pageLoaded,
    match: { path },
  } = props;

  const entityTypesChoices = getEntityTypesChoices();

  const centralKitchenTimezone = get(centralKitchens, '[0].timezone', defaultTimezone);
  const centralKitchenStoreName = getClientStoreNameTranslation(storeName, true).toLowerCase();

  // PeriodDatePicker
  const periodPickerState = usePeriodDatePickerState(null, null, centralKitchenTimezone);

  /* STATE */
  const [selectedCentralKitchenSupplier, setSelectedCentralKitchenSupplier] = useState(null);
  const [centralKitchenSuppliers, setCentralKitchenSuppliers] = useState([]);
  const [stores, setStores] = useState([]);
  const [selectedStores, setSelectedStores] = useState(stores);
  const [selectedMetric, setSelectedMetric] = useState(entityTypesChoices[0]);
  const [enableExport, setEnableExport] = useState(false);
  const [isLoadingRecommendations, setIsLoadingRecommendations] = useState(false);

  const [displayEmptyState, setDisplayEmptyState] = useState(false);
  const [recommendations, setRecommendations] = useState({});
  const [recommendationsGroupedByKey, setRecommendationsGroupedByKey] = useState({});

  // Pair of category - true/false boolean to say if a category need to be unfolded
  const [categoriesToUnfold, setCategoriesToUnfold] = useState({});

  /* USE EFFECT */
  useEffect(() => {
    (async () => {
      try {
        pageLoading();
        const centralKitchensIds = centralKitchens.map(({ id }) => id);
        const centralKitchenSuppliers = await centralService.getkitchenSupplierByStoreIds(
          centralKitchensIds,
        );

        setCentralKitchenSuppliers(centralKitchenSuppliers);

        // Default central kitchen supplier is first one available
        setSelectedCentralKitchenSupplier(centralKitchenSuppliers[0]);
      } catch {
        showErrorMessage(
          i18next.t('BACKOFFICE.STORES.FETCHING_ERROR', { storeName: centralKitchenStoreName }),
        );
      } finally {
        pageLoaded();
      }
    })();
  }, []);

  useEffect(() => {
    if (!selectedCentralKitchenSupplier) {
      return;
    }

    const loadStores = async () => {
      const stores = await getStoresMappedToSupplierOfClient(
        clientId,
        selectedCentralKitchenSupplier.id,
      );

      setStores(stores);
      setSelectedStores(stores);
    };

    loadStores();

    periodPickerState.setStartDate(null);
    periodPickerState.setEndDate(null);
    setRecommendations({});
    setRecommendationsGroupedByKey({});
  }, [selectedCentralKitchenSupplier]);

  useEffect(() => {
    setIsLoadingRecommendations(true);

    (async () => {
      try {
        if (
          isEmpty(selectedStores) ||
          isEmpty(selectedCentralKitchenSupplier) ||
          !periodPickerState.startDate ||
          !periodPickerState.endDate
        ) {
          setEnableExport(false);
          return;
        }

        setEnableExport(true);

        const storeIds = selectedStores.map(({ id }) => id);

        const startDate = moment(periodPickerState.startDate).format(
          DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
        );
        const endDate = moment(periodPickerState.endDate).format(
          DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
        );

        const fetchedRecommendations = await centralService.getProductionRecommendations(
          selectedCentralKitchenSupplier.storeId,
          storeIds,
          startDate,
          endDate,
        );

        const updatedRecommendations = Object.entries(
          fetchedRecommendations.recommendations,
        ).reduce((acc, [key, value]) => {
          acc[key] = value.map((recommendation) => {
            // TODO : update this condition when MS3 of categories management had been made
            if (ENTITY_TYPES_TO_CATEGORY_TYPES[key] === CATEGORY_TYPES_OBJECT.INGREDIENT) {
              return recommendation;
            }

            return {
              ...recommendation,
              category: recommendation.category || getPropertyNoneValue(),
            };
          });

          return acc;
        }, {});

        setRecommendations(updatedRecommendations);

        const groupedByCategoryRecommendations = groupBy(
          updatedRecommendations[selectedMetric.key],
          (recommendation) => get(recommendation, FILTER_KEY, ''),
        );

        if (groupedByCategoryRecommendations[EMPTY_VALUE]) {
          groupedByCategoryRecommendations[i18next.t('GENERAL.NONE_VALUE')] =
            groupedByCategoryRecommendations[EMPTY_VALUE];
          delete groupedByCategoryRecommendations[EMPTY_VALUE];
        }

        setRecommendationsGroupedByKey(groupedByCategoryRecommendations);
      } catch {
        showErrorMessage(i18next.t('PRODUCTION.CENTRAL_KITCHEN.RECIPE_LOADING_FAILURE'));
      } finally {
        setIsLoadingRecommendations(false);
      }
    })();
  }, [selectedStores, periodPickerState.startDate, periodPickerState.endDate]);

  useEffect(() => {
    if (isEmpty(recommendations) || !periodPickerState.startDate || !periodPickerState.endDate) {
      return;
    }

    const groupedByCategoryRecommendations = groupBy(
      recommendations[selectedMetric.key],
      (recommendation) => get(recommendation, FILTER_KEY, ''),
    );

    if (groupedByCategoryRecommendations[EMPTY_VALUE]) {
      groupedByCategoryRecommendations[i18next.t('GENERAL.NONE_VALUE')] =
        groupedByCategoryRecommendations[EMPTY_VALUE];
      delete groupedByCategoryRecommendations[EMPTY_VALUE];
    }

    setRecommendationsGroupedByKey(groupedByCategoryRecommendations);
  }, [selectedMetric]);

  useEffect(() => {
    setDisplayEmptyState(isEmpty(recommendations));
  }, [recommendations]);

  /** FUNCTIONS */

  const handleCategoryUnfolding = (selectedCategory) => {
    const isAlreadyUnfolded =
      categoriesToUnfold[selectedCategory] &&
      !!categoriesToUnfold[selectedCategory][selectedMetric.key];

    const updatedCategoriesToUnfold = {
      ...categoriesToUnfold,
      [selectedCategory]: {
        ...categoriesToUnfold[selectedCategory],
        [selectedMetric.key]: !isAlreadyUnfolded,
      },
    };

    setCategoriesToUnfold(updatedCategoriesToUnfold);
  };

  const exportRecommendations = () => {
    try {
      pageLoading();
      const recipesSheet = createRecipesExportSheet();
      const ingredientsSheet = createIngredientsExportSheet();
      const informationsSheet = createInformationsExportSheet();
      utilsXLS.makeXLS(
        i18next.t('PRODUCTION.RECOMMENDATIONS.CENTRAL_KITCHEN_RECOMMENDATIONS_EXPORT_FILENAME'),
        [recipesSheet, ingredientsSheet, informationsSheet],
      );
    } catch {
      showErrorMessage(i18next.t('GENERAL.EXPORT_FAILURE'));
    } finally {
      pageLoaded();
    }
  };

  const createRecipesExportSheet = () => {
    const columnSettings = getRecipeColumnsSettings();
    const headers = columnSettings.map(({ displayName }) => displayName);

    const sortedRecipes = sortBy(recommendations.RECIPES, EXPORT_SORT_COLUMNS);

    const data = mapDataForExport(columnSettings, sortedRecipes);

    return {
      title: i18next.t('GENERAL.RECIPE_PLURAL'),
      headers,
      data,
      formatToNumberColumnIndexes: { start: 2, end: 2 },
    };
  };

  const createIngredientsExportSheet = () => {
    const columnSettings = getIngredientsColumnsSettings();
    const headers = columnSettings.map(({ displayName }) => displayName);

    const sortedIngredients = sortBy(recommendations.INGREDIENTS, EXPORT_SORT_COLUMNS);
    const data = mapDataForExport(columnSettings, sortedIngredients);

    return {
      title: i18next.t('GENERAL.INGREDIENT_PLURAL'),
      headers,
      data,
      formatToNumberColumnIndexes: { start: 2, end: 2 },
    };
  };

  const createInformationsExportSheet = () => {
    const columnSettings = getInformationsColumnsSettings(storeName);
    const headers = columnSettings.map(({ displayName }) => displayName);

    const formattedStores = selectedStores.map(({ name }) => ({
      storeName: name,
      centralKitchenName: selectedCentralKitchenSupplier.name,
      startDate: periodPickerState.startDate,
      endDate: periodPickerState.endDate,
    }));

    const data = mapDataForExport(columnSettings, formattedStores);

    return {
      title: i18next.t('GENERAL.INFORMATIONS'),
      headers,
      data,
    };
  };

  return (
    <Container>
      <NavigationBreadCrumb featurePath={path} />
      <ContentContainer>
        <LeftRightSplitter
          left={
            <SettingContainer>
              <Dropdown
                iconSrc={'/images/inpulse/hub-black-small.svg'}
                isDisabled={false}
                items={centralKitchenSuppliers}
                searchPlaceholder={i18next.t('GENERAL.SEARCH')}
                selectedItem={selectedCentralKitchenSupplier}
                isRequired
                isUniqueSelection
                onSelectionChange={setSelectedCentralKitchenSupplier}
              />
              <Dropdown
                iconSrc={'/images/inpulse/store-black-small.svg'}
                isDisabled={false}
                isUniqueSelection={false}
                items={stores}
                searchPlaceholder={i18next.t('GENERAL.SEARCH')}
                selectedItems={selectedStores}
                isRequired
                onSelectionChange={setSelectedStores}
              />
              <PeriodDatePicker
                disabled={false}
                endDate={periodPickerState.endDate}
                focusedDateInput={periodPickerState.focusedDateInput}
                maxFutureDate={moment
                  .tz(centralKitchenTimezone)
                  .endOf('day')
                  .add(MAX_FUTURE_DAY, 'day')}
                maxPastDate={moment.tz(centralKitchenTimezone).endOf('day')}
                setFocusedDateInput={periodPickerState.setFocusedDateInput}
                startDate={periodPickerState.startDate}
                timezone={centralKitchenTimezone}
                onDatesChange={periodPickerState.handleSelectedDates}
              />
              <ToggleButton
                choices={entityTypesChoices}
                isDisabled={displayEmptyState}
                selectedChoice={selectedMetric}
                setSelectedChoice={setSelectedMetric}
              />
            </SettingContainer>
          }
          right={
            <>
              <ExportButton
                handleClick={exportRecommendations}
                isDisabled={!enableExport}
                isInpulseOnly={false}
              />
            </>
          }
        ></LeftRightSplitter>

        {isLoadingRecommendations ? (
          <LoadingState noMargin />
        ) : (
          <>
            {displayEmptyState ? (
              <EmptyState
                icon={'/images/inpulse/production-planning.svg'}
                label={i18next.t(
                  'PRODUCTION.RECOMMENDATIONS.CENTRAL_KITCHEN_RECOMMENDATIONS_EMPTY_STATE',
                  { storeName: centralKitchenStoreName },
                )}
              />
            ) : (
              <RecommendationsContainer>
                {Object.keys(recommendationsGroupedByKey)
                  .sort()
                  .map((key, index) => {
                    const isCategoryOpen =
                      categoriesToUnfold[key] && !!categoriesToUnfold[key][selectedMetric.key];

                    const recommendationQuantitiesCount = recommendationsGroupedByKey[key].length;

                    return (
                      <RecommendationFormContainer displayBottomBorder={isCategoryOpen} key={index}>
                        <ProductionCategorySumup
                          handleCategoryUnfolding={handleCategoryUnfolding}
                          isCategoryOpen={isCategoryOpen}
                          name={key}
                          total={recommendationQuantitiesCount}
                        />
                        {isCategoryOpen && (
                          <RecommendationCategoryContent
                            entities={recommendationsGroupedByKey[key]}
                            selectedChoiceKey={selectedMetric.key}
                            storeName={centralKitchenStoreName}
                          />
                        )}
                      </RecommendationFormContainer>
                    );
                  })}
              </RecommendationsContainer>
            )}
          </>
        )}
      </ContentContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  centralKitchens: getCentralKitchenStores(state.baseReducer.activeStores),
  prevHorizon: getPrevHorizon(state.baseReducer.userRights),
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CentralKitchenProductionRecommendations);

import { connect } from 'react-redux';
import { get } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { showConfirmationMessage } from '@actions/messageconfirmation';

import { Button } from '@commons/utils/styledLibraryComponents';
import { spUnitByIngredientUnit } from '@commons/utils/units';

import { ingredient as ingredientService } from '@services/ingredient';

import { areUnitsSame } from '@admin/products/ingredients/utils/assertSupplierProductAreSameUnit';

import MappingsErrorModal from '../MappingsErrorModal';

import {
  BodyListContainer,
  BodyListItem,
  BodyTextItem,
  Container,
  ModalBodySubtitle,
  WarningModalContainer,
} from './styledComponents';
/**
 * Assert that the general form of that page can be saved (ingredient)
 *
 * @param {Object} ingredient - The ingredient to save
 *
 * @returns {boolean} Whether the form is valid
 */
export function isFormValid(ingredient) {
  if (ingredient.name && ingredient.unit) {
    return (
      areUnitsSame(ingredient) ||
      ingredient.supplierProducts.some(({ conversions }) =>
        conversions.some(
          ({ convertedUnit }) => convertedUnit === spUnitByIngredientUnit.get(ingredient.unit),
        ),
      )
    );
  }
  return false;
}

const ModalContent = ({ supplierProductMappings }) => (
  <WarningModalContainer>
    <ModalBodySubtitle>
      {i18next.t('ADMIN.INGREDIENTS.ASSOCIATION_MODAL_ALREADY_SET_SUBTITLE')}
    </ModalBodySubtitle>
    <BodyListContainer>
      {supplierProductMappings.map((mapping, index) => (
        <BodyListItem key={index} type="disc">
          <BodyTextItem>
            {`${mapping.supplierProduct} ${i18next.t(
              'ADMIN.INGREDIENTS.ASSOCIATION_MODAL_ASSOCIATED_TO',
            )} ${mapping.ingredient}`}
          </BodyTextItem>
        </BodyListItem>
      ))}
    </BodyListContainer>
  </WarningModalContainer>
);

export function alreadyMappedChecking(supplierProducts, props) {
  const { onDisplayMappingErrorModalChange, onSupplierProductsAlreadyMappedChange } = props;

  let supplierProductMappings = [];
  for (let i = 0; i < supplierProducts.length; i++) {
    if (supplierProducts[i].entityId && supplierProducts[i].lnkEntitySupplierproductrel) {
      // if there is no lnkEntitySupplierproductrel, the SP already belongs to the current ingredient
      supplierProductMappings.push({
        supplierProduct: supplierProducts[i].name,
        ingredient: supplierProducts[i].lnkEntitySupplierproductrel.name,
      });
      onDisplayMappingErrorModalChange(true);
      onSupplierProductsAlreadyMappedChange(supplierProductMappings);
    }
  }

  if (supplierProductMappings.length > 0) {
    const params = {
      component: MappingsErrorModal,
      title: i18next.t('ADMIN.INGREDIENTS.ASSOCIATION_MODAL_ALREADY_SET_TITLE'),
      content: <ModalContent supplierProductMappings={supplierProductMappings} />,
      buttonLabel: i18next.t('GENERAL.UNDERSTOOD'),
    };
    props.openModal(params);
  }

  return supplierProductMappings.length > 0;
}

export async function makeSavingRequest(clientId, ingredient, savingType) {
  const supplierProducts = ingredient.supplierProducts.map(({ id, isUsedForCost }) => ({
    id,
    isUsedForCost,
  }));

  if (savingType === 'create') {
    return ingredientService.createIngredient(clientId, {
      name: ingredient.name,
      unit: ingredient.unit,
      categoryId: ingredient.categoryId,
      code: ingredient.code,
      cost: ingredient.cost,
      supplierProducts,
      allergenIds: ingredient.allergens.map((allergen) => allergen.id),
      isStrategic: ingredient.isStrategic,
    });
  }
  return ingredientService.updateIngredient(
    clientId,
    ingredient.id,
    ingredient.name,
    ingredient.unit,
    ingredient.categoryId,
    ingredient.code,
    ingredient.cost,
    supplierProducts,
    ingredient.allergens.map((allergen) => allergen.id),
    ingredient.isStrategic,
  );
}

/**
 * Handle the save of the entire form (ingredient)
 *
 * @param {Props} props - The props linked to the component
 *
 * @returns {void}
 */
export function handleSave(props) {
  let { ingredient, user, history, showMessage, savingInProgress, savingFinished } = props;
  const dispatchers = { savingInProgress, savingFinished };

  let ingredientToSave = ingredient;
  ingredientToSave.cost = parseFloat(ingredientToSave.cost);
  if (!alreadyMappedChecking(ingredientToSave.supplierProducts, props)) {
    dispatchers.savingInProgress();

    makeSavingRequest(get(user, 'clientId'), ingredient, ingredient.id ? 'update' : 'create')
      .then((resp) => {
        showMessage(i18next.t('ADMIN.INGREDIENTS.SAVING_SUCCESS'));
        history.goBack();
      })
      .catch(() => showMessage(i18next.t('ADMIN.INGREDIENTS.SAVING_FAIL'), 'error'))
      .finally(() => dispatchers.savingFinished());
  }
}

export const IngredientDetailsBottomBar = (props) => {
  const { ingredient, isReadOnly, handleIngredientActivation } = props;
  return (
    <Container>
      {isReadOnly ? (
        <Button
          buttonCustomStyle={{ margin: '12px 18px 0 0' }}
          color={'inpulse-default'}
          handleClick={() => handleIngredientActivation(ingredient)}
          icon={'/images/inpulse/power-white-small.svg'}
          label={i18next.t('GENERAL.ACTIVATE')}
        />
      ) : (
        <Button
          buttonCustomStyle={{ margin: '12px 18px 0 0' }}
          color={'inpulse-default'}
          handleClick={() => (isFormValid(ingredient) ? handleSave(props) : {})}
          icon={'/images/inpulse/save-white-small.svg'}
          isDisabled={!isFormValid(ingredient)}
          label={i18next.t('GENERAL.SAVE')}
        />
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  savingInProgress: () => {
    dispatch(loading());
  },
  savingFinished: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(IngredientDetailsBottomBar);

import React, { createContext, useState } from 'react';

import { CHOICES_DROPDOWN_ACTIVE } from '../routes/admin/utils/DropdownItems';
import { getListViewState } from './utils';

export const SupplierProductsContext = createContext();

export const SupplierProductsProvider = ({ children }) => {
  const listViewStates = getListViewState();

  // Applied filters
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(CHOICES_DROPDOWN_ACTIVE()[0]);
  const [selectedCatalogs, setSelectedCatalogs] = useState([]);

  // Advanced filters
  const [queryParams, setQueryParams] = useState({
    entityIds: [],
    params: '',
    inventoryListTemplateIds: [],
  });
  const [advancedFilters, setAdvancedFilters] = useState(null);

  return (
    <SupplierProductsContext.Provider
      value={{
        ...listViewStates,
        selectedSuppliers,
        setSelectedSuppliers,
        selectedBrands,
        setSelectedBrands,
        selectedStatus,
        setSelectedStatus,
        selectedCatalogs,
        setSelectedCatalogs,
        advancedFilters,
        setAdvancedFilters,
        queryParams,
        setQueryParams,
      }}
    >
      {children}
    </SupplierProductsContext.Provider>
  );
};

export default SupplierProductsProvider;

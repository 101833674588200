import { get, keyBy } from 'lodash';

import { productOrders as productOrderService } from '@services/productOrders';
import orderService from '@services/order';

import { PRODUCT_ORDER_TYPE } from '../constants';
import ORDER_STATUS, { ORDER_PREPARATION_STATUS } from '../components/OrderForm/constants/status';

export const processOrders = (orders) =>
  orders.map((order) => {
    order.storeName = get(order, 'lnkStoreOrderrel.name');
    order.storeTimezone = get(order, 'lnkStoreOrderrel.timezone');
    order.supplierName = get(order, 'lnkSupplierOrderrel.name');
    order.totalPrice = order.totalPrice;
    order.timestamp = order.orderDate;
    return order;
  });

/**********************/
/* Fetch Data Methods */
/**********************/

/**
 * Retrieve the information related to the orders matching the given list of filters
 *
 * @param {Array} storeIds                - The store ids on which retrieve the data
 * @param {Array} supplierIds             - The suppliers ids on which retrieve the data
 * @param {Function} onOrdersChange       - Method to set the local state of the orders
 * @param {Function} setTotalOrdersCount  - Method to set the total number of orders the route without pagination will send
 * @param {String} search                 - The search filter on which retrieve the data
 * @param {Number} skip                   - The number of elements to skip
 * @param {Number} limit                  - The number of elements to retrieve
 * @param {String} orderBy                - The field on which order to list
 * @param {String} orderType              - The order type to apply on order list
 * @param {String} params                 - A query string containing a set of query params to attached to request
 *
 * @returns {void}
 */
export async function fetchOrdersOfStores(
  storeIds,
  supplierIds,
  onOrdersChange,
  setTotalOrdersCount,
  search,
  skip,
  limit,
  orderBy,
  orderType,
  params,
) {
  const result = await orderService.getOrdersOfStoresPaginate(
    storeIds,
    supplierIds,
    search,
    skip,
    limit,
    orderBy,
    orderType,
    params,
  );

  const formatedOrders = processOrders(result.orders);

  if (onOrdersChange) {
    onOrdersChange(formatedOrders);
  }

  if (setTotalOrdersCount) {
    setTotalOrdersCount(result.totalCount);
  }

  return {
    orders: formatedOrders,
    totalCount: result.totalCount,
  };
}

/**
 * Retrieve the number of orders associated to the given list of store ids
 *
 * @param {Array} storeIds  - The store ids on which retrieve the orders count
 * @param {Array} supplierIrds  - The supplier ids of the orders to retrieve
 * @param {Integer} orderType  - The order type of the orders to retrieve
 * @param {Boolean} isCentralKitchenCount  - boolean to fetch orders count from central kitchen stores
 *
 * @returns {Promise<Number|null>} The number of orders found, NULL if an error happens
 */
export async function fetchOrdersStoresCount({
  storeIds,
  supplierIds,
  status,
  isForCentralKitchenPreparation,
}) {
  try {
    const { count } = await orderService.getOrdersCountOfStores(
      storeIds,
      supplierIds,
      status,
      isForCentralKitchenPreparation,
    );

    return count;
  } catch (err) {
    return null;
  }
}

/**
 * Retrieve the information related to the orders associated to the given storeIds
 *
 * @param {Array} orders        - The orders on which change the status
 * @param {String} newStatus    - The new status to apply to the given orders
 *
 * @returns {void}
 */
export async function updateOrderStatus(orders, newStatus, suppliers) {
  const ordersIds = orders.map(({ id }) => id);
  const productOrders = await productOrderService.getProductOrdersOfOrders(ordersIds);

  const suppliersById = keyBy(suppliers, 'id');

  await Promise.all(
    orders.map((order) => {
      const updatedOrder = { ...order };

      const productOrdersOfOrder = productOrders.filter(
        (productOrder) => productOrder.orderId === order.id,
      );

      if (parseInt(newStatus) === ORDER_STATUS.SENT) {
        const supplier = suppliersById[order.supplierId];

        if (!!supplier && supplier.isKitchen) {
          updatedOrder.preparationStatus = ORDER_PREPARATION_STATUS.TO_BE_PREPARED;
        }

        productOrdersOfOrder.forEach(
          (productOrder) => (productOrder.type = PRODUCT_ORDER_TYPE.ORDERED),
        );
      }

      updatedOrder.status = newStatus;
      updatedOrder.isEditable = false;

      return orderService.patchOrder(updatedOrder, productOrdersOfOrder);
    }),
  );
}

export const updatePreparationStatus = async (orders, status) => {
  const updatePreparationStatusFunction = {
    [ORDER_PREPARATION_STATUS.PREPARING]: orderService.markPreparationAsPreparing,
    [ORDER_PREPARATION_STATUS.COMPLIANT_PREPARED]: orderService.markPreparationAsPrepared,
  };

  const promises = orders.map(({ id }) => updatePreparationStatusFunction[status](id, null));

  await Promise.all(promises);
};

/**
 * Cancel the reception of the order associated to the given id
 *
 * @param {Array} orderId  - The id of the order on which cancel the reception
 *
 * @returns {Promise<void>}
 */
export async function cancelReceptionOrderById(orderId) {
  await orderService.cancelReceptionOrderById(orderId);
}

/**
 * Retrieve the information related to the orders associated to the given storeIds
 *
 * @param {Array} orderId  - The id of the order to delete
 *
 * @returns {void}
 */
export async function deleteOrderById(orderId) {
  await orderService.deleteOrderById(orderId);
}

import i18next from 'i18next';
import React from 'react';

import { Dropdown } from '@commons/utils/styledLibraryComponents';
import { getIngredientUnitName } from '@commons/utils/units';

import theme from '@theme';

import {
  DropdownIcon,
  DropdownItem,
  DropdownItemMainValue,
  DropdownTitle,
} from '@admin/suppliers/supplierProducts/details/supplierProductInformations/components/SupplierProductDetails/styledComponents';

const DEFAULT_INGREDIENT_PICTURE = '/images/inpulse/ingredient-photo-placeholder.svg';

const IngredientDropdown = (props) => {
  const {
    handleEntityChange,
    ingredients,
    isReadOnly,
    composition,
    isKitchenProduct,
    isLoading,
    handleIngredientCreation,
  } = props;

  const renderedIngredients = ingredients.map((ingredient) => ({
    ...ingredient,
    tags: [getIngredientUnitName(ingredient.unit)],
    value: ingredient.name,
    renderValue: () => (
      <DropdownItem>
        <DropdownIcon src={ingredient.img || DEFAULT_INGREDIENT_PICTURE} />
        <DropdownTitle>
          <DropdownItemMainValue>{ingredient.name}</DropdownItemMainValue>
        </DropdownTitle>
      </DropdownItem>
    ),
  }));

  return (
    <Dropdown
      button={
        isKitchenProduct
          ? {
              id: 1,
              text: i18next.t('ADMIN.INGREDIENTS.CREATE'),
              handleClick: handleIngredientCreation,
              iconSrc: '/images/inpulse/add-black-small.svg',
            }
          : null
      }
      customStyle={{ height: 'auto' }}
      isDisabled={isReadOnly || isLoading}
      items={renderedIngredients}
      label={i18next.t('GENERAL.INGREDIENT')}
      labelStyle={{ font: theme.fonts.textSmall }}
      placeholder={i18next.t('GENERAL.SEARCH')}
      selectedItem={composition}
      tooltipText={
        isKitchenProduct ? '' : i18next.t('ADMIN.PRODUCTS.COMPOSITION_TOOLTIP_INGREDIENT')
      }
      isRequired
      onSelectionChange={handleEntityChange}
    />
  );
};

export default IngredientDropdown;

import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import {
  showConfirmationMessage,
  showErrorMessage,
  showSuccessMessage,
} from '@actions/messageconfirmation';

import {
  Button,
  Dropdown,
  INPUT_WIDTH,
  Label,
  SearchBar,
  Tooltip,
} from '@commons/utils/styledLibraryComponents';
import { DeepsightCheckbox, InpulseLabel } from '@commons/DeepsightComponents';
import DisplayNumber from '@commons/DisplayNumber';

import { getClientInfo } from '@selectors/client';

import { cashierProductService } from '@services/cashierProduct';
import { product as productService } from '@services/product';

import theme from '@theme';

import { CHOICES_DROPDOWN_ACTIVE, CHOICES_DROPDOWN_MAPPED } from '@admin/utils/DropdownItems';

import { SIGNIFICANCE_THRESHOLD_SIMILARITY_SCORE_MAPPING } from '@admin/cashier/cashierProducts/common/constants';

import { fetchCashierProducts } from '@admin/products/products/detail';

import {
  BottomContainer,
  ContainerLastSync,
  Content,
  ContentHeader,
  ContentList,
  DateContainer,
  FilterContainer,
  Line,
  ListRowText,
  PointerDiv,
  Row,
  TagContainer,
  TagHoverBox,
  TagInfo,
  TagLabel,
  Text,
  TopContainer,
} from './styledComponents';

const HEADER_NAME = {
  baseName: 'name',
  displayName: 'Nom',
  renderItem: (item) => <ListRowText className="link">{item}</ListRowText>,
};

const HEADER_RELEVANCE = {
  baseName: 'similarityScore',
  displayName: 'Pertinence',
  renderItem: (item) => {
    const formattedScore = item ? Math.round(item) : 0;

    const isRelevant = formattedScore >= SIGNIFICANCE_THRESHOLD_SIMILARITY_SCORE_MAPPING;

    return (
      <Label
        background={isRelevant ? theme.colors.brand.secondary : theme.colors.greys.lighty}
        color={isRelevant ? theme.colors.greys.darkest : theme.colors.greys.lightest}
        type={'plain'}
        width={'fit-content'}
      >
        {formattedScore}
      </Label>
    );
  },
};

const HEADER_SKU = {
  baseName: 'sku',
  displayName: 'SKU',
  renderItem: (item) => (
    <ListRowText className="sku-label">{`#${item}` || 'Non défini'}</ListRowText>
  ),
};

const HEADER_TAG = {
  baseName: 'tags',
  displayName: i18next.t('GENERAL.TAGS'),
  renderItem: (item) => renderTags(item),
};

const HEADER_CREATED = {
  baseName: 'createdAt',
  displayName: `Date d'ajout`,
  renderItem: (item) =>
    item ? <DateContainer bold>{moment(item).format('DD MMMM YYYY')}</DateContainer> : '',
};

const HEADER_PRICE = {
  baseName: 'priceWithTaxes',
  displayName: (
    <Fragment>
      <span>{i18next.t('ADMIN.CASHIER_PRODUCTS.ASSOCIATION_MODAL_LIST_COLUMN_PRICE')}</span>
      <Tooltip
        text={i18next.t(
          'ADMIN.PRODUCTS.CREATE_PRODUCT_MODAL_CASHIER_PRODUCT_ASSOCIATION_ON_SITE_PRICE',
        )}
      />
    </Fragment>
  ),
  renderItem: (item) => <DisplayNumber displayCurrencyCode={true} number={item} />,
};

const HEADER_VAT_RATE = {
  displayName: 'TVA',
  baseName: 'vatRate',
  renderItem: (item) => <ListRowText>{item !== null ? `${item}%` : '-'}</ListRowText>,
};

const HEADER_SYNC = {
  baseName: 'isAssociated',
  displayName: 'Associé',
  renderItem: (item) => renderLabelAssociation(item),
};

export const renderTags = (itemList) => {
  if (!itemList || !itemList.length) {
    return null;
  }
  if (itemList.length === 1) {
    return (
      <TagContainer>
        <TagLabel>{itemList[0]}</TagLabel>
      </TagContainer>
    );
  }
  return (
    <TagContainer>
      <TagLabel>{itemList[0]}</TagLabel>
      <TagInfo>{`+ ${itemList.length - 1}`}</TagInfo>
      <TagHoverBox>
        <PointerDiv />
        {itemList.map((item, index) => (
          <TagLabel className="box" key={index}>
            {item}
          </TagLabel>
        ))}
      </TagHoverBox>
    </TagContainer>
  );
};

export const renderLabelAssociation = (item) => (
  <div className="supplier-label-render">
    <InpulseLabel color={item ? 'green' : 'red'} text={item ? 'Oui' : 'Non'} />
  </div>
);

/*********************/
/** Handler Methods **/
/*********************/

export function handleListSelection(
  itemsToDisplay,
  selectionCashierProducts,
  setSelectionCashierProducts,
  selectedId,
) {
  if (!selectedId) {
    const areAllSelected = itemsToDisplay.every((item) => item.selected);

    const updatedList = selectionCashierProducts.map((item) => {
      if (!itemsToDisplay.some(({ id }) => id === item.id)) {
        return item;
      }

      item.selected = areAllSelected ? false : true;

      return item;
    });

    setSelectionCashierProducts(updatedList);

    return;
  }

  const updatedList = selectionCashierProducts.map((item) => {
    if (item.id === selectedId) {
      item.selected = !item.selected;
    }

    return item;
  });

  setSelectionCashierProducts(updatedList);
}

export function handleSearchAndFilterLogic(
  headers,
  searchInput,
  selectionCashierProducts,
  setSelectionCashierProducts,
  setItemsToDisplay,
) {
  const updatedSelectionCashierProducts = selectionCashierProducts.map((item) => {
    item.display = headers.some((header) => {
      if (!item[header.baseName] || typeof item[header.baseName] !== 'string') {
        return false;
      }

      return item[header.baseName].toLowerCase().includes(searchInput.toLowerCase());
    });

    return item;
  });

  setSelectionCashierProducts(updatedSelectionCashierProducts);
}

export function keyPress(
  e,
  headers,
  searchInput,
  selectionCashierProducts,
  setSelectionCashierProducts,
) {
  if (e.keyCode === 13) {
    handleSearchAndFilterLogic(
      headers,
      searchInput,
      selectionCashierProducts,
      setSelectionCashierProducts,
    );
  }
}

export function isFormValid(selectionCashierProducts) {
  const selectedItems = selectionCashierProducts.filter(
    (selectionCashierProduct) => selectionCashierProduct.selected,
  );

  return !!selectedItems.length;
}

/********************/
/** Render Methods **/
/********************/

export function renderTopBar() {
  return (
    <TopContainer>
      {i18next.t('ADMIN.CASHIER_PRODUCTS.SYNC_SALES_WITH_CASHIER_PRODUCTS')}
    </TopContainer>
  );
}

export function renderBottomBar(
  closeModal,
  productId,
  cashierProducts,
  setCashierProducts,
  selectionCashierProducts,
  pageLoading,
  showMessage,
  showSuccessMessage,
  showErrorMessage,
  pageLoaded,
) {
  return (
    <BottomContainer>
      <Button
        buttonCustomStyle={{ marginRight: 16 }}
        color={'inpulse-outline'}
        handleClick={() => closeModal()}
        icon={'/images/inpulse/close-black-small.svg'}
        label={i18next.t('GENERAL.CANCEL')}
      />
      <Button
        buttonCustomStyle={{ marginRight: 18 }}
        color={'inpulse-default'}
        handleClick={() =>
          isFormValid(selectionCashierProducts) &&
          save(
            productId,
            cashierProducts,
            setCashierProducts,
            selectionCashierProducts,
            closeModal,
            pageLoading,
            showMessage,
            showSuccessMessage,
            showErrorMessage,
            pageLoaded,
          )
        }
        icon={'/images/inpulse/save-white-small.svg'}
        isDisable={isFormValid(selectionCashierProducts)}
        label={i18next.t('GENERAL.SAVE')}
      />
    </BottomContainer>
  );
}

export function renderLastSync(lastSync) {
  if (!lastSync) {
    return null;
  }

  const now = moment();
  const formattedDate = moment(lastSync);

  const isToday = formattedDate.isSame(now, 'day');
  const isYesterday = formattedDate.isSame(now.subtract(1, 'day'), 'day');

  const date = formattedDate.format('DD/MM');
  const time = formattedDate.format('HH:mm');

  return (
    <Text grey>
      {isToday
        ? i18next.t('ADMIN.CASHIER_PRODUCTS.LAST_SYNC_TODAY', { time })
        : isYesterday
        ? i18next.t('ADMIN.CASHIER_PRODUCTS.LAST_SYNC_YESTERDAY', { time })
        : i18next.t('ADMIN.CASHIER_PRODUCTS.LAST_SYNC_DATE_PARAM', { date, time })}
    </Text>
  );
}

export function renderContent(
  headers,
  selectionCashierProducts,
  setSelectionCashierProducts,
  activeKeysDropdowns,
  handleActiveKeysDropdownSelection,
  lastSync,
) {
  const itemsToDisplay = selectionCashierProducts.filter((item) => item.display !== false);

  const selectedActiveKeysDropdowns = activeKeysDropdowns?.filter(
    (activeKey) => activeKey.propertyKey === 'active',
  );

  const selectedMappedKeysDropdowns = activeKeysDropdowns?.filter(
    (activeKey) => activeKey.propertyKey === 'isAssociated',
  );

  return (
    <Content>
      <FilterContainer>
        <SearchBar
          placeholder={`${itemsToDisplay.length} produit${
            itemsToDisplay.length > 1 ? 's' : ''
          } caisse`}
          setValue={(input) =>
            handleSearchAndFilterLogic(
              headers,
              input,
              selectionCashierProducts,
              setSelectionCashierProducts,
            )
          }
        />
        <Dropdown
          customStyle={{ margin: '0px 10px' }}
          iconSrc={
            !selectedActiveKeysDropdowns
              ? '/images/inpulse/location-dmgrey-small.svg'
              : '/images/inpulse/location-black-small.svg'
          }
          isUniqueSelection={false}
          items={CHOICES_DROPDOWN_ACTIVE()}
          selectedItems={selectedActiveKeysDropdowns}
          width={INPUT_WIDTH.MEDIUM}
          onSelectionChange={handleActiveKeysDropdownSelection}
        />
        <Dropdown
          customStyle={{ marginRight: '10px' }}
          iconSrc={
            !selectedMappedKeysDropdowns
              ? '/images/inpulse/location-dmgrey-small.svg'
              : '/images/inpulse/location-black-small.svg'
          }
          isUniqueSelection={false}
          items={CHOICES_DROPDOWN_MAPPED()}
          selectedItems={selectedMappedKeysDropdowns}
          width={INPUT_WIDTH.MEDIUM}
          onSelectionChange={handleActiveKeysDropdownSelection}
        />
        <ContainerLastSync>{renderLastSync(lastSync)}</ContainerLastSync>
      </FilterContainer>
      <ContentHeader>
        <Line className="header">
          <DeepsightCheckbox
            handleClick={() => {
              handleListSelection(
                itemsToDisplay,
                selectionCashierProducts,
                setSelectionCashierProducts,
              );
            }}
            isChecked={!!itemsToDisplay.length && itemsToDisplay.every((item) => item.selected)}
            style="square"
            type={'inpulse'}
          />
          {headers.map((header, indexHeader) => (
            <Row
              isEllipsis={header.baseName === 'name'}
              key={`${indexHeader}`}
              width={
                header.baseName === 'vatRate' || header.baseName === 'isAssociated'
                  ? 'small'
                  : 'normal'
              }
            >
              {header.displayName}
            </Row>
          ))}
        </Line>
      </ContentHeader>
      <ContentList>
        {itemsToDisplay.map((item, indexList) => (
          <Line key={`${indexList}`}>
            <DeepsightCheckbox
              handleClick={() => {
                handleListSelection(
                  itemsToDisplay,
                  selectionCashierProducts,
                  setSelectionCashierProducts,
                  item.id,
                );
              }}
              isChecked={item.selected}
              style="square"
              type={'inpulse'}
            />
            {headers.map((header, indexHeader) => (
              <Row
                isEllipsis={header.baseName === 'name'}
                key={`${indexList}-${indexHeader}`}
                width={
                  header.baseName === 'vatRate' || header.baseName === 'isAssociated'
                    ? 'small'
                    : 'normal'
                }
              >
                {header.renderItem(item[header.baseName])}
              </Row>
            ))}
          </Line>
        ))}
      </ContentList>
    </Content>
  );
}

/***********************/
/** FORMAT FUNCTIONS ***/
/***********************/

export const formatCashierProducts = async (cashierProducts) => {
  const formattedCashierProducts = cashierProducts.map((cashierProduct) => ({
    ...cashierProduct,
    isAssociated: !!cashierProduct.associatedProduct,
  }));

  const visibleCashierProducts = formattedCashierProducts.filter(
    (cashierProduct) => !cashierProduct.hidden,
  );
  return _.orderBy(visibleCashierProducts, 'similarityScore', 'desc');
};

/***************/
/** API CALLS **/
/***************/

/**
 * Retrieve the list of cashier products related to a client with products' mapping suggestion
 *
 * @param {String} productId   - The id of the product for whom to retrieve the potential mapping with cashier products

 * @returns {Object[]}        - The list of cashier products related to the given productId
 */
export const fetchCashierProductsWithMappingByProductId = async (productId) =>
  cashierProductService.getCashierProductsWithProductMatchingScores(productId);

/**
 * Handle the save of the selected cashier products to associate them with the Inpulse product
 *
 * @param {String} productId                          - The product id on which associate the selected cashier product
 * @param {CashierProduct[]} cashierProducts          - The cashier products already attached to the product
 * @param {Function} setCashierProducts               - The method to update dynamically the cashier products attached the Inpulse product on the parent page
 * @param {CashierProduct[]} selectionCashierProducts - The list of unmapped cashier products that can be attached to the product
 * @param {Function} closeModal                       - The method to close the modal
 * @param {Function} pageLoading                      - The method to display the loading state
 * @param {Function} showMessage                      - The method to display a message
 * @param {Function} pageLoading                      - The method to remove the loading state
 *
 * @returns {void}
 */
export async function save(
  productId,
  cashierProducts,
  setCashierProducts,
  selectionCashierProducts,
  closeModal,
  pageLoading,
  showMessage,
  showSuccessMessage,
  showErrorMessage,
  pageLoaded,
) {
  const selectedCashierProducts = selectionCashierProducts.filter(
    (selectionCashierProduct) => selectionCashierProduct.selected,
  );

  const cashierProductIds = selectedCashierProducts.map(
    (selectedCashierProduct) => selectedCashierProduct.id,
  );

  pageLoading();

  try {
    await productService.associateCashierProductsToProduct(productId, cashierProductIds);

    await fetchCashierProducts(productId, setCashierProducts);

    showSuccessMessage(
      i18next.t(
        cashierProductIds.length > 1
          ? 'ADMIN.PRODUCTS.SYNC_CASHIER_PRODUCTS_SUCCESS'
          : 'ADMIN.PRODUCTS.SYNC_CASHIER_PRODUCT_SUCCESS',
      ),
    );

    closeModal();
  } catch {
    showErrorMessage(
      i18next.t(
        cashierProductIds.length > 1
          ? 'ADMIN.PRODUCTS.SYNC_CASHIER_PRODUCTS_FAILURE'
          : 'ADMIN.PRODUCTS.SYNC_CASHIER_PRODUCT_FAILURE',
      ),
    );
  } finally {
    pageLoaded();
  }
}

const AssociationListingSelection = (props) => {
  const {
    client: { lastSync },
    closeModal,
    pageLoaded,
    showMessage,
    showSuccessMessage,
    showErrorMessage,
    pageLoading,
    params: { productId, cashierProducts, setCashierProducts },
  } = props;

  const [cashierProductsOfClient, setCashierProductsOfClient] = useState([]);

  const [selectionCashierProducts, setSelectionCashierProducts] = useState([]);

  const [activeFilters, setActiveFilters] = useState([
    CHOICES_DROPDOWN_ACTIVE()[0],
    CHOICES_DROPDOWN_MAPPED()[1],
  ]);

  const [activeKeysDropdowns, setActiveKeysDropdowns] = useState([
    CHOICES_DROPDOWN_ACTIVE()[0],
    CHOICES_DROPDOWN_MAPPED()[1],
  ]);

  const headers = [
    HEADER_NAME,
    HEADER_RELEVANCE,
    HEADER_CREATED,
    HEADER_TAG,
    HEADER_SKU,
    HEADER_PRICE,
    HEADER_VAT_RATE,
    HEADER_SYNC,
  ];

  const loadCashierProducts = async () => {
    pageLoading();

    try {
      const cashierProducts = await fetchCashierProductsWithMappingByProductId(productId);

      const formattedCashierProducts = await formatCashierProducts(cashierProducts);

      setCashierProductsOfClient(formattedCashierProducts);
    } catch {
      showErrorMessage(i18next.t('ADMIN.CASHIER_PRODUCTS.LOAD_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  useEffect(() => {
    (async function loadData() {
      await loadCashierProducts();
    })();
  }, []);

  useEffect(() => {
    if (!activeFilters.length) {
      setSelectionCashierProducts(cashierProductsOfClient);

      return;
    }

    let updatedFilteredItems = cashierProductsOfClient;

    activeFilters.forEach((activeKey) => {
      updatedFilteredItems = updatedFilteredItems.filter((item) => {
        if (item[activeKey.propertyKey] === activeKey.filterValue) {
          item.display = true;
          return item;
        }
      });
    });

    setSelectionCashierProducts(updatedFilteredItems);
  }, [activeFilters, cashierProductsOfClient]);

  /************************/
  /** DROPDOWNS HANDLERS **/
  /************************/

  const handleActiveKeysDropdownSelection = (keys) => {
    if (keys.length === 0) {
      return;
    }

    // update dropdowns selection
    const updatedKeysDropdowns = activeKeysDropdowns.filter(
      (activeKey) => activeKey.propertyKey !== keys[0].propertyKey,
    );

    setActiveKeysDropdowns([...updatedKeysDropdowns, ...keys]);

    if (keys.length === 2) {
      // if both choices are selected, remove dropdown from filters
      const updatedFilters = activeFilters.filter(
        (activeKey) => activeKey.propertyKey !== keys[0].propertyKey,
      );

      setActiveFilters(updatedFilters);

      return;
    }

    // if one choice is selected, replace old choice by new one
    const updatedFilters = activeFilters.filter(
      (activeKey) => activeKey.propertyKey !== keys[0].propertyKey,
    );

    setActiveFilters([...updatedFilters, ...keys]);
  };

  return (
    <Fragment>
      {renderTopBar()}
      {renderContent(
        headers,
        selectionCashierProducts,
        setSelectionCashierProducts,
        activeKeysDropdowns,
        handleActiveKeysDropdownSelection,
        lastSync,
      )}
      {renderBottomBar(
        closeModal,
        productId,
        cashierProducts,
        setCashierProducts,
        selectionCashierProducts,
        pageLoading,
        showMessage,
        showSuccessMessage,
        showErrorMessage,
        pageLoaded,
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AssociationListingSelection);

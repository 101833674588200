import { get, keyBy } from 'lodash';
import i18next from 'i18next';
import XLSX from 'xlsx';

const MAX_SHEET_ELEMENT_SIZE = 50000;
const MAXIMUM_SHEET_NAME_LENGTH = 31;
const XLS_EXTENSION_FILE = '.xlsx';

import { formatNumberToExcelUsage } from '../DisplayNumber';

const formatRow = (item, { type, propertyKey, transform }, numberDecimals) => {
  const columnValue = get(item, propertyKey, null);

  if (type === 'currency' && columnValue) {
    return formatNumberToExcelUsage(columnValue, numberDecimals);
  }

  if (type === 'percentage' && columnValue) {
    return formatNumberToExcelUsage(columnValue, 2);
  }

  if (!transform) {
    return columnValue;
  }

  return transform(columnValue);
};

const _formatHeaders = (columns, hasCurrencyColumn) => {
  const headers = columns.map(({ name }) => name);

  if (!hasCurrencyColumn) {
    return headers;
  }

  return [...headers, i18next.t('GENERAL.CURRENCY')];
};

export const generateDefaultSheet = (
  title,
  columns,
  items,
  currency = { numberDecimals: 2 },
  styles = {},
) => {
  const hasCurrencyTypeColumn = columns.some(({ type }) => type === 'currency') && currency;
  const hasAlreadyCurrencyColumn = columns.some(
    ({ name }) => name === i18next.t('GENERAL.CURRENCY'),
  );

  const headers = _formatHeaders(columns, hasCurrencyTypeColumn && !hasAlreadyCurrencyColumn);

  const data = items.map((item) => {
    const content = columns.map((column) => formatRow(item, column, currency.numberDecimals));

    if (hasCurrencyTypeColumn && !hasAlreadyCurrencyColumn) {
      content.push(currency.name);
    }

    return content;
  });

  return {
    data,
    title,
    headers,
    styles,
  };
};

export const generateFiles = (fileName, sheets, contextSheet) => {
  const sheetsKeyByTitle = keyBy(sheets, 'title');

  const hasManyFiles = sheets.some(({ data }) => data.length > MAX_SHEET_ELEMENT_SIZE);

  let generatedFileCount = 1;

  while (Object.values(sheetsKeyByTitle).some(({ data }) => data.length > 0)) {
    const sheetsToCreate = Object.values(sheetsKeyByTitle).map((sheet) => ({
      ...sheet,
      data: sheet.data.splice(0, MAX_SHEET_ELEMENT_SIZE),
    }));

    if (contextSheet) {
      sheetsToCreate.push(contextSheet);
    }

    makeXLS(hasManyFiles ? `${fileName}-${generatedFileCount}` : fileName, sheetsToCreate);

    generatedFileCount++;
  }
};

export const generateDatesSheetFromStartEndDate = (startDate, endDate) => ({
  title: i18next.t('GENERAL.DATES'),
  headers: [
    i18next.t('FORECAST.TURNOVER.EVENTS_MODAL_FIELD_START_DATE'),
    i18next.t('FORECAST.TURNOVER.EVENTS_MODAL_FIELD_END_DATE'),
  ],
  data: [[startDate, endDate]],
});

export const generateStoresSheet = (clientStoreName, stores) => ({
  title: clientStoreName,
  headers: [clientStoreName, i18next.t('BACKOFFICE.CASHIER_STORES.PARTNER_ID')],
  data: stores.map(({ name, partnerId }) => [name, partnerId]),
});

/**
 * Format given sheet range to XLSX number format. Example: 1234,50 turned into 1 234,50
 *
 * @param {WorkSheet} sheet - Concerned sheet who need to be formatted
 * @param {Object} range - Concerned sheet range
 */
const _formatSheetColumnToXLSXNumberFormat = (sheet, range) => {
  const numberFormat = '#,##0.00';

  for (let row = range.s.r; row <= range.e.r; ++row) {
    for (let column = range.s.c; column <= range.e.c; ++column) {
      const cell = sheet[XLSX.utils.encode_cell({ r: row, c: column })];

      // only format numeric cells
      if (!cell || cell.t != 'n') {
        continue;
      }

      cell.z = numberFormat;
    }
  }
};

/**
 * Generate a XLS file filled from a list of sheets settings with the content to feed
 *
 * @param {String} fileName           - name of the file to generate
 * @param {Array} sheets 		          - list of sheets to create
 * @param {string} sheets[].title     - the title of sheet to create
 * @param {string[]} sheets[].headers - the columns name to apply on sheet
 * @param {string[][]} sheets[].data  - the data to feed in the sheet
 * @param {Object} sheets[].formatToNumberColumnIndexes  - column indexes to be formatted into XLSX number format. Object of {start , end}
 *
 *
 * @returns {void}
 */
export function makeXLS(fileName, sheets) {
  const fileNameWithExtension = `${fileName}${XLS_EXTENSION_FILE}`;

  const wb = XLSX.utils.book_new();

  sheets.forEach(({ title, headers, data, formatToNumberColumnIndexes, styles }) => {
    const sheetName = (title || '').substring(0, MAXIMUM_SHEET_NAME_LENGTH);

    wb.SheetNames.push(sheetName);
    wb.Sheets[sheetName] = XLSX.utils.aoa_to_sheet([headers, ...data]);

    if (get(styles, 'cellsHeight', null)) {
      wb.Sheets[sheetName]['!rows'] = styles.cellsHeight;
    }

    if (get(styles, 'cellsWidth', null)) {
      wb.Sheets[sheetName]['!cols'] = styles.cellsWidth;
    }

    if (!!formatToNumberColumnIndexes) {
      const range = {
        s: { r: 1, c: formatToNumberColumnIndexes.start },
        e: { r: data.length, c: formatToNumberColumnIndexes.end },
      };
      _formatSheetColumnToXLSXNumberFormat(wb.Sheets[sheetName], range);
    }
  });

  XLSX.writeFile(wb, fileNameWithExtension);
}

export const makeOrderOrInventoryXLS = (vueTitle, lines, vueDataName, columns, currency) =>
  makeXLS(vueDataName, [generateDefaultSheet(vueTitle, columns, lines, currency)]);

export default {
  makeXLS,
  // Methods to handle sheet generation with correct configurations
  formatRow,
  generateStoresSheet,
  generateDefaultSheet,
  generateDatesSheetFromStartEndDate,
};

import React from 'react';

import { DropdownItem, DropdownItemTitle, DropdownItemSubTitle } from './styledComponents';

const ItemsWithTitleSubtitle = ({ title, subtitle }) => (
  <DropdownItem>
    <DropdownItemTitle>{title}</DropdownItemTitle>
    <DropdownItemSubTitle>{subtitle}</DropdownItemSubTitle>
  </DropdownItem>
);

export default ItemsWithTitleSubtitle;

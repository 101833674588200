import { flatten, get, groupBy, sortBy } from 'lodash';
import { View, Text, Svg, Rect } from '@react-pdf/renderer';
import i18next from 'i18next';
import React from 'react';

import { convertSPPQuantityInMasterUnit } from '@orders/utils/computePackagingToSupplierProduct';

import { sharedContentStyle, supplierProductContentStyle, totalStyle } from './styleSheets';

const SupplierProductsPDFList = (props) => {
  const { supplierProducts, currency, filterBy } = props;

  const sortedSupplierProducts = sortBy(supplierProducts, 'name');

  const groupedSupplierProductsByKey = groupBy(sortedSupplierProducts, (supplierProduct) =>
    get(supplierProduct, filterBy, ''),
  );

  const sortedCategories = Object.keys(groupedSupplierProductsByKey).sort();

  // In order to display the supplier products correctly, we need to group them by entityId
  // and sort them by name afterwards
  const sortedGroupedSPsByKey = sortedCategories.map((key) => {
    const supplierProductsGroupedByEntityId = groupBy(
      groupedSupplierProductsByKey[key],
      'entityId',
    );

    // We need to separate the supplier products that are not associated to any entity (entityId = null)
    // and the ones that are associated
    const unassociatedSupplierProducts = supplierProductsGroupedByEntityId[null] || [];
    const associatedSupplierProducts = Object.entries(supplierProductsGroupedByEntityId).reduce(
      (acc, [key, value]) => {
        if (key !== 'null') {
          acc.push(value);
        }

        return acc;
      },
      [],
    );

    // We group associated supplier products by their entityId, but we do not group those
    // that are not associated
    const formattedSupplierProducts = associatedSupplierProducts.concat(
      unassociatedSupplierProducts,
    );

    // formattedSupplierProducts is an array of either arrays of supplier products or single supplier products
    const sortedSupplierProductsGroupedByEntityId = sortBy(formattedSupplierProducts, (sps) =>
      sps.length ? sps[0].name : sps.name,
    );

    // Index is added to display with the correct background color
    const formattedSupplierProductsGroupedByEntityId = sortedSupplierProductsGroupedByEntityId.map(
      (value, index) =>
        value.length ? value.map((sp) => ({ ...sp, index })) : { ...value, index },
    );

    return flatten(formattedSupplierProductsGroupedByEntityId);
  });

  const groupedSPsByKeyWithCategory = sortedGroupedSPsByKey.reduce((acc, item, index) => {
    const category = sortedCategories[index];

    acc[category] = sortedGroupedSPsByKey[index];

    return acc;
  }, {});

  const SPList = groupedSPsByKeyWithCategory;
  const categories = Object.keys(SPList);
  let nbLinesTotal = 0;

  const SubHeaderCategory = () => (
    <View style={sharedContentStyle.listColumns}>
      <Text style={supplierProductContentStyle.colunmTextSKU}>{i18next.t('GENERAL.SKU')}</Text>
      <View style={sharedContentStyle.borderSide}></View>
      <Text style={supplierProductContentStyle.colunmTextName}>{i18next.t('GENERAL.NAME')}</Text>
      <View style={sharedContentStyle.borderSide}></View>
      <Text style={supplierProductContentStyle.colunmTextSupplier}>
        {i18next.t('GENERAL.SUPPLIER')}
      </Text>
      <View style={sharedContentStyle.borderSide}></View>
      <Text style={supplierProductContentStyle.colunmTextPrice}>
        {i18next.t('STOCKS.STOCKS.INVENTORY_SHEET_PRICE', {
          currencyCode: currency.alphabeticCode,
        })}
      </Text>
      <View style={sharedContentStyle.borderSide}></View>
      <Text style={supplierProductContentStyle.colunmTextCond}>
        {i18next.t('STOCKS.STOCKS.INVENTORY_SHEET_PACKAGING')}
      </Text>
      <View style={sharedContentStyle.borderSide}></View>
      <Text style={sharedContentStyle.colunmTextStock}>
        {i18next.t('STOCKS.STOCKS.INVENTORY_SHEET_STOCK')}
      </Text>
    </View>
  );

  return (
    <View style={sharedContentStyle.contentContainer}>
      <View>
        {categories.map((category, index) => {
          let numberLines = 0;
          return (
            <View key={index}>
              <View style={sharedContentStyle.list}>
                <View wrap={false}>
                  <Text style={sharedContentStyle.listTitle}>{category}</Text>
                  <SubHeaderCategory />
                </View>
                {sortedGroupedSPsByKey[index].map((sp, subIndex) => {
                  {
                    {
                      numberLines = subIndex;
                      nbLinesTotal++;
                    }

                    const indexFirstPackaging = sp.packagings.findIndex(
                      (packaging) => packaging.isUsedInStock,
                    );

                    const invoicePackaging = sp.packagings.find(
                      (packaging) => packaging.isUsedInInvoice,
                    );

                    const nb_packagings = sp.packagings.length - 1;
                    const nb_sp = sortedGroupedSPsByKey[index].length - 1;

                    const supplierName = get(sp, 'lnkSupplierSupplierproductrel.name', '');

                    return sp.packagings.map((packaging, subSubIndex) => {
                      if (!packaging.isUsedInStock) {
                        return (
                          subSubIndex === nb_packagings &&
                          subIndex === nb_sp && (
                            <View
                              key={subSubIndex}
                              style={sharedContentStyle.borderEndOfCategory}
                            ></View>
                          )
                        );
                      } else {
                        let lineStyle;
                        if (indexFirstPackaging === subSubIndex) {
                          lineStyle =
                            sp.index % 2 === 0
                              ? sharedContentStyle.lineContainerEvenWithTopBorder
                              : sharedContentStyle.lineContainerOddWithTopBorder;
                        } else {
                          lineStyle =
                            sp.index % 2 === 0
                              ? sharedContentStyle.lineContainerEvenWithoutTopBorder
                              : sharedContentStyle.lineContainerOddWithoutTopBorder;
                        }
                        return (
                          <View key={subSubIndex}>
                            <View style={lineStyle} wrap={false}>
                              <View style={supplierProductContentStyle.lineTextContainerSKU}>
                                <Text style={sharedContentStyle.lineText}>
                                  {subSubIndex === indexFirstPackaging ? sp.sku : ''}
                                </Text>
                              </View>
                              <View style={supplierProductContentStyle.lineTextContainerName}>
                                <Text style={sharedContentStyle.lineText}>
                                  {subSubIndex === indexFirstPackaging ? sp.name : ''}
                                </Text>
                              </View>
                              <View style={supplierProductContentStyle.lineTextContainerSupplier}>
                                <Text style={sharedContentStyle.lineText}>
                                  {subSubIndex === indexFirstPackaging ? supplierName : ''}
                                </Text>
                              </View>
                              <View style={supplierProductContentStyle.lineTextContainerPrice}>
                                <Text style={sharedContentStyle.lineText}>
                                  {`${Number.parseFloat(
                                    !!invoicePackaging && invoicePackaging.quantity !== 0
                                      ? (sp.price / invoicePackaging.quantity) *
                                          convertSPPQuantityInMasterUnit(
                                            packaging.id,
                                            sp.packagings,
                                          )
                                      : 0,
                                  ).toFixed(currency.numberDecimals)}`}
                                </Text>
                              </View>
                              <View style={supplierProductContentStyle.lineTextContainerCond}>
                                <Text style={sharedContentStyle.lineText}>{packaging.name}</Text>
                              </View>
                              <View style={supplierProductContentStyle.lineTextContainerStock}>
                                <Svg viewBox="0 0 60 15">
                                  <Rect fill="#E2E2E2" height="15" width="60" x="0" />
                                </Svg>
                              </View>
                              {subSubIndex === nb_packagings && subIndex === nb_sp ? (
                                <View
                                  key={subSubIndex}
                                  style={sharedContentStyle.borderEndOfCategory}
                                ></View>
                              ) : (
                                <></>
                              )}
                            </View>
                            {subSubIndex === nb_packagings && subIndex === nb_sp && (
                              <View
                                key={subSubIndex}
                                style={sharedContentStyle.borderEndOfCategory}
                              ></View>
                            )}
                          </View>
                        );
                      }
                    });
                  }
                })}
                <Text style={sharedContentStyle.numberLineText}>{`${i18next.t(
                  'STOCKS.STOCKS.INVENTORY_SHEET_NUMBER_LINES',
                )} : ${numberLines + 1}`}</Text>
              </View>
            </View>
          );
        })}
      </View>
      <View style={totalStyle.globalContainer} wrap={false}>
        <View style={totalStyle.totalTitleContainer}>
          <Text style={sharedContentStyle.listTitle}>Total</Text>
        </View>
        <View style={totalStyle.totalContainer}>
          <Text style={sharedContentStyle.numberLineText}>{`${i18next.t(
            'STOCKS.STOCKS.INVENTORY_SHEET_NUMBER_LINES',
          )} : ${nbLinesTotal}`}</Text>
        </View>
      </View>
    </View>
  );
};

export default SupplierProductsPDFList;

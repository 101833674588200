/**
 * Maybe at one point refacto the following with CentralKitchenProductionPlanning/utils/columns.js
 **/

import { GridEditInputCell } from '@mui/x-data-grid';
import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import theme from '@theme';

import { convertUnits, convertEntityQuantityToInpulseUnit } from '@commons/utils/conversion';
import { formatNumber } from '@commons/DisplayNumber';
import { isBetweenDays, isSameOrAfterDay, isSameOrBeforeDay } from '@commons/utils/date';
import Text, { ENUM_FONTS, ENUM_COLORS } from '@commons/Text';

import { HeaderIcon } from '@productions/CentralKitchenProductionPlanning/styledComponents';

import { COLUMN_TYPES } from './constants';
import {
  Name,
  NameHover,
  NameCellContainer,
  RoundPicture,
  Sku,
  RowContainer,
  RecipeBatchProductionRowsContainer,
} from './styledComponents';

const DEFAULT_PRODUCT_PICTURE = '/images/inpulse/product-photo-placeholder.svg';
const DEFAULT_RECIPE_PICTURE = '/images/inpulse/recipe-photo-placeholder.svg';
const DEFAULT_INGREDIENT_PICTURE = '/images/inpulse/ingredient-photo-placeholder.svg';
const MAXIMUM_NAME_LENGTH = 22;

const DEFAULT_IMAGE = {
  [COLUMN_TYPES.PRODUCTS]: DEFAULT_PRODUCT_PICTURE,
  [COLUMN_TYPES.RECIPES]: DEFAULT_RECIPE_PICTURE,
  [COLUMN_TYPES.INGREDIENTS]: DEFAULT_INGREDIENT_PICTURE,
};

export const isCellEditable = (launchDate, endDate, field) => {
  if (!launchDate && !endDate) {
    return true;
  }

  const currentDate = moment(field);

  if (!launchDate && endDate) {
    return isSameOrBeforeDay(currentDate, endDate);
  }

  if (!endDate && launchDate) {
    return isSameOrAfterDay(currentDate, launchDate);
  }

  return isBetweenDays(currentDate, launchDate, endDate);
};

export const renderNameCell = (name, type, { sku = null, withImg = false, imgSrc = null } = {}) => {
  const [displayBigger, setDisplayBigger] = useState(false);

  const img = imgSrc || DEFAULT_IMAGE[type];

  return (
    <NameCellContainer>
      {withImg && <RoundPicture src={img} />}
      <Stack>
        <Name onMouseOver={() => setDisplayBigger(true)}>{name}</Name>
        {displayBigger && name.length > MAXIMUM_NAME_LENGTH && (
          <NameHover offsetName onMouseOut={() => setDisplayBigger(false)}>
            {name}
          </NameHover>
        )}
        {sku && <Sku>{sku}</Sku>}
      </Stack>
    </NameCellContainer>
  );
};

export const renderCategoryCell = (name) => {
  const [displayBigger, setDisplayBigger] = useState(false);

  const formattedCategoryName = name || i18next.t('GENERAL.NONE_VALUE');

  return (
    <NameCellContainer>
      <Name onMouseOver={() => setDisplayBigger(true)}>{formattedCategoryName}</Name>
      {displayBigger && name.length > MAXIMUM_NAME_LENGTH && (
        <NameHover offsetCategory onMouseOut={() => setDisplayBigger(false)}>
          {formattedCategoryName}
        </NameHover>
      )}
    </NameCellContainer>
  );
};

export const renderStandardHeader = (props) => {
  const { colDef } = props;

  return (
    <NameCellContainer>
      <Text font={ENUM_FONTS.TEXT_MIDDLE_NORMAL}>{colDef.headerName}</Text>
    </NameCellContainer>
  );
};

export const renderInputHeader = (props, hasSavedDates = false) => {
  const { colDef } = props;

  return (
    <NameCellContainer>
      <Text font={ENUM_FONTS.TEXT_MIDDLE_NORMAL}>{colDef.headerName}</Text>

      <Tooltip
        placement="top"
        title={
          <Text color={ENUM_COLORS.LIGHT}>
            {i18next.t(
              hasSavedDates
                ? 'FORECAST.POS_PRODUCTION_PLANNING.MANAGER_FORECAST_TOOLTIP'
                : 'FORECAST.POS_PRODUCTION_PLANNING.INPULSE_FORECAST_TOOLTIP',
            )}
          </Text>
        }
        arrow
      >
        <HeaderIcon
          src={
            hasSavedDates
              ? '/images/inpulse/manager-forecast.svg'
              : '/images/inpulse/inpulse-forecast.svg'
          }
        />
      </Tooltip>
    </NameCellContainer>
  );
};

/**
 *  Used for an input cell of products table
 * */
export const renderInputCell = (props) => {
  const {
    isEditable,
    value,
    field,
    row: { savedProductionPlanningDates: savedDates, launchDate, endDate },
  } = props;

  const hasBeenEdited = savedDates.includes(field);

  const formattedValue = value ? Math.round(value) : value;

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      sx={{ width: '100%', height: '100%', cursor: isEditable && 'pointer', fontSize: 14 }}
    >
      <Text font={ENUM_FONTS.TEXT_MIDDLE_NORMAL}>
        {isCellEditable(launchDate, endDate, field) ? formattedValue : '-'}
      </Text>
      {isEditable && (
        <img
          src={
            hasBeenEdited
              ? '/images/inpulse/edited-pen-black-small.svg'
              : '/images/inpulse/edit-pen-black-small.svg'
          }
        />
      )}
    </Stack>
  );
};

// We have to do this because the sx props doesn't work in this case
const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    border: `1px solid ${theme.colors.infoRed}`,
    backgroundColor: theme.colors.greys.lightest,
  },
}));

export const renderEditInputCell = (props) => {
  const { error } = props;

  return (
    <StyledTooltip
      {...props}
      open={!!error}
      title={
        <Text color={ENUM_COLORS.INFO_RED}>
          {i18next.t('FORECAST.POS_PRODUCTION_PLANNING.CELL_INPUT_ERROR')}
        </Text>
      }
    >
      <GridEditInputCell
        {...props}
        sx={error ? { height: '100%', color: theme.colors.infoRed } : {}}
      />
    </StyledTooltip>
  );
};

const TotalStack = ({ children }) => (
  <Stack
    alignItems="center"
    direction="row"
    justifyContent="space-between"
    sx={{ width: '100%', height: '100%' }}
  >
    {children}
  </Stack>
);

export const renderTotalCell = (props, type) => {
  const { value, row } = props;

  // convertUnits will always return undefined for products
  const convertedUnit = convertUnits(row.unit, true) || i18next.t('GENERAL.MINIFIED_UNIT');

  const font =
    type === COLUMN_TYPES.RECIPES ? ENUM_FONTS.TEXT_SMALL_HEIGHT_16 : ENUM_FONTS.TEXT_MIDDLE_NORMAL;

  if (value === null) {
    return (
      <TotalStack>
        <Text font={font}>{`- ${convertedUnit}`}</Text>
      </TotalStack>
    );
  }

  if (value === 0) {
    // When stricly 0, ignore decimals, ignore batch production
    return (
      <TotalStack>
        <Text font={font}>{`0 ${convertedUnit}`}</Text>
      </TotalStack>
    );
  }

  const convertedTotal = convertEntityQuantityToInpulseUnit(value, row.unit);
  const formattedTotal =
    type === COLUMN_TYPES.PRODUCTS ? Math.round(convertedTotal) : formatNumber(convertedTotal, 2);

  if (row.batchProduction) {
    const convertedQuantity = formatNumber(
      convertEntityQuantityToInpulseUnit(row.quantity, row.unit),
      2,
    );

    return (
      <RecipeBatchProductionRowsContainer>
        <Text font={font}>{`${formattedTotal} ${convertedUnit}`}</Text>
        <Text color={ENUM_COLORS.IP_BLACK_5} font={font}>{`(${formatNumber(
          convertedTotal / convertedQuantity,
          2,
        )} x ${convertedQuantity} ${convertedUnit})`}</Text>
      </RecipeBatchProductionRowsContainer>
    );
  }

  return (
    <TotalStack>
      <Text font={font}>{`${formattedTotal} ${convertedUnit}`}</Text>
    </TotalStack>
  );
};

export const renderRecipeQuantityCell = ({ value, row }) => {
  const convertedUnit = convertUnits(row.unit, true, value);
  const convertedValue = convertEntityQuantityToInpulseUnit(value, row.unit);

  const formattedValue =
    value != null && !isNaN(convertedValue) ? formatNumber(convertedValue, 2) : '-';

  if (!row.batchProduction) {
    return (
      <RowContainer>
        <Text font={ENUM_FONTS.TEXT_SMALL_HEIGHT_16}>{`${formattedValue} ${convertedUnit}`}</Text>
      </RowContainer>
    );
  }

  const convertedQuantity = formatNumber(
    convertEntityQuantityToInpulseUnit(row.quantity, row.unit),
    2,
  );

  return (
    <RecipeBatchProductionRowsContainer>
      <Text font={ENUM_FONTS.TEXT_SMALL_HEIGHT_16}>{`${formattedValue} ${convertedUnit}`}</Text>
      {value != null && !!convertedQuantity && (
        <Text
          color={ENUM_COLORS.IP_BLACK_5}
          font={ENUM_FONTS.TEXT_SMALL_HEIGHT_16}
        >{`(${formatNumber(
          convertedValue / convertedQuantity,
          2,
        )} x ${convertedQuantity} ${convertedUnit})`}</Text>
      )}
    </RecipeBatchProductionRowsContainer>
  );
};

export const renderIngredientQuantityCell = ({ value, row }) => {
  const convertedValue = convertEntityQuantityToInpulseUnit(value, row.unit);
  const convertedUnit = convertUnits(row.unit, true, value);

  const formattedValue =
    value != null && !isNaN(convertedValue) ? formatNumber(convertedValue, 2) : '-';

  return (
    <RowContainer>
      <Text font={ENUM_FONTS.TEXT_MIDDLE_NORMAL}>{`${formattedValue} ${convertedUnit}`}</Text>
    </RowContainer>
  );
};

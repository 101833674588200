import { get } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';

// Modal
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_IMPORT_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';

// Commons
import { DATE_DISPLAY_FORMATS as DATE_DISPLAY_FORMAT } from '@commons/DatePickers/constants';
import {
  downloadFile,
  mapColumnIdsAndNormalizedColumnIds,
  normalizeJsonObject,
} from '@backoffice/utils';
import { getUserTimezone } from '@commons/utils/date';
import ImportExcelFileModal from '@backoffice/components/ImportExcelFileModal';
import importTypeXlsConstants from '@commons/Excels/constants/importType.xslx.constant';
import ordersXlsConstants from '@commons/Excels/constants/orders.xlsx.constant';

/*********************/
/* Import formatting */
/*********************/

export const formatOrderBatchCreationFile = (ordersWorkbook) => {
  const normalizedOrdersWorkbook = normalizeJsonObject(ordersWorkbook);

  return Object.keys(normalizedOrdersWorkbook).reduce(
    (acc, sheetName) => {
      if (!acc[importTypeXlsConstants.KEY]) {
        acc[importTypeXlsConstants.KEY] = get(
          normalizedOrdersWorkbook,
          'ImportType[0].importtype',
          null,
        );
      }

      // Orders sheet
      if (sheetName === ordersXlsConstants.SHEET.NAME) {
        const USER_TIMEZONE = getUserTimezone();

        acc[ordersXlsConstants.SHEET.ID] = normalizedOrdersWorkbook[sheetName].map((row) => ({
          ...mapColumnIdsAndNormalizedColumnIds(row, ordersXlsConstants.COLUMN_IDS),
          // Adding one hour because xlsx parsing remove approximately 30 minutes from a date and there's no real fix for it
          // except this quickfix, see issue: https://github.com/SheetJS/sheetjs/issues/1565
          [ordersXlsConstants.COLUMN_IDS.START_ORDER_DATE.key]: moment
            .tz(
              row[ordersXlsConstants.COLUMN_IDS.START_ORDER_DATE.normalizedKey],
              // Expected format
              [
                DATE_DISPLAY_FORMAT.SLASHED_DAY_MONTH_YEAR,
                DATE_DISPLAY_FORMAT.DASHED_DAY_MONTH_YEAR,
              ],
              USER_TIMEZONE,
            )
            .add(1, 'hour')
            .format(),
        }));
      }

      // Stores sheet
      if (sheetName === ordersXlsConstants.HIDDEN_SHEET_STORES.NAME) {
        acc[ordersXlsConstants.HIDDEN_SHEET_STORES.ID] = normalizedOrdersWorkbook[sheetName].map(
          (row) => ({
            [ordersXlsConstants.HIDDEN_COLUMN_IDS.ID]:
              row[ordersXlsConstants.HIDDEN_STORES_NORMALIZE_COLUMN_IDS.ID],
            [ordersXlsConstants.HIDDEN_COLUMN_IDS.NAME]: row[
              ordersXlsConstants.HIDDEN_STORES_NORMALIZE_COLUMN_IDS.NAME
            ].replace(/\s/g, ' '),
          }),
        );
      }

      // Supplier sheet
      if (sheetName === ordersXlsConstants.HIDDEN_SHEET_SUPPLIERS.NAME) {
        acc[ordersXlsConstants.HIDDEN_SHEET_SUPPLIERS.ID] = normalizedOrdersWorkbook[sheetName].map(
          (row) => ({
            [ordersXlsConstants.HIDDEN_COLUMN_IDS.ID]:
              row[ordersXlsConstants.HIDDEN_SUPPLIERS_NORMALIZE_COLUMN_IDS.ID],
            [ordersXlsConstants.HIDDEN_COLUMN_IDS.NAME]: row[
              ordersXlsConstants.HIDDEN_SUPPLIERS_NORMALIZE_COLUMN_IDS.NAME
            ].replace(/\s/g, ' '),
          }),
        );
      }

      return acc;
    },
    {
      userTimezone: getUserTimezone(),
    },
  );
};

/****************************/
/* Step modal configuration */
/****************************/

const defaultModalConfig = {
  width: '542px',
  height: 'auto',
  component: ImportExcelFileModal,
};

export const IMPORT_MODAL_STEPS = {
  SELECT_FILE: 'select-file',
  VALIDATE_FILE: 'validate-file',
  ERROR_FILE: 'error-file',
  VALIDATED_FILE: 'validated-file',
};

export const exportOrdersImportErrors = (data) =>
  downloadFile(data, i18next.t('ORDERS.ORDERS.IMPORT_TEMPLATE_BATCH_ERROR_FILENAME'), true, false);

export const getImportOrdersModalConfig = (step, modalConfig) => {
  switch (step) {
    case IMPORT_MODAL_STEPS.SELECT_FILE:
      return getSelectFileConfig(modalConfig);
    case IMPORT_MODAL_STEPS.VALIDATE_FILE:
      return getValidateFileConfig(modalConfig);
    case IMPORT_MODAL_STEPS.VALIDATED_FILE:
      return getValidatedFileConfig(modalConfig);
    case IMPORT_MODAL_STEPS.ERROR_FILE:
      return getErrorFileConfig(modalConfig);
    default:
      return defaultModalConfig;
  }
};

const getSelectFileConfig = (modalConfig) => {
  const { selectedFile, handleFileChange } = modalConfig;

  return {
    ...defaultModalConfig,
    type: 'action',
    title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_BATCH_TITLE'),
    icon: '/images/inpulse/file-upload-black-small.svg',
    data: {
      subTitle: i18next.t('ORDERS.ORDERS.IMPORT_TEMPLATE'),
      selectedFile,
      setSelectedFile: (file) => handleFileChange(file),
    },
    actions: [
      {
        ...GENERIC_MODAL_CANCEL_BUTTON(),
        handleClick: () => handleFileChange(null),
      },
      {
        ...GENERIC_MODAL_IMPORT_BUTTON(),
        isDisabled: true,
        handleClick: () => false,
      },
    ],
  };
};

const getValidateFileConfig = (modalConfig) => {
  const { updatedFile, handleFileChange, handleFileValidation } = modalConfig;

  return {
    ...defaultModalConfig,
    type: 'action',
    title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_BATCH_TITLE'),
    icon: '/images/inpulse/file-upload-black-small.svg',
    data: {
      subTitle: i18next.t('ORDERS.ORDERS.IMPORT_TEMPLATE'),
      selectedFile: updatedFile,
      setSelectedFile: (file) => handleFileChange(file),
    },
    actions: [
      {
        ...GENERIC_MODAL_CANCEL_BUTTON(),
        handleClick: () => handleFileChange(null),
      },
      {
        ...GENERIC_MODAL_IMPORT_BUTTON(),
        handleClick: () => handleFileValidation(updatedFile),
      },
    ],
  };
};

const getValidatedFileConfig = (modalConfig) => {
  const { updatedFile = null, handleFileImport } = modalConfig;

  return {
    ...defaultModalConfig,
    type: 'success',
    title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_BATCH_TITLE'),
    icon: '/images/inpulse/check-white-small.svg',
    data: {
      validatedFile: true,
      selectedFile: updatedFile,
    },
    actions: [
      {
        ...GENERIC_MODAL_IMPORT_BUTTON(),
        handleClick: () => handleFileImport(),
      },
    ],
  };
};

const getErrorFileConfig = (modalConfig) => {
  const { updatedFile, handleFileChange, exportOrdersImportErrors, errorFile, status } =
    modalConfig;

  const subTitle = i18next.t(
    status === 422
      ? 'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_SELECT_FILE_ERROR_SUBTITLE'
      : 'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_SELECT_FILE_ERROR_CONTENT',
  );

  const additionalText = i18next.t(
    status === 422
      ? 'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_BATCH_SELECT_FILE_UNVALIDATED_LINES_ERROR_ADDITIONAL_TEXT'
      : 'ORDERS.ORDERS.IMPORT_TEMPLATE_BATCH_SELECT_FILE_ERROR_ADDITIONAL_TEXT',
  );

  const configData = {
    subTitle,
    additionalText,
    validatedFile: false,
    selectedFile: updatedFile,
  };

  return {
    ...defaultModalConfig,
    type: 'error',
    title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_BATCH_SELECT_FILE_ERROR_TITLE'),
    icon: '/images/inpulse/info-white-small.svg',
    data:
      status === 422
        ? { ...configData, exportXLS: () => exportOrdersImportErrors(errorFile) }
        : configData,
    actions: [
      {
        ...GENERIC_MODAL_CANCEL_BUTTON(),
        handleClick: () => {
          handleFileChange(null);
        },
      },
    ],
  };
};

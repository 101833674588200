import { GridToolbar } from '@mui/x-data-grid-pro';

import { COLUMNS } from './constants';
import { CustomColumnMenu, CustomMenuIcon } from './customMenu';

// See https://mui.com/x/react-data-grid/style/#using-the-sx-prop for reference
const DATAGRID_STYLE = {
  maxHeight: 'calc(100% - 48px)',
  height: 'auto',
  minWidth: '886px',
  marginBottom: '24px',
};

// See https://mui.com/x/api/data-grid/data-grid-pro/ for reference
const DATAGRID_OTHER_PROPS = {
  autosizeOnMount: true,
  disableColumnResize: true,
  disableColumnSelector: true,
  disableDensitySelector: true,
  disableRowSelectionOnClick: true,
  hideFooter: true,
  ignoreDiacritics: true,
  showCellVerticalBorder: true,
  showColumnVerticalBorder: true,
};

const DATAGRID_INITIAL_STATE = {
  pinnedColumns: {
    left: [COLUMNS.NAME, COLUMNS.CATEGORY],
    right: [COLUMNS.TOTAL],
  },
  columns: {
    columnVisibilityModel: {
      sku: false,
    },
  },
  sorting: {
    sortModel: [
      { field: COLUMNS.CATEGORY, sort: 'asc' },
      { field: COLUMNS.NAME, sort: 'asc' },
    ],
  },
  filter: {
    filterModel: {
      items: [
        { columnField: COLUMNS.SKU, operatorValue: 'contains', value: '' },
        { columnField: COLUMNS.NAME, operatorValue: 'contains', value: '' },
      ],
    },
  },
};

const DATAGRID_SLOTS = {
  toolbar: GridToolbar,
  columnMenu: CustomColumnMenu,
  columnMenuIcon: CustomMenuIcon,
};

const DATAGRID_SLOT_PROPS = {
  toolbar: {
    showQuickFilter: true,
    printOptions: { disableToolbarButton: true },
    csvOptions: { disableToolbarButton: true },
  },
};

export const COMMON_DATAGRID_PROPS = {
  initialState: DATAGRID_INITIAL_STATE,
  sx: DATAGRID_STYLE,
  slots: DATAGRID_SLOTS,
  slotProps: DATAGRID_SLOT_PROPS,
  ...DATAGRID_OTHER_PROPS,
};

import i18next from 'i18next';
import React from 'react';

import WysiwygEditor from '@commons/WysiwygEditor';

import { ContainerTextArea, SubTitleWhiteCard } from '../../styledComponents';

import {
  EdiStateContainer,
  IconContainer,
  SubtitleContainer,
  TitleContainer,
} from './styledComponents';

const EDI_SEND_ICON = '/images/inpulse/send-edi-state.svg';

export const EdiSummaryPanel = (props) => {
  const { ediPartner, wysiwygContent, isWysiwygDisabled, setWysiwygContent } = props;

  const displayPurchaseOrderComment = ediPartner === 'SELLSY';

  return (
    <EdiStateContainer>
      {!displayPurchaseOrderComment && (
        <IconContainer>
          <img alt="edi-icon" src={EDI_SEND_ICON} />
        </IconContainer>
      )}
      {displayPurchaseOrderComment && (
        <ContainerTextArea style={{ marginBottom: '24px' }}>
          <SubTitleWhiteCard>{i18next.t('ORDERS.ORDERS.CART_COMMENT_PN')} :</SubTitleWhiteCard>
          <>
            <WysiwygEditor
              content={wysiwygContent}
              isDisabled={isWysiwygDisabled}
              setter={setWysiwygContent}
              onlyReturnText
            />
          </>
        </ContainerTextArea>
      )}
      <TitleContainer>{i18next.t('ORDERS.ORDERS.SUMMARY_SEND_EDI_STATE_TITLE')}</TitleContainer>
      <SubtitleContainer>
        {i18next.t('ORDERS.ORDERS.SUMMARY_SEND_EDI_STATE_SUBTITLE')}
      </SubtitleContainer>
    </EdiStateContainer>
  );
};
export default EdiSummaryPanel;

import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font: ${(props) => props.theme.fonts.h1InterNonBold};
  color: ${(props) => props.theme.colors.greys.darkest};
`;

export const Subtitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font: ${(props) => props.theme.fonts.h3Bold700};
  color: ${(props) => props.theme.colors.greys.dark};
`;

export const HeaderContainer = styled.div`
  flex: 0 0 auto;
`;

export const ContentContainer = styled.div`
  flex: 1 1 auto;
  padding: 24px 0;
  overflow: auto;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  padding-top: 24px;
  border-top: 2px solid ${(props) => props.theme.colors.greys.light};
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TableBorder = styled.div`
  width: 100%;
  height: 100%;

  border-radius: 8px;
  border: solid 1px ${(props) => props.theme.colors.greys.lighty};

  background-color: ${(props) => props.theme.colors.greys.lightest};
`;

export const TextContent = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200;
`;

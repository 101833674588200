import { connect } from 'react-redux';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage } from '@actions/messageconfirmation';

import StoreAssociatedUsers from './StoreDetailsModules/StoreAssociatedUsers';
import StoreInformations from './components/StoreInformations';
import StoreProductMappings from './StoreDetailsModules/StoreProductMappingList';
import StoreSupplierProductMapping from './StoreDetailsModules/StoreSupplierProductMappingList';
import StoreSupplierProfileMappings from './StoreDetailsModules/StoreSupplierProfileMappingList';

import centralService from '@services/central';
import storeService from '@services/store';

import { ENUM_MODULE_NAME } from '../../../commons/utils/features';
import NavigationBar from '../../../commons/NavigationBar';

import { DAYS } from '../utils/DropdownItems';

import { ContentContainer } from './styledComponents';

const PATHS_AND_PAGES = {
  '/admin/stores/:id/details': 'details',
  '/admin/stores/:id/details-users': 'details-users',
  '/admin/stores/:id/products': 'products',
  '/admin/stores/:id/supplierProfiles': 'supplierProfiles',
  '/admin/stores/:id/supplier-products': 'supplier-products',
};

const StoreDetails = (props) => {
  const {
    history,
    match: {
      path,
      params: { id: storeId },
    },
    pageLoaded,
    pageLoading,
    showErrorMessage,
  } = props;

  const [store, setStore] = useState({
    name: '',
    city: '',
    adress: '',
    country: '',
    postCode: '',
    partnerId: '',
    telephone: '',
    active: false,
    closingDays: [],
  });

  const [selectedPage, setSelectedPage] = useState({});

  useEffect(() => {
    if (!storeId) {
      return;
    }

    getStoreById(storeId);
  }, [storeId]);

  useEffect(() => {
    if (!path) {
      return;
    }

    setSelectedPage(getSelectedPageFromPath(path));
  }, [path]);

  const getStoreById = async (id) => {
    pageLoading();

    try {
      const fetchedStore = await storeService.getById(id);

      if (fetchedStore.isKitchen) {
        fetchedStore.supplier = await centralService.getkitchenSupplierByStoreIds([
          fetchedStore.id,
        ]);
      }

      const storeClosingDays = (fetchedStore.closingDays || '').split(',');

      fetchedStore.closingDays = DAYS().filter(({ itemValue }) =>
        storeClosingDays.includes(itemValue.toString()),
      );

      setStore(fetchedStore);
    } catch (err) {
      showErrorMessage(i18next.t('ADMIN.STORES.PRODUCT_MAPPING_LOADING_ERROR')); // using existing translation key

      history.goBack();
    } finally {
      pageLoaded();
    }
  };

  const getSelectedPageFromPath = (path) => {
    if (!path || typeof path !== 'string') {
      return null;
    }

    const storeDetailPaths = Object.keys(PATHS_AND_PAGES);

    const matchingPath = storeDetailPaths.find((item) => path === item);

    if (!matchingPath) {
      return null;
    }

    return PATHS_AND_PAGES[matchingPath];
  };

  return (
    <>
      <NavigationBar
        module={ENUM_MODULE_NAME.STORE_DETAIL}
        path={path}
        storeId={store.id}
        storeName={
          store.isKitchen
            ? i18next.t('ADMIN.STORES.NAVIGATION_BAR_TITLE_KITCHEN', { storeName: store.name })
            : i18next.t('ADMIN.STORES.NAVIGATION_BAR_TITLE_SALE_POINT', { storeName: store.name })
        }
      />
      {selectedPage === 'details' && store.id && (
        <ContentContainer>
          <StoreInformations storeParams={store} />
        </ContentContainer>
      )}
      {selectedPage === 'details-users' && store.id && <StoreAssociatedUsers storeParams={store} />}
      {selectedPage === 'products' && store.id && (
        <StoreProductMappings history={props.history} match={props.match} storeParams={store} />
      )}
      {selectedPage === 'supplierProfiles' && store.id && (
        <StoreSupplierProfileMappings history={props.history} storeParams={store} />
      )}
      {selectedPage === 'supplier-products' && store.id && (
        <StoreSupplierProductMapping history={props.history} storeParams={store} />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(null, mapDispatchToProps)(StoreDetails);

import axios from '../core/http';

import { APIcaller } from './APIcaller';
import { config } from '../config';
const baseApiUrl = config.baseApiUrl;

const getCashierProductsByClientId = async (
  clientId,
  options = {
    withInactive: false,
    withHidden: false,
    withAlreadyAssociated: false,
  },
) =>
  APIcaller.apiGet(`/admin/cashier-products`, {
    clientId,
    ...options,
  });

const getCashierProductsWithProductMatchingScores = async (productId) =>
  APIcaller.apiGet(`/admin/cashier-products-with-product-matching-scores`, {
    productId,
  });

const getCashierProductsWithProductMappingScoresByProductName = async (
  productName,
  cashierProductNames,
  clientId,
) =>
  APIcaller.apiPost(`/admin/cashier-products-names-with-product-mapping-scores`, {
    productName,
    cashierProductNames,
    clientId,
  });

const getCashierProductTagsByClientId = async (clientId) => {
  try {
    const { data } = await axios.get(`${baseApiUrl}/admin/cashier-products/tags`, {
      params: {
        clientId,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    });
    return data;
  } catch (error) {
    return [];
  }
};

const getPaginatedCashierProductsByClientId = (clientId, applyFilter, queryParams, getAll) => {
  let requestUri = `${baseApiUrl}/admin/cashier-products/${clientId}/get-paginated-list?`;

  if (queryParams.skip != null) {
    requestUri = requestUri.concat('skip=', `${queryParams.skip}&`);
  }

  if (queryParams.limit != null) {
    requestUri = requestUri.concat('limit=', `${queryParams.limit}&`);
  }

  if (queryParams.orderBy != null) {
    requestUri = requestUri.concat('orderBy=', `${queryParams.orderBy}&`);
  }

  if (queryParams.orderType != null) {
    requestUri = requestUri.concat('orderType=', `${queryParams.orderType}&`);
  }

  if (queryParams.search) {
    requestUri = requestUri.concat('search=', `${queryParams.search}&`);
  }

  requestUri = requestUri.replace(/(&)$/, '');

  if (queryParams.params) {
    requestUri = requestUri.concat(queryParams.params);
  }

  return axios
    .post(requestUri, {
      ...applyFilter,
      search: queryParams.search,
      getAll,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
};

function updateCashierProduct(cashierProduct) {
  return axios
    .patch(
      `${baseApiUrl}/admin/cashier-products/${cashierProduct.id}`,
      {
        cashierProduct,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function updateCashierProductByBatch(cashierProducts) {
  return axios
    .put(
      `${baseApiUrl}/admin/cashier-products`,
      {
        cashierProducts,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

const syncCashierProduct = (clientId) =>
  axios
    .post(
      `${baseApiUrl}/admin/cashier-products/sync`,
      {
        clientId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

const getCashierProductSyncStatus = (clientId) =>
  axios
    .get(`${baseApiUrl}/admin/cashier-products/sync/${clientId}/status`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

function getMostConsumedCashierProductsByClientId(clientId, startDate, endDate, status) {
  return axios
    .get(`${baseApiUrl}/backoffice/products/export-most-consumed-cashier-products`, {
      params: {
        clientId,
        startDate,
        endDate,
        status,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}
const getSimilarityScoresForMappingCashierProductProduct = async (clientId) =>
  APIcaller.apiPost('/admin/cashier-products/similarity-scores-with-products', {
    clientId,
  });

export const cashierProductService = {
  syncCashierProduct,
  updateCashierProduct,
  getCashierProductSyncStatus,
  updateCashierProductByBatch,
  getCashierProductsByClientId,
  getCashierProductTagsByClientId,
  getPaginatedCashierProductsByClientId,
  getMostConsumedCashierProductsByClientId,
  getCashierProductsWithProductMatchingScores,
  getSimilarityScoresForMappingCashierProductProduct,
  getCashierProductsWithProductMappingScoresByProductName,
};

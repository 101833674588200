import styled, { css } from 'styled-components';

export const SupplierProfileModalContainer = styled.div`
  height: 100%;
  width: 100%;

  & .medium-modal-form-header {
    width: 100%;
    height: 88px;

    padding: 36px 0;

    background-color: ${(props) => props.theme.colors.greys.lightest};
  }

  & .medium-modal-form-header-title {
    margin-left: 14px;

    color: ${(props) => props.theme.colors.greys.darkest};
    font: ${(props) => props.theme.fonts.kpiSmall};
  }

  & .medium-modal-form-footer {
    width: 100%;
    height: 88px;

    position: absolute;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: end;

    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
    background-color: ${(props) => props.theme.colors.greys.lightest};
    z-index: 5;
  }

  & .medium-modal-form-main-container {
    width: 100%;
    height: ${(props) => (props.hasCustomStyle ? 'auto' : 'calc(100% - 176px)')};

    overflow: auto;
    background-color: ${(props) => props.theme.colors.greys.lighter};
  }

  & .medium-modal-form-category-block {
    margin: 24px;
    padding: 24px;

    background-color: ${(props) => props.theme.colors.greys.lightest};
    border-radius: 16px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  }

  & .medium-modal-form-category-header {
    margin-bottom: 24px;

    font: ${(props) => props.theme.fonts.h2};
  }

  & .medium-modal-form-category-body {
    > :not(:last-child) {
      margin-bottom: 24px;
    }
  }

  & .medium-modal-form-category-body-row {
    display: flex;

    & > :not(:last-child) {
      margin-right: 35px;
    }
  }

  & .item-list-searchbar {
    max-width: 465px;
    display: flex;
    align-items: center;
  }

  & .medium-modal-associations-header-encloser {
    height: 64px;
    width: 100%;

    padding: 0px 24px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  & .item-list-content-checkbox {
    padding: 24px 0px 24px 3px;

    display: flex;
    align-items: center;

    z-index: 1;
  }

  & .title-item-encloser {
    display: flex;
    align-items: center;

    color: ${(props) => props.theme.colors.greys.darker};
    font: normal 500 14px/17px inter;
  }

  & .medium-modal-associations-encloser {
    height: calc(100% - 150px);

    padding: 0px 24px;

    overflow: auto;

    & > .list-item-line-encloser:last-child {
      margin-bottom: 24px;
    }
  }

  & .list-item-line-encloser {
    width: 100%;
    height: 64px;

    margin-bottom: 8px;

    display: flex;
    justify-content: flex-start;

    background-color: ${(props) => props.theme.colors.greys.lightest};
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
  }

  & .list-item-line-element {
    height: 100%;

    display: flex;
    align-items: center;

    color: ${(props) => props.theme.colors.greys.darkest};
    font: ${(props) => props.theme.fonts.textBig};

    overflow: hidden;
  }

  & .sp-properties-toggle-form-label {
    margin-top: 3px;
    margin-left: 30px;
  }
`;

export const FooterButtonContainer = styled.div`
  display: flex;

  padding: 24px 0px;

  & > * {
    margin-right: 24px;
  }
`;

export const BodyRow = styled.div`
  width: ${(props) =>
    props.childCount === 3
      ? '100%'
      : `calc((100% / 3) * ${props.childCount} - (24px / ${props.childCount}))`};
`;

export const InputContainer = styled.div`
  flex: 1;

  ${(props) =>
    props.isError &&
    css`
      display: flex;
      align-items: center;
    `}
`;

export const DeliveryOptionsRow = styled.div`
  width: 100%;
`;

export const DeliveryOptionsInputContainer = styled.div`
  flex: ${(props) => props.flex};

  margin-right: ${(props) => props.marginRight};

  ${(props) =>
    props.inpulse &&
    props.display &&
    css`
      display: ${props.display};
    `}
`;

export const ErrorMessage = styled.div`
  ${(props) =>
    props.margin
      ? css`
          margin: ${props.margin};
        `
      : css`
          margin-left: 15px;
        `}

  color: ${(props) => props.theme.colors.infoRed};
  font: ${(props) => props.theme.fonts.textMiddleBold};
`;

export const SearchBarContainer = styled.div`
  margin: 24px 0px 3px 24px;
`;

export const DeleteText = styled.div`
  margin-left: 24px;

  color: ${(props) => props.theme.colors.greys.darkest};
  font: ${(props) => props.theme.fonts.textBig};
`;

export const PropertyContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  width: 240px;
  height: 64px;
  gap: 8px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 24px;
`;

export const SupplierModalAssociationsBodyContainer = styled.div`
  width: 100%;
  padding: 24px;
  overflow: hidden;
  height: calc(100% - 176px);
`;

export const StoreListViewContainer = styled.div`
  position: relative;

  width: 100%;
  height: calc(100% + 15px);

  > div {
    position: absolute;

    overflow: auto;

    width: 100%;
    height: 100%;
  }
`;

export const WildCard = styled.span`
  color: ${(props) =>
    props.isDisabled ? props.theme.colors.greys.dark : props.theme.colors.infoRed};
  padding-right: 2px;
`;

export const BlockContainer = styled.div`
  margin: 24px;
  padding: 24px;

  background-color: ${(props) => props.theme.colors.greys.lightest};
  border-radius: 16px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
`;

export const BlockHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;

  font: ${(props) => props.theme.fonts.h2};
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 24px;
  margin-top: 12px;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RowInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 240px;
`;

export const RightSide = styled.div`
  display: flex;
  width: 100%;
`;

export const LeadTimeContainer = styled.div`
  display: flex;
  column-gap: 16px;
`;

export const LeadTimeChoicesContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
`;

export const DeleteContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
  margin-right: 25px;
`;

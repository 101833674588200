import { connect } from 'react-redux';
import { get } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

// ACTIONS
import { openSmallModal } from '@actions/modal';
import { showConfirmationMessage, showErrorMessage } from '@actions/messageconfirmation';

// COMMONS
import { ANALYTICS_TYPE } from '@commons/constants/analyticsType';
import { Button, NestedList } from '@commons/utils/styledLibraryComponents';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { getUserTimezone } from '@commons/utils/date';
import { PeriodDatePicker } from '@commons/DatePickers/PeriodDatePicker';
import { usePeriodDatePickerState } from '@hooks/usePeriodDatePickerState';
import DeepsightFiltersButton from '@commons/DeepsightAnalyticsHeaders/components/DeepsightFiltersButton';
import EmptyState from '@commons/EmptyState';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

// SELECTORS
import { getClientInfo } from '@selectors/client';
import { getSalesPointStores } from '@selectors/stores';

import { fetchInventoryDateOfStoreByDates } from '../OrderAnalyticsCategory/utils/fetch';
import OrderAnalyticsExportModal from '../components/OrderAnalyticsExportModal';

import {
  Container,
  ContentContainer,
  HeaderContainer,
  FilterContainer,
  Filter,
  NestedListContainer,
} from './styledComponents';
import fetchUtils from './utils/fetch';
import getColumnsTable from './utils/columns';

export const OrdersAnalyticsStore = (props) => {
  const {
    user,
    client: { clientId, hasMultipleBrands },
    match,
    stores,
    showMessage,
    showErrorMessage,
    currency,
  } = props;

  const userTimezone = getUserTimezone();
  const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');

  /**
   * FILTERS STATES
   */
  const [locations, setLocations] = useState(null);
  const [selectedLocations, setSelectedLocations] = useState([]);

  const [brands, setBrands] = useState(null);
  const [selectedBrands, setSelectedBrands] = useState([]);

  const [retailers, setRetailers] = useState([]);
  const [selectedRetailers, setSelectedRetailers] = useState([]);

  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);

  const [selectedStores, setSelectedStores] = useState([]);

  const yesterday = moment.tz(userTimezone).subtract(1, 'days').endOf('day');

  const periodPickerState = usePeriodDatePickerState(
    moment.tz(userTimezone).subtract(1, 'month').startOf('day'),
    yesterday,
    userTimezone,
  );

  /**
   * ANALYTICS STATES
   */
  const [analytics, setAnalytics] = useState(null);
  const [activeData, setActiveData] = useState([]);
  const [totalRow, setTotalRow] = useState([]);

  const [focusedMonth, setFocusedMonth] = useState(
    moment
      .tz(userTimezone)
      .subtract(1, 'month')
      .startOf('month')
      .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
  );
  /* 
    datesWithInventory is an object of the shape 
    { startDate: [], endDate: [] }
    where each array contains the dates on which an inventory exists, taking into account the stock convention
    see fetchInventoryDateOfStoreByDates for more information
  */
  const [datesWithInventory, setDatesWithInventory] = useState({});

  const [advancedFilters, setAdvancedFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);

  const [filtersAnalyticsOrders, setFiltersAnalyticsOrders] = useState(null);

  const [columns, setColumns] = useState([]);

  /**
   * LOADING STATES
   */
  const [isLoading, setIsLoading] = useState(true);
  const [isRetrievingAnalytics, setIsRetrievingAnalytics] = useState(true);

  const [renderEmptyState, setRenderEmptyState] = useState(false);

  useEffect(() => {
    if (!stores || !stores.length) {
      return;
    }

    setColumns(getColumnsTable(currency, periodPickerState.startDate, periodPickerState.endDate));

    // Set default selected stores
    setSelectedStores(stores);

    (async function loadData() {
      const storeIds = stores.map((store) => store.id);

      const [fetchedGroups, fetchedRetailers, fetchedLocations] = await Promise.all([
        fetchUtils.fetchGroupsByStoreIds(storeIds, showMessage),
        fetchUtils.fetchRetailersOfClient(clientId, showMessage),
        fetchUtils.fetchLocationsOfAccount(user.id, showMessage),
      ]);

      setGroups(Object.values(fetchedGroups));
      setRetailers(fetchedRetailers);
      setLocations(fetchedLocations);

      // Fetch Brands
      if (hasMultipleBrands) {
        const fetchedBrands = await fetchUtils.fetchBrandsOfClient(clientId, showMessage);

        setBrands(fetchedBrands);
      }

      setIsLoading(false);
    })();
  }, []);

  // Handle the fetch of analytics data
  useEffect(() => {
    const selectedStoreIds = selectedStores.map((store) => store.id);

    if (
      isLoading ||
      !selectedStoreIds.length ||
      !periodPickerState.startDate ||
      !periodPickerState.endDate
    ) {
      return;
    }

    setRenderEmptyState(false);
    setIsRetrievingAnalytics(true);

    (async function loadData() {
      setAnalytics(null);

      const result = await fetchUtils.fetchAnalyticsData(
        selectedStoreIds,
        periodPickerState.startDate,
        periodPickerState.endDate,
        showMessage,
      );

      setTotalRow([result.total]);
      setAnalytics(result.data);
    })();
  }, [selectedStores, periodPickerState.startDate, periodPickerState.endDate, isLoading]);

  // Handle filters
  useEffect(() => {
    if (!analytics) {
      return;
    }

    if ((!advancedFilters || !advancedFilters.length) && applyFilters) {
      setActiveData(analytics);

      return;
    }

    if (!applyFilters) {
      return;
    }

    let filteredData = analytics.filter((data) => data.id !== 'total');

    advancedFilters.forEach(({ propertyKey, doFilter, value }) => {
      filteredData = doFilter(filteredData, propertyKey, value);
    });

    setActiveData(filteredData);
  }, [analytics, advancedFilters, applyFilters]);

  // Retrieve inventory dates when only one store is selected
  useEffect(() => {
    if (selectedStores.length !== 1) {
      return;
    }

    const storeIds = selectedStores.map((stores) => stores.id);

    try {
      (async function loadData() {
        const startDate = moment
          .tz(focusedMonth, userTimezone)
          .subtract(1, 'month')
          .startOf('month')
          .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
        const endDate = moment
          .tz(focusedMonth, userTimezone)
          .add(1, 'month')
          .endOf('month')
          .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

        const formattedDates = await fetchInventoryDateOfStoreByDates(
          storeIds,
          startDate,
          endDate,
          userTimezone,
          showMessage,
        );

        formattedDates.startDate = formattedDates.startDate.map((date) =>
          moment.tz(date, userTimezone),
        );
        formattedDates.endDate = formattedDates.endDate.map((date) =>
          moment.tz(date, userTimezone),
        );

        setDatesWithInventory(formattedDates);
      })();
    } catch {
      showErrorMessage(i18next.t('ORDERS.BY_CATEGORY.FETCH_DATA_FAILURE'));
    }
  }, [selectedStores, focusedMonth]);

  useEffect(() => {
    if (!analytics) {
      return;
    }

    setRenderEmptyState(!activeData || !activeData.length);

    if (!isRetrievingAnalytics) {
      return;
    }

    setIsRetrievingAnalytics(false);
  }, [activeData]);

  const triggerExport = (selectedStores, startDate, endDate, analytics) => {
    if (!selectedStores) {
      return;
    }

    props.openModalExportInfo({
      component: OrderAnalyticsExportModal,
      endDate,
      startDate,
      analytics,
      title: i18next.t('ORDERS.BY_CATEGORY.EXPORT_IN_PROGRESS'),
    });

    return;
  };

  return (
    <Container>
      <NavigationBreadCrumb featurePath={match.path} />
      <ContentContainer>
        <HeaderContainer>
          <FilterContainer>
            <Filter>
              <DeepsightFiltersButton
                advancedFilters={advancedFilters}
                analyticsType={ANALYTICS_TYPE.ORDER}
                brands={brands}
                columnsFilterList={columns.filter((column) => column.filterType === 'numeric')}
                disableExportButton={!activeData || !activeData.length}
                exportButtonPositionLeft={true}
                filters={filtersAnalyticsOrders}
                groups={groups}
                locations={locations}
                readOnly={isRetrievingAnalytics}
                retailers={retailers}
                selectedBrands={selectedBrands}
                selectedGroups={selectedGroups}
                selectedLocations={selectedLocations}
                selectedRetailers={selectedRetailers}
                selectedStores={selectedStores}
                setAdvancedFilters={setAdvancedFilters}
                setApplyFilters={setApplyFilters}
                setFilters={setFiltersAnalyticsOrders}
                setSelectedBrands={setSelectedBrands}
                setSelectedGroups={setSelectedGroups}
                setSelectedLocations={setSelectedLocations}
                setSelectedRetailers={setSelectedRetailers}
                setSelectedStores={setSelectedStores}
                setSelectedSuppliers={() => {}}
                stores={stores}
                suppliers={[]}
                textFilterButton={i18next.t('GENERAL.ANALYTICS_BUTTON')}
              />
            </Filter>
            <PeriodDatePicker
              authorizedDates={
                // focusedDateInput is either 'startDate' or 'endDate'
                !!periodPickerState.focusedDateInput &&
                datesWithInventory[periodPickerState.focusedDateInput]
              }
              disabled={isRetrievingAnalytics}
              endDate={periodPickerState.endDate}
              focusedDateInput={periodPickerState.focusedDateInput}
              maxFutureDate={yesterday}
              setFocusedDateInput={periodPickerState.setFocusedDateInput}
              startDate={periodPickerState.startDate}
              timezone="Europe/Paris" // TODO - [TIMEZONES] To be replaced by IPP-5229 Admin Module
              onDatesChange={periodPickerState.handleSelectedDates}
              onNextMonthClick={(nextMonth) => setFocusedMonth(nextMonth)}
              onPrevMonthClick={(prevMonth) => setFocusedMonth(prevMonth)}
            />
          </FilterContainer>
          <Button
            color={'inpulse-default'}
            handleClick={() =>
              triggerExport(
                selectedStores,
                periodPickerState.startDate,
                periodPickerState.endDate,
                activeData,
              )
            }
            icon={'/images/inpulse/file-download-white-small.svg'}
            isDisabled={isRetrievingAnalytics}
            label={i18next.t('GENERAL.EXPORT')}
          />
        </HeaderContainer>

        <NestedListContainer>
          {renderEmptyState && (
            <div style={{ verticalAlign: 'middle', height: 'calc(100vh - 225px)' }}>
              <EmptyState
                iconHeight={'160px'}
                iconWidth={'160px'}
                label={i18next.t('ORDERS.BY_STORE.EMPTY_STATE_LABEL')}
              />
            </div>
          )}
          {!renderEmptyState && (
            <NestedList
              data={activeData}
              defaultOrderBy={'store.name'}
              defaultOrderType={'asc'}
              fixedRowsData={totalRow}
              headers={getColumnsTable(
                currency,
                periodPickerState.startDate,
                periodPickerState.endDate,
              )}
              // Necessary because isExpandable is equal to true by default
              isExpandable={false}
              isLoading={isRetrievingAnalytics}
              labelEmptyState={i18next.t('GENERAL.NO_RESULT')}
              languageCode={userLanguageCode}
              minWidth={'978px'}
            />
          )}
        </NestedListContainer>
      </ContentContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  currency: state.baseReducer.currency,
  stores: getSalesPointStores(state.baseReducer.activeStores),
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  openModalExportInfo: (params) => {
    dispatch(openSmallModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersAnalyticsStore);

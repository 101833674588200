export const ISO_FRANCE = 'FR';
export const COUNTRY_FRANCE = 'France';

export const COUNTRY_FRANCE_TYPO = ['FR', 'Fr', 'fr', 'france', 'France', 'FRANCE'];

export const USER_LANGUAGE_CODE = {
  CN: 'cn',
  EN: 'en',
  FR: 'fr',
  TH: 'th',
  VI: 'vi',
};

export default {
  ISO_FRANCE,
  COUNTRY_FRANCE,
};

import styled from 'styled-components';

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;

  padding: 16px 24px;
`;

export const ContentContainer = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;

  width: 100%;
  height: calc(100% - 96px);
`;

export const LastRowContainer = styled.div`
  flex: 1;
  min-height: 0;
`;

export const MetricsContainer = styled.div`
  flex: 1;

  display: flex;
  flex-flow: row wrap;
  gap: 16px;
`;

import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openSmallModal } from '@actions/modal';
import { showErrorMessage } from '@actions/messageconfirmation';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { DeepsightComponentLoader } from '@commons/DeepsightComponents';
import { ExportButton } from '@commons/ExportButton';
import { Filters } from '@commons/Filters';
import { formatNumber } from '@commons/DisplayNumber';
import {
  getCustomFilterIsStrategic,
  getCustomFilterMultipleCategory,
} from '@commons/utils/filtersFetches';
import { getPreviousDayOfStore } from '@commons/utils/date';
import { LeftRightSplitter } from '@commons/LeftRightSplitter';
import { NestedList, SearchBar } from '@commons/utils/styledLibraryComponents';
import { PastDayDatePicker } from '@commons/DatePickers/PastDayDatePicker';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import { useDatePickerState } from '@hooks/useDatePickerState';
import { useFiltersButtonState } from '@hooks/useFiltersButtonState';
import { usePaginatorState } from '@hooks/usePaginatorState';

import { getClientInfo } from '@selectors/client';
import { getSalesPointStores } from '@selectors/stores';

import { stock as stockService } from '@services/stock';

import ProductionAnalyticsToggle from '@productions/components/AnalyticsContainer/components/ProductionAnalyticsToggle';

import { getCalendarInfo, sortByGapPercentage } from '@stocks/utils';
import { PAST_STOCKS_TYPE } from '@stocks/utils/constants';

import {
  ConfigPanelContainer,
  ContentContainer,
  LeftSide,
  ListContainer,
  MainPageContainer,
  Metric,
  RightSide,
} from '../commonStyledComponents';
import {
  fetchBrandsAndSet,
  fetchCategoriesAndSet,
  fetchLocationsAndSet,
  fetchRetailersAndSet,
  METRICS,
} from '../utils/commons';
import { getPastInventoryOfEntityId } from '../utils/getPastInventoryOfEntityId';
import { getPastStockData } from '../utils/getPastStockData';

import utilsColumns from './commons/columns';

import ExportModal from './ExportModal';

export const StocksAnalyticsPast = (props) => {
  const {
    match,
    user,
    stores,
    client: { clientId, storeName },
    showErrorMessage,
    openModalExportInfo,
    currency,
  } = props;

  const path = _.get(match, 'path');
  const userLanguageCode = _.get(user, 'lnkLanguageAccountrel.code', 'fr');

  const paginatorState = usePaginatorState({ defaultMaxPerPage: 50 });

  // Loader states
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFilters, setIsLoadingFilters] = useState(true);
  const [isLoadingDatePicker, setIsLoadingDatePicker] = useState(false);

  // Data
  const [pastStocks, setPastStocks] = useState([]);
  const [displayedPastStocks, setDisplayedPastStocks] = useState([]);

  // Interactive inputs states with data
  const [searchInput, setSearchInput] = useState('');
  const [selectedMetric, setSelectedMetric] = useState(_.head(METRICS));

  // Columns
  const [columns, setColumns] = useState(utilsColumns.getListConfig(selectedMetric, currency));

  // Dates states
  const [maxFutureDate, setMaxFutureDate] = useState(moment());
  const [focusedMonth, setFocusedMonth] = useState(moment());
  const [recentDatesWithStocks, setRecentDatesWithStocks] = useState([]);
  const datePickerState = useDatePickerState(moment().subtract(1, 'day'));

  // Brands dropdown
  const [brands, setBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);

  // Retailers dropdown
  const [retailers, setRetailers] = useState([]);
  const [selectedRetailers, setSelectedRetailers] = useState([]);

  // Locations dropdown
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);

  // Categories dropdown
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  // Stores dropdown
  const [selectedStore, setSelectedStore] = useState({});

  // Strategic toggle
  const [isStrategic, setIsStrategic] = useState(true);

  // Filters states
  const [filters, setFilters] = useState(null);
  const filtersButtonState = useFiltersButtonState([]);
  const [applyFilters, setApplyFilters] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState(null);

  // Define default selected store on loading
  useEffect(() => {
    if (_.isEmpty(stores)) {
      return;
    }

    setSelectedStore(_.head(stores));
  }, []);

  // Load data to initialize dropdown filters data
  useEffect(() => {
    if (!clientId) {
      return;
    }

    (async function loadData() {
      await fetchBrandsAndSet(clientId, setBrands, showErrorMessage);

      await fetchRetailersAndSet(clientId, setRetailers, showErrorMessage);

      await fetchLocationsAndSet(clientId, setLocations, showErrorMessage);

      const clientCategories = await fetchCategoriesAndSet(
        clientId,
        setCategories,
        showErrorMessage,
      );
      setSelectedCategories(clientCategories);

      setIsLoadingFilters(false);
    })();
  }, [clientId]);

  // Update datepicker restriction when store changes
  useEffect(() => {
    const previousDayOfStore = getPreviousDayOfStore(selectedStore);

    setMaxFutureDate(previousDayOfStore);

    datePickerState.setDate(previousDayOfStore);
  }, [selectedStore]);

  // Trigger date picker dates refresh on focused month changes
  useEffect(() => {
    if (isLoadingDatePicker || _.isEmpty(selectedStore)) {
      return;
    }

    getDatesWithStocksForMonth();
  }, [focusedMonth, datePickerState.date]);

  // Trigger refresh on filters changes
  useEffect(() => {
    if (!applyFilters) {
      return;
    }

    refreshPastStockData();
  }, [applyFilters]);

  // Trigger refresh on date changes
  useEffect(() => {
    if (!datePickerState.date) {
      return;
    }

    refreshPastStockData();
  }, [datePickerState.date]);

  // Handle search input
  useEffect(() => {
    paginatorState.setCurrentPage(1);

    if (!searchInput) {
      setDisplayedPastStocks(pastStocks);
      return;
    }

    const filteredPastStocks = pastStocks.filter((data) =>
      data.name.toLowerCase().includes(searchInput.toLowerCase()),
    );

    setDisplayedPastStocks(filteredPastStocks);
  }, [pastStocks, searchInput]);

  // Trigger column displayed when selected metric changes
  useEffect(() => {
    setColumns(utilsColumns.getListConfig(selectedMetric, currency));

    // To reset column and data in graph
    refreshPastStockData();
  }, [selectedMetric, currency]);

  /*
    Handles the fetch of dates of the existing inventoryList for the datePicker
  */
  const getDatesWithStocksForMonth = async () => {
    setIsLoadingDatePicker(true);

    const startDate = moment(focusedMonth)
      .startOf('month')
      .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

    const endDate = moment(focusedMonth)
      .add(1, 'month')
      .endOf('month')
      .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

    try {
      const existingInventoriesDates = await stockService.getPastStocksDates(
        selectedStore.id,
        startDate,
        endDate,
      );

      const datesAsMoment = existingInventoriesDates.map((date) => moment(date));

      setRecentDatesWithStocks(datesAsMoment);
    } catch {
      showErrorMessage(i18next.t('STOCKS.PAST_STOCKS.GET_PAST_STOCK_DATE'));
    } finally {
      setIsLoadingDatePicker(false);
    }
  };

  /**
   * Handle the refresh of the past stock data
   */
  const refreshPastStockData = async () => {
    if (isLoading || _.isEmpty(selectedStore)) {
      return;
    }
    setIsLoading(true);

    const categoryIds = selectedCategories.map(({ id }) => id);

    try {
      const pastStocks = await stockService.getPastStocksOfIngredientsByCategories(
        selectedStore.id,
        moment(datePickerState.date).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        categoryIds,
        isStrategic,
      );

      const formattedPastStocks = await formatPastStockData(pastStocks, selectedMetric.key);

      setPastStocks(formattedPastStocks);
    } catch {
      setPastStocks([]);
      showErrorMessage(i18next.t('STOCKS.PAST_STOCKS.GET_PAST_STOCK_FAILURE'));
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Format the response of the API call `getPastStocksOfIngredientsByCategories` to make it compatible with NestList component props definition
   *
   * @param data - The data to format
   * @param selectedMetricKey - Either "turnover" or "unit"
   *
   * @returns The formatted data
   */
  const formatPastStockData = async (data, selectedMetricKey) =>
    data.map((item) => ({
      ...item,
      stockGapPercentage: item.stockGapPercentagePerMetric[selectedMetricKey],
      modalContent: () =>
        getPastInventoryOfEntityId(
          item.entityId,
          selectedStore,
          selectedMetric,
          currency,
          PAST_STOCKS_TYPE.INGREDIENTS,
          showErrorMessage,
        ),
      graphContent: () =>
        getPastStockData(
          item,
          datePickerState.date,
          selectedStore,
          selectedMetric,
          currency,
          PAST_STOCKS_TYPE.INGREDIENTS,
          showErrorMessage,
        ),
    }));

  const computeTheoricalTotal = () => {
    const total = displayedPastStocks.reduce((result, { theoricalStock }) => {
      const value = theoricalStock.turnover;

      if (value == null) {
        return result;
      }

      return result + Math.max(Math.round(value * 100) / 100, 0);
    }, null);

    if (total === null) {
      return null;
    }

    return Math.round(total * 100) / 100;
  };

  const computeRealTotal = () => {
    const total = displayedPastStocks.reduce((result, { realStockWithRecipes }) => {
      const value = realStockWithRecipes.turnover;

      if (value == null) {
        return result;
      }

      return result + Math.max(Math.round(value * 100) / 100, 0);
    }, null);

    if (total === null) {
      return null;
    }

    return Math.round(total * 100) / 100;
  };

  const openExportModal = () => {
    openModalExportInfo({
      component: ExportModal,
      customStyle: {
        overflow: 'initial',
      },
      stores,
      currency,
      storeName,
      searchInput,
      isStrategic,
      date: datePickerState.date,
      categoryIds: selectedCategories.map(({ id }) => id),
    });
  };

  return (
    <MainPageContainer>
      <NavigationBreadCrumb featurePath={path} />
      <ContentContainer>
        <ConfigPanelContainer>
          <LeftRightSplitter
            left={
              <LeftSide>
                <SearchBar
                  disabled={isLoading}
                  placeholder={i18next.t('GENERAL.SEARCH')}
                  setValue={setSearchInput}
                  value={searchInput}
                />
                <Filters
                  customWidth={160}
                  filtersButtonState={filtersButtonState}
                  filtersModalParams={{
                    // Modal Filters
                    applyFilters,
                    setApplyFilters,
                    filters,
                    setFilters,
                    advancedFilters,
                    setAdvancedFilters,
                    // Brands
                    brands,
                    selectedBrands,
                    setSelectedBrands,
                    // Retailers
                    retailers,
                    selectedRetailers,
                    setSelectedRetailers,
                    // Locations
                    locations,
                    selectedLocations,
                    setSelectedLocations,
                    // Store
                    stores,
                    selectedStore,
                    setSelectedStore,
                    // Categories
                    customMultipleDropDowns: [
                      getCustomFilterMultipleCategory(
                        categories,
                        selectedCategories,
                        setSelectedCategories,
                      ),
                    ],
                    // Strategic
                    customToggles: [getCustomFilterIsStrategic(isStrategic, setIsStrategic)],
                  }}
                  filterText={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
                  isDisabled={isLoadingFilters || isLoading}
                />
                <PastDayDatePicker
                  authorizedDates={recentDatesWithStocks}
                  calendarInfo={getCalendarInfo(storeName)}
                  date={datePickerState.date}
                  disabled={isLoading || isLoadingDatePicker}
                  maxFutureDate={maxFutureDate}
                  numberOfMonths={2}
                  width={160}
                  onDateChange={datePickerState.setDate}
                  onNextMonthClick={setFocusedMonth}
                  onPrevMonthClick={setFocusedMonth}
                />
                <ProductionAnalyticsToggle
                  choices={METRICS}
                  isDisabled={isLoading}
                  noRightMargin={true}
                  selectedChoice={selectedMetric}
                  setSelectedChoice={setSelectedMetric}
                />
                <Metric>
                  <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.TEXT_MICRO_HEIGHT_12}>
                    {i18next.t('STOCKS.PAST_STOCKS.THEORETICAL_TOTAL')}
                  </Text>
                  {isLoading ? (
                    <DeepsightComponentLoader height={16} width={16} />
                  ) : (
                    <Text>
                      {formatNumber(computeTheoricalTotal(), currency.numberDecimals)}{' '}
                      {currency.alphabeticCode}
                    </Text>
                  )}
                </Metric>
                <Metric>
                  <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.TEXT_MICRO_HEIGHT_12}>
                    {i18next.t('STOCKS.PAST_STOCKS.REAL_TOTAL')}
                  </Text>
                  {isLoading ? (
                    <DeepsightComponentLoader height={16} width={16} />
                  ) : (
                    <Text>
                      {formatNumber(computeRealTotal(), currency.numberDecimals)}{' '}
                      {currency.alphabeticCode}
                    </Text>
                  )}
                </Metric>
              </LeftSide>
            }
          />

          <RightSide>
            <ExportButton
              handleClick={openExportModal}
              isDisabled={isLoading}
              isInpulseOnly={true}
            />
          </RightSide>
        </ConfigPanelContainer>
        <ListContainer>
          <NestedList
            currentPage={paginatorState.currentPage}
            customModalIcon={'icon-stock'}
            customSortFunc={sortByGapPercentage}
            customSortProperty={'stockGapPercentage'}
            data={displayedPastStocks}
            defaultOrderBy={'stockGapPercentage'}
            defaultOrderType={'desc'}
            graphEmptyStateLabel={i18next.t('STOCKS.PAST_STOCKS.GRAPH_EMPTY_STATE_LABEL')}
            headers={columns}
            isLoading={isLoading}
            labelModalEmptyState={i18next.t(
              'STOCKS.PAST_STOCKS.LAST_INVENTORIES_GRAPH_EMPTY_STATE',
            )}
            languageCode={userLanguageCode}
            maxPerPage={paginatorState.maxPerPage}
            maxPerPageOptions={paginatorState.maxPerPageOptions}
            minWidth={'978px'}
            modalHeaders={utilsColumns.getModalConfig()}
            modalTitle={i18next.t('ANALYSIS.STOCKS.COLUMN_NAME_LAST_INVENTORIES')}
            setCurrentPage={paginatorState.setCurrentPage}
            setMaxPerPage={paginatorState.setMaxPerPage}
            hasPagination
          />
        </ListContainer>
      </ContentContainer>
    </MainPageContainer>
  );
};

StocksAnalyticsPast.propTypes = {
  match: PropTypes.any, // react-router-dom's match route prop
  user: PropTypes.object,
  stores: PropTypes.array,
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  currency: state.baseReducer.currency,
  stores: getSalesPointStores(state.baseReducer.activeStores),
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  openModalExportInfo: (params) => {
    dispatch(openSmallModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StocksAnalyticsPast);

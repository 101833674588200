// Components
import ItemListSorter from '@commons/ItemListSorter';

// Commons
import { GENERIC_MODAL_CANCEL_BUTTON } from '@commons/Modals/GenericModal/genericModalActions';

const GENERIC_MODAL_CONFIG = {
  type: 'action',
  width: '542px',
  height: 'auto',
  maxHeight: '560px',
  component: ItemListSorter,
  actions: [GENERIC_MODAL_CANCEL_BUTTON()],
  icon: '/images/inpulse/gear-ipblack-small.svg',
};

export default { GENERIC_MODAL_CONFIG };

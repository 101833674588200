import i18next from 'i18next';
import moment from 'moment-timezone';

import { convertEntityQuantityToInpulseUnit } from '@commons/utils/conversion';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { formatNumber } from '@commons/DisplayNumber';
import { getUserTimezone } from '@commons/utils/date';

import centralService from '@services/central';

import { config } from '@src/config';

import { getEntityUnit } from '@stocks/utils';
import { METRICS } from '@stocks/StocksAnalytics/utils/commons';

export const getPastRecipeInventoriesOfRecipe = async (
  entityId,
  storeId,
  selectedMetric,
  currency,
  showErrorMessage,
) => {
  try {
    const currentTime = moment
      .tz(getUserTimezone())
      .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

    const pastInventories = await centralService.getRecipePastAnalyticsLastRecipeInventories(
      storeId,
      entityId,
      currentTime,
    );

    return pastInventories.map((item) => {
      const inventoryDate = moment(item.inventoryDate).format(
        DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR,
      );

      const openLink = () =>
        `${config.baseAppUrl}central-kitchen-stocks/inventories/recipes?search=${item.reference}`;

      const quantity =
        selectedMetric === METRICS[0]
          ? `${formatNumber(item.price, currency.numberDecimals)} ${currency.alphabeticCode}`
          : `${convertEntityQuantityToInpulseUnit(item.quantity, item.unit).toFixed(
              2,
            )} ${getEntityUnit(item.unit)}`;

      return {
        ...item,
        inventoryListReference: item.reference,
        inventoryDate,
        openLink,
        quantity,
      };
    });
  } catch {
    showErrorMessage(i18next.t('STOCKS.PAST_STOCKS.FETCH_PAST_INVENTORIES_ON_INGREDIENT_FAILED'));

    return {};
  }
};

import {
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-pro';
import { IconButton, SvgIcon } from '@mui/material';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import Stack from '@mui/material/Stack';

import { CONSUMPTION_COMPUTATION_TYPES } from '@commons/constants/centralConstants';
import { convertEntityQuantityToInpulseUnit } from '@commons/utils/conversion';
import { customSorter } from '@commons/utils/dataGrid';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { DisabledTooltip } from '@commons/DisabledTooltip';
import { formatNumber } from '@commons/DisplayNumber';
import { HoverTooltip } from '@commons/utils/styledLibraryComponents';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import { getEntityUnit } from '../../../stocks/utils';

import { CONSUMPTION_STRATEGY, PRODUCTION_PLANNING_TAB_INDEXES } from './constants';
import { HeaderIcon, NameCellContainer, RoundPicture, Name, NameHover } from '../styledComponents';

const DEFAULT_INGREDIENT_PICTURE = '/images/inpulse/ingredient-photo-placeholder.svg';
const DEFAULT_RECIPE_PICTURE = '/images/inpulse/recipe-photo-placeholder.svg';
const YESTERDAY_STOCK_ICON = '/images/inpulse/stocks-bis.svg';
const YESTERDAY_STOCK_ICON_BLUE = '/images/inpulse/stocks-bis-blue.svg';
const YESTERDAY_STOCK_ICON_GREY = '/images/inpulse/stocks-bis-dmgrey.svg';
const CONSUMPTION_TOOLTIP_ICON = '/images/inpulse/info-black-small.svg';
const ORDER_ICON_BLACK = '/images/inpulse/order-black-small.svg';
const CONSUMPTION_ICON_BLACK = '/images/inpulse/bolt-black-small.svg';
const CONSUMPTION_ICON_GREY = '/images/inpulse/bolt-dmgrey-small.svg';
const CONSUMPTION_ICON_BLUE = '/images/inpulse/bolt-blue-small.svg';

const MAXIMUM_NAME_LENGTH = 22;
const MAXIMUM_CATEGORY_LENGTH = 12;

const renderNameCell = (img, name, isIngredient, forRecipesTab = false) => {
  const [displayBigger, setDisplayBigger] = useState(false);

  return (
    <NameCellContainer>
      <RoundPicture
        src={img || (isIngredient ? DEFAULT_INGREDIENT_PICTURE : DEFAULT_RECIPE_PICTURE)}
      />
      <Name onMouseOver={() => setDisplayBigger(true)}>{name}</Name>
      {displayBigger && name.length > MAXIMUM_NAME_LENGTH && (
        <NameHover
          addSelectionOffset={forRecipesTab}
          offsetName
          onMouseOut={() => setDisplayBigger(false)}
        >
          {name}
        </NameHover>
      )}
    </NameCellContainer>
  );
};

const renderCategoryCell = (name, forRecipesTab = false) => {
  const [displayBigger, setDisplayBigger] = useState(false);

  const formattedName = name || i18next.t('GENERAL.NONE_VALUE');

  return (
    <NameCellContainer>
      <Name onMouseOver={() => setDisplayBigger(true)}>{formattedName}</Name>
      {displayBigger && formattedName.length > MAXIMUM_CATEGORY_LENGTH && (
        <NameHover
          addSelectionOffset={forRecipesTab}
          offsetCategory
          onMouseOut={() => setDisplayBigger(false)}
        >
          {formattedName}
        </NameHover>
      )}
    </NameCellContainer>
  );
};

const renderInputCell = (props) => {
  const { isEditable, value } = props;

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      sx={{ width: '100%', height: '100%', cursor: isEditable && 'pointer', fontSize: 14 }}
    >
      {isEditable && <img src={'/images/inpulse/edit-pen-black-small.svg'}></img>}
      <strong>{value}</strong>
    </Stack>
  );
};

const renderStandardHeader = (props) => {
  const { colDef } = props;

  return (
    <NameCellContainer>
      <Text font={ENUM_FONTS.TEXT_MIDDLE_NORMAL}>{colDef.headerName}</Text>
    </NameCellContainer>
  );
};

/**
 *  Used for the total column of recipes table
 * */
const renderStandardCell = (props) => {
  const {
    value,
    row: { unit },
  } = props;

  const formattedUnit = getEntityUnit(unit);

  return (
    <Text font={ENUM_FONTS.TEXT_MIDDLE_NORMAL}>
      {value !== null &&
        `${Number.isInteger(value) ? value : formatNumber(value, 2)} ${formattedUnit}`}
    </Text>
  );
};

const renderConvertedCell = (props) => {
  const { value, row } = props;

  const { unit } = row;

  const convertedQuantity = convertEntityQuantityToInpulseUnit(value, unit);

  const formattedUnit = getEntityUnit(unit);

  return (
    <Text font={ENUM_FONTS.TEXT_MIDDLE_NORMAL}>
      {!!value &&
        `${
          Number.isInteger(convertedQuantity)
            ? convertedQuantity
            : formatNumber(convertedQuantity, 2)
        } ${formattedUnit}`}
    </Text>
  );
};

const renderYesterdayStockHeader = (yesterdayDate) => (
  <NameCellContainer>
    <HoverTooltip
      place={'top'}
      renderContent={() => (
        <DisabledTooltip
          text={i18next.t('PRODUCTION.CENTRAL_KITCHEN.YESTERDAY_STOCK_TOOLTIP', { yesterdayDate })}
        />
      )}
      isTooltipDisplayed
    >
      <HeaderIcon src={YESTERDAY_STOCK_ICON} />
    </HoverTooltip>
    <Text font={ENUM_FONTS.TEXT_MIDDLE_NORMAL}>
      {i18next.t('ORDERS.ORDERS.FORM_YESTERDAY_STOCK_COLUMN_TITLE')}
    </Text>
  </NameCellContainer>
);

const renderYesterdayStockCell = (
  yesterdayStock,
  yesterdayRealStock,
  yesterdayTheoricalStock,
  unit,
) => {
  let yesterdayStockIcon = YESTERDAY_STOCK_ICON;
  let yesterdayStockColor = ENUM_COLORS.DARKEST;

  const entityUnit = getEntityUnit(unit);

  let formattedYesterdayStock = yesterdayStock;

  if (yesterdayRealStock != null || yesterdayTheoricalStock != null) {
    const stockValue = yesterdayRealStock != null ? yesterdayRealStock : yesterdayTheoricalStock;

    yesterdayStockIcon =
      yesterdayRealStock != null ? YESTERDAY_STOCK_ICON_GREY : YESTERDAY_STOCK_ICON_BLUE;

    yesterdayStockColor = yesterdayRealStock != null ? ENUM_COLORS.DARK : ENUM_COLORS.SECONDARY;

    formattedYesterdayStock = formatNumber(stockValue, 2);
  }

  return (
    <NameCellContainer>
      <HeaderIcon src={yesterdayStockIcon} />
      <Text color={yesterdayStockColor}>
        {!!formattedYesterdayStock ? `${formattedYesterdayStock} ${entityUnit}` : '-'}
      </Text>
    </NameCellContainer>
  );
};

const getConsumptionTooltipText = (consumptionStrategy, selectedTabIndex) => {
  if (selectedTabIndex === PRODUCTION_PLANNING_TAB_INDEXES.RECIPES) {
    if (consumptionStrategy === CONSUMPTION_STRATEGY.ORDERS) {
      return i18next.t('PRODUCTION.CENTRAL_KITCHEN.RECIPES_ORDER_STRATEGY_CONSUMPTION_TOOLTIP');
    }

    return i18next.t('PRODUCTION.CENTRAL_KITCHEN.RECIPES_PASTMIX_STRATEGY_CONSUMPTION_TOOLTIP');
  }

  if (selectedTabIndex === PRODUCTION_PLANNING_TAB_INDEXES.PREPARATIONS) {
    return i18next.t('PRODUCTION.CENTRAL_KITCHEN.PREPARATIONS_ORDER_STRATEGY_CONSUMPTION_TOOLTIP');
  }

  return i18next.t('PRODUCTION.CENTRAL_KITCHEN.INGREDIENTS_ORDER_STRATEGY_CONSUMPTION_TOOLTIP');
};

const renderConsumptionHeader = (consumptionStrategy, selectedTabIndex) => (
  <NameCellContainer>
    <HoverTooltip
      place={'top'}
      renderContent={() => (
        <DisabledTooltip text={getConsumptionTooltipText(consumptionStrategy, selectedTabIndex)} />
      )}
      isTooltipDisplayed
    >
      <HeaderIcon src={CONSUMPTION_TOOLTIP_ICON} />
    </HoverTooltip>
    <Text font={ENUM_FONTS.TEXT_MIDDLE_NORMAL}>
      {i18next.t('PRODUCTION.CENTRAL_KITCHEN.PLANNING_CONSUMPTION_CONSUMPTIONS')}
    </Text>
  </NameCellContainer>
);

const renderConsumptionCell = (consumption, consumptionEndDate, consumptionComputation, unit) => {
  const entityUnit = getEntityUnit(unit);

  const isRealConsumption = moment(consumptionEndDate)
    .startOf('day')
    .isBefore(moment().startOf('day'));

  let color = isRealConsumption ? ENUM_COLORS.DARK : ENUM_COLORS.SECONDARY;
  let consumptionIcon = isRealConsumption ? CONSUMPTION_ICON_GREY : CONSUMPTION_ICON_BLUE;

  if (consumption == null) {
    color = ENUM_COLORS.DARKEST;
    consumptionIcon = CONSUMPTION_ICON_BLACK;
  }

  if (consumptionComputation === CONSUMPTION_COMPUTATION_TYPES.ORDERS) {
    color = ENUM_COLORS.DARKEST;
    consumptionIcon = ORDER_ICON_BLACK;
  }

  const formattedConsumption = `${formatNumber(consumption, 2)}`;

  return (
    <NameCellContainer>
      <HeaderIcon src={consumptionIcon} />
      <Text color={color}>{`${
        consumption != null ? formattedConsumption : '-'
      } ${entityUnit}`}</Text>
    </NameCellContainer>
  );
};

const carretDown = (isDisabled) =>
  isDisabled ? (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.1782 4.11749C14.0319 3.96462 13.7905 3.96037 13.639 4.108L7.99999 9.60375L2.36096 4.10802C2.20949 3.96039 1.96811 3.96464 1.82183 4.11751L1.10701 4.86455C0.960733 5.01742 0.964946 5.26103 1.11642 5.40865L7.76881 11.892C7.92029 12.0396 8.16167 12.0354 8.30795 11.8825L9.23478 10.9139L14.8836 5.40863C15.0351 5.26101 15.0393 5.01741 14.893 4.86453L14.1782 4.11749Z"
        fill="#E2E2E2"
      />
    </svg>
  ) : (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.1782 4.11749C14.0319 3.96462 13.7905 3.96037 13.639 4.108L7.99999 9.60375L2.36096 4.10802C2.20949 3.96039 1.96811 3.96464 1.82183 4.11751L1.10701 4.86455C0.960733 5.01742 0.964946 5.26103 1.11642 5.40865L7.76881 11.892C7.92029 12.0396 8.16167 12.0354 8.30795 11.8825L9.23478 10.9139L14.8836 5.40863C15.0351 5.26101 15.0393 5.01741 14.893 4.86453L14.1782 4.11749Z"
        fill="#1D1D1B"
      />
    </svg>
  );

const carretUp = (
  <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.82183 11.8825C1.96811 12.0354 2.20949 12.0396 2.36096 11.892L8.00001 6.39625L13.639 11.892C13.7905 12.0396 14.0319 12.0354 14.1782 11.8825L14.893 11.1354C15.0393 10.9826 15.0351 10.739 14.8836 10.5913L8.23119 4.108C8.07971 3.96037 7.83833 3.96462 7.69206 4.1175L6.76522 5.08611L1.11642 10.5914C0.964946 10.739 0.960733 10.9826 1.10701 11.1355L1.82183 11.8825Z"
      fill="#1D1D1B"
    />
  </svg>
);

const getToggleDetailPanelIcon = (isExpanded, isDisabled) => {
  if (isExpanded) {
    return carretUp;
  }

  return carretDown(isDisabled);
};

const CustomDetailPanelToggle = (props) => {
  const { isExpanded, isDisabled } = props;

  return (
    <IconButton
      aria-label={isExpanded ? 'Close' : 'Open'}
      disabled={isDisabled}
      size="small"
      sx={{ display: 'flex', alignSelf: 'center', width: '100%' }}
      tabIndex={-1}
    >
      <SvgIcon>{getToggleDetailPanelIcon(isExpanded, isDisabled)}</SvgIcon>
    </IconButton>
  );
};

export const getProductionPlanningColumns = (
  store,
  startDate,
  endDate,
  selectedTabIndex,
  disableToggleDetailPanel,
  consumptionEndDate,
  recipeCategories,
  selectedRecipeIds,
  canEditRecipeProduction,
) => {
  const today = moment().format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

  const recipeTabColumns = [];
  const preparationTabColumns = [];
  const ingredientTabColumns = [];

  const yesterdayDate = moment()
    .subtract(1, 'day')
    .format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR);

  const detailPanelToggleColumn = {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    renderCell: ({ value, row }) => (
      <CustomDetailPanelToggle
        isDisabled={disableToggleDetailPanel || row.consumption == null}
        isExpanded={value}
      />
    ),
  };

  const nameColumn = {
    field: 'name',
    headerName: i18next.t('GENERAL.NAME'),
    minWidth: 240,
    disableReorder: true,
    renderCell: ({ row }) => renderNameCell(row.img, row.name, row.isIngredient),
    renderHeader: renderStandardHeader,
    sortComparator: customSorter,
  };

  const categoryColumn = {
    field: 'category',
    headerName: i18next.t('GENERAL.CATEGORY'),
    minWidth: 120,
    renderCell: ({ row }) => renderCategoryCell(row.category),
    renderHeader: renderStandardHeader,
    sortComparator: customSorter,
    type: 'singleSelect',
    valueOptions: recipeCategories,
  };

  recipeTabColumns.push(
    GRID_CHECKBOX_SELECTION_COL_DEF,
    {
      ...nameColumn,
      renderCell: ({ row }) => renderNameCell(row.img, row.name, row.isIngredient, true),
    },
    {
      ...categoryColumn,
      renderCell: ({ row }) => renderCategoryCell(row.category, true),
    },
    detailPanelToggleColumn,
  );
  preparationTabColumns.push(nameColumn, categoryColumn, detailPanelToggleColumn);
  ingredientTabColumns.push(nameColumn, categoryColumn, detailPanelToggleColumn);

  const yesterdayStockColumn = {
    field: 'yesterdayStock',
    type: 'number',
    headerName: i18next.t('ORDERS.ORDERS.FORM_YESTERDAY_STOCK_COLUMN_TITLE'),
    minWidth: 140,
    disableReorder: true,
    renderHeader: () => renderYesterdayStockHeader(yesterdayDate),
    renderCell: ({ row }) =>
      renderYesterdayStockCell(
        row.yesterdayStock,
        row.yesterdayRealStock,
        row.yesterdayTheoricalStock,
        row.unit,
      ),
  };

  const consumptionColumn = {
    field: 'consumption',
    type: 'number',
    headerName: i18next.t('PRODUCTION.CENTRAL_KITCHEN.PLANNING_CONSUMPTION_CONSUMPTIONS'),
    minWidth: 140,
    renderHeader: () => renderConsumptionHeader(store.consumptionComputation, selectedTabIndex),
    renderCell: ({ row }) =>
      renderConsumptionCell(
        row.consumption,
        consumptionEndDate,
        store.consumptionComputation,
        row.unit,
      ),
  };

  if (store.showYesterdayStock) {
    recipeTabColumns.push(yesterdayStockColumn);
  }

  if (!!store.consumptionComputation) {
    recipeTabColumns.push(consumptionColumn);
    preparationTabColumns.push(consumptionColumn);
    ingredientTabColumns.push(consumptionColumn);
  }

  const dayColumnFields = {
    type: 'number',
    minWidth: 140,
    disableReorder: true,
    filterable: false,
  };

  for (
    let currentDate = moment(startDate).clone();
    currentDate.isSameOrBefore(moment(endDate));
    currentDate.add(1, 'day')
  ) {
    const isRecipeCellEditable = currentDate.isSameOrAfter(today) && canEditRecipeProduction;

    recipeTabColumns.push({
      ...dayColumnFields,
      field: currentDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      headerName: currentDate.format(DATE_DISPLAY_FORMATS.CONDENSED_DAY_DATE_MONTH),
      editable: isRecipeCellEditable,
      renderCell: renderInputCell,
      renderHeader: renderStandardHeader,
      headerClassName: isRecipeCellEditable && selectedRecipeIds.length ? 'customMenuIcon' : null,
      disableColumnMenu: !isRecipeCellEditable || !selectedRecipeIds.length,
    });
    preparationTabColumns.push({
      ...dayColumnFields,
      field: currentDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      headerName: currentDate.format(DATE_DISPLAY_FORMATS.CONDENSED_DAY_DATE_MONTH),
      editable: false,
      renderCell: renderConvertedCell,
      renderHeader: renderStandardHeader,
      disableColumnMenu: true,
    });
    ingredientTabColumns.push({
      ...dayColumnFields,
      field: currentDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      headerName: currentDate.format(DATE_DISPLAY_FORMATS.CONDENSED_DAY_DATE_MONTH),
      editable: false,
      renderCell: renderConvertedCell,
      renderHeader: renderStandardHeader,
      disableColumnMenu: true,
    });
  }

  const totalColumnFields = {
    field: 'total',
    type: 'number',
    headerName: i18next.t('GENERAL.TOTAL'),
    minWidth: 104,
    disableReorder: true,
    renderHeader: renderStandardHeader,
  };

  const totalColumn = {
    ...totalColumnFields,
    renderCell: renderStandardCell,
  };

  const convertedTotalColumn = {
    ...totalColumnFields,
    renderCell: renderConvertedCell,
  };

  recipeTabColumns.push(totalColumn);
  preparationTabColumns.push(convertedTotalColumn);
  ingredientTabColumns.push(convertedTotalColumn);

  return {
    [PRODUCTION_PLANNING_TAB_INDEXES.RECIPES]: recipeTabColumns,
    [PRODUCTION_PLANNING_TAB_INDEXES.PREPARATIONS]: preparationTabColumns,
    [PRODUCTION_PLANNING_TAB_INDEXES.INGREDIENTS]: ingredientTabColumns,
  };
};

export const getProductionPlanningInnerColumns = (startDate, endDate, consumptionComputation) => {
  const columns = [
    {
      field: 'name',
      headerName: '',
      width: 116,
      sortable: false,
      disableReorder: true,
    },
  ];

  const consumptionComputationIsOrders =
    consumptionComputation === CONSUMPTION_COMPUTATION_TYPES.ORDERS;

  for (
    let currentDate = moment(startDate).clone();
    currentDate.isSameOrBefore(moment(endDate));
    currentDate.add(1, 'day')
  ) {
    const cellClassName = consumptionComputationIsOrders
      ? 'consumptionViaOrders'
      : currentDate.isBefore(moment())
      ? 'real'
      : 'theorical';

    columns.push({
      field: currentDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      headerName: currentDate.format(DATE_DISPLAY_FORMATS.CONDENSED_DAY_DATE_MONTH),
      width: 116,
      sortable: false,
      disableReorder: true,
      cellClassName: cellClassName,
    });
  }

  return columns;
};

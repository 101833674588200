import {
  CHOICES_DROPDOWN_ACTIVE,
  CHOICES_ORDER_AVAILABILITY,
  CHOICES_STOCK_AVAILABILITY,
  CHOICES_LOSS_AVAILABILITY,
} from '../../routes/admin/utils/DropdownItems';
import { uniqBy } from 'lodash';
import i18next from 'i18next';

import { supplier as supplierService } from '../../services/supplier';

export const getColumnsAdvancedFilters = (columns = [], filtersList = []) =>
  columns.reduce((result, column) => {
    if (!column.filterType) {
      return result;
    }

    // No need to add extra configuration to handle advanced filter
    if (column.filterType !== 'string') {
      result.push(column);

      return result;
    }

    // Need to provide a property list with all possibilities within the filter
    result.push({
      ...column,
      list: uniqBy(
        filtersList.map((item, key) => ({
          id: key,
          name: item[column.propertyKey],
          [column.propertyKey]: item[column.propertyKey], // need to have a property named with the colum.property to get dynamically the text to provide in the dropdown
        })),
        'name', // To remove duplicates from list
      ),
    });

    return result;
  }, []);

export const ENUM_CUSTOM_FILTERS = {
  TOGGLE_IS_STRATEGIC: 'isStrategic',
  SINGLE_SELECT_CATEGORY: 'singleSelectCategory',
  MULTIPLE_SELECT_CATEGORY: 'multipleSelectCategory',
  SINGLE_SELECT_SUB_CATEGORY: 'singleSelectSubCategory',
  SINGLE_SELECT_STATUS: 'singleSelectStatus',
  MULTIPLE_SELECT_STATUSES: 'multipleSelectStatuses',
  MULTIPLE_SELECT_ORDER_AVAILABILITY: 'multipleSelectOrderAvailability',
  MULTIPLE_SELECT_STOCK_AVAILABILITY: 'multipleSelectStockAvailability',
  MULTIPLE_SELECT_LOSS_AVAILABILITY: 'multipleSelectLossAvailability',
};

export const getCustomFilterIsStrategic = (isStrategic, setIsStrategic) => ({
  id: ENUM_CUSTOM_FILTERS.TOGGLE_IS_STRATEGIC,
  label: i18next.t('STOCKS.CURRENT_STOCKS.FILTERS_STRATEGIC_ONLY'),
  value: isStrategic,
  setToggleValue: setIsStrategic,
});

export const getCustomFilterSingleStatus = (onlyActiveItems, setOnlyActiveItems) => ({
  id: ENUM_CUSTOM_FILTERS.SINGLE_SELECT_STATUS,
  itemSelectedIcon: '/images/inpulse/power-ip-black.svg',
  list: CHOICES_DROPDOWN_ACTIVE(),
  defaultSelectedItem: CHOICES_DROPDOWN_ACTIVE()[0],
  selectedItem: onlyActiveItems,
  setSelectedItem: setOnlyActiveItems,
});

export const getCustomFilterSingleCategory = (
  categoriesToChoose,
  selectedCategory,
  setSelectedCategory,
  reliedOnIsStrategicToggle = true,
) => {
  const customFilter = {
    id: ENUM_CUSTOM_FILTERS.SINGLE_SELECT_CATEGORY,
    icon: '/images/inpulse/category-dmgrey-small.svg',
    disabledIcon: '/images/inpulse/category-white-small.svg',
    itemSelectedIcon: '/images/inpulse/category-ipblack-small.svg',
    list: categoriesToChoose,
    defaultSelectedItem: null,
    selectedItem: selectedCategory,
    setSelectedItem: setSelectedCategory,
    placeholder: i18next.t('GENERAL.CATEGORY'),
    isRequired: false,
  };

  if (!reliedOnIsStrategicToggle) {
    return customFilter;
  }

  return {
    ...customFilter,
    reliedOnFilter: {
      id: ENUM_CUSTOM_FILTERS.TOGGLE_IS_STRATEGIC,
      filterValueKey: 'value', // can be 'selectedStores', 'selectedItem' (if customSingleDropdown), 'value' (if customToggle) etc..
      reliedOnValue: true, // What the filter's value should be for this current one to be disabled, here the category dropdown should be disabled if isStrategic is true
    },
  };
};

export const getCustomFilterMultipleCategory = (
  categoriesToChoose,
  selectedCategories,
  setSelectedCategories,
  reliedOnIsStrategicToggle = true,
) => {
  const customFilter = {
    id: ENUM_CUSTOM_FILTERS.MULTIPLE_SELECT_CATEGORY,
    icon: '/images/inpulse/category-dmgrey-small.svg',
    disabledIcon: '/images/inpulse/category-white-small.svg',
    itemSelectedIcon: '/images/inpulse/category-ipblack-small.svg',
    list: categoriesToChoose,
    defaultSelectedItems: [],
    selectedItems: selectedCategories,
    originalSelectedItems: selectedCategories,
    setSelectedItems: setSelectedCategories,
    placeholder: i18next.t('GENERAL.CATEGORY'),
    isRequired: true,
  };

  if (!reliedOnIsStrategicToggle) {
    return customFilter;
  }

  return {
    ...customFilter,
    reliedOnFilter: {
      id: ENUM_CUSTOM_FILTERS.TOGGLE_IS_STRATEGIC,
      filterValueKey: 'value', // can be 'selectedStores', 'selectedItem' (if customSingleDropdown), 'value' (if customToggle) etc..
      reliedOnValue: true, // What the filter's value should be for this current one to be disabled, here the category dropdown should be disabled if isStrategic is true
    },
  };
};

export const getCustomFilterSingleSubCategory = (
  subCategoriesToChoose,
  selectedSubCategory,
  setSelectedSubCategory,
) => ({
  id: ENUM_CUSTOM_FILTERS.SINGLE_SELECT_SUB_CATEGORY,
  icon: '/images/inpulse/category-dmgrey-small.svg',
  disabledIcon: '/images/inpulse/category-white-small.svg',
  itemSelectedIcon: '/images/inpulse/category-ipblack-small.svg',
  list: subCategoriesToChoose,
  defaultSelectedItem: null,
  selectedItem: selectedSubCategory,
  setSelectedItem: setSelectedSubCategory,
  placeholder: i18next.t('GENERAL.SUB_CATEGORY'),
  reliedOnFilter: {
    id: ENUM_CUSTOM_FILTERS.TOGGLE_IS_STRATEGIC,
    filterValueKey: 'value', // can be 'selectedStores', 'selectedItem' (if customSingleDropdown), 'value' (if customToggle) etc..
    reliedOnValue: true, // What the filter's value should be for this current one to be disabled, here the subcategory dropdown should be disabled if isStrategic is true
  },
  isRequired: false,
});
export const getCustomFilterMultipleStatuses = (selectedStatuses, setSelectedStatuses) => ({
  id: ENUM_CUSTOM_FILTERS.MULTIPLE_SELECT_STATUSES,
  icon: '/images/inpulse/power-ip-black.svg',
  list: CHOICES_DROPDOWN_ACTIVE(),
  defaultSelectedItems: CHOICES_DROPDOWN_ACTIVE(),
  selectedItems: selectedStatuses,
  setSelectedItems: setSelectedStatuses,
});

export const getCustomFilterMultipleAvailableOrder = (
  selectedAvailabilities,
  setSelectedAvailabilities,
) => ({
  id: ENUM_CUSTOM_FILTERS.MULTIPLE_SELECT_ORDER_AVAILABILITY,
  icon: '/images/inpulse/order-black.svg',
  list: CHOICES_ORDER_AVAILABILITY(),
  defaultSelectedItems: CHOICES_ORDER_AVAILABILITY(),
  selectedItems: selectedAvailabilities,
  setSelectedItems: setSelectedAvailabilities,
});

export const getCustomFilterMultipleAvailableStock = (
  selectedAvailabilities,
  setSelectedAvailabilities,
) => ({
  id: ENUM_CUSTOM_FILTERS.MULTIPLE_SELECT_STOCK_AVAILABILITY,
  icon: '/images/inpulse/stock-black.svg',
  list: CHOICES_STOCK_AVAILABILITY(),
  defaultSelectedItems: CHOICES_STOCK_AVAILABILITY(),
  selectedItems: selectedAvailabilities,
  setSelectedItems: setSelectedAvailabilities,
});

export const getCustomFilterMultipleAvailableLoss = (
  selectedAvailabilities,
  setSelectedAvailabilities,
) => ({
  id: ENUM_CUSTOM_FILTERS.MULTIPLE_SELECT_LOSS_AVAILABILITY,
  icon: '/images/inpulse/loss-black.svg',
  list: CHOICES_LOSS_AVAILABILITY(),
  defaultSelectedItems: CHOICES_LOSS_AVAILABILITY(),
  selectedItems: selectedAvailabilities,
  setSelectedItems: setSelectedAvailabilities,
});

export const fetchSuppliersAndSet = async (storeId, showErrorMessage) => {
  try {
    return supplierService.getSuppliersOfStore(storeId);
  } catch (err) {
    showErrorMessage(i18next.t('ADMIN.SUPPLIERS.FETCH_FAILURE'));
    return [];
  }
};

import { Controller } from 'react-hook-form';
import { get, isEqual, omit } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { Input } from '@lib/inpulse/Input';

import {
  Button,
  Checkbox,
  Dropdown,
  InputIncrement,
  ToggleSwitch,
  Tooltip,
} from '@commons/utils/styledLibraryComponents';
import { formatTextNumberInputValue } from '@commons/utils/format';
import { INPUT_TYPES } from '@commons/constants/inputType';
import { SingleDatePicker } from '@commons/DatePickers/SingleDatePicker';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import {
  AttachmentDropdownContainer,
  AttachmentDropdownContent,
  AttachmentDropdownItem,
  AttachmentDropdownRown,
  CheckboxContainer,
  Container,
  CustomRenderContainer,
  DatePickerContainer,
  ErrorText,
  IconContainer,
  InputActionButton,
  InputContainer,
  LabelContainer,
  LoadingState,
  PlainText,
  ToggleContainer,
  TooltipContainer,
  WildCard,
} from './styledComponents';

const AttachmentDropdown = ({ actions, selectFileInputRefs, setIsActionDropdownOpened }) => {
  const handleFileChange = (index) => {
    if (
      !selectFileInputRefs ||
      !selectFileInputRefs.current ||
      !selectFileInputRefs.current[index]
    ) {
      return;
    }

    selectFileInputRefs.current[index].click();
  };

  const handleSelectedFile = async (event, handleClick) => {
    const file = get(event, 'target.files[0]');

    if (!file) {
      return;
    }

    handleClick(file);
    setIsActionDropdownOpened(false);
  };

  return (
    <AttachmentDropdownContainer>
      <AttachmentDropdownContent>
        {actions.map(({ label, icon, handleClick, canSelectFile, acceptedFileTypes }, index) => (
          <AttachmentDropdownRown inputId={`select-file-input-${index}`} key={index}>
            {canSelectFile && (
              <input
                accept={acceptedFileTypes}
                id={`select-file-input-${index}`}
                ref={(ref) => (selectFileInputRefs.current[index] = ref)}
                type="file"
                onInput={(event) => handleSelectedFile(event, handleClick)}
              />
            )}
            <AttachmentDropdownItem
              onClick={() => {
                if (canSelectFile) {
                  handleFileChange(index);
                  return;
                }

                handleClick();
                setIsActionDropdownOpened(false);
              }}
            >
              <IconContainer src={icon} />
              <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}>
                {label}
              </Text>
            </AttachmentDropdownItem>
          </AttachmentDropdownRown>
        ))}
      </AttachmentDropdownContent>
    </AttachmentDropdownContainer>
  );
};

export const FormInput = ({
  prefixInputName,
  control,
  shouldDisplayError,
  shouldDisplayErrorMessage = true,
  formState: { errors },
  input,
  getValues,
  isEditionAllowed,
}) => {
  const errorMessage = get(errors, `${prefixInputName || ''}${input.name}.message`);

  const inputValue = getValues(prefixInputName || input.name) || {};

  const [isActionDropdownOpened, setIsActionDropdownOpened] = useState(false);

  const selectFileInputRefs = useRef([]);

  const triggerActionsDropdown = () => {
    setIsActionDropdownOpened(!isActionDropdownOpened);
  };

  useEffect(() => {
    if (!input.dropdownActions || !input.dropdownActions.length) {
      return;
    }

    /*
      Used to create on ref for each dropdownActions so the input target the expected action
      See the first answer of this post https://stackoverflow.com/questions/54633690/how-can-i-use-multiple-refs-for-an-array-of-elements-with-hooks
    */
    selectFileInputRefs.current = selectFileInputRefs.current.slice(0, input.dropdownActions);
  }, [input.dropdownActions]);

  return (
    <Container style={input.parentContainerStyle}>
      <Controller
        control={control}
        defaultValue={input.defaultValue}
        name={`${prefixInputName}${input.name}`}
        render={({ field }) => {
          const row = getValues(prefixInputName);

          if (row && row.isLoading) {
            return <LoadingState width={input.width} />;
          }

          if (input.type === INPUT_TYPES.PLAIN_TEXT) {
            if (input.computeValue) {
              return (
                <CustomRenderContainer width={input.width}>
                  {input.computeValue(inputValue)}
                </CustomRenderContainer>
              );
            }

            return <PlainText width={input.width}>{field.value}</PlainText>;
          }

          if (input.type === INPUT_TYPES.CURRENCY) {
            if (input.computeValue) {
              return <PlainText width={input.width}>{input.computeValue(inputValue)}</PlainText>;
            }

            return <PlainText width={input.width}>{field.value}</PlainText>;
          }

          if (input.type === INPUT_TYPES.TOGGLE) {
            return (
              <ToggleContainer key={input.label} width={input.width}>
                <ToggleSwitch
                  {...omit(field, 'ref')}
                  checked={field.value}
                  disabled={
                    (!!input.isDisabled && input.isDisabled(inputValue)) || !isEditionAllowed
                  }
                  handleClick={() => field.onChange(!field.value)}
                  id={`${prefixInputName}${input.name}`}
                  key={`${prefixInputName}${input.name}`}
                />
                <Text font={ENUM_FONTS.TEXT_SMALL} style={{ padding: '4px 8px' }}>
                  {input.label}
                </Text>
                {input.tooltipText && <Tooltip displayBigger={true} text={input.tooltipText} />}
              </ToggleContainer>
            );
          }

          if (input.type === INPUT_TYPES.CHECKBOX) {
            return (
              <CheckboxContainer width={input.width}>
                <Checkbox
                  handleClick={() => field.onChange(!field.value)}
                  isChecked={field.value}
                  isDisabled={
                    (!!input.isDisabled && input.isDisabled(inputValue)) || !isEditionAllowed
                  }
                  shape="square"
                  noSize
                />
              </CheckboxContainer>
            );
          }

          if (
            [INPUT_TYPES.TEXT, INPUT_TYPES.TEXT_NUMBER, INPUT_TYPES.TEXT_WITH_ACTION].includes(
              input.type,
            )
          ) {
            return (
              <InputContainer>
                <Input
                  {...omit(field, 'ref')}
                  error={!!errorMessage && shouldDisplayError}
                  isDisabled={input.isDisabled}
                  isUnchangeable={input.isUnchangeable}
                  label={input.label}
                  link={input.link}
                  placeholder={input.placeholder}
                  readOnly={
                    (!!input.isDisabled && input.isDisabled(inputValue)) || !isEditionAllowed
                  }
                  tooltipText={input.tooltipText}
                  type={'text'}
                  value={input.value || field.value}
                  width={input.width}
                  onChange={(event) => {
                    const value = event.target.value;

                    if (
                      input.type === INPUT_TYPES.TEXT ||
                      input.type === INPUT_TYPES.TEXT_WITH_ACTION
                    ) {
                      field.onChange(value);
                      return;
                    }

                    field.onChange(formatTextNumberInputValue(value));
                  }}
                />
                {input.type === INPUT_TYPES.TEXT_WITH_ACTION && input.button && (
                  <>
                    <InputActionButton>
                      <Button
                        buttonCustomStyle={{
                          borderRadius: '0 8px 8px 0',
                          font: 'normal 600 12px/16px inter',
                          width: '48px',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                        color="inpulse-default"
                        handleClick={() =>
                          !!input.dropdownActions
                            ? triggerActionsDropdown()
                            : input.button.handleClick(field.value)
                        }
                        isDisabled={input.button.isDisabled}
                        label={input.button.text}
                      />
                    </InputActionButton>
                    {isActionDropdownOpened &&
                      !!input.dropdownActions &&
                      !!input.dropdownActions.length &&
                      !input.button.isDisabled && (
                        <AttachmentDropdown
                          actions={input.dropdownActions}
                          selectFileInputRefs={selectFileInputRefs}
                          setIsActionDropdownOpened={setIsActionDropdownOpened}
                        />
                      )}
                  </>
                )}
              </InputContainer>
            );
          }

          if (input.type === INPUT_TYPES.NUMBER) {
            return (
              <InputIncrement
                {...omit(field, 'ref')}
                enableNullValue={true}
                hasErrors={!!errorMessage && inputValue.hasErrors}
                isButtonHidden={input.isButtonHidden}
                isDisabled={
                  (!!input.isDisabled && input.isDisabled(inputValue)) || !isEditionAllowed
                }
                label={input.label}
                limitValues={input.limitValues || { min: 0 }}
                placeholder={input.placeholder}
                setValue={(value) => field.onChange(value)}
                width={input.width}
              />
            );
          }

          if ([INPUT_TYPES.MULTI_SELECT, INPUT_TYPES.SINGLE_SELECT].includes(input.type)) {
            const isSingleSelection = input.type === INPUT_TYPES.SINGLE_SELECT;

            const selection =
              field.value && !isEqual(field.value, {}) ? field.value : input.defaultValue;

            return (
              <Dropdown
                {...omit(field, 'ref')}
                button={input.button}
                customStyle={{ position: 'relative' }}
                height={'auto'}
                iconSrc={input.iconSrc}
                isDisabled={
                  (!!input.isDisabled && input.isDisabled(inputValue)) || !isEditionAllowed
                }
                isErrorState={!!errorMessage && shouldDisplayError}
                isLabelTextSmall={true}
                isRequired={input.isRequired}
                isUniqueSelection={isSingleSelection}
                items={input.items}
                label={input.label}
                link={input.link}
                placeholder={input.placeholder}
                selectedItems={isSingleSelection && selection ? [selection] : selection}
                sortBy={input.sortBy}
                tooltipText={input.tooltipText}
                width={input.width}
                onSelectionChange={(value) => field.onChange(value)}
              />
            );
          }

          if (input.type === INPUT_TYPES.SINGLE_DATEPICKER) {
            const selectedDate = field.value ? field.value : input.defaultValue;
            return (
              <DatePickerContainer>
                {!!input.label && (
                  <LabelContainer>
                    {input.isRequired && <WildCard>*</WildCard>}
                    {input.label}
                    {!!input.tooltipText && (
                      <TooltipContainer>
                        <Tooltip text={input.tooltipText} />
                      </TooltipContainer>
                    )}
                  </LabelContainer>
                )}
                <SingleDatePicker
                  authorizedDates={input.authorizedDates}
                  calendarInfo={input.calendarInfo}
                  customStyle={input.customStyle}
                  date={selectedDate}
                  disabled={
                    (!!input.isDisabled && input.isDisabled(inputValue)) || !isEditionAllowed
                  }
                  displayFormat={input.displayFormat}
                  forbiddenDates={input.forbiddenDates}
                  isErrorState={!!errorMessage && shouldDisplayError}
                  maxFutureDate={input.maxFutureDate}
                  maxPastDate={input.maxPastDate}
                  numberOfMonths={input.numberOfMonths}
                  placeholder={input.placeholder}
                  showClearDate={input.showClearDate}
                  warnedDates={input.warnedDates}
                  onDateChange={(value) => field.onChange(value)}
                  onFocusChange={input.onFocusChange}
                  onNextMonthClick={input.onNextMonthClick}
                  onPrevMonthClick={input.onPrevMonthClick}
                />
              </DatePickerContainer>
            );
          }

          if (input.type === INPUT_TYPES.CUSTOM_RENDER && !!input.customRender) {
            return input.customRender();
          }

          return <>!!NEED INPUT DEFINITION!!</>;
        }}
      />
      {errorMessage && !prefixInputName && shouldDisplayError && shouldDisplayErrorMessage && (
        <ErrorText width={input.width}>{errorMessage}</ErrorText>
      )}
    </Container>
  );
};

FormInput.propTypes = {
  control: PropTypes.any.isRequired, // property form.control from useForm usage
  formState: PropTypes.any.isRequired, // property form.formState from useForm usage
  input: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    width: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.any,
    rule: PropTypes.any,
  }),
  prefixInputName: PropTypes.string,
};

FormInput.defaultProps = {
  prefixInputName: '',
  input: {},
};

export default FormInput;

import { connect } from 'react-redux';
import { get, isEmpty, head } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

import { openSmallModal } from '@actions/modal';
import { showErrorMessage } from '@actions/messageconfirmation';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { ExportButton } from '@commons/ExportButton';
import { Filters } from '@commons/Filters';
import {
  getCustomFilterIsStrategic,
  getCustomFilterMultipleCategory,
} from '@commons/utils/filtersFetches';
import { getPreviousDayOfStore } from '@commons/utils/date';
import { LeftRightSplitter } from '@commons/LeftRightSplitter';
import { NestedList, SearchBar } from '@commons/utils/styledLibraryComponents';
import { PastDayDatePicker } from '@commons/DatePickers/PastDayDatePicker';

import { useDatePickerState } from '@hooks/useDatePickerState';
import { useFiltersButtonState } from '@hooks/useFiltersButtonState';

import { getCentralKitchenStores } from '@selectors/stores';
import { getClientInfo } from '@selectors/client';

import { stock as stockService } from '@services/stock';
import centralService from '@services/central';

import ProductionAnalyticsToggle from '@src/routes/prod/components/AnalyticsContainer/components/ProductionAnalyticsToggle';

import { exportStocksAnalyticsPast } from '@stocks/StocksAnalytics/utils/exportStocksAnalyticsPast';
import { getPastInventoryOfEntityId } from '@stocks/StocksAnalytics/utils/getPastInventoryOfEntityId';
import { getPastStockData } from '@stocks/StocksAnalytics/utils/getPastStockData';
import { METRICS } from '@stocks/StocksAnalytics/utils/commons';
import { PAST_STOCKS_TYPE } from '@stocks/utils/constants';
import { sortByGapPercentage, getCalendarInfo } from '@stocks/utils';

import {
  ContentContainer,
  ListContainer,
  ConfigPanelContainer,
  LeftSide,
  RightSide,
} from './styledComponents';
import { getPastRecipeInventoriesOfRecipe } from './utils/getPastRecipeInventoriesOfRecipe';
import { getPastStockDates } from './utils/getPastStocksDates';
import utilsColumns from './utils/columns';
import utilsFilters from './utils/getPastStocksFilters';

const StocksAnalyticsPastNestedList = (props) => {
  const {
    user,
    currency,
    stores,
    pastStockType,
    showErrorMessage,
    openModalExportInfo,
    client: { clientId, storeName },
  } = props;

  const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');

  const [isLoading, setIsLoading] = useState(false);
  const [selectedMetric, setSelectedMetric] = useState(METRICS[0]);
  const [pastStocks, setPastStocks] = useState([]);
  const [displayedPastStocks, setDisplayedPastStocks] = useState([]);
  const [columns, setColumns] = useState(
    utilsColumns.getListConfig(pastStockType, selectedMetric, currency),
  );
  const [columnsModal] = useState(utilsColumns.getModalConfig());

  // SEARCH BAR & FILTERS STATES
  const [searchInput, setSearchInput] = useState('');
  const [filters, setFilters] = useState({});
  const filtersButtonState = useFiltersButtonState([]);
  const [applyFilters, setApplyFilters] = useState(false);
  const [selectedStore, setSelectedStore] = useState({});
  const [categoriesToChoose, setCategoriesToChoose] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(null);
  const [isStrategic, setIsStrategic] = useState(true);

  // DATE PICKER STATES
  const datePickerState = useDatePickerState(moment().subtract(1, 'day'));
  const [recentDatesWithStocks, setRecentDatesWithStocks] = useState([]);
  const [focusedMonth, setFocusedMonth] = useState(moment());
  const [maxFutureDate, setMaxFutureDate] = useState(moment());

  const [customFilterMultipleCategories, setCustomFilterMultipleCategories] = useState(
    getCustomFilterMultipleCategory(
      categoriesToChoose,
      selectedCategories,
      setSelectedCategories,
      pastStockType === PAST_STOCKS_TYPE.CENTRAL_KITCHEN_INGREDIENTS, // specify if is relied on isStrategic toggle
    ),
  );

  const customFilterIsStrategic = getCustomFilterIsStrategic(isStrategic, setIsStrategic);

  const [filtersModalParams, setFilterModalParams] = useState({
    applyFilters,
    setApplyFilters,
    filters,
    setFilters,
    stores,
    selectedStore,
    setSelectedStore,
    customToggles:
      pastStockType === PAST_STOCKS_TYPE.CENTRAL_KITCHEN_INGREDIENTS
        ? [customFilterIsStrategic]
        : null,
    customMultipleDropDowns: [customFilterMultipleCategories],
  });

  /** USE EFFECTS */
  useEffect(() => {
    if (!selectedStore) {
      // no selectedStore means first load
      const firstLoadedStore = head(stores);
      const previousDayOfStore = getPreviousDayOfStore(firstLoadedStore);

      setMaxFutureDate(previousDayOfStore);
      datePickerState.setDate(previousDayOfStore);

      return;
    }

    const previousDayOfStore = getPreviousDayOfStore(selectedStore);

    setMaxFutureDate(previousDayOfStore);
    datePickerState.setDate(previousDayOfStore);
  }, [stores, selectedStore]);

  useEffect(() => {
    if (isEmpty(stores)) {
      return;
    }
    (async function loadData() {
      setIsLoading(true);

      setSelectedStore(head(stores));

      const updatedFilters =
        pastStockType === PAST_STOCKS_TYPE.CENTRAL_KITCHEN_RECIPES
          ? await utilsFilters.getRecipesPastStockFilters(clientId, stores, {
              setCategoriesToChoose,
              setSelectedCategories,
              customFilterMultipleCategories,
              showErrorMessage,
            })
          : await utilsFilters.getIngredientsPastStockFilters(clientId, stores, {
              setCategoriesToChoose,
              customFilterIsStrategic,
              customFilterMultipleCategories,
              showErrorMessage,
            });

      setFilters(updatedFilters);
      setApplyFilters(true);
      setIsLoading(false);
    })();
  }, [stores]);

  useEffect(() => {
    if (isEmpty(selectedStore) && !isLoading) {
      return;
    }

    (async function loadPastStocksDates() {
      const recentDates = await getPastStockDates(pastStockType, focusedMonth, selectedStore.id, {
        showErrorMessage,
      });
      setRecentDatesWithStocks(recentDates);
    })();
  }, [focusedMonth, selectedStore, datePickerState.date]);

  useEffect(() => {
    if (applyFilters) {
      refreshPastStockData();
    }
  }, [applyFilters]);

  useEffect(() => {
    if (!!datePickerState.date && !isLoading) {
      refreshPastStockData();
    }
  }, [datePickerState.date]);

  useEffect(() => {
    if (!searchInput) {
      setDisplayedPastStocks(pastStocks);
      return;
    }
    const filteredPastStocks = pastStocks.filter(({ name }) =>
      name.toLowerCase().includes(searchInput.toLowerCase()),
    );
    setDisplayedPastStocks(filteredPastStocks);
  }, [searchInput]);

  useEffect(() => {
    setFilterModalParams({
      ...filtersModalParams,
      selectedStore,
      stores,
      filters,
      customMultipleDropDowns: [
        {
          ...customFilterMultipleCategories,
          selectedItems: selectedCategories,
          list: categoriesToChoose,
        },
      ],
      customToggles:
        pastStockType === PAST_STOCKS_TYPE.CENTRAL_KITCHEN_INGREDIENTS
          ? [
              {
                ...customFilterIsStrategic,
                value: isStrategic,
              },
            ]
          : null,
    });
  }, [stores, filters, categoriesToChoose, selectedStore, selectedCategories, isStrategic]);

  useEffect(() => {
    if (selectedCategories) {
      setCustomFilterMultipleCategories({
        ...customFilterMultipleCategories,
        selectedItems: selectedCategories,
      });
    }
  }, [selectedCategories]);

  useEffect(() => {
    setColumns(utilsColumns.getListConfig(pastStockType, selectedMetric, currency));
    if (!isEmpty(selectedStore)) {
      refreshPastStockData();
    }
  }, [pastStockType, selectedMetric, currency]);

  useEffect(() => {
    (async function refreshStockGraph() {
      const formattedPastStocks = await formatData(displayedPastStocks);

      setDisplayedPastStocks(formattedPastStocks);
    })();
  }, [selectedMetric.key]);

  /** FUNCTIONS */

  const formatData = async (data) =>
    data.map((item) => ({
      ...item,
      type: i18next.t(
        pastStockType === PAST_STOCKS_TYPE.CENTRAL_KITCHEN_INGREDIENTS
          ? 'GENERAL.INGREDIENT'
          : 'GENERAL.RECIPE',
      ),
      modalContent: async () =>
        pastStockType === PAST_STOCKS_TYPE.CENTRAL_KITCHEN_INGREDIENTS
          ? await getPastInventoryOfEntityId(
              item.entityId,
              selectedStore,
              selectedMetric,
              currency,
              PAST_STOCKS_TYPE.CENTRAL_KITCHEN_INGREDIENTS,
              showErrorMessage,
            )
          : await getPastRecipeInventoriesOfRecipe(
              item.entityId,
              selectedStore.id,
              selectedMetric,
              currency,
              showErrorMessage,
            ),
      graphContent: async () =>
        await getPastStockData(
          item,
          moment(datePickerState.date).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
          selectedStore,
          selectedMetric,
          currency,
          pastStockType,
          showErrorMessage,
        ),
    }));

  const refreshPastStockData = async () => {
    if (isEmpty(selectedStore)) {
      return;
    }
    setIsLoading(true);

    const categoryIds = selectedCategories ? selectedCategories.map(({ id }) => id) : [];

    const selectedStoreId = selectedStore.id;

    const selectedDateToFetch = moment(datePickerState.date).format(
      DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
    );

    try {
      const data =
        pastStockType === PAST_STOCKS_TYPE.CENTRAL_KITCHEN_INGREDIENTS
          ? await stockService.getPastStocksOfIngredientsByCategories(
              selectedStoreId,
              selectedDateToFetch,
              categoryIds,
              isStrategic,
            )
          : await centralService.getRecipesPastAnalyticsList(
              selectedStoreId,
              selectedDateToFetch,
              categoryIds,
            );

      const formattedData = await formatData(data);

      setPastStocks(formattedData);
      setDisplayedPastStocks(formattedData);
    } catch {
      setPastStocks([]);
      setDisplayedPastStocks([]);
      showErrorMessage(i18next.t('STOCKS.PAST_STOCKS.DATA_FETCH_ERROR'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ContentContainer>
      <ConfigPanelContainer>
        <LeftRightSplitter
          left={
            <LeftSide>
              <SearchBar
                placeholder={i18next.t('GENERAL.SEARCH')}
                setValue={setSearchInput}
                value={searchInput}
              />
              <Filters
                filtersButtonState={filtersButtonState}
                filtersModalParams={filtersModalParams}
                filterText={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
              />
              <PastDayDatePicker
                authorizedDates={recentDatesWithStocks}
                calendarInfo={getCalendarInfo(storeName)}
                date={datePickerState.date}
                maxFutureDate={maxFutureDate}
                numberOfMonths={2}
                onDateChange={datePickerState.setDate}
                onNextMonthClick={(nextMonth) => setFocusedMonth(nextMonth)}
                onPrevMonthClick={(prevMonth) => setFocusedMonth(prevMonth)}
              />
              <ProductionAnalyticsToggle
                choices={METRICS}
                selectedChoice={selectedMetric}
                setSelectedChoice={setSelectedMetric}
              />
            </LeftSide>
          }
        />

        <RightSide>
          <ExportButton
            handleClick={() =>
              exportStocksAnalyticsPast(displayedPastStocks, openModalExportInfo, pastStockType)
            }
            isInpulseOnly
          />
        </RightSide>
      </ConfigPanelContainer>
      <ListContainer>
        <NestedList
          customModalIcon={'icon-stock'}
          customSortFunc={sortByGapPercentage}
          customSortProperty={'stockGapPercentage'}
          data={displayedPastStocks}
          defaultOrderBy={'stockGapPercentage'}
          defaultOrderType={'desc'}
          graphEmptyStateLabel={i18next.t(
            pastStockType === PAST_STOCKS_TYPE.CENTRAL_KITCHEN_INGREDIENTS
              ? 'STOCKS.PAST_STOCKS_INGREDIENTS.GRAPH_EMPTY_STATE_LABEL'
              : 'STOCKS.PAST_STOCKS_RECIPES.GRAPH_EMPTY_STATE_LABEL',
          )}
          headers={columns}
          isLoading={isLoading}
          labelModalEmptyState={i18next.t(
            pastStockType === PAST_STOCKS_TYPE.CENTRAL_KITCHEN_INGREDIENTS
              ? 'STOCKS.PAST_STOCKS_INGREDIENTS.LAST_INVENTORIES_GRAPH_EMPTY_STATE'
              : 'STOCKS.PAST_STOCKS_RECIPES.LAST_INVENTORIES_GRAPH_EMPTY_STATE',
          )}
          languageCode={userLanguageCode}
          minWidth={'978px'}
          modalHeaders={columnsModal}
          modalTitle={i18next.t('STOCKS.PAST_STOCKS.LAST_INVENTORIES')}
        />
      </ListContainer>
    </ContentContainer>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  currency: state.baseReducer.currency,
  stores: getCentralKitchenStores(state.baseReducer.stores),
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  openModalExportInfo: (params) => {
    dispatch(openSmallModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StocksAnalyticsPastNestedList);

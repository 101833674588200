import { APIcaller } from './APIcaller';

const getCentralKitchenProductionPlanning = (storeId, startDate, endDate) =>
  APIcaller.apiPost('/production/central-kitchen/production-planning', {
    storeId,
    startDate,
    endDate,
  });

const getCentralKitchenRecipesOfStore = (storeId, startDate, endDate) =>
  APIcaller.apiPost(`/production/central-kitchen/recipes`, {
    storeId,
    startDate,
    endDate,
  });

const getCentralKitchenRecipesConsumptions = (
  productionStoreId,
  consumptionStoreIds,
  startDate,
  endDate,
  entityIds,
) =>
  APIcaller.apiPost(`/production/central-kitchen/consumption-forecast`, {
    productionStoreId,
    consumptionStoreIds,
    startDate,
    endDate,
    entityIds,
  });

const upsertCentralKitchenProductionPlanning = (
  storeId,
  entityId,
  productionDate,
  quantity,
  userId,
) =>
  APIcaller.apiPost(`/production/central-kitchen/production-planning/${storeId}`, {
    entityId,
    productionDate,
    quantity,
    userId,
  });

const upsertCentralKitchenProductionPlanningByBatch = (productionPlannings) =>
  APIcaller.apiPost(`/production/central-kitchen/batch/production-planning`, {
    productionPlannings,
  });

const getProductionPlanning = (
  storeId,
  { startDate, endDate, categories, includeProductionMininum },
) =>
  APIcaller.apiPost(`/production/stores/${storeId}/production-planning`, {
    startDate,
    endDate,
    categories,
    includeProductionMininum,
  });

const upsertProductionProductForecast = (storeId, productId, { date, tot, delta }) =>
  APIcaller.apiPost(`/production/stores/${storeId}/products/${productId}/product-forecast`, {
    date,
    tot,
    delta,
  });

const downloadProductionPlanningPdf = (
  storeId,
  { startDate, endDate, categories, productionDate, planningConfiguration },
) =>
  APIcaller.apiPost(`/production/stores/${storeId}/planning-pdf`, {
    startDate,
    endDate,
    categories,
    productionDate,
    planningConfiguration,
  });

export default {
  getProductionPlanning,
  getCentralKitchenProductionPlanning,
  getCentralKitchenRecipesOfStore,
  getCentralKitchenRecipesConsumptions,
  upsertCentralKitchenProductionPlanning,
  upsertCentralKitchenProductionPlanningByBatch,
  upsertProductionProductForecast,
  downloadProductionPlanningPdf,
};

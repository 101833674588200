export const COLUMN_TYPES = {
  PRODUCTS: 'PRODUCTS',
  RECIPES: 'RECIPES',
  INGREDIENTS: 'INGREDIENTS',
};

export const COLUMNS = {
  NAME: 'name',
  CATEGORY: 'category',
  TOTAL: 'total',
  SKU: 'sku',
};

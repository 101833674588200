import { orderBy } from 'lodash';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';
import { getTheme } from '@commons/utils/theme';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import {
  Container,
  ContentContainer,
  FooterContainer,
  HeaderContainer,
  LoaderContainer,
  Subtitle,
  TableBorder,
  TextContent,
  Title,
} from './styledComponents';
import { DeepsightComponentLoader } from '@commons/DeepsightComponents';

const StoresCreditDrawerContent = (props) => {
  const { subtitle, onClose, credits, isLoading } = props;

  const [order, setOrder] = useState('asc');
  const [orderedBy, setOrderedBy] = useState('storeName');
  const [orderedCredits, setOrderedCredits] = useState(credits);

  const theme = getTheme();

  const headerCells = [
    {
      id: 'storeName',
      numeric: false,
      label: i18next.t('GENERAL.STORES'),
    },
    {
      id: 'remaining',
      numeric: false,
      label: i18next.t('INVOICE.INVOICE_CONTROLS.DRAWER_REMAINING_CREDITS_COLUMN_HEADER'),
    },
  ];

  const handleSort = (property) => {
    const isAsc = orderedBy === property && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderedBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleSort(property);
  };

  useEffect(() => {
    setOrderedCredits(orderBy(credits, [orderedBy], [order]));
  }, [credits, order, orderedBy]);

  return (
    <Container>
      <HeaderContainer>
        <Title>{i18next.t('INVOICE.INVOICE_CONTROLS.DRAWER_TITLE_CREDITS_LEFT')}</Title>

        <Subtitle>{subtitle}</Subtitle>
      </HeaderContainer>

      {isLoading ? (
        <LoaderContainer>
          <DeepsightComponentLoader height={24} width={24} />
        </LoaderContainer>
      ) : (
        <ContentContainer>
          <TableBorder>
            <TableContainer
              component={Paper}
              style={{ maxHeight: '100%', height: '100%', borderRadius: 'inherit' }}
            >
              <Table aria-label="sticky table" stickyHeader>
                <TableHead sx={{ height: 40 }}>
                  <TableRow>
                    {headerCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        sortDirection={orderedBy === headCell.id ? order : false}
                        sx={{
                          borderRight: `1px solid ${theme.colors.greys.lighty}`,
                          padding: '0 8px 0 8px',
                        }}
                      >
                        <TableSortLabel
                          active={orderedBy === headCell.id}
                          direction={orderedBy === headCell.id ? order : 'asc'}
                          onClick={createSortHandler(headCell.id)}
                        >
                          <Text color={ENUM_COLORS.DGREY} font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}>
                            {headCell.label}
                          </Text>
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderedCredits.map(
                    ({ storeName, remaining, invoiceVolume, insufficientCredit }) => (
                      <TableRow key={storeName} sx={{ height: 40 }} tabIndex={-1}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            borderRight: `1px solid ${theme.colors.greys.lighty}`,
                            height: 40,
                            maxWidth: 200,
                            padding: '0 8px 0 8px',
                          }}
                        >
                          <Text
                            color={insufficientCredit ? ENUM_COLORS.INFO_RED : ENUM_COLORS.DARKEST}
                            font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}
                          >
                            <TextContent>{storeName}</TextContent>
                          </Text>
                        </TableCell>
                        <TableCell sx={{ width: 110, height: 40, padding: '0 8px 0 8px' }}>
                          <Text
                            color={insufficientCredit ? ENUM_COLORS.INFO_RED : ENUM_COLORS.DARKEST}
                            font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}
                          >{`${remaining}/${invoiceVolume}`}</Text>
                        </TableCell>
                      </TableRow>
                    ),
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </TableBorder>
        </ContentContainer>
      )}

      <FooterContainer>
        <Button
          buttonCustomStyle={{ justifyContent: 'center' }}
          color={'inpulse-outline'}
          handleClick={onClose}
          icon={'/images/inpulse/close-black-small.svg'}
          label={i18next.t('GENERAL.CLOSE')}
          minWidth={168}
        />
      </FooterContainer>
    </Container>
  );
};

export default StoresCreditDrawerContent;

import { connect } from 'react-redux';
import { get, isEmpty, set } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';

import { showErrorMessage } from '@actions/messageconfirmation';

import { capitalizeFirstLetter } from '@commons/utils/format';
import { CATEGORY_TYPES_OBJECT } from '@commons/constants/categoryTypes';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import {
  ENUM_CUSTOM_FILTERS,
  fetchSuppliersAndSet,
  getColumnsAdvancedFilters,
  getCustomFilterIsStrategic,
  getCustomFilterSingleCategory,
  getCustomFilterSingleSubCategory,
  getCustomFilterMultipleStatuses,
} from '@commons/utils/filtersFetches';
import { ExportButton } from '@commons/ExportButton';
import { formatNumber } from '@commons/DisplayNumber';
import { getUserTimezone } from '@commons/utils/date';
import { LeftRightSplitter } from '@commons/LeftRightSplitter';
import { NestedList, TimeRangedLineGraph } from '@commons/utils/styledLibraryComponents';
import { PeriodDatePicker } from '@commons/DatePickers/PeriodDatePicker';
import { translatedUnitCondensed } from '@commons/utils/translateUnit';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { usePaginatorState } from '@hooks/usePaginatorState';
import { usePeriodDatePickerState } from '@hooks/usePeriodDatePickerState';

import { getClientInfo } from '@selectors/client';
import { getSalesPointStores } from '@selectors/stores';

import clientService from '@services/client';
import orderService from '@services/order';

import { CHOICES_DROPDOWN_ACTIVE } from '@admin/utils/DropdownItems';

import DeepsightFiltersButton from '@orders/components/FilterButton';

import {
  Container,
  ContentContainer,
  SidePaddedGapContainer,
  FlexedGapContainer,
  ListContainer,
} from './styledComponents';
import { exportPriceEvolution } from './export';
import { getListHeaders } from './config';
import graphTooltipStyles from './graphTooltipStyles';

const DAYS_IN_PAST = 31;

const OrderAnalyticsPriceEvolution = (props) => {
  const {
    client: { clientId, storeName },
    match,
    user,
    showErrorMessage,
    stores,
    currency,
  } = props;

  const path = get(match, 'path');
  const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');
  const userTimezone = getUserTimezone();

  const [headers] = useState(getListHeaders());
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState([]);

  const paginatorState = usePaginatorState();

  /** PeriodDatePicker **/
  const periodPickerState = usePeriodDatePickerState(
    moment.tz(userTimezone).subtract(DAYS_IN_PAST, 'days').startOf('day'),
    moment.tz(userTimezone).endOf('day').subtract(1, 'day'),
    userTimezone,
  );

  /** Filters **/
  const [categoriesToChoose, setCategoriesToChoose] = useState([]);
  const [subCategoriesToChoose, setSubCategoriesToChoose] = useState([]);

  const [suppliers, setSuppliers] = useState([]);

  const [filters, setFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [isStrategic, setIsStrategic] = useState(true);
  const [statuses, setStatuses] = useState(CHOICES_DROPDOWN_ACTIVE());

  const selectedStoreTimezone = get(selectedStore, 'timezone', userTimezone);

  const customFilterMultipleStatuses = getCustomFilterMultipleStatuses(statuses, setStatuses);
  const customFilterIsStrategic = getCustomFilterIsStrategic(isStrategic, setIsStrategic);
  const customFilterSingleCategory = getCustomFilterSingleCategory(
    categoriesToChoose,
    selectedCategory,
    setSelectedCategory,
  );
  const customFilterSingleSubCategory = getCustomFilterSingleSubCategory(
    subCategoriesToChoose,
    selectedSubCategory,
    setSelectedSubCategory,
  );

  useEffect(() => {
    if (!stores.length) {
      return;
    }

    setSelectedStore(stores[0]);
  }, []);

  useEffect(() => {
    if (isEmpty(selectedStore)) {
      return;
    }

    (async function loadData() {
      try {
        const clientSuppliers = await fetchSuppliersAndSet(selectedStore.id, showErrorMessage);
        setIsLoading(true);
        setSuppliers(clientSuppliers);

        const { categories, subCategories } = await clientService.getCategoriesAndSubCategories(
          clientId,
          CATEGORY_TYPES_OBJECT.SUPPLIER_PRODUCT,
        );

        const formattedCategories = categories.map(({ name }) => ({
          name: name || i18next.t('GENERAL.NONE_VALUE'),
        }));

        const formattedSubCategories = subCategories.map(({ name }) => ({
          name: name || i18next.t('GENERAL.NONE_VALUE'),
        }));

        setCategoriesToChoose(formattedCategories);
        setSubCategoriesToChoose(formattedSubCategories);
        setSelectedSuppliers(clientSuppliers);

        const updatedFilters = {
          stores: {
            activeList: stores,
            list: stores,
            selectedStore: selectedStore,
          },
          suppliers: !isEmpty(clientSuppliers)
            ? {
                list: clientSuppliers,
                activeList: clientSuppliers,
                selectedSuppliers: clientSuppliers,
              }
            : null,
          [ENUM_CUSTOM_FILTERS.TOGGLE_IS_STRATEGIC]: customFilterIsStrategic,
          [ENUM_CUSTOM_FILTERS.MULTIPLE_SELECT_STATUSES]: customFilterMultipleStatuses,
          [ENUM_CUSTOM_FILTERS.SINGLE_SELECT_CATEGORY]: !isEmpty(formattedCategories)
            ? {
                ...set(customFilterSingleCategory, 'list', formattedCategories),
              }
            : null,
          [ENUM_CUSTOM_FILTERS.SINGLE_SELECT_SUB_CATEGORY]: !isEmpty(formattedSubCategories)
            ? {
                ...set(customFilterSingleSubCategory, 'list', formattedSubCategories),
              }
            : null,
        };

        setFilters(updatedFilters);
        setApplyFilters(true);
      } catch {
        showErrorMessage(i18next.t('ORDERS.PRICE_EVOLUTION.FETCH_FAILURE'));
        setIsLoading(false);
      }
    })();
  }, [selectedStore]);

  useEffect(() => {
    if (!selectedSuppliers.length || isEmpty(selectedStore)) {
      return;
    }

    (async function loadData() {
      await getPricesEvolutionAnalyticsData();
    })();
  }, [selectedStore, selectedSuppliers]);

  useEffect(() => {
    if (applyFilters) {
      (async function loadData() {
        await getPricesEvolutionAnalyticsData();
      })();
    }
  }, [applyFilters]);

  useEffect(() => {
    if (periodPickerState.startDate && periodPickerState.endDate) {
      (async function loadData() {
        await getPricesEvolutionAnalyticsData();
      })();
    }
  }, [periodPickerState.startDate, periodPickerState.endDate]);

  const addGraphModalRow = (tableBody, { rowLabel, rowValue }) => {
    const tr = document.createElement('tr');
    const tdLabel = document.createElement('td');
    const tdValue = document.createElement('td');

    const tdLabelDiv = document.createElement('div');
    const tdValueDiv = document.createElement('div');

    graphTooltipStyles.setElementStyle(tdLabelDiv, graphTooltipStyles.tableLabel);
    graphTooltipStyles.setElementStyle(tdValueDiv, graphTooltipStyles.tableValue);
    graphTooltipStyles.setElementStyle(tr, graphTooltipStyles.tableRow);

    tdLabelDiv.appendChild(document.createTextNode(rowLabel));
    tdValueDiv.appendChild(document.createTextNode(rowValue));

    tdLabel.appendChild(tdLabelDiv);
    tdValue.appendChild(tdValueDiv);

    tr.appendChild(tdLabel);
    tr.appendChild(tdValue);

    tableBody.appendChild(tr);
  };

  const renderGraphModalBody = (tooltip, statsObject, unit) => {
    const tableBody = document.createElement('tbody');

    addGraphModalRow(tableBody, {
      rowLabel: i18next.t('ORDERS.PRICE_EVOLUTION.GRAPH_TOOLTIP_PRICE_PER_UNIT', {
        unit: translatedUnitCondensed(unit),
      }),
      rowValue: `${formatNumber(statsObject.pricePerUnit, 2)} ${currency.alphabeticCode}`,
    });
    addGraphModalRow(tableBody, {
      rowLabel: i18next.t('ORDERS.PRICE_EVOLUTION.GRAPH_TOOLTIP_ORDERED_IN_CURRENCY'),
      rowValue: `${formatNumber(statsObject.ordered, 2)} ${currency.alphabeticCode}`,
    });
    addGraphModalRow(tableBody, {
      rowLabel: i18next.t('ORDERS.PRICE_EVOLUTION.GRAPH_TOOLTIP_ORDERED_IN_QUANTITY'),
      rowValue: `${formatNumber(statsObject.quantity, 2)} ${translatedUnitCondensed(unit)}`,
    });
    addGraphModalRow(tableBody, {
      rowLabel: i18next.t('ORDERS.PRICE_EVOLUTION.GRAPH_TOOLTIP_ORDERS_NUMBER'),
      rowValue: `${statsObject.ordersNb}`,
    });

    graphTooltipStyles.setElementStyle(tableBody, graphTooltipStyles.tableBody);

    const tableRoot = tooltip.querySelector('table');

    tableRoot.appendChild(tableBody);
  };

  const renderGraphModalTitle = (tooltip, title) => {
    const tableHead = document.createElement('thead');

    const tr = document.createElement('tr');
    const th = document.createElement('th');

    const formattedTitle = capitalizeFirstLetter(
      moment.tz(title, selectedStoreTimezone).format(DATE_DISPLAY_FORMATS.FULL_DAY_DATE_MONTH),
    );

    graphTooltipStyles.setElementStyle(th, graphTooltipStyles.tableTitle);

    th.appendChild(document.createTextNode(formattedTitle));
    tr.appendChild(th);
    tableHead.appendChild(tr);

    const tableRoot = tooltip.querySelector('table');

    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    tableRoot.appendChild(tableHead);
  };

  const formatForNestedList = (pricesEvolutionData) =>
    pricesEvolutionData.map((spPriceEvolution) => ({
      ...spPriceEvolution,
      ingredient: spPriceEvolution.ingredient || '-',
      nestedContent: () => (
        <TimeRangedLineGraph
          chartName={`${spPriceEvolution.name}_${spPriceEvolution.unit}`}
          endDate={periodPickerState.endDate}
          legend={i18next.t('ORDERS.PRICE_EVOLUTION.GRAPH_LEGEND')}
          rawData={spPriceEvolution.stats}
          renderTooltipBody={(tooltipEl, data) =>
            renderGraphModalBody(tooltipEl, data, spPriceEvolution.unit)
          }
          renderTooltipHeader={renderGraphModalTitle}
          startDate={periodPickerState.startDate}
          timezone={userTimezone}
          title={i18next.t('ORDERS.PRICE_EVOLUTION.GRAPH_TITLE', {
            currencyCode: currency.alphabeticCode,
            unit: spPriceEvolution.unit,
            startDate: periodPickerState.startDate.format(
              DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR,
            ),
            endDate: periodPickerState.endDate.format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
          })}
          tooltipTitlePropertyKey="orderDate"
          xAxisPropertyKey="orderDate"
          yAxisPropertyKey="pricePerUnit"
        />
      ),
    }));

  const getPricesEvolutionAnalyticsData = async () => {
    if (isEmpty(selectedStore) || !selectedSuppliers.length) {
      return;
    }
    setIsLoading(true);

    try {
      const category = selectedCategory && !isStrategic ? [selectedCategory.name] : null;
      const subCategory = selectedSubCategory && !isStrategic ? [selectedSubCategory.name] : null;

      const selectedSuppliersIds = selectedSuppliers.map(({ id }) => id);
      const startDate = moment
        .tz(periodPickerState.startDate, userTimezone)
        .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
      const endDate = moment
        .tz(periodPickerState.endDate, userTimezone)
        .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

      const pricesEvolutionAnalyticsData = await orderService.getOrdersAnalyticsPricesEvolution(
        clientId,
        startDate,
        endDate,
        [selectedStore.id],
        selectedSuppliersIds,
        isStrategic,
        category,
        subCategory,
      );

      const statusesFilter = statuses.map((status) => status.filterValue);
      const filteredByStatus = pricesEvolutionAnalyticsData.filter((analytic) =>
        statusesFilter.includes(get(analytic, 'active', false)),
      );

      // No advanced filter to take into consideration, then early return
      if (isEmpty(advancedFilters)) {
        setData(formatForNestedList(filteredByStatus));

        return;
      }

      const filteredDataWithAdvancedFilters = advancedFilters.reduce(
        (result, { doFilter, propertyKey, value }) => doFilter(result, propertyKey, value),
        filteredByStatus,
      );

      setData(formatForNestedList(filteredDataWithAdvancedFilters));
    } catch (err) {
      setData([]);
      showErrorMessage(i18next.t('ORDERS.PRICE_EVOLUTION.FETCH_FAILURE'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <NavigationBreadCrumb featurePath={path} />
      <ContentContainer>
        <SidePaddedGapContainer>
          <LeftRightSplitter
            left={
              <FlexedGapContainer>
                <DeepsightFiltersButton
                  advancedFilters={advancedFilters}
                  columnsFilterList={getColumnsAdvancedFilters(headers)}
                  customMultipleDropDowns={[customFilterMultipleStatuses]}
                  customSingleDropDowns={[
                    customFilterSingleCategory,
                    customFilterSingleSubCategory,
                  ]}
                  customToggles={[customFilterIsStrategic]}
                  filters={filters}
                  readOnly={isLoading}
                  selectedStore={selectedStore}
                  selectedSuppliers={selectedSuppliers}
                  setAdvancedFilters={setAdvancedFilters}
                  setApplyFilters={setApplyFilters}
                  setFilters={setFilters}
                  setSelectedStore={setSelectedStore}
                  setSelectedSuppliers={setSelectedSuppliers}
                  stores={stores}
                  suppliers={suppliers}
                  textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
                />
                <PeriodDatePicker
                  disabled={isLoading}
                  endDate={periodPickerState.endDate}
                  focusedDateInput={periodPickerState.focusedDateInput}
                  maxFutureDate={moment.tz(getUserTimezone()).endOf('day').subtract(1, 'day')}
                  setFocusedDateInput={periodPickerState.setFocusedDateInput}
                  startDate={periodPickerState.startDate}
                  timezone={userTimezone}
                  onDatesChange={periodPickerState.handleSelectedDates}
                />
              </FlexedGapContainer>
            }
            right={
              <ExportButton
                handleClick={() => {
                  exportPriceEvolution({
                    data,
                    metaData: {
                      selectedStore,
                      startDate: periodPickerState.startDate,
                      endDate: periodPickerState.endDate,
                      isStrategic,
                      selectedSuppliers,
                      selectedCategory,
                      selectedSubCategory,
                      statuses,
                    },
                    clientStoreName: storeName,
                    currency,
                  });
                }}
                isDisabled={isLoading}
                isInpulseOnly={true}
              />
            }
          />
        </SidePaddedGapContainer>
        <ListContainer>
          <NestedList
            currentPage={paginatorState.currentPage}
            data={data}
            defaultOrderBy={`orderedInCurrencyVolume`}
            defaultOrderType={'desc'}
            headers={headers}
            isLoading={isLoading}
            itemsCount={data.length}
            labelEmptyState={i18next.t('GENERAL.NO_RESULT')}
            languageCode={userLanguageCode}
            maxPerPage={paginatorState.maxPerPage}
            maxPerPageOptions={paginatorState.maxPerPageOptions}
            minWidth={'978px'}
            setCurrentPage={paginatorState.setCurrentPage}
            setMaxPerPage={paginatorState.setMaxPerPage}
            hasPagination
          />
        </ListContainer>
      </ContentContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  stores: getSalesPointStores(state.baseReducer.activeStores),
  client: getClientInfo(state.baseReducer.user),
  currency: state.baseReducer.currency,
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderAnalyticsPriceEvolution);

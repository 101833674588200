import { groupBy, keys, mapValues, orderBy } from 'lodash';

export default (productOrders) => {
  const formattedProductOrders = aggregateProductOrders(productOrders);

  return orderBy(
    formattedProductOrders,
    ['receptionDate', 'supplier', 'subCategory', 'name'],
    ['desc', 'asc', 'asc', 'asc'],
  );
};

const aggregateProductOrders = (productOrders) => {
  const groupedOrders = groupByMultipleKeys(productOrders, [
    'order.deliveryDate',
    'supplierProduct.id',
  ]);

  return keys(groupedOrders).reduce((result, deliveryDate) => {
    keys(groupedOrders[deliveryDate]).forEach((supplierProductId) => {
      const productOrder = groupedOrders[deliveryDate][supplierProductId].reduce(
        (info, current) => ({
          sku: current.supplierProduct.sku,
          receptionDate: current.order.startOrderDate,
          name: current.supplierProduct.name,
          supplier: current.supplier.name,
          subCategory: current.supplierProduct.subCategory,
          packagingName: current.supplierProduct.packagings.find(
            ({ isUsedInOrder }) => isUsedInOrder,
          ).name,
          vatRate: current.supplierProduct.vatRate,
          ordered: info.ordered + (isNaN(current.ordered.quantity) ? 0 : current.ordered.quantity),
          totalExclTaxes:
            info.totalExclTaxes +
            (isNaN(current.ordered.quantity)
              ? 0
              : current.ordered.quantity * current.ordered.price),
        }),
        {
          ordered: 0,
          totalExclTaxes: 0,
        },
      );

      result.push({ ...productOrder, deliveryDate });
    });

    return result;
  }, []);
};
const groupByMultipleKeys = (array, keys) => {
  if (!keys.length) {
    return array;
  }

  const first = keys[0];

  const rest = keys.slice(1);

  return mapValues(groupBy(array, first), (value) => groupByMultipleKeys(value, rest));
};

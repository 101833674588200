import { connect } from 'react-redux';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { showErrorMessage } from '@actions/messageconfirmation';

import { ListView } from '@commons/utils/styledLibraryComponents';
import { Tags } from '@commons/Tags';
import EmptyState from '@commons/EmptyState';
import Text from '@commons/Text';

import useLocalStorage from '@hooks/useLocalStorage';

import centralService from '@services/central';
import recipeService from '@services/recipe';

import { getPropertyNoneValue } from '@commons/constants/categoryTypes';

import { Container } from './styledComponents';

const getColumns = (hasMultipleBrands) => {
  const columns = [{ id: 'name', name: i18next.t('GENERAL.NAME'), propertyKey: 'name' }];

  if (hasMultipleBrands) {
    columns.push({
      id: 'brands',
      name: i18next.t('GENERAL.BRAND_PLURAL'),
      propertyKey: 'brands',
      render: (brands) => <Tags items={brands.map(({ name }) => name)} />,
    });
  }

  columns.push({
    id: 'category',
    name: i18next.t('GENERAL.CATEGORY'),
    propertyKey: 'category',
    render: (category) => <Text>{category || i18next.t('GENERAL.NONE_VALUE')}</Text>,
  });

  return columns;
};

const RecipesMappingsModal = (props) => {
  const {
    clientId,
    hasMultipleBrands,
    showErrorMessage,
    setRecipesToBeAdded,
    alreadyMappedRecipes = [],
  } = props;

  const [isInCentralMode] = useLocalStorage('isCentralMode', false);

  const [listViewColumns] = useState(getColumns(hasMultipleBrands));
  const [isLoadingRecipes, setIsLoadingRecipes] = useState(true);
  const [availableRecipes, setAvailableRecipes] = useState([]);

  useEffect(() => {
    (async () => {
      await reloadRecipes();
    })();
  }, []);

  /** FUNCTIONS */
  const reloadRecipes = async () => {
    setIsLoadingRecipes(true);

    try {
      const entityIdsToExclude = alreadyMappedRecipes.map(({ entityId, id }) => entityId || id);

      const recipes = isInCentralMode
        ? await centralService.getKitchenRecipesOfClient(clientId)
        : await recipeService.getRecipesOfClient(clientId);

      const activeRecipes = recipes.filter(({ active }) => active);

      const formattedAvailableRecipes = activeRecipes.reduce(
        (acc, { id, name, category, isKitchen, brands }) => {
          if (!entityIdsToExclude.includes(id)) {
            acc.push({
              id,
              name,
              category: category || getPropertyNoneValue(),
              isKitchen,
              brands,
              isRowSelected: false,
            });
          }

          return acc;
        },
        [],
      );

      setAvailableRecipes(formattedAvailableRecipes);
    } catch {
      showErrorMessage(i18next.t('ADMIN.RECIPES.COMPOSITION_FETCH_ERROR_PLURAL'));
    } finally {
      setIsLoadingRecipes(false);
    }
  };

  const handleSelection = (selectedItems) => {
    const formattedRecipes = selectedItems.map(({ id, name, category, isKitchen, brands }) => ({
      name,
      category,
      entityId: id,
      brands,
      isKitchen,
    }));

    setRecipesToBeAdded(formattedRecipes);
  };

  return (
    <Container>
      <ListView
        columns={listViewColumns}
        data={availableRecipes}
        defaultMaxPerPage={500}
        isLoading={isLoadingRecipes}
        maxPerPageOptions={[10, 20, 50, 100, 500, 1000]}
        renderEmptyState={() => <EmptyState />}
        setSelectedItems={handleSelection}
        forceEnableSelection
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RecipesMappingsModal);

import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  color: ${({ theme }) => theme.colors.blacks.ipBlack4};
`;

export const Title = styled.div`
  font: ${({ theme }) => theme.fonts.textSmallHeight16};
`;

export const HeaderContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
`;

import styled, { css } from 'styled-components';

export const DropdownItem = styled.div`
  width: 100%;
  padding: 12px 0px 0px 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const DropdownItemTitle = styled.div`
  font: ${(props) => props.theme.fonts.textMiddleNormal};
  margin-bottom: 2px;
  padding-right: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const DropdownItemSubTitle = styled.div`
  font: ${(props) => props.theme.fonts.textSmallHeight16};
  color: ${(props) => props.theme.colors.greys.dark};
  margin-top: 2px;
  padding-right: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${(props) =>
    props.isDisabled &&
    css`
      color: ${props.theme.colors.greys.light};
    `};
`;

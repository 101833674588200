import { connect } from 'react-redux';
import { get, keys } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

import { openSmallModal } from '@actions/modal';
import { showErrorMessage } from '@actions/messageconfirmation';

import { Button, Dropdown, Franco, NestedList } from '@commons/utils/styledLibraryComponents';
import {
  getClientStoreNameTranslation,
  getFormattedCurrencyName,
} from '@commons/utils/translations';
import { getTheme } from '@commons/utils/theme';
import { numberToFixed } from '@commons/utils/format';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { getUserTimezone } from '@commons/utils/date';
import { LeftRightSplitter } from '@commons/LeftRightSplitter';
import { PeriodDatePicker } from '@commons/DatePickers/PeriodDatePicker';
import DisplayNumber from '@commons/DisplayNumber';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { usePeriodDatePickerState } from '@hooks/usePeriodDatePickerState';

import { getClientInfo } from '@selectors/client';
import { getSalesPointStores } from '@selectors/stores';

import { forecast as forecastService } from '@services/forecast';

import getStatsFromData from '../../utils/getStatsFromData';

import {
  Container,
  ListContainer,
  ContentContainer,
  FlexedGapContainer,
  SidePaddedGapContainer,
  ButtonContainer,
} from './styledComponents';
import { exportPastMix } from './exports/export';
import { getListHeaders } from './config';
import ExportPastMixByStoreModal from './exports/ExportPastMixByStoreModal';

const PRODUCT_LINK_PREFIX = '/admin/products/';
const PRODUCT_LINK_SUFFIX = '/details';
const PERIOD_NB_DAYS_DEFAULT = 8;
const USER_TIMEZONE = getUserTimezone();
const YESTERDAY = moment.tz(USER_TIMEZONE).subtract(1, 'days').endOf('day');

const PastMix = (props) => {
  const {
    match,
    user,
    stores,
    currency,
    openSmallModal,
    showErrorMessage,
    client: { useTtc, storeName },
  } = props;

  const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');
  const theme = getTheme();

  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([]);

  const [selectedStores, setSelectedStores] = useState(
    !!stores && stores.length ? [stores[0]] : [],
  );

  const [headers] = useState(getListHeaders());

  const defaultStartDate = moment
    .tz(USER_TIMEZONE)
    .subtract(PERIOD_NB_DAYS_DEFAULT, 'days')
    .startOf('day');
  const [maxFutureDate, setMaxFutureDate] = useState(YESTERDAY);

  const periodPickerState = usePeriodDatePickerState(
    defaultStartDate,
    maxFutureDate,
    USER_TIMEZONE,
  );
  const [headerData, setHeaderData] = useState({});

  const triggerExportAnalyticsByStore = () => {
    openSmallModal({
      component: ExportPastMixByStoreModal,
      currency,
      context: {
        stores: selectedStores,
        startDate: periodPickerState.startDate,
        endDate: periodPickerState.endDate,
      },
    });
  };

  const actions = [
    {
      label: i18next.t('FORECAST.PAST_MIX.EXPORT_ACTION'),
      icon: `/images/inpulse/file-download-black-small.svg`,
      handleClick: () => {
        exportPastMix({
          data,
          useTtc,
          currency,
          clientStoreName: storeName,
          startDate: periodPickerState.startDate,
          endDate: periodPickerState.endDate,
          stores: selectedStores,
        });
      },
    },
    {
      label: i18next.t('FORECAST.PAST_MIX.EXPORT_BY_STORE_ACTION'),
      icon: `/images/inpulse/file-download-black-small.svg`,
      handleClick: triggerExportAnalyticsByStore,
    },
  ];

  const formatForNestedList = (pastMixData) => {
    const productsByCategory = get(pastMixData, 'productsByCategory', []);
    return keys(productsByCategory).reduce((result, category) => {
      const data = productsByCategory[category];
      const nestedData = data.nestedData.map((product) => ({
        id: product.id,
        name: product.name,
        link: `${PRODUCT_LINK_PREFIX}${product.id}${PRODUCT_LINK_SUFFIX}`,
        ...getStatsFromData(product),
      }));
      const item = {
        name: category,
        nestedData: nestedData,
        ...getStatsFromData(data),
      };

      result.push(item);
      return result;
    }, []);
  };

  const getPastMixData = async () => {
    setIsLoading(true);

    try {
      const startDate = moment
        .tz(periodPickerState.startDate, USER_TIMEZONE)
        .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
      const endDate = moment
        .tz(periodPickerState.endDate, USER_TIMEZONE)
        .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

      const selectedStoreIds = selectedStores.map(({ id }) => id);

      const pastMix = await forecastService.getPastProductMix(selectedStoreIds, startDate, endDate);

      const formattedData = formatForNestedList(pastMix);

      setData(formattedData);

      const marginRatio = get(pastMix, 'margin.ratioToTurnover', null);

      setHeaderData({
        turnover: get(pastMix, 'turnoverExcludingTaxes', null),
        margin: get(pastMix, 'margin.total', null),
        ratio: numberToFixed(marginRatio, 2, null, ' %'),
      });
    } catch {
      setData([]);
      showErrorMessage(i18next.t('FORECAST.PAST_MIX.FETCH_DATA_FAILURE'));
    } finally {
      setIsLoading(false);
    }
  };

  const getCalendarInfo = () => ({
    globalInfo: i18next.t('FORECAST.PAST_MIX.DATEPICKER_TOOLTIP', {
      storeNameSingle: getClientStoreNameTranslation(storeName, false).toLowerCase(),
      storeNamePlural: getClientStoreNameTranslation(storeName, true).toLowerCase(),
    }),
  });

  useEffect(() => {
    const periodDiff = periodPickerState.endDate.diff(periodPickerState.startDate, 'days');

    if (selectedStores.length > 1 && periodDiff > 31) {
      periodPickerState.handleSelectedDates(
        {
          startDate: defaultStartDate,
          endDate: YESTERDAY,
        },
        true,
      );
    }
  }, [selectedStores]);

  useEffect(() => {
    const maxEndDate = moment
      .tz(periodPickerState.startDate, USER_TIMEZONE)
      .add(1, selectedStores.length > 1 ? 'month' : 'year');

    setMaxFutureDate(moment.min(YESTERDAY, maxEndDate));
  }, [periodPickerState.startDate]);

  useEffect(() => {
    if (periodPickerState.startDate && periodPickerState.endDate) {
      const periodDiff = periodPickerState.endDate.diff(periodPickerState.startDate, 'days');

      if (!selectedStores.length || (selectedStores.length > 1 && periodDiff > 31)) {
        return;
      }

      (async function loadData() {
        await getPastMixData();
      })();
    }
  }, [selectedStores, periodPickerState.startDate, periodPickerState.endDate]);

  return (
    <Container>
      <NavigationBreadCrumb featurePath={match.path} />
      <ContentContainer>
        <SidePaddedGapContainer>
          <LeftRightSplitter
            left={
              <FlexedGapContainer>
                <Dropdown
                  iconSrc="/images/inpulse/pin-black-small.svg"
                  isDisabled={isLoading}
                  isUniqueSelection={false}
                  items={stores}
                  searchPlaceholder={i18next.t('GENERAL.SEARCH')}
                  selectedItems={selectedStores}
                  isRequired
                  onSelectionChange={setSelectedStores}
                />
                <PeriodDatePicker
                  calendarInfo={getCalendarInfo()}
                  disabled={isLoading}
                  endDate={periodPickerState.endDate}
                  focusedDateInput={periodPickerState.focusedDateInput}
                  maxFutureDate={maxFutureDate}
                  setFocusedDateInput={periodPickerState.setFocusedDateInput}
                  startDate={periodPickerState.startDate}
                  timezone="Europe/Paris" // TODO - [TIMEZONES] replace this when IPP-5225 TIMEZONES Forecast Module is finished
                  onDatesChange={periodPickerState.handleSelectedDates}
                />
                <Franco
                  content={
                    <DisplayNumber
                      number={get(headerData, 'turnover', null)}
                      displayCurrencyCode
                      shortenDigits
                    />
                  }
                  infoTooltip={i18next.t('FORECAST.PAST_MIX.TURNOVER_EX_TAX_TOOLTIP', {
                    currencyName: getFormattedCurrencyName(currency.shortenedName, true),
                  })}
                  title={`${i18next.t('GENERAL.METRIC_TURNOVER_EX_TAX_WITHOUT_CURRENCY')} :`}
                  width={'248px'}
                  hasBorder
                />
                <Franco
                  background={theme.colors.greys.dark}
                  color={theme.colors.greys.lightest}
                  content={
                    <DisplayNumber
                      number={get(headerData, 'margin', null)}
                      displayCurrencyCode
                      shortenDigits
                    />
                  }
                  infoTooltip={i18next.t('GENERAL.METRIC_MARGIN_EX_TAX_TOOLTIP')}
                  labelContent={get(headerData, 'ratio', '')}
                  title={`${i18next.t('GENERAL.METRIC_MARGIN_EX_TAX_WITHOUT_CURRENCY')} :`}
                  width={'248px'}
                  hasBorder
                />
              </FlexedGapContainer>
            }
            right={
              <ButtonContainer style={{ display: 'flex', flex: 1, justifyContent: 'end' }}>
                <Button
                  actions={actions}
                  color={'inpulse-outline'}
                  iconDropdown={'/images/inpulse/carret-black.svg'}
                  iconOnLeft={false}
                  label={i18next.t('GENERAL.ACTIONS')}
                />
              </ButtonContainer>
            }
          />
        </SidePaddedGapContainer>
        <ListContainer>
          <NestedList
            data={data}
            defaultOrderBy={useTtc ? 'mix' : 'mixExcludingTaxes'}
            defaultOrderType={'desc'}
            headers={headers}
            isLoading={isLoading}
            labelEmptyState={i18next.t('GENERAL.NO_RESULT')}
            languageCode={userLanguageCode}
            minWidth={'978px'}
            hasNestedData
          />
        </ListContainer>
      </ContentContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  userRights: state.baseReducer.userRights,
  stores: getSalesPointStores(state.baseReducer.activeStores),
  client: getClientInfo(state.baseReducer.user),
  currency: state.baseReducer.currency,
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  openSmallModal: (params) => {
    dispatch(openSmallModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PastMix);

import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;

  background-color: ${(props) => props.theme.colors.greys.lightest};

  border-radius: 8px;
  border: solid 1px ${(props) => props.theme.colors.greys.lighty};

  background-color: ${(props) => props.theme.colors.greys.lightest};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;

  gap: 8px;
`;

export const FileSizeContainer = styled.div`
  /* text-wrap: nowrap; */
  white-space: nowrap;
`;

export const CellContent = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
`;

export const LoaderContainer = styled.div`
  width: fit-content;
  margin-bottom: 24px;

  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors.greys.lighter};
`;

export const StatusContainer = styled.div`
  height: 28px;
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;
`;

export const StoreNameContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 2px;
`;

export const DropdownContainer = styled.div`
  width: 100%;
  height: 100%;

  > div:first-child > div:first-child {
    border-style: none;
    border-radius: 0;
    height: 100%;

    ${({ isDisabled, theme }) =>
      isDisabled &&
      css`
        background-color: ${theme.colors.greys.lighter};
      `}
  }
`;

// MUI styles
export const getStoreTableCellSX = (theme, error = false) => ({
  borderRight: `1px solid ${theme.colors.greys.lighty}`,
  borderLeft: `1px solid ${theme.colors.greys.lighty}`,

  ...(error && {
    border: `1px solid ${theme.colors.infoRed}`,
  }),
  width: 228,
  padding: '0px 0px 0px 0px',
  position: 'relative',
});

export const tableCellStoreNameCustomStyle = {
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2, // Limit to 2 lines
  textOverflow: 'ellipsis',
  wordBreak: 'break-word', // Break long words
  overflowWrap: 'break-word', // Fallback for long unbroken text
};

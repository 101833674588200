import i18next from 'i18next';

export const CATEGORY_TYPES_OBJECT = {
  RECIPE: 'recipe',
  CENTRAL_KITCHEN_RECIPE: 'centralKitchenRecipe',
  INGREDIENT: 'ingredient',
  SUPPLIER_PRODUCT: 'supplierProduct',
  PRODUCT: 'product',
  CENTRAL_KITCHEN_PRODUCT: 'centralKitchenProduct',
  SUPPLIER: 'supplier',
};

export const SUB_CATEGORY_TYPES_OBJECT = {
  SUPPLIER_PRODUCT: 'supplierProduct',
  PRODUCT: 'product',
  CENTRAL_KITCHEN_PRODUCT: 'centralKitchenProduct',
};

export const getPropertyNoneValue = () => i18next.t('GENERAL.NONE_VALUE');

export const getAssignCategoriesModalParams = () => ({
  title: i18next.t('GENERAL.LIST_ACTION_ASSIGN_CATEGORY'),
  closeContent: i18next.t('ADMIN.GENERAL.MANAGE_CATEGORIES_CLOSE_CONFIRMATION_CONTENT'),
});

export const getAssignSubCategoriesModalParams = () => ({
  title: i18next.t('GENERAL.LIST_ACTION_ASSIGN_SUBCATEGORY'),
  closeContent: i18next.t('ADMIN.GENERAL.MANAGE_SUB_CATEGORIES_CLOSE_CONFIRMATION_CONTENT'),
});

export const getManageCategoriesModalParams = () => ({
  title: i18next.t('ADMIN.GENERAL.MANAGE_CATEGORIES'),
  closeContent: i18next.t('ADMIN.GENERAL.MANAGE_CATEGORIES_CLOSE_CONFIRMATION_CONTENT'),
});

export const getManageSubCategoriesModalParams = () => ({
  title: i18next.t('ADMIN.GENERAL.MANAGE_SUB_CATEGORIES'),
  closeContent: i18next.t('ADMIN.GENERAL.MANAGE_SUB_CATEGORIES_CLOSE_CONFIRMATION_CONTENT'),
});

import { orderBy, get } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { ENTITY_UNITS } from '@commons/constants/units';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getUserTimezone } from '@commons/utils/date';
import { numberToFixed } from '@commons/utils/format';
import utilsXLS from '@commons/utils/makeXLS';

const createPriceEvolutionSheet = (data, currency) => {
  const title = i18next.t('ORDERS.PRICE_EVOLUTION.PRICE_EVOLUTION');

  const columns = [
    {
      name: i18next.t('GENERAL.SUPPLIER_PRODUCT'),
      propertyKey: 'name',
    },
    {
      name: i18next.t('GENERAL.CATEGORY'),
      propertyKey: 'category',
      transform: (category) => category || i18next.t('GENERAL.NONE_VALUE'),
    },
    {
      name: i18next.t('GENERAL.SUB_CATEGORY'),
      propertyKey: 'subCategory',
      transform: (subCategory) => subCategory || i18next.t('GENERAL.NONE_VALUE'),
    },
    {
      name: i18next.t('GENERAL.SUPPLIER'),
      propertyKey: 'supplierName',
    },
    {
      name: i18next.t('GENERAL.ASSOCIATED_INGREDIENT'),
      propertyKey: 'ingredient',
    },
    {
      name: i18next.t('GENERAL.STRATEGIC'),
      propertyKey: 'isHighlighted',
    },
    {
      name: i18next.t('GENERAL.UNIT'),
      propertyKey: 'unit',
      transform: (value) => (value === ENTITY_UNITS.UNIT ? i18next.t('GENERAL.UNIT') : value),
    },
    {
      name: i18next.t('ANALYSIS.PURCHASES.COLUMN_NAME_TOT_PO'),
      propertyKey: 'orderedInCurrencyVolume',
      type: 'currency',
    },
    {
      name: i18next.t('ORDERS.PRICE_EVOLUTION.INITIAL_PRICE_COLUMN'),
      propertyKey: `initialPrice`,
      type: 'currency',
    },
    {
      name: i18next.t('ORDERS.PRICE_EVOLUTION.FINAL_PRICE_COLUMN'),
      propertyKey: `finalPrice`,
      type: 'currency',
    },
    {
      name: i18next.t('ORDERS.PRICE_EVOLUTION.PRICE_EVOLUTION_COLUMN'),
      propertyKey: 'priceEvolution',
      transform: (value) => numberToFixed(value, 2, '-', '%'),
    },
  ];

  return utilsXLS.generateDefaultSheet(title, columns, data, currency);
};

const createInfoSheet = (metaData, clientStoreName) => {
  const {
    selectedStore,
    startDate,
    endDate,
    isStrategic,
    selectedSuppliers,
    selectedCategory,
    selectedSubCategory,
    statuses,
  } = metaData;

  const headers = [
    getClientStoreNameTranslation(clientStoreName, true),
    i18next.t('GENERAL.PERIOD_START'),
    i18next.t('GENERAL.PERIOD_END'),
    i18next.t('ADMIN.INGREDIENTS.STRATEGIC'),
    i18next.t('GENERAL.SUPPLIER_PLURAL'),
    i18next.t('GENERAL.CATEGORIES'),
    i18next.t('GENERAL.SUB_CATEGORIES'),
    i18next.t('GENERAL.STATUSES'),
  ];

  const selectedStoreName = selectedStore.name;

  const selectedSupplierNames = selectedSuppliers
    .map(({ name }) => name)
    .sort()
    .toString();
  const statusesNames = statuses.map(({ name }) => name).toString();

  return {
    title: i18next.t('GENERAL.INFORMATIONS'),
    headers,
    data: [
      [
        selectedStoreName,
        moment.tz(startDate, getUserTimezone()).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
        moment.tz(endDate, getUserTimezone()).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
        isStrategic,
        selectedSupplierNames,
        get(selectedCategory, 'name', ''),
        get(selectedSubCategory, 'name', ''),
        statusesNames,
      ],
    ],
  };
};

export const exportPriceEvolution = ({ data, metaData, clientStoreName, currency }) => {
  const sortedData = orderBy(data, 'orderedInCurrencyVolume', 'desc');

  const priceEvolutionSheet = createPriceEvolutionSheet(sortedData, currency);
  const infoSheet = createInfoSheet(metaData, clientStoreName);

  const priceEvolutionFilename = i18next.t('ORDERS.PRICE_EVOLUTION.EXPORT_FILENAME', {
    date: moment.tz(getUserTimezone()).format(DATE_DISPLAY_FORMATS.STICKED_YEAR_MONTH_DAY),
  });

  utilsXLS.makeXLS(priceEvolutionFilename, [priceEvolutionSheet, infoSheet]);
};

import { get } from 'lodash';
import i18next from 'i18next';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';

import { brand as brandService } from '@services/brand';
import { group as groupService } from '@services/group';
import { supplier as supplierService } from '@services/supplier';
import accountService from '@services/account';
import clientService from '@services/client';
import orderService from '@services/order';

const fetchSuppliersOfClient = async (clientId, pageLoading, pageLoaded, showMessage) => {
  pageLoading();

  try {
    const suppliers = await supplierService.getSuppliersOfClient(clientId);

    return suppliers;
  } catch (err) {
    showMessage(i18next.t('ORDERS.BY_CATEGORY.FETCH_SUPPLIERS_FAILURE'), 'error');

    return [];
  } finally {
    pageLoaded();
  }
};

const fetchBrandsOfClient = async (clientId, showMessage) => {
  try {
    const brands = await brandService.getBrandsOfClient(clientId);

    return brands;
  } catch (err) {
    showMessage('Impossible de récupérer les marques', 'error');

    return [];
  }
};

const fetchLocationsOfAccount = async (accountId, showMessage) => {
  try {
    const locations = await accountService.getLocationsOfAccount(accountId);

    return locations;
  } catch (err) {
    showMessage(i18next.t('HOME.ACTIVITY_REPORT_FETCH_LOCATIONS_FAILURE'), 'error');

    return [];
  }
};

const fetchRetailersOfClient = async (clientId, showMessage) => {
  try {
    const retailers = await clientService.getRetailersOfClient(clientId);

    return retailers;
  } catch (err) {
    showMessage(i18next.t('STOCKS.CURRENT_STOCKS.GET_RETAILERS_FAILURE'), 'error');

    return [];
  }
};

const fetchGroupsByStoreIds = async (storeIds, showMessage) => {
  const formattedGroups = {};

  try {
    const groupMappings = await groupService.getGroupsOfStores(storeIds);

    groupMappings.forEach((groupMapping) => {
      const groupId = groupMapping.lnkGroupStoregroupmappingrel.id;
      const groupName = groupMapping.lnkGroupStoregroupmappingrel.name;

      if (!formattedGroups[groupId]) {
        formattedGroups[groupId] = {
          groupId,
          storeIds: [],
          name: groupName,
          id: groupMapping.id,
        };
      }

      formattedGroups[groupId].storeIds.push(groupMapping.storeId);
    });
  } catch (err) {
    showMessage(i18next.t('GENERAL.GROUPS_CLIENT_FETCH_FAILURE'), 'error');

    return {};
  }

  return formattedGroups;
};

const _formatRow = (row, index) => {
  const lossTotal = get(row, 'losses.total', 0);
  const foodCost = get(row, 'foodCost.realConsumption', 0);
  const foodCostGapFromTheorical = get(row, 'foodCost.gapFromTheoretical', 0);

  const materialRatio = !row.turnover ? null : (row.theoricalConsumption / row.turnover) * 100;

  const orderOverTurnoverRatio = !row.turnover ? null : (foodCost / row.turnover) * 100;

  const realFromTheoricalRatio = !row.theoricalConsumption
    ? null
    : (foodCostGapFromTheorical / row.theoricalConsumption) * 100;

  const lossRatio = !row.turnover ? null : (lossTotal / row.turnover) * 100;

  return {
    ...row,
    id: index,
    // Computed data
    lossRatio,
    materialRatio,
    realFromTheoricalRatio,
    orderOverTurnoverRatio,
  };
};

const fetchAnalyticsData = async (storeIds, startDate, endDate, showMessage) => {
  try {
    const analytics = await orderService.getOrdersAnalyticsByStore(
      storeIds,
      startDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      endDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
    );

    const formattedData = analytics.data.map(_formatRow);

    const totalRow = _formatRow(
      { ...analytics.totalData, store: { name: i18next.t('ORDERS.BY_CATEGORY.LABEL_TOTAL') } },
      'total',
    );

    return {
      total: totalRow,
      data: formattedData,
    };
  } catch {
    showMessage(i18next.t('ORDERS.BY_CATEGORY.FETCH_DATA_FAILURE'), 'error');

    return { data: [], total: {} };
  }
};

export default {
  fetchAnalyticsData,
  fetchBrandsOfClient,
  fetchGroupsByStoreIds,
  fetchRetailersOfClient,
  fetchSuppliersOfClient,
  fetchLocationsOfAccount,
};

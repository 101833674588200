import styled from 'styled-components';

export const CreditInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TextWithToolTipContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: fit-content;
  align-items: top;
  gap: 8px;
`;

export const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
  > div {
    display: flex;
    justify-content: center;
    height: 12px;
  }
`;

export const Credits = styled.div`
  width: fit-content;
  padding: 4px 8px;
  border: 2px solid;
  border-radius: 4px;
  color: ${({ theme, creditsLeft }) =>
    creditsLeft ? theme.colors.infoGreen : theme.colors.infoOrange};
`;

export const LoaderContainer = styled.div`
  width: fit-content;
`;

import { connect } from 'react-redux';
import i18next from 'i18next';
import moment from 'moment';
import React, { Component } from 'react';
import styled from 'styled-components';

import {
  deleteStoreSupplierProductMapping,
  getStoreSupplierProductMapping,
  getStoreSupplierProductMappingsOfStore,
  patchStoreSupplierProductMapping,
  receiveDeleteStoreSupplierProductMapping,
  receivePatchStoreSupplierProductMapping,
  receiveStoreSupplierProductMapping,
  receiveStoreSupplierProductMappings,
  requestDeleteStoreSupplierProductMappingError,
  requestPatchStoreSupplierProductMappingError,
  requestStoreSupplierProductMappingError,
  requestStoreSupplierProductMappingsError,
} from '@actions/storeSupplierProductMapping';
import { getSingleStore, receiveSingleStore, requestSingleStoreError } from '@actions/store';
import {
  getSupplierProductsOfSuppliers,
  receiveSupplierProducts,
  requestSupplierProductsError,
} from '@actions/supplierProduct';
import { getSuppliersOfStore, receiveSuppliers, requestSuppliersError } from '@actions/supplier';
import { showConfirmationMessage } from '@actions/messageconfirmation';

import {
  Button,
  Dropdown,
  INPUT_WIDTH,
  ToggleSwitch,
} from '@commons/utils/styledLibraryComponents';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { getClientStoreNameTranslation } from '@commons/utils/translations';

import { Input } from '@lib/inpulse/Input';

import { getClientInfo } from '@selectors/client';

import { PrevButton } from './styledComponents';

moment.locale('fr');

export class StoreSupplierProductMappingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storeId: null,
      storeName: '',
      supplierId: null,
      supplierName: '',
      supplierProductId: null,
      supplierProductName: '',
      storeSupplierProductMappingId: null,
      price: '',
      suppliers: [],
      formattedSupplierProducts: [],
      available: false,
      hasStock: false,
      hasDlc: false,
    };
  }

  initialize() {
    const storeSupplierProductMapping = this.props.storeSupplierProductMapping;
    const storeId = storeSupplierProductMapping.lnkStoreStoresupplierproductmappingrel.id;
    const storeName = storeSupplierProductMapping.lnkStoreStoresupplierproductmappingrel.name;
    const supplierProductId =
      storeSupplierProductMapping.lnkSupplierproductStoresupplierproductmappingrel.id;
    const supplierProductName =
      storeSupplierProductMapping.lnkSupplierproductStoresupplierproductmappingrel.name;
    const storeSupplierProductMappingId = storeSupplierProductMapping.id;
    const available = storeSupplierProductMapping.available || false;
    const hasStock = storeSupplierProductMapping.hasStock || false;
    const hasDlc = storeSupplierProductMapping.hasDlc || false;

    let price = '';
    if (storeSupplierProductMapping.price || storeSupplierProductMapping.price === 0) {
      price = storeSupplierProductMapping.price;
    } else {
      price = '';
    }

    const supplierId =
      storeSupplierProductMapping.lnkSupplierproductStoresupplierproductmappingrel.supplierId;

    this.setState({
      storeId,
      storeName,
      supplierProductId,
      supplierProductName,
      storeSupplierProductMappingId,
      price,
      supplierId,
      available,
      hasStock,
      hasDlc,
    });
  }

  preprocessStoreSupplierProducts() {
    let supplierProductIdsMapped = this.props.storeSupplierProductMappings.map(
      (mapping) => mapping.supplierProductId,
    );
    let formattedSupplierProducts = [];
    this.props.supplierProducts.forEach((supplierProduct) => {
      if (!supplierProductIdsMapped.includes(supplierProduct.id)) {
        formattedSupplierProducts.push(supplierProduct);
      }
    });
    this.setState({ formattedSupplierProducts });
  }

  componentDidMount() {
    const storeId = this.props.match.params.id;
    const storeSupplierProductMappingId = this.props.match.params.idmapping;
    if (storeSupplierProductMappingId && storeSupplierProductMappingId !== 'create') {
      //Edit
      this.props.getStoreSupplierProductMapping(storeSupplierProductMappingId); //.then(() => this.preprocessStoreSupplierProducts())
    } else {
      this.props.getSingleStore(storeId);
      this.props.getSuppliersOfStore(storeId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const storeSupplierProductMappingId = this.props.match.params.idmapping;
    if (prevProps.storeSupplierProductMapping !== this.props.storeSupplierProductMapping) {
      this.initialize();
    }
    if (prevProps.store !== this.props.store) {
      this.setState({ storeId: this.props.store.id, storeName: this.props.store.name });
    }
    if (prevState.supplierId !== this.state.supplierId) {
      if (storeSupplierProductMappingId && storeSupplierProductMappingId === 'create') {
        this.setState({ supplierProductId: null });
      }
      if (this.state.supplierId !== null) {
        this.props.getSupplierProductsOfSuppliers([this.state.supplierId]);
      }
    }
    if (prevProps.supplierProducts !== this.props.supplierProducts) {
      this.props.getStoreSupplierProductMappingsOfStore(
        this.props.match.params.id,
        moment().startOf('day').format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        false,
      );
      if (
        storeSupplierProductMappingId &&
        storeSupplierProductMappingId !== 'create' &&
        this.props.supplierProducts.length > 0
      ) {
        this.setState({
          supplierName: this.props.supplierProducts[0].lnkSupplierSupplierproductrel.name,
        });
      }
    }
    if (prevProps.storeSupplierProductMappings !== this.props.storeSupplierProductMappings) {
      this.preprocessStoreSupplierProducts();
    }
  }

  handleSave() {
    let price = this.state.price;
    if (!this.state.price) {
      price = null;
    }

    if (this.state.supplierProductId) {
      const storeSupplierProductMapping = {
        storeId: this.state.storeId,
        supplierProductId: this.state.supplierProductId,
        price: price,
        available: this.state.available,
        hasStock: this.state.hasStock,
        hasDlc: this.state.hasDlc,
        id: this.state.storeSupplierProductMappingId
          ? this.state.storeSupplierProductMappingId
          : null,
      };
      if (!this.state.storeSupplierProductMappingId) {
        storeSupplierProductMapping.available = this.state.available;
        storeSupplierProductMapping.hasStock = this.state.hasStock;
        storeSupplierProductMapping.hasDlc = this.state.hasDlc;
      }
      this.props
        .patchStoreSupplierProductMapping(storeSupplierProductMapping)
        .then(() => {
          if (this.state.storeSupplierProductMappingId) {
            this.props.showMessage("L'association a bien été modifiée");
          } else {
            this.props.showMessage("L'association a bien été créée");
          }
          this.props.history.goBack();
        })
        .catch(() => this.props.showMessage('Une erreur est survenue', 'error'));
    } else {
      this.props.showMessage('Certains champs ne sont pas conformes', 'error');
    }
  }

  handleDelete = () => {
    const mappingId = this.props.match.params.idmapping;
    this.props
      .deleteStoreSupplierProductMapping(mappingId)
      .then(() => {
        this.props.showMessage("L'association a bien été supprimée");
        this.props.history.goBack();
      })
      .catch(() => this.props.showMessage('Une erreur est survenue', 'error'));
  };

  handleStoreSupplierProductMappingAvailableChange() {
    this.setState({ available: !this.state.available });
  }

  handleStoreSupplierProductMappingHasStockChange() {
    this.setState({ hasStock: !this.state.hasStock });
  }

  handleStoreSupplierProductMappingHasDlcChange() {
    this.setState({ hasDlc: !this.state.hasDlc });
  }

  handleSupplierInput(supplierNameInput) {
    const supplierMatch = this.props.suppliers.find(
      (supplier) => supplier.name.toLowerCase() === supplierNameInput.toLowerCase(),
    );
    if (supplierMatch) {
      this.setState({
        supplierId: supplierMatch.id,
        supplierName: supplierMatch.name,
        supplierProductId: null,
        supplierProductName: '',
        formattedSupplierProducts: [],
      });
    } else {
      this.setState({
        supplierId: null,
        supplierName: supplierNameInput,
        supplierProductId: null,
        supplierProductName: '',
        formattedSupplierProducts: [],
      });
    }
  }

  handleSupplierSelection(supplierIdInput, supplierNameInput) {
    const supplierMatch = this.props.suppliers.find((supplier) => supplier.id === supplierIdInput);
    if (supplierMatch) {
      this.setState({
        supplierId: supplierMatch.id,
        supplierName: supplierMatch.name,
        supplierProductId: null,
        supplierProductName: '',
        formattedSupplierProducts: [],
      });
    }
  }

  handleSupplierProductInput(supplierProductNameInput) {
    const supplierProductMatch = this.state.formattedSupplierProducts.find(
      (supplierProduct) =>
        supplierProduct.name.toLowerCase() === supplierProductNameInput.toLowerCase(),
    );
    if (supplierProductMatch) {
      this.setState({
        supplierProductId: supplierProductMatch.id,
        supplierProductName: supplierProductMatch.name,
        price: supplierProductMatch.price,
      });
    } else {
      this.setState({
        supplierProductId: null,
        supplierProductName: supplierProductNameInput,
        price: '',
      });
    }
  }

  handleSupplierProductSelection(supplierProductIdInput, supplierProductNameInput) {
    const supplierProductMatch = this.state.formattedSupplierProducts.find(
      (supplier) => supplier.id === supplierProductIdInput,
    );
    if (supplierProductMatch) {
      this.setState({
        supplierProductId: supplierProductMatch.id,
        supplierProductName: supplierProductMatch.name,
        price: supplierProductMatch.price,
      });
    }
  }

  render() {
    let {
      storeSupplierProductMappingId,
      formattedSupplierProducts,
      supplierProductName,
      supplierProductId,
      price,
      available,
      hasStock,
      hasDlc,
    } = this.state;

    const { currency } = this.props;

    return (
      <div
        className={this.props.className}
        style={{ position: 'relative', width: '100%', height: '100%' }}
      >
        <div className="event-edit-header">
          <div className="event-edit-header-title">
            <PrevButton
              alt=""
              src="/images/inpulse/chevron-left-black-small.svg"
              onClick={() => this.props.history.goBack()}
            />
            <span style={{ marginLeft: '20px' }}>
              {i18next.t(
                storeSupplierProductMappingId
                  ? 'FEATURE.ADMIN.UPDATE_STORE_ASSOCIATED_SUPPLIER_PRODUCT'
                  : 'ADMIN.STORES.ADD_SSPM',
              )}
            </span>
          </div>
          <div className="event-edit-header-actions">
            {this.state.storeSupplierProductMappingId && (
              <Button
                buttonCustomStyle={{ marginRight: 20 }}
                color={'blue-outline-no-shadow'}
                handleClick={() => this.handleDelete()}
                icon={'/images/inpulse/delete-black-small.svg'}
                label={i18next.t('GENERAL.DELETE')}
              />
            )}
            <Button
              color={'blue'}
              handleClick={() => this.handleSave()}
              icon={'/images/inpulse/save-white-small.svg'}
              label={i18next.t('GENERAL.SAVE')}
            />
          </div>
        </div>
        <div className="display-box">
          <div className="box">
            <div className="event-edit-main-container" style={{ paddingBottom: '5%' }}>
              <div className="supplier-form-title">
                {i18next.t(
                  storeSupplierProductMappingId
                    ? 'ADMIN.STORES.ASSOCIATED_SUPPLIER_PRODUCT'
                    : 'ADMIN.STORES.SUPPLIER_PRODUCT_TO_ASSOCIATE',
                )}
              </div>
              <div className="new-supplier-form-container" style={{ marginBottom: '100px' }}>
                <div className="supplier-form-column">
                  <div style={{ marginTop: '20px', marginRight: '40px' }}>
                    <Dropdown
                      height="100%"
                      isDisabled={true}
                      isLabelTextSmall={true}
                      isRequired={true}
                      label={getClientStoreNameTranslation(this.props.client.storeName) + ' :'}
                      selectedItem={{ id: this.state.storeId, value: this.state.storeName }}
                      width={INPUT_WIDTH.FULL}
                    />
                  </div>
                  <div className="input-form">
                    <Input
                      label={`${i18next.t('GENERAL.PRICE_EX_TAX')} (${currency.alphabeticCode})`}
                      name="price"
                      readOnly={true}
                      type="number"
                      value={price}
                    />
                  </div>
                </div>
                <div className="supplier-form-column">
                  <div style={{ marginTop: '20px', marginRight: '40px' }}>
                    <Dropdown
                      height="100%"
                      isDisabled={!!storeSupplierProductMappingId}
                      isLabelTextSmall={true}
                      isRequired={true}
                      items={this.props.suppliers}
                      label={`${i18next.t('GENERAL.SUPPLIER')} :`}
                      placeholder={i18next.t('GENERAL.CHOOSE_PLACEHOLDER')}
                      selectedItem={{ id: this.state.supplierId, value: this.state.supplierName }}
                      width={INPUT_WIDTH.FULL}
                      onSelectionChange={(selectedItem) => {
                        this.handleSupplierSelection(selectedItem.id, selectedItem.value);
                      }}
                    />
                  </div>
                  <div className="input-form">
                    <Dropdown
                      isDisabled={true}
                      isLabelTextSmall={true}
                      isRequired={true}
                      label={`${i18next.t('GENERAL.CURRENCY')} :`}
                      placeholder={i18next.t('GENERAL.CURRENCY')}
                      selectedItem={currency}
                      width={INPUT_WIDTH.FULL}
                    />
                  </div>
                </div>
                <div className="supplier-form-column">
                  {formattedSupplierProducts && (
                    <div style={{ marginTop: '20px', marginRight: '40px' }}>
                      <Dropdown
                        height="100%"
                        isDisabled={!!storeSupplierProductMappingId}
                        isLabelTextSmall={true}
                        isRequired={true}
                        items={formattedSupplierProducts.filter(
                          (supplierProduct) => supplierProduct.active,
                        )}
                        label={`${i18next.t('GENERAL.SUPPLIER_PRODUCT')} :`}
                        placeholder={i18next.t('GENERAL.CHOOSE_PLACEHOLDER')}
                        selectedItem={{
                          id: supplierProductId,
                          value: supplierProductName,
                        }}
                        width={INPUT_WIDTH.FULL}
                        onSelectionChange={(selectedItem) => {
                          this.handleSupplierProductSelection(selectedItem.id, selectedItem.value);
                        }}
                      />
                    </div>
                  )}
                  <div className="toggle-form">
                    <div className="toggle-container">
                      <div className="toggle-form-input">
                        <ToggleSwitch
                          checked={available}
                          handleClick={() =>
                            this.handleStoreSupplierProductMappingAvailableChange()
                          }
                          id="available-to-ordrer"
                        />
                      </div>
                      <label className="toggle-form-label">
                        {i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_PROPERTIES_AVAILABLE_ORDER')}
                      </label>
                    </div>
                    <div className="toggle-container">
                      <div className="toggle-form-input">
                        <ToggleSwitch
                          checked={hasStock}
                          handleClick={() => this.handleStoreSupplierProductMappingHasStockChange()}
                          id="stock-inventory"
                        />
                      </div>
                      <label className="toggle-form-label">
                        {i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_PROPERTIES_INVENTORY_STOCKS')}
                      </label>
                    </div>
                    <div className="toggle-container">
                      <div className="toggle-form-input">
                        <ToggleSwitch
                          checked={hasDlc}
                          handleClick={() => this.handleStoreSupplierProductMappingHasDlcChange()}
                          id="inventory-loss"
                        />
                      </div>
                      <label className="toggle-form-label">
                        {i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_PROPERTIES_INVENTORY_LOSS')}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state.baseReducer.store,
  currency: state.baseReducer.currency,
  suppliers: state.baseReducer.suppliers,
  supplierProducts: state.baseReducer.supplierProducts,
  storeSupplierProductMapping: state.baseReducer.storeSupplierProductMapping,
  storeSupplierProductMappings: state.baseReducer.storeSupplierProductMappings,
  client: getClientInfo(state.baseReducer.user),
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  getSingleStore: (storeId) =>
    dispatch(getSingleStore(storeId))
      .then((result) => {
        dispatch(receiveSingleStore(result));
        return result;
      })
      .catch((error) => {
        dispatch(requestSingleStoreError(error));
      }),
  getSuppliersOfStore: (storeId) =>
    dispatch(getSuppliersOfStore(storeId)).then(
      (result) => {
        dispatch(receiveSuppliers(result));
      },
      (error) => {
        dispatch(requestSuppliersError(error));
      },
    ),
  getStoreSupplierProductMappingsOfStore: (storeId, date, filterByUserCatalog) =>
    dispatch(getStoreSupplierProductMappingsOfStore(storeId, date, filterByUserCatalog)).then(
      (result) => {
        dispatch(receiveStoreSupplierProductMappings(result));
      },
      (error) => {
        dispatch(requestStoreSupplierProductMappingsError(error));
      },
    ),
  getSupplierProductsOfSuppliers: (suppliers, skip, limit) =>
    dispatch(getSupplierProductsOfSuppliers(suppliers, skip, limit)).then(
      (result) => {
        dispatch(receiveSupplierProducts(result));
      },
      (error) => {
        dispatch(requestSupplierProductsError(error));
      },
    ),
  getStoreSupplierProductMapping: (id) =>
    dispatch(getStoreSupplierProductMapping(id)).then(
      (result) => {
        dispatch(receiveStoreSupplierProductMapping(result));
      },
      (error) => {
        dispatch(requestStoreSupplierProductMappingError(error));
      },
    ),
  patchStoreSupplierProductMapping: (mapping) =>
    dispatch(patchStoreSupplierProductMapping(mapping)).then(
      (result) => dispatch(receivePatchStoreSupplierProductMapping(result)),
      (error) => {
        dispatch(requestPatchStoreSupplierProductMappingError(error));
        throw 'Error';
      },
    ),
  deleteStoreSupplierProductMapping: (mappingId) =>
    dispatch(deleteStoreSupplierProductMapping(mappingId)).then(
      (result) => dispatch(receiveDeleteStoreSupplierProductMapping(result)),
      (error) => {
        dispatch(requestDeleteStoreSupplierProductMappingError(error));
        throw 'Error';
      },
    ),
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
});

const StyledStoreSupplierProductMappingDetails = styled(StoreSupplierProductMappingDetails)`
  & .display-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & .box {
    margin-top: 20px;
    width: calc(100% - 60px);
    min-width: 800px;
    height: fit-content;
    background: #ffffff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
  }

  & .event-edit-header {
    width: calc(100%);
    min-width: 800px;
    height: 60px;
    background: white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 3px;
    padding: 0 20px;
    position: relative;
    top: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    background: #ffffff;
    z-index: 9;
  }

  & .event-edit-header-title {
    margin-left: 10px;
    font-size: 16px;
    color: #000000;
    font: ${(props) => props.theme.fonts?.h3} !important;
  }

  & .event-edit-header-actions {
    display: flex;
  }

  & .prev-button-arrow {
    height: 20px;
    width: 20px;
  }

  & .prev-button-arrow:hover {
    cursor: pointer;
    -webkit-filter: url(#colorize);
    filter: url(#colorize);
  }

  & .event-edit-main-container {
    float: left;
    width: calc(100% - 60px);
    height: 220px;
    margin: 20px 30px 20px 40px;
  }

  & .supplier-form-title {
    font: ${(props) => props.theme.fonts?.h2} !important;
  }

  & .new-supplier-form-container {
    display: flex;
    flex-direction: row;
  }

  & .supplier-form-column {
    flex: 1;
  }

  & .input-form {
    margin-top: 30px;
    width: calc(100% - 40px);
  }

  & .toggle-form {
    margin-top: 20px;
  }

  & .text-input-form-label {
    font-family: proximanovalight;
    font-size: 13px;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .toggle-form-label {
    font-family: proximanovalight;
    font-size: 13px;
    margin-left: 15px;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .block-product-order-anomaly-locked {
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    border-radius: ${(props) => props.theme.inputs?.primary.default.borderRadius};
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${(props) => props.theme.colors?.greys.light} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .location-filter-input-arrow {
    float: right;
    margin-right: 20px;
    height: 5px;
    width: 9px;
  }

  & .toggle-container {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
  }

  & .event-edit-footer {
    position: fixed;
    bottom: 0;
    display: flex;
    width: 100%;
    height: 60px;
    justify-content: space-between;
    padding: 0 30px;
    background: #ffffff;
    box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.1);
    z-index: 9;
  }

  & .event-edit-footer-right {
    display: flex;
  }
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StyledStoreSupplierProductMappingDetails);

import { APIcaller } from './APIcaller';

/**
 * Central Kitchen - Stores
 */
const getkitchenSupplierByStoreIds = (storeIds) =>
  APIcaller.apiPost(`/admin/central/suppliers-by-store`, { storeIds });

/**
 * Central Kitchen - Store Supplier Profile Mappings
 */
// SSPFMs stands for Store Supplier Profile Mapping
const getCentralKitchenSSPFMsOfUser = () =>
  APIcaller.apiGet(`/admin/central/central-kitchen-suppliers/store-supplier-profile-mappings`);

/**
 * Central Kitchen - Products
 */
const getCentralKitchenProductCategoriesByClientId = (clientId) =>
  APIcaller.apiGet('/admin/central/products/categories', { clientId });

const createKitchenProduct = async (payload) =>
  APIcaller.apiPost(`/admin/central/create/product`, { ...payload });

const getCentralKitchenSupplierProductsByProductId = (productId) =>
  APIcaller.apiGet('/admin/central/products/supplierProducts', {
    productId,
  });

const updateCentralKitchenProduct = async (product) =>
  APIcaller.apiPatch('/admin/central/product/update', product);

const getCentralKitchenProductsOfClient = (clientId) =>
  APIcaller.apiGet(`/admin/central/products`, { clientId });

const deleteCentralKitchenProductsByIds = (clientId, productIds) =>
  APIcaller.apiDelete(`/admin/central/products`, { clientId, productIds });

const disableKitchenProductByIds = async (productIds) =>
  APIcaller.apiPatch('/admin/central/products/disable', { productIds });

const enableKitchenProductByIds = async (productIds) =>
  APIcaller.apiPatch('/admin/central/products/enable', { productIds });

/**
 * Central Kitchen - Recipes
 */
const getKitchenRecipesOfClient = (
  clientId,
  computeRecipesCost = false,
  onlyAvailableInStocks = false,
) =>
  APIcaller.apiPost(`/admin/central/recipes`, {
    clientId,
    computeRecipesCost,
    onlyAvailableInStocks,
  });

const getCentralKitchenRecipesCategoriesOfClient = (clientId, shouldReturnArrayOfObjects = true) =>
  APIcaller.apiGet(`/admin/central/client/${clientId}/recipes/categories`, {
    shouldReturnArrayOfObjects,
  });

const createKitchenRecipe = (
  clientId,
  {
    img,
    recipePreparationSteps,
    name,
    unit,
    value,
    category,
    hasMultipleChannels,
    hasStock,
    hasLoss,
    compositions,
    brandIds,
    inventoryListTemplates,
    storageAreas,
    categoryId,
  },
) =>
  APIcaller.apiPost('/admin/central/create/recipe', {
    recipe: {
      name,
      category,
      unit,
      hasMultipleChannels,
      hasStock,
      hasLoss,
      img,
      recipePreparationSteps,
      value,
      brandIds,
      inventoryListTemplates,
      storageAreas,
      categoryId,
    },
    compositions,
    clientId,
  });

const patchKitchenRecipeById = ({
  id,
  img,
  recipePreparationSteps,
  name,
  unit,
  value,
  category,
  categoryId,
  hasMultipleChannels,
  hasStock,
  hasLoss,
  compositions,
  storeIds,
  brandIds,
  inventoryListTemplates,
  storageAreas,
}) =>
  APIcaller.apiPatch(`/admin/central/recipes/${id}`, {
    recipe: {
      name,
      category,
      categoryId,
      unit,
      hasMultipleChannels,
      hasStock,
      hasLoss,
      img,
      recipePreparationSteps,
      value,
      brandIds,
      inventoryListTemplates,
      storageAreas,
    },
    compositions,
    storeIds,
  });

const getKitchenRecipesByCategoriesForRecipeComposition = (clientId, withoutIngredients = false) =>
  APIcaller.apiGet(`/admin/central/recipes/byCategories/recipeComposition`, {
    clientId,
    withoutIngredients,
  });

const getCentralKitchenRecipeProductionMappingsByEntityId = (recipeId) =>
  APIcaller.apiGet(`/admin/central/recipes/${recipeId}/production`);

const getCentralKitchenRecipeProductionMappingsByEntityIds = async (entityIds) =>
  APIcaller.apiPost(`/admin/central/recipes/productions`, { entityIds });

const patchCentralKitchenRecipesCategories = async (recipeIds, categoryId) =>
  APIcaller.apiPatch(`/admin/central/recipes/categories`, { recipeIds, categoryId });

/**
 * Central Kitchen - Recipe Inventories
 */

const getRecipeInventoryDatesByStoresIds = async (storeIds, startDate, endDate) =>
  APIcaller.apiPost('/central-kitchen/stocks/inventories/recipes/dates', {
    storeIds,
    startDate,
    endDate,
  });

const getRecipesPastAnalyticsList = async (storeId, date, categoryIds, isStrategic = false) =>
  APIcaller.apiPost('/central-kitchen/analytics/past/recipes', {
    storeId,
    date,
    categoryIds,
    isStrategic,
  });

const getRecipePastAnalyticsGraph = async (storeId, recipeId, date) =>
  APIcaller.apiPost(`/central-kitchen/analytics/past/recipes/${recipeId}/graph`, {
    storeId,
    date,
  });

const getRecipePastAnalyticsLastRecipeInventories = async (storeId, recipeId, date) =>
  APIcaller.apiGet(
    `/central-kitchen/analytics/past/store/${storeId}/recipe/${recipeId}/recipe-inventories`,
    {
      date,
    },
  );

// Central Kitchen - Production
const getMappedRecipesListByStoreId = async (storeId) =>
  APIcaller.apiGet(`/production/central-kitchen/stores/${storeId}/recipes`);

const getRecipeProductionByStoreIdAndDate = async (storeId, date) =>
  APIcaller.apiGet(`/production/central-kitchen/stores/${storeId}/production`, { date });

const createRecipeProduction = async (storeId, entityId, date, quantity) =>
  APIcaller.apiPost(`/production/central-kitchen/stores/${storeId}/production`, {
    entityId,
    date,
    quantity,
  });

const getRecipesProductionDates = async (storeId, startDate, endDate) =>
  APIcaller.apiGet(`/production/central-kitchen/stores/${storeId}/dates`, {
    startDate,
    endDate,
  });

const getProductionRecommendations = async (
  centralKitchenId,
  salesPointStoreIds,
  startDate,
  endDate,
) =>
  APIcaller.apiPost(`/production/central-kitchen/stores/${centralKitchenId}/recommendations`, {
    salesPointStoreIds,
    startDate,
    endDate,
  });

const getProductionPlanning = async (centralKitchenId, startDate, endDate) =>
  APIcaller.apiPost(`/production/central-kitchen/production-planning`, {
    storeId: centralKitchenId,
    startDate,
    endDate,
  });

export default {
  // Central Kitchen - Stores
  getkitchenSupplierByStoreIds,

  // Central Kitchen - Store Supplier Profile Mappings
  getCentralKitchenSSPFMsOfUser,

  // Central Kitchen - Products
  getCentralKitchenProductCategoriesByClientId,
  createKitchenProduct,
  updateCentralKitchenProduct,
  getCentralKitchenProductsOfClient,
  deleteCentralKitchenProductsByIds,
  disableKitchenProductByIds,
  enableKitchenProductByIds,

  // Central Kitchen - Recipes
  getKitchenRecipesOfClient,
  getCentralKitchenRecipesCategoriesOfClient,
  createKitchenRecipe,
  getCentralKitchenSupplierProductsByProductId,
  patchKitchenRecipeById,
  getKitchenRecipesByCategoriesForRecipeComposition,
  getCentralKitchenRecipeProductionMappingsByEntityId,
  getCentralKitchenRecipeProductionMappingsByEntityIds,
  patchCentralKitchenRecipesCategories,

  // Recipe Inventories
  getRecipeInventoryDatesByStoresIds,
  getRecipesPastAnalyticsList,
  getRecipePastAnalyticsGraph,
  getRecipePastAnalyticsLastRecipeInventories,

  // Central Kitchen - Production
  getMappedRecipesListByStoreId,
  getRecipeProductionByStoreIdAndDate,
  createRecipeProduction,
  getRecipesProductionDates,
  getProductionRecommendations,
  getProductionPlanning,
};

import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';

import {
  computeTypesOfPackaging,
  convertPriceToPackagingById,
  convertQuantityToPackagingById,
} from '@commons/utils/packagings';
import { formatNumber } from '@commons/DisplayNumber';
import { REGEX_IMAGE_FILE_EXTENSION } from '@commons/utils/regex';
import Drawer from '@commons/Drawer';

import { canEditSentOrReceivedOrder } from '@selectors/actions/orderActions';
import { getAuthorizedActions } from '@selectors/featureProps';

import ORDER_STATUS from '@orders/OrderList/components/OrderForm/constants/status';

import {
  BorderMultipleProduct,
  CheckContainer,
  DisplayImgName,
  DisplayName,
  DisplayNameHovered,
  DotAddedReference,
  Icon,
  ItemMoreData,
  ItemName,
  Name,
  OpenSidePanelButtonContainer,
  ProductImagDiv,
  ProductRow,
  SkuContainer,
} from '../../styledComponents';

import OrderReceptionDrawerContent from '../OrderSidePanel/OrderReceptionDrawerContent';

import PreparationOrderPriceQuantityColumns from './components/PreparationOrderPriceQuantityColumns';
import ProductOrderPriceQuantityColumns from './components/ProductOrderPriceQuantityColumns';

const GENERIC_PRODUCT_INPULSE_ICON = '/images/icon-generic-inpulse-product.svg';
const ICON_EDIT_PEN_IP_BLACK = '/images/inpulse/edit-pen-black-small.svg';
const ICON_CHECK_WHITE_SMALL = '/images/inpulse/check-white-small.svg';
const ICON_CHECK_BLACK_SMALL = '/images/inpulse/check-black-small.svg';

const MAXIMUM_CHARACTER_BEFORE_SHOWING_BIGGER_NAME = 22;
const PRECISION_NUMBER_COMPARISON = 10000;

/**
 * All products orders should be displayed here for order status >= ORDER_STATUS.SENT (ie. status >= 3)
 */
const SentProductOrder = (props) => {
  const {
    allowRowOpening,
    authorizedActions,
    category,
    currency,
    deleteProductOrder,
    displayBigger,
    handleInputChange,
    handleInvoicedDiff,
    handleReceivedDiff,
    hasBLI,
    indexCategory,
    indexEntity,
    invoicedDiffOfProductOrder,
    isEditingPreparationOrder,
    isFirstElement,
    isLastElement,
    isOpen,
    isTablet,
    multiple,
    nbColumns,
    orderStatus,
    preparationStatus,
    product,
    receivedDiffOfProductOrder,
    selectDisplayBigger,
    setDisplayProductPicture,
    setOpenStockInfo,
    updateProduct,
    allCreditPicturesHandlers,
  } = props;

  /** STATES */
  const [priceBDL, setPriceBDL] = useState(
    product.priceBDL != null ? product.priceBDL : product.price,
  );

  /** USE EFFECT */
  useEffect(() => {
    if (product.priceBDL !== priceBDL) {
      updateProduct(product.id, 'priceBDL', priceBDL, category);
    }
  }, [priceBDL]);

  useEffect(() => {
    updateDiff();

    if (product.invoiced === product.received && product.price == priceBDL) {
      handleReceptionAnomaly('');
    }
  }, [product.invoiced, product.received, product.price, priceBDL]);

  /** FUNCTIONS */
  const updateDiff = () => {
    const { packagingUsedInReception, packagingUsedInOrder } = computeTypesOfPackaging(
      product.receptionPackagingId,
      product.packagings,
    );

    const isOrderPackagingSameAsReceptionPackaging =
      packagingUsedInOrder.id === packagingUsedInReception.id;

    let convertedPriceBDC = product.price;
    if (!isOrderPackagingSameAsReceptionPackaging && !isEditingPreparationOrder) {
      convertedPriceBDC = convertPriceToPackagingById(
        product.price,
        packagingUsedInOrder.id,
        packagingUsedInReception.id,
        product.packagings,
      );
    }

    const computedDiff = product.received * convertedPriceBDC - product.invoiced * product.priceBDL;

    const formattedComputedDiff = Math.round(computedDiff * 100) / 100;

    handleReceivedDiff(formattedComputedDiff, product.id);

    const computedDiffBetweenInvoicedAndOrdered =
      product.invoiced * product.priceBDL - product.ordered * convertedPriceBDC;

    const formattedInvoicedDiff = Math.round(computedDiffBetweenInvoicedAndOrdered * 100) / 100;

    handleInvoicedDiff(formattedInvoicedDiff, product.id);
  };

  const handleReceptionAnomaly = (anomaly = {}) => {
    if (!anomaly) {
      updateProduct(product.id, 'receptionAnomaly', null, category);
      updateProduct(product.id, 'anomalie', null, category);
      return;
    }

    if (product.receptionAnomaly !== anomaly.id && product.anomalie !== anomaly.id) {
      updateProduct(product.id, 'receptionAnomaly', anomaly.id, category);
      updateProduct(product.id, 'anomalie', anomaly.id, category);
    }
  };

  const handleReceptionInputChange = (field, value) => {
    updateProduct(product.id, field, value, category);
  };

  const handleCheck = (event, value) => {
    event.stopPropagation();

    if (isReception && canUserEditSentOrReceivedOrder) {
      handleReceptionInputChange('checked', value);
    }
  };

  const closeDrawer = () => {
    setOpenStockInfo({
      entity: null,
      category: null,
    });
  };

  const computedAddedReferenceProduct = () => {
    const { packagingUsedInReception, packagingUsedInOrder } = computeTypesOfPackaging(
      product.receptionPackagingId,
      product.packagings,
    );

    const convertedPrice = convertPriceToPackagingById(
      product.price,
      packagingUsedInOrder.id,
      packagingUsedInReception.id,
      product.packagings,
    );

    /************************/
    /* Format Invoiced data */
    /************************/
    const formattedInvoicedQuantity = `${product.invoiced} x ${packagingUsedInReception.name}`;

    const invoicedTotal = product.invoiced * convertedPrice;
    const formattedInvoicedTotal = `${formatNumber(invoicedTotal, currency.numberDecimals)} ${
      currency.alphabeticCode
    }`;

    const formattedInvoicedUnit = `${formatNumber(convertedPrice, currency.numberDecimals)} ${
      currency.alphabeticCode
    } / ${packagingUsedInReception.name}`;

    /************************/
    /* Format Received data */
    /************************/
    const formattedReceivedQuantity = `${product.received} x ${packagingUsedInReception.name}`;

    const receivedTotal = product.received * convertedPrice;
    const formattedReceivedTotal = `${formatNumber(receivedTotal, currency.numberDecimals)} ${
      currency.alphabeticCode
    }`;

    /*********************/
    /* Format Diff data */
    /*********************/
    const productOrderDiff = receivedDiffOfProductOrder[product.id];

    const formattedDiff = `${productOrderDiff > 0 ? '+ ' : ''}${formatNumber(
      productOrderDiff,
      currency.numberDecimals,
    )} ${currency.alphabeticCode}`;

    const productInvoicedDiff = invoicedDiffOfProductOrder[product.id];

    const formattedInvoicedDiff = `${productInvoicedDiff > 0 ? '+ ' : ''}${formatNumber(
      productInvoicedDiff,
      currency.numberDecimals,
    )} ${currency.alphabeticCode}`;

    /**********************/
    /* Compute rules data */
    /**********************/
    const displayRules = getDisplayRules({
      convertedOrderedQuantity: 0,
      invoicedQuantity: product.invoiced,
      receivedQuantity: product.received,
      priceBDL:
        Math.round(product.priceBDL * PRECISION_NUMBER_COMPARISON) / PRECISION_NUMBER_COMPARISON,
      priceBDCInReceptionPackaging:
        Math.round(convertedPrice * PRECISION_NUMBER_COMPARISON) / PRECISION_NUMBER_COMPARISON,
    });

    return {
      ...product,
      formattedInvoicedQuantity,
      formattedInvoicedTotal,
      formattedInvoicedUnit,
      formattedReceivedQuantity,
      formattedReceivedTotal,
      formattedDiff,
      productOrderDiff,
      productInvoicedDiff,
      formattedInvoicedDiff,
      displayRules,
    };
  };

  const computedReceivedProduct = () => {
    const { packagingUsedInOrder, packagingUsedInReception, packagingUsedInInvoice } =
      computeTypesOfPackaging(product.receptionPackagingId, product.packagings);

    const isOrderPackagingSameAsReceptionPackaging =
      packagingUsedInOrder.id === packagingUsedInReception.id;

    /************************/
    /* Format Invoiced data */
    /************************/
    const formattedInvoicedQuantity = `${product.invoiced} x ${
      isOrderPackagingSameAsReceptionPackaging
        ? packagingUsedInOrder.name
        : packagingUsedInReception.name
    }`;

    const formattedInvoicedTotal = `${formatNumber(
      product.invoiced * product.priceBDL,
      currency.numberDecimals,
    )} ${currency.alphabeticCode}`;

    const formattedOrderedQuantity = `${product.ordered} x ${packagingUsedInOrder.name}`;

    const formattedOrderedTotal = `${formatNumber(
      product.ordered * product.price,
      currency.numberDecimals,
    )} ${currency.alphabeticCode}`;

    const sourcePackaging = isOrderPackagingSameAsReceptionPackaging
      ? packagingUsedInOrder.id
      : packagingUsedInReception.id;

    const targetPackaging = isOrderPackagingSameAsReceptionPackaging
      ? packagingUsedInOrder.id
      : packagingUsedInInvoice.id;

    const convertedPriceBDL = convertPriceToPackagingById(
      product.priceBDL,
      sourcePackaging,
      targetPackaging,
      product.packagings,
    );

    const formattedInvoicedUnit = `${formatNumber(convertedPriceBDL, currency.numberDecimals)} ${
      currency.alphabeticCode
    } / ${
      isOrderPackagingSameAsReceptionPackaging
        ? packagingUsedInOrder.name
        : packagingUsedInInvoice.name
    }`;

    /************************/
    /* Format Received data */
    /************************/
    let convertedPriceBDC = product.price;
    if (!isOrderPackagingSameAsReceptionPackaging) {
      convertedPriceBDC = convertPriceToPackagingById(
        product.price,
        packagingUsedInOrder.id,
        packagingUsedInReception.id,
        product.packagings,
      );
    }

    const formattedReceivedQuantity = `${product.received} x ${
      isOrderPackagingSameAsReceptionPackaging
        ? packagingUsedInOrder.name
        : packagingUsedInReception.name
    }`;

    const formattedReceivedTotal = `${formatNumber(
      product.received * convertedPriceBDC,
      currency.numberDecimals,
    )} ${currency.alphabeticCode}`;

    /*********************/
    /* Format Total data */
    /*********************/
    const productOrderDiff = receivedDiffOfProductOrder[product.id];

    const formattedDiff = `${productOrderDiff > 0 ? '+ ' : ''}${formatNumber(
      productOrderDiff,
      currency.numberDecimals,
    )} ${currency.alphabeticCode}`;

    const productInvoicedDiff = invoicedDiffOfProductOrder[product.id];

    const formattedInvoicedDiff = `${productInvoicedDiff > 0 ? '+ ' : ''}${formatNumber(
      productInvoicedDiff,
      currency.numberDecimals,
    )} ${currency.alphabeticCode}`;

    /**********************/
    /* Compute rules data */
    /**********************/

    const convertedOrderedQuantity = convertQuantityToPackagingById(
      product.ordered,
      packagingUsedInOrder.id,
      packagingUsedInReception.id,
      product.packagings,
    );

    const displayRules = getDisplayRules({
      convertedOrderedQuantity,
      invoicedQuantity: product.invoiced,
      receivedQuantity: product.received,
      priceBDL:
        Math.round(product.priceBDL * PRECISION_NUMBER_COMPARISON) / PRECISION_NUMBER_COMPARISON,
      priceBDCInReceptionPackaging:
        Math.round(convertedPriceBDC * PRECISION_NUMBER_COMPARISON) / PRECISION_NUMBER_COMPARISON,
    });

    return {
      ...product,
      formattedInvoicedQuantity,
      formattedInvoicedTotal,
      formattedOrderedQuantity,
      formattedOrderedTotal,
      formattedInvoicedUnit,
      formattedReceivedQuantity,
      formattedReceivedTotal,
      formattedDiff,
      productOrderDiff,
      productInvoicedDiff,
      formattedInvoicedDiff,
      displayRules,
    };
  };

  const getDisplayRules = ({
    priceBDL,
    invoicedQuantity,
    receivedQuantity,
    convertedOrderedQuantity,
    priceBDCInReceptionPackaging,
  }) => {
    const rules = {
      boldInvoicedQuantity: false,
      boldReceivedQuantity: false,
      boldInvoicedUnitPrice: false,
      boldInvoicedTotalPrice: false,
      boldReceivedTotalPrice: false,
    };

    // Diff between invoiced and received quantities
    if (invoicedQuantity !== convertedOrderedQuantity) {
      rules.boldInvoicedQuantity = true;
    }

    // Diff between received and ordered quantities
    if (receivedQuantity !== convertedOrderedQuantity) {
      rules.boldReceivedQuantity = true;
    }

    // Diff between invoiced and ordered unit price
    if (Math.abs(priceBDCInReceptionPackaging - priceBDL) > Number.EPSILON) {
      rules.boldInvoicedUnitPrice = true;
    }

    const invoicedTotalPrice = priceBDL * invoicedQuantity;
    const orderedTotalPrice = priceBDCInReceptionPackaging * convertedOrderedQuantity;
    const receivedTotalPrice = receivedQuantity * priceBDL;

    // Diff between invoiced and ordered total price
    if (invoicedTotalPrice !== orderedTotalPrice) {
      rules.boldInvoicedTotalPrice = true;
    }

    // Diff between received and ordered total price
    if (receivedTotalPrice !== orderedTotalPrice) {
      rules.boldReceivedTotalPrice = true;
    }

    return rules;
  };

  const computedProduct = product.addedAfterOrdered
    ? computedAddedReferenceProduct()
    : computedReceivedProduct();

  const isReception = [ORDER_STATUS.SENT, ORDER_STATUS.RECEPTION].includes(orderStatus);

  const canUserEditSentOrReceivedOrder = canEditSentOrReceivedOrder(authorizedActions);

  return (
    <>
      <ProductRow
        addBorder={false}
        cantOpen={!allowRowOpening}
        isOpen={isOpen}
        orderStatus={orderStatus}
        onClick={() => {
          if (allowRowOpening) {
            setOpenStockInfo({
              entity: isOpen ? null : indexEntity,
              category: isOpen ? null : indexCategory,
            });
          }
        }}
      >
        {product.addedAfterOrdered && <DotAddedReference />}

        {/* Product */}
        <ItemName nbColumns={nbColumns} orderStatus={orderStatus}>
          <DisplayImgName>
            <ProductImagDiv
              pointerCursor={!!computedProduct.img}
              src={
                `${
                  !!product.img && product.img.replace(REGEX_IMAGE_FILE_EXTENSION, '_compressed.$1')
                }` || GENERIC_PRODUCT_INPULSE_ICON
              }
              onClick={(e) => {
                e.stopPropagation();
                if (!!computedProduct.img) {
                  setDisplayProductPicture({
                    state: true,
                    img: computedProduct.img,
                  });
                }
              }}
              onError={({ currentTarget }) => {
                // if the image is not found
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = GENERIC_PRODUCT_INPULSE_ICON;
              }}
            />
            <DisplayName
              onClick={(e) => {
                if (computedProduct.name.length > MAXIMUM_CHARACTER_BEFORE_SHOWING_BIGGER_NAME) {
                  e.stopPropagation();
                }
                selectDisplayBigger('reference');
              }}
              onMouseOver={() => selectDisplayBigger('reference')}
            >
              <Name isTablet={isTablet} orderStatus={orderStatus}>
                {computedProduct.name}
              </Name>
              <SkuContainer orderStatus={orderStatus}>{computedProduct.sku}</SkuContainer>
            </DisplayName>
            {displayBigger.reference === true &&
              computedProduct.name.length > MAXIMUM_CHARACTER_BEFORE_SHOWING_BIGGER_NAME && (
                <DisplayNameHovered
                  nbColumns={nbColumns}
                  hasPicture
                  onClick={(e) => e.stopPropagation()}
                  onMouseOut={() => selectDisplayBigger('reference')}
                >
                  {computedProduct.name}
                </DisplayNameHovered>
              )}
          </DisplayImgName>
        </ItemName>

        {isEditingPreparationOrder && (
          <PreparationOrderPriceQuantityColumns
            displayBigger={displayBigger}
            maxCharBeforeShowingBiggerName={MAXIMUM_CHARACTER_BEFORE_SHOWING_BIGGER_NAME}
            nbColumns={nbColumns}
            product={computedProduct}
            selectDisplayBigger={selectDisplayBigger}
          />
        )}

        {!isEditingPreparationOrder && (
          <ProductOrderPriceQuantityColumns
            displayBigger={displayBigger}
            maxCharBeforeShowingBiggerName={MAXIMUM_CHARACTER_BEFORE_SHOWING_BIGGER_NAME}
            nbColumns={nbColumns}
            product={computedProduct}
            selectDisplayBigger={selectDisplayBigger}
          />
        )}

        {/* Actions */}
        <ItemMoreData nbColumns={nbColumns} orderStatus={orderStatus}>
          {isFirstElement && allowRowOpening && (
            <OpenSidePanelButtonContainer>
              <Icon src={ICON_EDIT_PEN_IP_BLACK} />
            </OpenSidePanelButtonContainer>
          )}

          {!isEditingPreparationOrder && (
            <CheckContainer
              checked={canUserEditSentOrReceivedOrder ? product.checked : true}
              isDisabled={!isReception || !canUserEditSentOrReceivedOrder}
              onClick={(event) => handleCheck(event, !product.checked)}
            >
              <Icon src={product.checked ? ICON_CHECK_WHITE_SMALL : ICON_CHECK_BLACK_SMALL} />
            </CheckContainer>
          )}
        </ItemMoreData>
      </ProductRow>

      {!!multiple && <BorderMultipleProduct />}

      <Drawer
        isOpened={isOpen && isLastElement && orderStatus >= ORDER_STATUS.SENT}
        onClick={closeDrawer}
      >
        <OrderReceptionDrawerContent
          allCreditPicturesHandlers={allCreditPicturesHandlers}
          deleteProductOrder={deleteProductOrder}
          handleInputChange={handleInputChange}
          handleReceptionAnomaly={handleReceptionAnomaly}
          handleReceptionInputChange={handleReceptionInputChange}
          hasBLI={hasBLI}
          isEditingPreparationOrder={isEditingPreparationOrder}
          orderStatus={orderStatus}
          preparationStatus={preparationStatus}
          priceBDL={priceBDL}
          product={product}
          readOnly={!isReception}
          setOpenStockInfo={setOpenStockInfo}
          setPriceBDL={setPriceBDL}
          onClose={closeDrawer}
        />
      </Drawer>
    </>
  );
};

const mapStateToProps = (state) => ({
  authorizedActions: getAuthorizedActions(state.baseReducer.userRights, '/order/orders'),
});

export default connect(mapStateToProps)(SentProductOrder);

import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';

/**
 * The following components are used to add standard buttons for:
 * - Cancelling
 * - Downloading
 */

export const GenericCancelButton = ({ handleClick, isDisabled }) => (
  <Button
    buttonCustomStyle={{ justifyContent: 'center' }}
    color={'inpulse-outline'}
    handleClick={handleClick}
    icon={'/images/inpulse/close-black-small.svg'}
    isDisabled={isDisabled}
    label={i18next.t('GENERAL.CANCEL')}
    minWidth={120}
  />
);

export const GenericDownloadButton = ({ handleClick, isDisabled }) => (
  <Button
    buttonCustomStyle={{ justifyContent: 'center' }}
    color={'inpulse-default'}
    handleClick={handleClick}
    icon={'/images/inpulse/file-download-white-small.svg'}
    isDisabled={isDisabled}
    label={i18next.t('GENERAL.DOWNLOAD')}
    minWidth={120}
  />
);

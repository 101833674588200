// ===== Module features for easy import in ClientContainer=====

import ForecastAnalyticsContainer from './Analytics';
import ForecastForecastContainer from './Forecast';

import IngredientsConsumption from './Analytics/IngredientsConsumption';
import PastMix from './Analytics/PastMix';
import Sales from './Analytics/Sales';

import DashboardForecastsContainer from './Forecast/Forecasts/containers/DashboardForecastsContainer';
import PoSProductionPlanning from './Forecast/PoSProductionPlanning';
import ProductMix from './Forecast/ProductMix';

const ForecastModule = {
  ForecastAnalyticsContainer,
  ForecastForecastContainer,
  PastMix,
  Sales,
  IngredientsConsumption,
  DashboardForecastsContainer,
  ProductMix,
  PoSProductionPlanning,
};

export default ForecastModule;

// External Dependencies
import { connect } from 'react-redux';
import { get, flatten, sortBy, keyBy, orderBy } from 'lodash';
import i18next from 'i18next';
import React, { Component } from 'react';

import { CHANNELS, CHANNEL_ID_KEY_BY_NAME } from '@commons/constants/channel';
import { translateUnit } from '@commons/utils/translateUnit';

import ExportModalContent from '@lib/inpulse/ExportModal';

import { getClientInfo } from '@selectors/client';

import recipeService from '@services/recipe';

import utilsAnalytics from '../../utils/index';

const BATCH_RECIPES_TO_FETCH_AT_ONCE = 50;

const initialState = {
  title: '',
  progress: 0,
  error: false,
  loading: true,
  recipesIds: [],
  totalRecipes: 0,
  detailedRecipesList: [],
  detailedRecipesWithComponents: [],
};

export class ExportRecipeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      recipesIds: this.props.recipesIds || initialState.recipesIds,
    };
  }

  /**********\   Life Methods Cycle   /***********/

  componentDidMount() {
    const {
      params: { title, recipesIds },
    } = this.props;

    this.setState({ title, recipesIds, totalRecipes: recipesIds.length });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.recipesIds !== this.state.recipesIds) {
      this.setState({
        progress: 100 - (this.state.recipesIds.length / this.state.totalRecipes) * 100,
      });

      this.loadRecipesByBatchOfIds();
    }
  }

  /**********\   Component Methods   /***********/

  /**
   * Close the modal
   *
   * @returns {void}
   */
  exitModal = () => {
    this.setState({ ...initialState, loading: false });

    this.props.closeModal();
  };

  /**
   * Updates the state accordingly from recently process recipes
   * to keep track of the data that is still to get
   *
   * @param {number[]} selectedIds          - The list of recipe ids that have been treated
   * @param {Recipe[]} newlyTreatedRecipes  - The list of recipes that have been treated
   *
   * @returns {void}
   */
  updateTreatedRecipes(
    selectedIds,
    newlyTreatedRecipes,
    newlyTreatedDetailedRecipesWithComponents,
  ) {
    const { loading, recipesIds, detailedRecipesList, detailedRecipesWithComponents } = this.state;

    if (!loading) {
      return;
    }

    const filteredRecipesIds = recipesIds.filter((recipeId) => !selectedIds.includes(recipeId));

    this.setState({
      recipesIds: filteredRecipesIds,
      detailedRecipesList: detailedRecipesList.concat(newlyTreatedRecipes),
      detailedRecipesWithComponents: detailedRecipesWithComponents.concat(
        newlyTreatedDetailedRecipesWithComponents,
      ),
    });
  }

  /**
   * Format the list of recipes given in parameter in order to match the necesarry content
   * when exporting the data
   *
   * @param {Recipe[]} recipes - The list of recipes to format
   * @param {Boolean} hasMultipleBrands - Client has multiple brands value
   *
   * @returns {Recipe[]} The formatted list of recipes
   */
  formatListOfRecipes(recipes, hasMultipleBrands = false) {
    const formattedRecipes = recipes.reduce((res, item) => {
      const associatedProduct = item.product;
      const allergensNames = item.allergens.map(({ name }) => name).sort();
      const recipeBrands = hasMultipleBrands ? item.recipe.brands : [];

      const commonFields = {
        id: item.recipe.id,
        name: item.recipe.name,
        associatedProductName: get(associatedProduct, 'name', null),
        unit: translateUnit(item.recipe.unit).toLowerCase(),
        quantity: item.recipe.value,
        category: item.recipe.category || i18next.t('GENERAL.NONE_VALUE'),
        status: item.recipe.active,
        allergensNames,
        components: item.compositionSettings,
        brands: recipeBrands,
        hasMultipleChannels: item.recipe.hasMultipleChannels,
      };

      res.push(commonFields);

      return res;
    }, []);

    return sortBy(formattedRecipes, 'name');
  }

  _getComponentCost(entity, recipeHasMultipleChannels, channel, defaultChannelId) {
    const componentCost =
      entity.costByChannelId[
        recipeHasMultipleChannels ? CHANNEL_ID_KEY_BY_NAME[channel] : defaultChannelId
      ];

    return componentCost == null ? null : componentCost * entity.packagingValue;
  }

  /** Get ingredient default cost based on whether parent recipe has multiple channel or whether current ingredient is used on delivery */
  _getComponentIngredientCost(
    parentRecipeHasMultipleChannels,
    componentIsUsedOnDelivery,
    computedIngredientCost,
  ) {
    if (componentIsUsedOnDelivery || !parentRecipeHasMultipleChannels) {
      return computedIngredientCost;
    }

    return 0;
  }

  formatListOfRecipesWithComponents(formattedRecipes) {
    const {
      client: { defaultChannelId, hasMultipleChannels: clientHasMultipleChannels },
    } = this.props;

    const formattedRecipesWithCompositions = formattedRecipes.map((formattedRecipe) => {
      const formattedRecipeDetail = {
        recipeId: formattedRecipe.id,
        name: formattedRecipe.name,
        associatedProductName: formattedRecipe.associatedProductName,
        unit: formattedRecipe.unit,
        quantity: formattedRecipe.quantity,
        category: formattedRecipe.category || i18next.t('GENERAL.NONE_VALUE'),
        status: formattedRecipe.status,
        brands: formattedRecipe.brands,
      };

      const recipeHasMultipleChannels = formattedRecipe.hasMultipleChannels;

      // Compute recipe total costs (with his components) while mapping on recipe's components
      // Recipe default cost is used by client without multiple channels
      let recipeTotalCost = { onSiteCost: 0, deliveryCost: 0, defaultCost: 0 };

      const formattedCompositions = formattedRecipe.components.map(
        ({ entity, allergens: componentAllergens }) => {
          const { channels } = entity;

          const componentIsIngredient = entity.isIngredient;

          const componentAllergensNames = componentAllergens.map(({ name }) => name).sort();

          const formattedCategory = entity.category || i18next.t('GENERAL.NONE_VALUE');

          const componentEntityDetail = {
            componentId: entity.id,
            componentName: entity.name,
            componentType: i18next.t(
              componentIsIngredient ? 'GENERAL.INGREDIENT' : 'GENERAL.RECIPE',
            ),
            // TODO: remove condition when MS3 for categories has been done
            componentCategory: componentIsIngredient ? entity.category : formattedCategory,
            componentQuantity: entity.packagingValue,
            componentUnit: translateUnit(entity.unit).toLowerCase(),
            componentAllergensNames,
            isIngredient: entity.isIngredient,
            isPreparationIngredient: entity.isPreparationIngredient,
          };

          if (!clientHasMultipleChannels) {
            const componentCost = componentIsIngredient
              ? entity.cost
              : entity.costByChannelId[defaultChannelId];

            const computedComponentCost =
              componentCost == null ? null : componentCost * entity.packagingValue;

            // Compute recipe default total cost
            if (!entity.isPreparationIngredient) {
              recipeTotalCost = {
                ...recipeTotalCost,
                defaultCost: recipeTotalCost.defaultCost + computedComponentCost,
              };
            }

            return {
              ...formattedRecipeDetail,
              ...componentEntityDetail,
              componentCost: computedComponentCost,
            };
          }

          const componentIsUsedOnSite = !!channels.find(
            (channel) => channel.id === CHANNEL_ID_KEY_BY_NAME[CHANNELS.ON_SITE],
          );

          const componentIsUsedOnDelivery = !!channels.find(
            (channel) => channel.id === CHANNEL_ID_KEY_BY_NAME[CHANNELS.DELIVERY],
          );

          const componentRecipeHasMultipleChannel = entity.hasMultipleChannels;

          const componentOnSiteCost = componentIsIngredient
            ? entity.cost * entity.packagingValue
            : this._getComponentCost(
                entity,
                recipeHasMultipleChannels,
                CHANNELS.ON_SITE,
                defaultChannelId,
              );

          const componentDeliveryCost = componentIsIngredient
            ? entity.cost * entity.packagingValue
            : componentRecipeHasMultipleChannel
            ? this._getComponentCost(
                entity,
                recipeHasMultipleChannels,
                CHANNELS.DELIVERY,
                defaultChannelId,
              )
            : componentOnSiteCost;

          // Compute recipe on site and delivery total cost
          // Preparations ingredients costs are already taken into account in the recipe cost
          const onSiteCost =
            componentIsUsedOnSite && !entity.isPreparationIngredient
              ? recipeTotalCost.onSiteCost + componentOnSiteCost
              : recipeTotalCost.onSiteCost;

          const deliveryCost =
            componentIsUsedOnDelivery && !entity.isPreparationIngredient
              ? recipeTotalCost.deliveryCost + componentDeliveryCost
              : recipeTotalCost.deliveryCost;

          recipeTotalCost = {
            ...recipeTotalCost,
            onSiteCost,
            deliveryCost,
          };

          return {
            ...formattedRecipeDetail,
            ...componentEntityDetail,
            componentIsUsedOnSite,
            componentIsUsedOnDelivery,
            componentOnSiteCost,
            componentDeliveryCost,
          };
        },
      );

      const formattedCompositionsWithRecipeTotalCost = formattedCompositions.map((composition) => ({
        ...composition,
        onSiteCost: recipeTotalCost.onSiteCost,
        deliveryCost: recipeTotalCost.deliveryCost,
        defaultCost: recipeTotalCost.defaultCost,
      }));

      return formattedCompositionsWithRecipeTotalCost;
    });

    const flattennedRecipesWithCompositions = flatten(formattedRecipesWithCompositions);

    return sortBy(flattennedRecipesWithCompositions, ['name', 'componentName']);
  }

  exportReady() {
    this.setState({
      title: i18next.t('GENERAL.EXPORT_SUCCESS'),
      loading: false,
    });
  }

  exportFailure() {
    this.setState({
      title: i18next.t('GENERAL.EXPORT_FAILURE'),
      loading: false,
    });
  }

  /**
   * Handle the progressive loading of recipes from the list of recipesIds that are still to fetch
   *
   * @returns {void}
   */
  async loadRecipesByBatchOfIds() {
    const {
      currency,
      params: { isInCentralMode },
      client: { hasMultipleChannels, hasMultipleBrands },
    } = this.props;

    const { recipesIds, detailedRecipesList, detailedRecipesWithComponents } = this.state;

    // If no more ids, then all data has been fetched
    if (!recipesIds.length) {
      const sortedDetailedRecipesWithComponents = orderBy(
        detailedRecipesWithComponents,
        ['name', 'associatedProductName', `componentQuantity`],
        // sorts name and associatedProductName in ascending order and componentQuantity in descending order
        ['asc', 'asc', 'desc'],
      );

      utilsAnalytics.exportRecipes(
        detailedRecipesList,
        sortedDetailedRecipesWithComponents,
        currency,
        hasMultipleChannels,
        hasMultipleBrands,
        isInCentralMode,
      );

      return this.exportReady();
    }

    // Handle ids selection to not overload API
    const selectedIds = recipesIds.slice(0, BATCH_RECIPES_TO_FETCH_AT_ONCE);

    try {
      // Perform request
      const recipes = await recipeService.getAssociatedRecipesByIds(selectedIds, true, true);

      // Format the received data
      const formattedRecipes = this.formatListOfRecipes(recipes, hasMultipleBrands);

      const formattedRecipesWithComponents =
        this.formatListOfRecipesWithComponents(formattedRecipes);

      const recipesWithComponentsKeyByRecipeId = keyBy(formattedRecipesWithComponents, 'recipeId');

      const formattedRecipesWithTotalCost = formattedRecipes.map((recipe) => {
        const concernedRecipe = recipesWithComponentsKeyByRecipeId[recipe.id];

        return {
          ...recipe,
          onSiteCost: concernedRecipe.onSiteCost,
          deliveryCost: concernedRecipe.deliveryCost,
          defaultCost: concernedRecipe.defaultCost,
        };
      });

      // Update state accordingly
      this.updateTreatedRecipes(
        selectedIds,
        formattedRecipesWithTotalCost,
        formattedRecipesWithComponents,
      );
    } catch (error) {
      return this.exportFailure();
    }
  }

  /**********\   RENDER   /***********/

  render() {
    const { loading, progress, title } = this.state;

    return (
      <ExportModalContent
        {...this.props}
        exitModal={this.exitModal}
        isLoading={loading}
        progress={progress}
        titleModal={title}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
  client: getClientInfo(state.baseReducer.user),
});

export default connect(mapStateToProps)(ExportRecipeModal);

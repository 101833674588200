import { connect } from 'react-redux';
import { sortBy } from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal, refreshGenericModal } from '@actions/modal';
import { showErrorMessage } from '@actions/messageconfirmation';

import normalizeStringValue from '@commons/utils/normalizeStringValue';
import WhiteCardForm from '@commons/WhiteCardForm';

import HeaderPicture from '@admin/components/HeaderPicture';
import ProductComposition from '@admin/products/products/detail/components/ProductRecipeAssociation';

import { KITCHEN_PRODUCT_GENERAL_INFOS_INPUTS } from '../../utils/formInputsConfigurations';

import { Container, FormContainer } from './styledComponents';
import { getModalParams } from './utils/getModalParams';
import KitchenProductDelivery from './KitchenProductDelivery';

const DEFAULT_KITCHEN_PRODUCT_PICTURE = '/images/inpulse/supplier-product-photo-placeholder.svg';

const KitchenProductInformationsSection = (props) => {
  const {
    formFields,
    product,
    setProduct,
    productForm,
    brands,
    hasMultipleBrands,
    productsCategories,
    setProductsCategories,
    productsSubCategories,
    setProductsSubCategories,
    composition,
    compositionError,
    setComposition,
    selectedCompoType,
    setSelectedCompoType,
    supplierProduct,
    setSupplierProduct,
    supplierProducts,
    suppliers,
    selectedSupplier,
    setSelectedSupplier,
    picture,
    setPicture,
    selectedPictureFile,
    setSelectedPictureFile,
    isSaveAlreadyTriggered,
    formError,
    // Reducer
    openGenericModal,
    refreshGenericModal,
    pageLoading,
    pageLoaded,
    showErrorMessage,
  } = props;

  // Categories modal states
  const [inputValue, setInputValue] = useState('');
  const [propertyName, setPropertyName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!!selectedPictureFile && !!picture) {
      formFields.selectedPictureFile = selectedPictureFile;
      formFields.picture = picture;
    }
  }, [selectedPictureFile]);

  // Handle category and sub category creation modal refresh
  useEffect(() => {
    const modalParams = getModalParams({
      propertyName,
      closeCleanUp,
      errorMessage,
      handleInputChange,
      inputValue,
      handleSaveNewItemDropdown,
    });

    refreshGenericModal(modalParams);
  }, [inputValue]);

  const handleCategorySubcategoryCreation = (propertyName) => {
    setPropertyName(propertyName);

    const modalParams = getModalParams({
      propertyName,
      closeCleanUp,
      errorMessage,
      handleInputChange,
      inputValue,
      handleSaveNewItemDropdown,
    });

    openGenericModal(modalParams);
  };

  const handleSaveNewItemDropdown = () => {
    switch (propertyName) {
      case 'category':
        const updatedCategories = sortBy(
          [...productsCategories, { id: productsCategories.length, name: inputValue }],
          ['name'],
        );

        setProductsCategories(updatedCategories);
        productForm.setValue(
          'category',
          { id: productsCategories.length, name: inputValue },
          { shouldDirty: true }, // Option "shouldDirty" allows us to dirty productForm.category field
        );
        break;

      case 'subCategory':
        const updatedSubCategories = sortBy(
          [...productsSubCategories, { id: productsSubCategories.length, name: inputValue }],
          ['name'],
        );

        setProductsSubCategories(updatedSubCategories);
        productForm.setValue(
          'subCategory',
          {
            id: productsSubCategories.length,
            name: inputValue,
          },
          { shouldDirty: true },
        );
        break;

      default:
        return;
    }

    setInputValue('');
    setErrorMessage('');
  };

  const handleInputChange = (newValue) => {
    setInputValue(newValue);

    if (!newValue) {
      setErrorMessage(i18next.t('GENERAL.REQUIRED_FILED_ERROR_MESSAGE'));

      return;
    }

    const selectedDropdownItems =
      propertyName === 'category' ? productsCategories : productsSubCategories;

    const alreadyExists = selectedDropdownItems.some(
      ({ name }) => normalizeStringValue(name) === normalizeStringValue(newValue),
    );

    if (alreadyExists) {
      setErrorMessage(
        i18next.t(
          propertyName === 'category'
            ? 'GENERAL.MODAL_CATEGORY_NAME_ALREADY_USED'
            : 'GENERAL.MODAL_SUBCATEGORY_NAME_ALREADY_USED',
        ),
      );

      return;
    }

    setErrorMessage('');
  };

  const closeCleanUp = () => {
    setErrorMessage('');
    setInputValue('');
  };

  return (
    <Container>
      <FormContainer>
        <HeaderPicture
          isDefaultThumbnail={!picture}
          picture={picture || DEFAULT_KITCHEN_PRODUCT_PICTURE}
          setSelectedFile={setSelectedPictureFile}
          title={formFields.name}
          onPictureChange={setPicture}
        />
        <WhiteCardForm
          form={productForm}
          inputs={KITCHEN_PRODUCT_GENERAL_INFOS_INPUTS({
            productsCategories,
            productsSubCategories,
            handleCategorySubcategoryCreation,
            brands,
            hasMultipleBrands,
          })}
          shouldDisplayError={isSaveAlreadyTriggered}
          title={i18next.t('ADMIN.SUPPLIERS.SUPPLIER_DETAIL_GENERAL_INFORMATION')}
        />
        <ProductComposition
          composition={composition}
          compositionError={compositionError}
          isReadOnly={false}
          pageLoaded={pageLoaded}
          pageLoading={pageLoading}
          product={product}
          selectedCompoType={selectedCompoType}
          setComposition={setComposition}
          setSelectedCompoType={setSelectedCompoType}
          showErrorMessage={showErrorMessage}
          isKitchenProduct
          onProductChange={setProduct}
        />
        <KitchenProductDelivery
          formError={formError}
          product={product}
          selectedSupplier={selectedSupplier}
          setProduct={setProduct}
          setSelectedSupplier={setSelectedSupplier}
          setSupplierProduct={setSupplierProduct}
          supplierProduct={supplierProduct}
          supplierProducts={supplierProducts}
          suppliers={suppliers}
        />
      </FormContainer>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
});

export default connect(null, mapDispatchToProps)(KitchenProductInformationsSection);

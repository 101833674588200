import styled, { css } from 'styled-components';

export const GraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 650px;

  justify-content: center;
  align-items: center;

  background: ${(props) => props.theme.colors?.greys.lightest};
  border: 1px solid ${(props) => props.theme.colors?.greys.light};
  box-sizing: border-box;
  border-radius: 8px;
  cursor: auto;
`;

export const TitleCloseRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

/**
 * LEGEND CSS
 */
export const LegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 16px 16px 24px 16px;
  justify-content: ${(props) => (props.theme.fonts ? 'flex-start' : 'center')};
`;

export const LegendContent = styled.div`
  display: flex;
  /*width: 100%;*/
  padding-top: 10px;
  padding-left: 24px;
  padding-right: 24px;
  align-items: center;
`;

export const LegendBox = styled.div`
  width: 16px;
  height: 16px;
  position: relative;
  left: -8px;
  border-radius: 50%;

  ${({ color }) =>
    css`
      background-color: ${color};
    `}
`;

export const LegendTitle = styled.span`
  color: ${(props) => props.theme.colors?.greys.darkest || '#6b768c'};
  font: ${(props) => props.theme.fonts?.textBigHeight16};
`;

/**
 * TITLE CSS
 */
export const TitleContainer = styled.div`
  padding: 11px 0 0 16px;
  font-weight: 600;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;

  margin-bottom: 32px;
`;

export const Title = styled.div`
  font: ${(props) => props.theme.fonts?.h3Inter};
`;

export const Subtitle = styled.div`
  ${(props) =>
    props.theme.fonts
      ? `font: ${props.theme.fonts?.textSmall}`
      : `padding-left: 30px;
  font-weight: 600;
  font-size: 12px;`}
  color: ${(props) => props.theme.colors?.greys.dark || '#6b768c'};
`;

export const GraphContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-end;
`;

export const GraphDisplayContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const GraphDisplayContentRow = styled.div`
  width: 60%;
  height: ${(props) => (props.columnHeight ? `100%` : '4%')};

  cursor: pointer;

  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: center;

  > .__react_component_tooltip {
    ${(props) => (props.theme.fonts ? `padding: 0;` : undefined)}
  }
`;

export const GraphBars = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 32px; /* espacement entre 2 graph bars */
  height: 100%;
  font: ${(props) => props.theme.fonts?.textSmall};
  color: ${(props) => props.theme.colors?.greys.dark};

  ${(props) =>
    props.nbColumn &&
    css`
      flex-basis: calc(100 / ${props.nbColumn}) %;
    `}
`;

export const GraphLabels = styled.div`
  font: ${(props) => props.theme.fonts?.textSmall};
  color: ${(props) => props.theme.colors?.greys.dark};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 0 10px 0;
  border-top: 1px solid ${(props) => props.theme.colors?.greys.darker};
  width: 100%;

  ${(props) =>
    props.nbColumn &&
    css`
      flex-basis: calc(100 / ${props.nbColumn}) %;
    `}
`;

export const GraphValue = styled.div`
  color: ${(props) => props.theme.colors?.greys?.darkest || props.color};
  padding-bottom: 5px;
  height: 25px;
  width: max-content;

  text-align: center;

  position: relative;
  left: ${({ position }) => position};

  font: ${(props) => props.theme.fonts?.textMicro};
`;

export const GraphBar = styled.div`
  width: 16px;
  border-radius: 16px 16px 0 0;

  height: ${({ height }) => `${height}%` || 0};
  min-height: 8px;
  background: ${({ color }) => color};
`;

export const GraphLabelDay = styled.div`
  font: ${(props) => props.theme.fonts?.textMicro};
  font-weight: ${(props) => props.bold && '700'};
`;

export const EmptyGraph = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const DisplayIconNoData = styled.img`
  width: 160px;
  margin-bottom: 24px;
`;

export const NoDataInfo = styled.div`
  font: ${(props) => props.theme.fonts?.textSmallHeight14};
  color: ${(props) => props.theme.colors?.greys.darker};
`;

export const GraphNoDataToDisplay = styled.div`
  position: relative;
  bottom: 10px;
  color: ${(props) => props.theme.colors?.greys.dark};
  text-align: center;

  font: ${(props) => props.theme.fonts?.textSmall};
`;

export const GraphBarsContainer = styled.div`
  height: 100%;
  width: 32px;
  position: absolute;
  z-index: ${({ zIndex }) => zIndex};
`;

export const OverlappedGraphBarsContainer = styled.div`
  height: 100%;
  position: relative;
`;

export const canEditAdminReservedFieldsIfCentralKitchenSP = (
  isCentralKitchen,
  centralKitchenSuppliersOfUser,
  selectedSupplier,
) => {
  // If the supplier product is not linked to a central kitchen product thus
  // all fields are enabled.
  if (!isCentralKitchen || !selectedSupplier) {
    return true;
  }

  const userCentralkitchenSupplierIds = new Set(centralKitchenSuppliersOfUser.map(({ id }) => id));

  return userCentralkitchenSupplierIds.has(selectedSupplier.id);
};

import { STORAGE_AREA_TYPE, INVENTORY_LIST_TEMPLATE_TYPE } from './constants';

export const handleNewDataCreation = (
  item,
  type,
  {
    createdStorageAreas,
    setCreatedStorageAreas,
    createdInventoryListTemplates,
    setCreatedInventoryListTemplates,
  },
) => {
  const dataToUpdate = {
    [STORAGE_AREA_TYPE.STORAGE_AREA]: {
      data: createdStorageAreas,
      setter: setCreatedStorageAreas,
    },
    [INVENTORY_LIST_TEMPLATE_TYPE.INVENTORY_LIST_TEMPLATE]: {
      data: createdInventoryListTemplates,
      setter: setCreatedInventoryListTemplates,
    },
  };

  const currentDataToUpdate = dataToUpdate[type];
  currentDataToUpdate.setter([...currentDataToUpdate.data, item]);
};

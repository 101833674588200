import i18next from 'i18next';
import React from 'react';

import { Dropdown } from '@commons/utils/styledLibraryComponents';

import theme from '@theme';

import {
  DropdownIcon,
  DropdownItem,
  DropdownItemMainValue,
  DropdownItemSecondaryValue,
  DropdownTitle,
} from '@admin/suppliers/supplierProducts/details/supplierProductInformations/components/SupplierProductDetails/styledComponents';

const DEFAULT_RECIPE_PICTURE = '/images/inpulse/recipe-photo-placeholder.svg';

const RecipeDropdown = (props) => {
  const { handleEntityChange, recipes, isReadOnly, composition, isLoading } = props;

  const renderedRecipes = recipes.map((recipe) => ({
    ...recipe,
    value: recipe.name,
    renderValue: () => (
      <DropdownItem style={{ marginRight: '32px' }}>
        <DropdownIcon src={recipe.img || DEFAULT_RECIPE_PICTURE} />
        <DropdownTitle>
          <DropdownItemMainValue>{recipe.name}</DropdownItemMainValue>
          <DropdownItemSecondaryValue isDisabled={recipe.isDisabled}>
            {recipe.category}
          </DropdownItemSecondaryValue>
        </DropdownTitle>
      </DropdownItem>
    ),
  }));

  return (
    <Dropdown
      customStyle={{ height: 'auto' }}
      isDisabled={isReadOnly || isLoading}
      items={renderedRecipes}
      label={i18next.t('GENERAL.RECIPE')}
      labelStyle={{ font: theme.fonts.textSmall }}
      placeholder={i18next.t('GENERAL.SEARCH')}
      selectedItem={composition}
      tooltipText={i18next.t('ADMIN.PRODUCTS.COMPOSITION_TOOLTIP_RECIPE')}
      isRequired
      onSelectionChange={handleEntityChange}
    />
  );
};

export default RecipeDropdown;

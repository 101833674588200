import { connect } from 'react-redux';
import { get, isEmpty, isEqual } from 'lodash';
import { useForm, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { buildSchema } from '@commons/GenericForm';
import { Button } from '@commons/utils/styledLibraryComponents';
import { ENUM_MODULE_NAME } from '@commons/utils/features';
import Footer from '@commons/Footer/Footer';
import NavigationBar from '@commons/NavigationBar';
import normalizeStringValue from '@commons/utils/normalizeStringValue';

import { getClientInfo } from '@selectors/client';

import { getRecipeCost } from '@services/recipe';
import centralService from '@services/central';

import { fetchProduct } from '@admin/products/products/components/CreateProductModal';
import { fetchProductsCategoriesOfClient } from '@admin/products/products/detail/components/ProductInfo/common/services';
import { getCompositionTypes } from '@admin/products/products/detail/components/ProductRecipeAssociation';
import utilsProductInfo from '@admin/products/products/detail/components/ProductInfo/utils';

import {
  ALL_KITCHEN_PRODUCTS_FIELDS_FOR_DETAILS_VIEW,
  KITCHEN_PRODUCT_FORM_INPUTS,
} from '../utils/formInputsConfigurations';
import { uploadPicture } from '../utils/uploadPicture';

import { ButtonsContainer, ContentContainer } from './styledComponents';
import KitchenProductInformationsSection from './KitchenProductInformationsSection';

const KitchenProductDetails = (props) => {
  const {
    client: { clientId, storeName },
    match,
    showSuccessMessage,
    showErrorMessage,
    pageLoading,
    pageLoaded,
    currency,
  } = props;

  const path = get(match, 'path');
  const params = get(match, 'params');

  const history = useHistory();

  const compositionTypes = getCompositionTypes();

  const [productId] = useState(params.id);
  const [product, setProduct] = useState({});
  const [productsCategories, setProductsCategories] = useState([]);
  const [productsSubCategories, setProductsSubCategories] = useState([]);

  // Product picture
  const [picture, setPicture] = useState(null);
  const [selectedPictureFile, setSelectedPictureFile] = useState(null);

  // Product composition
  const [composition, setComposition] = useState({});
  const [selectedCompoType, setSelectedCompoType] = useState({});

  // Footer states
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [isSaveAlreadyTriggered, setIsSaveAlreadyTriggered] = useState(false);
  const [isFooterDisplayed, setIsFooterDisplayed] = useState(false);

  // Form
  const [supplierProduct, setSupplierProduct] = useState({});
  const [supplierProducts, setSupplierProducts] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState({});
  const [initialSupplier, setInitialSupplier] = useState({});

  const allInputs = [
    ...KITCHEN_PRODUCT_FORM_INPUTS({
      productsCategories,
      setProductsCategories,
      productsSubCategories,
      setProductsSubCategories,
    }),
  ];

  const productForm = useForm({
    defaultValues: {},
    resolver: yupResolver(buildSchema(allInputs)),
  });

  const formFields = useWatch({
    control: productForm.control,
  });

  // Retrieve all necessary data to configure the product
  useEffect(() => {
    pageLoading();

    (async function loadData() {
      try {
        const result = await fetchProduct(productId, setProduct);

        if (!!result.lnkEntityProductrel) {
          const recipeCost = await getRecipeCost(result.entityId, { withAllergens: false });

          result.lnkEntityProductrel.costByChannelId = recipeCost.costByChannelId;
        }

        const priceHT = utilsProductInfo.getPriceExcludingVAT(result);
        result.priceHT = priceHT;

        const { name, sku, shelfLife, category, subCategory } = result;

        const productsCategoriesAndSubCategories = await fetchProductsCategoriesOfClient(
          clientId,
          setProductsCategories,
          setProductsSubCategories,
          true,
        );

        const fetchedCategory = productsCategoriesAndSubCategories.categories.find(
          ({ name }) => normalizeStringValue(name) === normalizeStringValue(category),
        );

        const fetchedSubCategory = productsCategoriesAndSubCategories.subCategories.find(
          ({ name }) => normalizeStringValue(name) === normalizeStringValue(subCategory),
        );

        await fetchSupplierProducts();

        productForm.setValue('name', name);
        productForm.setValue('sku', sku);
        productForm.setValue('shelfLife', shelfLife);
        productForm.setValue('currency', currency);
        productForm.setValue('category', fetchedCategory);
        productForm.setValue('subCategory', fetchedSubCategory);

        setPicture(result.img);
        setCompositionOnFirstLoad(result);
        setProduct(result);
      } catch {
        showErrorMessage(i18next.t('ADMIN.PRODUCTS.CATEGORIES_FETCH_ERROR'));
      } finally {
        pageLoaded();
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const fieldsToCheck = ALL_KITCHEN_PRODUCTS_FIELDS_FOR_DETAILS_VIEW;

      const fieldsValidation = await productForm.trigger(fieldsToCheck);

      const invalidForm = !fieldsValidation || isEmpty(composition) || isEmpty(selectedSupplier);

      setIsSaveDisabled(invalidForm);

      const isFormDirty = get(productForm, 'formState.isDirty', false);

      const dirtyFields = Object.keys(get(productForm, 'formState.dirtyFields', {}));

      const hasAtLeastOneDifferentField = dirtyFields.some(
        (field) => !isEqual(product[field], formFields[field]),
      );

      if (
        !isEmpty(composition) &&
        ((isFormDirty && hasAtLeastOneDifferentField) ||
          product.lnkEntityProductrel !== composition ||
          initialSupplier.id !== selectedSupplier.id ||
          product.img !== picture)
      ) {
        setIsFooterDisplayed(true);
        return;
      }

      setIsFooterDisplayed(false);
    })();
  }, [formFields, composition, picture, selectedSupplier]);

  const fetchSupplierProducts = async () => {
    pageLoading();

    try {
      const fetchedSupplierProducts =
        await centralService.getCentralKitchenSupplierProductsByProductId(productId);

      const formattedSupplierProducts = fetchedSupplierProducts.filter(
        ({ productMapping }) => productMapping.productId === productId,
      );

      const fetchedSuppliers = formattedSupplierProducts.map(({ supplierId, supplierName }) => ({
        id: supplierId,
        value: supplierName,
      }));

      const selectedSupplierProduct = formattedSupplierProducts.find(
        ({ productMapping }) => productMapping.usedByDefault,
      );

      const selectedSupplier = {
        id: selectedSupplierProduct.supplierId,
        value: selectedSupplierProduct.supplierName,
      };

      setSupplierProducts(formattedSupplierProducts);
      setSuppliers(fetchedSuppliers);
      setSelectedSupplier(selectedSupplier);
      setInitialSupplier(selectedSupplier);
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.FETCH_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const setCompositionOnFirstLoad = (fetchedProduct) => {
    if (!fetchedProduct.lnkEntityProductrel) {
      return;
    }

    setComposition(fetchedProduct.lnkEntityProductrel);
    setSelectedCompoType(
      fetchedProduct.lnkEntityProductrel.isIngredient ? compositionTypes[2] : compositionTypes[1],
    );
  };

  const handleSave = async () => {
    pageLoading();

    try {
      const pictureUrl =
        product.img !== picture && !!picture
          ? await uploadPicture(clientId, formFields.name, selectedPictureFile)
          : picture;

      const formattedCategory = get(formFields, 'category.name', '');
      const formattedSubCategory = get(formFields, 'subCategory.name', '');

      const productPayload = {
        ...product,
        ...formFields,
        category: formattedCategory,
        subCategory: formattedSubCategory,
        supplierId: selectedSupplier.id,
        entityId: composition.id,
        img: pictureUrl,
        supplierProductId: supplierProduct.id,
      };

      await centralService.updateCentralKitchenProduct(productPayload);
      showSuccessMessage(i18next.t('GENERAL.CHANGES_SUCCESSFULLY_SAVED'));
      redirectToProductList();
    } catch {
      showErrorMessage(i18next.t('ADMIN.PRODUCTS.UPDATE_PRODUCT_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const redirectToProductList = () => {
    history.push('/admin/kitchen-products/products');
  };

  const validateForm = async () => {
    const fieldsToCheck = ALL_KITCHEN_PRODUCTS_FIELDS_FOR_DETAILS_VIEW;

    const fieldsValidation = await productForm.trigger(fieldsToCheck);

    const invalidForm = !fieldsValidation || isEmpty(composition) || isEmpty(selectedSupplier);

    setIsSaveAlreadyTriggered(true);

    if (invalidForm) {
      setIsSaveDisabled(true);
      return;
    }

    handleSave();
  };

  return (
    <>
      <NavigationBar
        module={ENUM_MODULE_NAME.KITCHEN_PRODUCT_DETAILS}
        path={path}
        product={{ name: product.name, id: productId }}
        bigTopBar
        displaySubFeatures
        enableActionBottomOrder
      />
      <ContentContainer isFooterDisplayed={isFooterDisplayed}>
        <KitchenProductInformationsSection
          composition={composition}
          compositionError={isEmpty(composition) && isSaveAlreadyTriggered}
          currency={currency}
          formError={isEmpty(selectedSupplier) && isSaveAlreadyTriggered}
          formFields={formFields}
          isSaveAlreadyTriggered={isSaveAlreadyTriggered}
          match={match}
          picture={picture}
          product={product}
          productForm={productForm}
          productsCategories={productsCategories}
          productsSubCategories={productsSubCategories}
          selectedCompoType={selectedCompoType}
          selectedPictureFile={selectedPictureFile}
          selectedSupplier={selectedSupplier}
          setComposition={setComposition}
          setPicture={setPicture}
          setProduct={setProduct}
          setProductsCategories={setProductsCategories}
          setProductsSubCategories={setProductsSubCategories}
          setSelectedCompoType={setSelectedCompoType}
          setSelectedPictureFile={setSelectedPictureFile}
          setSelectedSupplier={setSelectedSupplier}
          setSupplierProduct={setSupplierProduct}
          storeName={storeName}
          supplierProduct={supplierProduct}
          supplierProducts={supplierProducts}
          suppliers={suppliers}
        />
      </ContentContainer>
      {isFooterDisplayed && (
        <Footer>
          <ButtonsContainer>
            <Button
              color={'inpulse-outline'}
              handleClick={redirectToProductList}
              icon={'/images/inpulse/close-black-small.svg'}
              label={i18next.t('GENERAL.CANCEL')}
            />
            <Button
              color={'inpulse-default'}
              handleClick={() => validateForm()}
              icon={'/images/inpulse/save-white-small.svg'}
              isDisabled={isSaveDisabled}
              label={i18next.t('GENERAL.SAVE')}
            />
          </ButtonsContainer>
        </Footer>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(KitchenProductDetails);

import { get } from 'lodash';
import React, { useEffect, useState } from 'react';

import ProductOrder from './components/ProductOrder';
import utilsLib from './utils';

import { ProductGroupContainer } from './styledComponents';
import ORDER_STATUS from '../../constants/status';

const ProductOrdersContainer = (props) => {
  const {
    canStillOrder,
    category,
    columns,
    currency,
    deleteProductOrder,
    endDate,
    handleInvoicedDiff,
    hasBLI,
    indexCategory,
    indexEntity,
    invoicedDiffOfProductOrder,
    isEditable,
    isEditingPreparationOrder,
    openStockInfo,
    orderStatus,
    prefillInpulse,
    preparationStatus,
    products,
    receivedDiffOfProductOrder,
    selectedStore,
    setDisplayProductPicture,
    setOpenStockInfo,
    startDate,
    storeId,
    storeInfos,
    sumUpRecommendations,
    supplierInfos,
    updateProduct,
    user,
    allCreditPicturesHandlers,
  } = props;

  const [computedOrderedAmount, setComputedOrderedAmount] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [updateAmount, setUpdateAmount] = useState(false);

  useEffect(() => {
    if (!products || (!products.length && !updateAmount)) {
      return;
    }

    setComputedOrderedAmount(utilsLib.computeOrderedAmount(products));
    setUpdateAmount(false);
  }, [products, updateAmount]);

  const _isFirstElementAvailable = (currentProduct, index) =>
    products.slice(0, index).every(({ available }) => !available) && currentProduct.available;

  return (
    <>
      <ProductGroupContainer addBorder={orderStatus < ORDER_STATUS.SENT} isOpen={isOpen}>
        {products.map((product, index) => {
          const uniqKey = `${product.name}-${index}`;

          return (
            <ProductOrder
              allCreditPicturesHandlers={allCreditPicturesHandlers}
              allProductsUnavailable={products.every((product) => !product.available)}
              canStillOrder={canStillOrder}
              category={category}
              columns={columns}
              computedOrderedAmount={computedOrderedAmount}
              currency={currency}
              deleteProductOrder={deleteProductOrder}
              endDate={endDate}
              entityStockEvent={get(products, '[0].entityStockEvent', {})}
              handleInvoicedDiff={handleInvoicedDiff}
              handleReceivedDiff={props.handleReceivedDiff}
              hasBLI={hasBLI}
              indexCategory={indexCategory}
              indexEntity={indexEntity}
              invoicedDiffOfProductOrder={invoicedDiffOfProductOrder}
              isEditable={isEditable}
              isEditingPreparationOrder={isEditingPreparationOrder}
              isFirstElement={index === 0}
              isFirstElementAvailable={_isFirstElementAvailable(product, index)}
              isLastElement={index === products.length - 1}
              isOpen={isOpen}
              key={uniqKey}
              multiple={products.length > 0 && products.length - 1 !== index}
              openStockInfo={openStockInfo}
              orderStatus={orderStatus}
              prefillInpulse={prefillInpulse}
              preparationStatus={preparationStatus}
              product={product}
              receivedDiffOfProductOrder={receivedDiffOfProductOrder}
              selectedStore={selectedStore}
              setDisplayProductPicture={setDisplayProductPicture}
              setIsOpen={setIsOpen}
              setOpenStockInfo={setOpenStockInfo}
              setUpdateAmount={setUpdateAmount}
              startDate={startDate}
              storeId={storeId}
              storeInfos={storeInfos}
              sumUpRecommendations={sumUpRecommendations}
              supplierInfos={supplierInfos}
              updateProduct={updateProduct}
              user={user}
            />
          );
        })}
      </ProductGroupContainer>
    </>
  );
};

export default ProductOrdersContainer;

import { get, isEmpty, omit } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import {
  Container,
  ContainerRadioButton,
  CreateItemContainer,
  Icon,
  IconContainer,
  Input,
  Item,
  ItemContainer,
  Text,
} from './styledComponents';

import { RadioButton } from '../utils/styledLibraryComponents';

import { capitalizeFirstLetter } from '../utils/format';
import sortSupplierProductProperties from '../../routes/admin/utils/sortSupplierProductProperties';

export const PROPERTY_NONE = 'Aucune';
export const STANDARD_PRICE = 'Prix HT Standard';
export const CENTRAL_CATEGORY = 'Centrale';

const ItemListSorter = (props) => {
  const {
    groupAllAtOnce,
    disableCreateItem,
    createItemLabel,
    disableMergeItems,
    checkForPropertyNone,
    propertyNoneLabel = PROPERTY_NONE,
  } = props;
  const [list, setList] = useState({});
  const [properties, setProperties] = useState([]);

  const [propertyToEdit, setPropertyToEdit] = useState(null);
  const [propertyToDelete, setPropertyToDelete] = useState(null);

  const [inputValue, setInputValue] = useState('');

  const [selectedItemSingleSelection, setSelectedItemSingleSelection] = useState('');

  const [penHover, setPenHover] = useState(null);
  const [crossHover, setCrossHover] = useState(null);
  const [trashHover, setTrashHover] = useState(null);
  const [checkHover, setCheckHover] = useState(null);
  const [createHover, setCreateHover] = useState(null);
  const [displayCreateInput, setDisplayCreateInput] = useState(false);

  const sortProperties = (items) =>
    items.sort((itemA, itemB) => {
      const propertyNameA = typeof itemA === 'string' ? itemA : itemA.value;

      const propertyNameB = typeof itemB === 'string' ? itemB : itemB.value;

      if ([propertyNoneLabel, STANDARD_PRICE].includes(propertyNameA)) {
        return -1;
      }

      if ([propertyNoneLabel, STANDARD_PRICE].includes(propertyNameB)) {
        return 1;
      }

      return propertyNameA > propertyNameB ? 1 : propertyNameA < propertyNameB ? -1 : 0;
    });

  useEffect(() => {
    if (isEmpty(props)) {
      return;
    }

    setList(props.data);
    setProperties(sortProperties(props.properties));

    if (props.groupAllAtOnce && Object.keys(props.data).length === 1) {
      const value = Object.keys(props.data)[0];

      setSelectedItemSingleSelection(value);
    }
  }, [props]);

  useEffect(() => {
    if (propertyToEdit) {
      setInputValue(propertyToEdit);

      return;
    }

    setInputValue('');
  }, [propertyToEdit]);

  const isPropertyNameValid = (property) => {
    if (!property) {
      return false;
    }

    const formattedProperty = property.trim();

    if (disableMergeItems && formattedProperty !== propertyToEdit && !!list[formattedProperty]) {
      return false;
    }

    return !!formattedProperty;
  };

  const deleteProperty = (property) => {
    const updatedList = { ...list };

    if (!!checkForPropertyNone) {
      const associatedItems = get(updatedList[propertyNoneLabel], 'associatedItems', []);
      updatedList[propertyNoneLabel].associatedItems = associatedItems.concat(
        updatedList[property].associatedItems,
      );
    }
    delete updatedList[property];

    triggerOnChange(updatedList);
    setList(updatedList);
    setProperties(sortSupplierProductProperties(Object.keys(updatedList)));

    setPropertyToDelete(null);
  };

  const editProperty = (actualProperty, newProperty) => {
    const formattedProperty = disableMergeItems
      ? newProperty.trim()
      : capitalizeFirstLetter(newProperty.trim());

    if (actualProperty === formattedProperty) {
      setInputValue('');
      setPropertyToEdit(null);

      return;
    }

    const updatedList = { ...list };

    if (!!updatedList[formattedProperty]) {
      updatedList[formattedProperty].associatedItems = updatedList[
        formattedProperty
      ].associatedItems.concat(updatedList[actualProperty].associatedItems);
    } else {
      updatedList[formattedProperty] = updatedList[actualProperty];
    }

    delete updatedList[actualProperty];

    triggerOnChange(updatedList);
    setList(updatedList);
    setProperties(sortSupplierProductProperties(Object.keys(updatedList)));

    setInputValue('');
    setPropertyToEdit(null);
  };

  const createProperty = (newProperty) => {
    const formattedProperty = disableMergeItems
      ? newProperty.trim()
      : capitalizeFirstLetter(newProperty.trim());
    const updatedList = { ...list };
    updatedList[formattedProperty] = { associatedItems: [] };

    triggerOnChange(updatedList);
    setList(updatedList);
    setProperties(sortSupplierProductProperties(Object.keys(updatedList)));

    setInputValue('');
    setPropertyToEdit(null);
    setDisplayCreateInput(false);
  };

  const triggerOnChange = (list) => {
    if (!list) {
      return;
    }

    const formattedList = [];

    for (const [key, value] of Object.entries(list)) {
      formattedList.push({
        value: key,
        field: props.field,
        ids: value.associatedItems.map((item) => item.id),
        originalItem:
          value.originalItem ||
          omit(value, ['associatedItems', 'originalItem', 'value', 'field', 'ids']),
      });
    }

    props.onChange(formattedList);
  };

  const triggerOnChangeGroupAllAtOnce = (selectedProperty) => {
    if (!list) {
      return;
    }

    const formattedList = [];

    for (const [, value] of Object.entries(list)) {
      formattedList.push({
        field: props.field,
        value: selectedProperty.value,
        ids: value.associatedItems.map((item) => item.id),
        originalItem:
          value.originalItem ||
          omit(value, ['associatedItems', 'originalItem', 'value', 'field', 'ids']),
        originalProperty: omit(selectedProperty, ['value']),
      });
    }

    props.onChange(formattedList, selectedProperty);
    setSelectedItemSingleSelection(selectedProperty.value);
  };

  const renderProperty = ({ property, isDisabled, isDeleting, isEditing }) => {
    if (isDeleting) {
      return (
        <Item>
          <Text orange>{i18next.t('GENERAL.DELETE')} ?</Text>
        </Item>
      );
    }

    if (isEditing) {
      return (
        <Item>
          <Input
            type="text"
            value={inputValue}
            autoFocus
            onChange={(e) => setInputValue(e.target.value)}
          />
        </Item>
      );
    }

    return (
      <Item>
        <Text isDisabled={isDisabled}>{property}</Text>
      </Item>
    );
  };

  const renderContent = ({ property, isDisabled, isDeleting, isEditing }) => {
    if (isDeleting || isEditing) {
      return null;
    }

    const useStoreCountLabel = get(list, `${property}.useStoreCountLabel`, null);

    return (
      <Item>
        <Text isDisabled={isDisabled}>{`${list[property].associatedItems.length} ${
          useStoreCountLabel
            ? i18next
                .t('GENERAL.STORE', { count: list[property].associatedItems.length })
                .toLowerCase()
            : props.entityName
        }`}</Text>
      </Item>
    );
  };

  const renderIcons = ({ property, isDisabled, isEditing, isDeleting, index }) => {
    if (isDisabled) {
      return <Item></Item>;
    }

    if (isDeleting) {
      return (
        <>
          <IconContainer>
            <Icon
              src="/images/inpulse/close-info-orange-small.svg"
              onClick={() => setPropertyToDelete(null)}
            />
            <Icon
              src="/images/inpulse/check-info-orange-small.svg"
              onClick={() => deleteProperty(property)}
            />
          </IconContainer>
        </>
      );
    }

    if (isEditing) {
      const isValid = isPropertyNameValid(inputValue);

      return (
        <>
          <IconContainer>
            <Icon
              src={
                !!crossHover && crossHover === index
                  ? '/images/inpulse/close-ip-green-small.svg'
                  : '/images/inpulse/close-black.svg'
              }
              onClick={() => {
                setInputValue('');
                setCrossHover(null);

                setPropertyToEdit(null);

                if (displayCreateInput) {
                  setDisplayCreateInput(false);
                }
              }}
              onMouseOut={() => setCrossHover(null)}
              onMouseOver={() => setCrossHover(index)}
            />
            <Icon
              isDisabled={!isValid}
              src={
                !isValid
                  ? '/images/inpulse/check-lmgrey-small.svg'
                  : !!checkHover && checkHover === index
                  ? '/images/inpulse/check-ip-green-small.svg'
                  : '/images/inpulse/check-black-small.svg'
              }
              onClick={() => {
                if (!isValid) {
                  return;
                }

                setCheckHover(null);

                if (displayCreateInput) {
                  return createProperty(inputValue);
                }

                editProperty(property, inputValue);
              }}
              onMouseOut={() => setCheckHover(null)}
              onMouseOver={() => setCheckHover(index)}
            />
          </IconContainer>
        </>
      );
    }

    return (
      <Item>
        <IconContainer>
          <Icon
            src={
              !!penHover && penHover === index
                ? '/images/inpulse/pen-ip-green-small.svg'
                : '/images/inpulse/pen-ip-black-small.svg'
            }
            onClick={() => {
              setInputValue(property);
              setPenHover(null);

              setPropertyToEdit(property);
            }}
            onMouseOut={() => setPenHover(null)}
            onMouseOver={() => setPenHover(index)}
          />
          <Icon
            src={
              !!trashHover && trashHover === index
                ? '/images/inpulse/delete-ip-green-small.svg'
                : '/images/inpulse/delete-black-small.svg'
            }
            onClick={() => {
              setPropertyToDelete(property);
              setTrashHover(null);
            }}
            onMouseOut={() => setTrashHover(null)}
            onMouseOver={() => setTrashHover(index)}
          />
        </IconContainer>
      </Item>
    );
  };

  const renderPropertyGroupAllAtOnce = ({ property, isDisabled }) => (
    <ContainerRadioButton onClick={() => triggerOnChangeGroupAllAtOnce(property)}>
      <RadioButton selected={selectedItemSingleSelection} size="small" value={property.value} />
      <Text isDisabled={isDisabled}>{property.value}</Text>
    </ContainerRadioButton>
  );

  const renderCreateInput = () => {
    const property = '';
    const index = '';

    const isDisabled = false;
    const isDeleting = false;
    const isEditing = true;

    return (
      <ItemContainer>
        {renderProperty({ property, isDisabled, isDeleting, isEditing })}
        {renderContent({ property, isDisabled, isDeleting, isEditing })}
        {renderIcons({ property, isDisabled, isDeleting, isEditing, index })}
      </ItemContainer>
    );
  };

  if (groupAllAtOnce) {
    return (
      <Container>
        {!!properties &&
          properties.map((property, index) => {
            // Keyword 'Aucun' keep for disable state cause null is cast as string by Object.keys()
            const isDisabled = property.value === propertyNoneLabel;
            return (
              <ItemContainer key={`${property.value}-${index}`}>
                {renderPropertyGroupAllAtOnce({ property, isDisabled })}
              </ItemContainer>
            );
          })}
      </Container>
    );
  }

  return (
    <Container>
      {!disableCreateItem && !displayCreateInput && (
        <CreateItemContainer
          onClick={() => {
            setDisplayCreateInput(true);
            setCreateHover(null);
          }}
        >
          <Icon
            src={
              !!createHover
                ? '/images/inpulse/plus-green-small.svg'
                : '/images/inpulse/plus-black-small.svg'
            }
            style={{ marginLeft: 0, marginRight: '12px' }}
            keepIconsSize
          />
          <Text
            bold
            onMouseOut={() => setCreateHover(null)}
            onMouseOver={() => setCreateHover(true)}
          >
            {createItemLabel || i18next.t('GENERAL.CREATE')}
          </Text>
        </CreateItemContainer>
      )}
      {displayCreateInput && renderCreateInput()}
      {!!properties &&
        properties.map((property, index) => {
          if (!list[property]) {
            return null;
          }

          // Keyword 'Aucun' keep for disable state cause null is cast as string by Object.keys()
          const isDisabled = new Set([propertyNoneLabel, STANDARD_PRICE, CENTRAL_CATEGORY]).has(
            property,
          );
          const hideIcons = isDisabled || displayCreateInput;

          const isDeleting = property === propertyToDelete;

          const isEditing = property === propertyToEdit;

          return (
            <ItemContainer isDisabled={isDisabled} key={`${property}-${index}`} orange={isDeleting}>
              {renderProperty({ property, isDisabled, isDeleting, isEditing })}
              {renderContent({ property, isDisabled, isDeleting, isEditing })}
              {renderIcons({ property, isDisabled: hideIcons, isDeleting, isEditing, index })}
            </ItemContainer>
          );
        })}
    </Container>
  );
};

ItemListSorter.defaultProps = {
  groupAllAtOnce: false,
  disableCreateItem: true,
  createItemLabel: '',
  disableMergeItems: false,
  checkForPropertyNone: true,
};

export default ItemListSorter;

import { connect } from 'react-redux';
import { get, isEmpty, head } from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { showConfirmationMessage } from '@actions/messageconfirmation';

import { ListView } from '@commons/utils/styledLibraryComponents';

import { CHOICES_DROPDOWN_ACTIVE } from '@admin/utils/DropdownItems';
import DeepsightFiltersButton from '@orders/components/FilterButton';

import EmptyState from '../EmptyState';

import { Container, Content } from './styledComponents';

export const WarningModalContent = (props) => {
  const { storeToCloneFrom, storeToCloneInto } = props;
  return (
    <Content>
      {i18next.t('BACKOFFICE.STORES.DUPLICATION_WARNING_MODAL_CONTENT', {
        storeToCloneFrom: storeToCloneFrom.name || 'A',
        storeToCloneInto: storeToCloneInto.name || 'B',
      })}
    </Content>
  );
};

const StoreDuplicationComponent = (props) => {
  const { storeList, brands, dataColumns, user, handleStoreToCloneFromChange, storeToCloneFrom } =
    props;

  const [selectedBrands, setSelectedBrands] = useState([]);
  const [filters, setFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);
  const [advancedFilters, setAdvancedFilters] = useState(null);
  const [columnsFilterList, setColumnsFilterList] = useState([]);
  const [filteredStoresList, setFilteredStoresList] = useState([...storeList]);
  const [activeKeysForActiveDropdown, setActiveKeysForActiveDropdown] = useState([
    CHOICES_DROPDOWN_ACTIVE()[0],
  ]);

  const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');

  useEffect(() => {
    if (!user) {
      return;
    }

    setColumnsFilterList(
      dataColumns.filter((column) => ['date', 'string', 'numeric'].includes(column.filterType)),
    );
  }, [user]);

  useEffect(() => {
    const filteredSelectedStores = storeList.filter((store) =>
      activeKeysForActiveDropdown.some((key) => key.filterValue === store.active),
    );

    if (!advancedFilters || !advancedFilters.length) {
      setFilteredStoresList(filteredSelectedStores);
      return;
    }

    const filteredStoresListWithAdvancedFilters = advancedFilters.reduce(
      (result, { doFilter, propertyKey, value }) => doFilter(result, propertyKey, value),
      filteredSelectedStores,
    );

    setFilteredStoresList(filteredStoresListWithAdvancedFilters);
  }, [storeList, applyFilters]);

  useEffect(() => {
    if (isEmpty(storeToCloneFrom) || !filteredStoresList.length) {
      return;
    }
    const isIncluded = filteredStoresList.some((store) => store.id === storeToCloneFrom.id);

    if (!isIncluded) {
      handleStoreToCloneFromChange({});
    }
  }, [filteredStoresList, applyFilters]);

  return (
    <Container style={{ height: '100%' }}>
      <ListView
        columns={dataColumns}
        data={filteredStoresList}
        defaultOrderBy={'name'}
        defaultOrderType={'asc'}
        disableFooter={true}
        disableMultipleSelection={true}
        languageCode={userLanguageCode}
        padding={'6px 0px 0px 0px'}
        placeholderShape={i18next.t('GENERAL.SEARCH')}
        renderEmptyState={() => <EmptyState />}
        renderFilterButton={() => (
          <DeepsightFiltersButton
            advancedFilters={advancedFilters}
            applyFilters={applyFilters}
            brands={brands}
            columnsFilterList={columnsFilterList}
            customMultipleDropDowns={[
              {
                id: 'status',
                icon: '/images/icon-dropdown-grey.svg',
                list: CHOICES_DROPDOWN_ACTIVE(),
                defaultSelectedItems: [CHOICES_DROPDOWN_ACTIVE()[0]],
                selectedItems: activeKeysForActiveDropdown,
                setSelectedItems: (selectedItems) => setActiveKeysForActiveDropdown(selectedItems),
              },
            ]}
            filters={filters}
            selectedBrands={selectedBrands}
            setAdvancedFilters={setAdvancedFilters}
            setApplyFilters={setApplyFilters}
            setFilters={setFilters}
            setSelectedBrands={setSelectedBrands}
            textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
          />
        )}
        setSelectedItems={(selectedStores) => handleStoreToCloneFromChange(head(selectedStores))}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreDuplicationComponent);

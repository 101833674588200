import styled, { css } from 'styled-components';

export const ModalBackground = styled.div`
  z-index: 1;
  position: fixed;

  background: #1d1d1d40;

  top: 0px;
  left: 0px;

  width: 100%;
  height: 100vh;

  display: flex;
  align-items: ${(props) => !props.isTablet && 'center'};
  justify-content: center;
`;

export const ModalContainer = styled.div`
  margin-top: ${(props) => props.isTablet && '3.5vh'};
  position: relative;

  background: #ffffff;

  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);

  width: 560px;
  min-height: 408px;
  max-height: ${(props) => props.isTablet && '80vh'};

  display: flex;
  flex-direction: column;
`;

export const ModalHeader = styled.div`
  min-height: 72px;

  padding: 24px;
  background: ${(props) => props.theme.colors?.brand?.primaryLight};
  border-radius: 8px 8px 0px 0px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ModalHeaderRushOrder = styled.div`
  display: flex;
  align-items: center;
`;

export const ModalHeaderRight = styled.div`
  justify-content: space-between;
`;

export const ModalContent = styled.div`
  min-height: 348px;
  max-height: 496px;
  overflow: scroll;
  flex: 1;

  padding: 24px 160px 72px 160px;
`;

export const ModalFooter = styled.div`
  padding: 24px;
  min-height: 88px;

  display: flex;
  justify-content: flex-end;

  border-radius: 0px 0px 8px 8px;
  box-shadow: inset 0px 2px 0px ${(props) => props.theme.colors?.greys?.lighter};
`;

export const Text = styled.div`
  color: ${(props) =>
    props.disabled ? props.theme.colors?.greys.dark : props.theme.colors?.greys?.darkest};
  font: ${(props) => (props.title ? props.theme.fonts?.h1Inter : props.theme.fonts?.textSmall)};
`;

export const Section = styled.div`
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const Icon = styled.img`
  ${(props) =>
    props.interactive &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}

  ${(props) =>
    props.isRushOrder &&
    css`
      margin-right: 12px;
    `}
`;

import i18next from 'i18next';
import moment from 'moment/moment';
import React, { Fragment } from 'react';

// Commons
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { Label, Tooltip } from '@commons/utils/styledLibraryComponents';
import { SIGNIFICANCE_THRESHOLD_SIMILARITY_SCORE_MAPPING } from '@admin/cashier/cashierProducts/common/constants';
import DisplayNumber from '@commons/DisplayNumber';

// Design
import {
  DateContainer,
  ListRowText,
  PointerDiv,
  TagContainer,
  TagHoverBox,
  TagInfo,
  TagLabel,
} from './styledComponents';
import {
  DisplayBiggerContainer,
  ItemRowContent,
} from '@admin/cashier/transactions/styledComponents';
import { getTheme } from '@commons/utils/theme';

const theme = getTheme();

export const renderTags = (itemList) => {
  if (!itemList || !itemList.length) {
    return null;
  }
  if (itemList.length === 1) {
    return (
      <TagContainer>
        <TagLabel>{itemList[0]}</TagLabel>
      </TagContainer>
    );
  }
  return (
    <TagContainer>
      <TagLabel>{itemList[0]}</TagLabel>
      <TagInfo>{`+ ${itemList.length - 1}`}</TagInfo>
      <TagHoverBox>
        <PointerDiv />
        {itemList.map((item, index) => (
          <TagLabel className="box" key={index}>
            {item}
          </TagLabel>
        ))}
      </TagHoverBox>
    </TagContainer>
  );
};

export const getListColumns = () => [
  {
    name: i18next.t('GENERAL.NAME'),
    propertyKey: 'name',
    displayBigger: true,
  },
  {
    propertyKey: 'similarityScore',
    name: i18next.t('ADMIN.CASHIER_PRODUCTS.ASSOCIATION_MODAL_LIST_COLUMN_RELEVANCE'),
    render: (score) => {
      // We use floor and not round because we don't want a score of 100 if it's not totally equal
      const formattedScore = score ? Math.floor(score) : 0;

      const isRelevant = formattedScore >= SIGNIFICANCE_THRESHOLD_SIMILARITY_SCORE_MAPPING;

      return (
        <Label
          background={isRelevant ? theme.colors.brand.secondary : theme.colors.greys.lighty}
          color={isRelevant ? theme.colors.greys.darkest : theme.colors.greys.lightest}
          type={'plain'}
          width={'fit-content'}
        >
          {formattedScore}
        </Label>
      );
    },
  },
  {
    propertyKey: 'sku',
    name: i18next.t('GENERAL.SKU'),
    render: (item) => (
      <DisplayBiggerContainer>
        <Tooltip
          renderTooltipTrigger={() => (
            <ItemRowContent displayBigger>
              {item || i18next.t('GENERAL.SELECT_UNDEFINED')}
            </ItemRowContent>
          )}
          text={item}
          displayBigger
        />
      </DisplayBiggerContainer>
    ),
  },
  {
    propertyKey: 'tags',
    name: i18next.t('ADMIN.PRODUCTS.CREATE_PRODUCT_MODAL_CASHIER_PRODUCT_ASSOCIATION_TAGS'),
    render: (item) => renderTags(item),
  },
  {
    propertyKey: 'createdAt',
    name: i18next.t('ADMIN.PRODUCTS.CREATE_PRODUCT_MODAL_CASHIER_PRODUCT_ASSOCIATION_ADD_DATE'),
    render: (item) =>
      item ? (
        <DateContainer bold>
          {moment(item).format(DATE_DISPLAY_FORMATS.DATE_MONTH_YEAR)}
        </DateContainer>
      ) : (
        ''
      ),
  },
  {
    propertyKey: 'priceWithTaxes',
    name: (
      <Fragment>
        <span>{i18next.t('GENERAL.PRICE_TTC')}</span>
        <Tooltip
          text={i18next.t(
            'ADMIN.PRODUCTS.CREATE_PRODUCT_MODAL_CASHIER_PRODUCT_ASSOCIATION_ON_SITE_PRICE',
          )}
        />
      </Fragment>
    ),
    render: (item) => <DisplayNumber displayCurrencyCode={true} number={item} />,
  },
  {
    propertyKey: 'vatRate',
    name: i18next.t('GENERAL.VAT_RATE'),
    render: (item) => <ListRowText>{item !== null ? `${item}%` : '-'}</ListRowText>,
  },
];

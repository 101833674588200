import i18next from 'i18next';

import { getClientStoreNameTranslation } from '@commons/utils/translations';

export const PROP_KEYS = {
  ADJUSTED_THEORETICAL_MARGIN: 'adjustedTheoreticalMargin',
  AVERAGE_PRICE: 'averagePrice',
  CATEGORY: 'category',
  CATEGORY_RATIO: 'categoryRatio',
  END_DATE: 'endDate',
  FOOD_COST_PER_UNIT: 'foodCostPerUnit',
  FOOD_COST_TOTAL: 'foodCostTotal',
  GROUPS: 'groups',
  INGREDIENT_LOSSES: 'ingredientLosses',
  INVENTORY_SHRINKAGE: 'inventoryShrinkage',
  MARGIN: 'margin',
  MARGIN_RATIO: 'marginRatio',
  MIX_RATIO: 'mixRatio',
  PRODUCT_LOSSES: 'productLosses',
  PRODUCT_NAME: 'productName',
  SOLD_QUANTITY: 'soldQuantity',
  START_DATE: 'startDate',
  STORE_NAME: 'storeName',
  SUB_CATEGORY: 'subCategory',
  TURNOVER: 'turnover',
};

export const getByStoreSheetColumns = (storeName, groupMappings) => {
  const translatedStoreName = getClientStoreNameTranslation(storeName, true);

  const columns = [
    {
      name: translatedStoreName,
      propertyKey: PROP_KEYS.STORE_NAME,
    },
    {
      name: i18next.t('GENERAL.PRODUCT'),
      propertyKey: PROP_KEYS.PRODUCT_NAME,
    },
    {
      name: i18next.t('GENERAL.CATEGORY'),
      propertyKey: PROP_KEYS.CATEGORY,
    },
    {
      name: i18next.t('GENERAL.SUB_CATEGORY'),
      propertyKey: PROP_KEYS.SUB_CATEGORY,
    },
    {
      name: i18next.t('GENERAL.SOLD_QUANTITY'),
      propertyKey: PROP_KEYS.SOLD_QUANTITY,
    },
    {
      name: i18next.t('GENERAL.AVERAGE_PRICE_EX_TAX'),
      propertyKey: PROP_KEYS.AVERAGE_PRICE,
      type: 'currency',
    },
    {
      name: i18next.t('ANALYSIS.SALES.COLUMN_NAME_TURNOVER_TOTAL_EX_TAX'),
      propertyKey: PROP_KEYS.TURNOVER,
      type: 'currency',
    },
    {
      name: `%${i18next.t('GENERAL.CATEGORY')}`,
      propertyKey: PROP_KEYS.CATEGORY_RATIO,
      type: 'percentage',
    },
    {
      name: i18next.t('ANALYSIS.SALES.COLUMN_NAME_PERCENT_PRODUCT_MIX'),
      propertyKey: PROP_KEYS.MIX_RATIO,
      type: 'percentage',
    },
    {
      name: i18next.t('ANALYSIS.SALES.COLUMN_NAME_FOOD_COST_PER_UNIT_EX_TAXES'),
      propertyKey: PROP_KEYS.FOOD_COST_PER_UNIT,
      type: 'currency',
    },
    {
      name: i18next.t('ANALYSIS.SALES.COLUMN_NAME_FOOD_COST_TOTAL_EX_TAXES'),
      propertyKey: PROP_KEYS.FOOD_COST_TOTAL,
      type: 'currency',
    },
    {
      name: i18next.t('ANALYSIS.SALES.COLUMN_NAME_THEORETICAL_MARGIN_EX_TAXES'),
      propertyKey: PROP_KEYS.MARGIN,
      type: 'currency',
    },
    {
      name: i18next.t('ANALYSIS.SALES.COLUMN_NAME_PERCENT_MARGIN'),
      propertyKey: PROP_KEYS.MARGIN_RATIO,
      type: 'percentage',
    },
    {
      name: i18next.t('ANALYSIS.SALES.COLUMN_NAME_PRODUCT_LOSSES_EX_TAX'),
      propertyKey: PROP_KEYS.PRODUCT_LOSSES,
      type: 'currency',
    },
    {
      name: i18next.t('ANALYSIS.SALES.COLUMN_NAME_INGREDIENTS_LOSSES_EX_TAX'),
      propertyKey: PROP_KEYS.INGREDIENT_LOSSES,
      type: 'currency',
    },
    {
      name: i18next.t('ANALYSIS.SALES.COLUMN_NAME_INVENTORY_SHRINKAGE_EX_TAXES'),
      propertyKey: PROP_KEYS.INVENTORY_SHRINKAGE,
      type: 'currency',
    },
    {
      name: i18next.t('ANALYSIS.SALES.COLUMN_NAME_ADJUSTED_THEORETICAL_MARGIN_EX_TAXES'),
      propertyKey: PROP_KEYS.ADJUSTED_THEORETICAL_MARGIN,
      type: 'currency',
    },
  ];

  if (groupMappings.length) {
    columns.splice(1, 0, {
      name: i18next.t('GENERAL.GROUPS'),
      propertyKey: PROP_KEYS.GROUPS,
    });
  }

  return columns;
};

export const getContextSheetColumns = (storeName) => {
  const translatedStoreName = getClientStoreNameTranslation(storeName, true);

  const columns = [
    {
      name: translatedStoreName,
      propertyKey: PROP_KEYS.STORE_NAME,
    },
    {
      name: i18next.t('GENERAL.START_DATE'),
      propertyKey: PROP_KEYS.START_DATE,
    },
    {
      name: i18next.t('GENERAL.END_DATE'),
      propertyKey: PROP_KEYS.END_DATE,
    },
    {
      name: i18next.t('GENERAL.TURNOVER_EX_TAX'),
      propertyKey: PROP_KEYS.TURNOVER,
      type: 'currency',
    },
    {
      name: i18next.t('GENERAL.METRIC_MARGIN_EX_TAX_WITHOUT_CURRENCY'),
      propertyKey: PROP_KEYS.MARGIN,
      type: 'currency',
    },
    {
      name: i18next.t('GENERAL.METRIC_MARGIN_EX_TAX_RATE'),
      propertyKey: PROP_KEYS.MARGIN_RATIO,
      type: 'percentage',
    },
  ];

  return columns;
};

export const getDetailedCalculationSheetColumns = () => {
  const columns = [
    {
      name: i18next.t('GENERAL.SOLD_QUANTITY'),
      propertyKey: PROP_KEYS.SOLD_QUANTITY,
    },
    {
      name: i18next.t('GENERAL.AVERAGE_PRICE_EX_TAX'),
      propertyKey: PROP_KEYS.AVERAGE_PRICE,
    },
    {
      name: i18next.t('ANALYSIS.SALES.COLUMN_NAME_TURNOVER_TOTAL_EX_TAX'),
      propertyKey: PROP_KEYS.TURNOVER,
    },
    {
      name: `%${i18next.t('GENERAL.CATEGORY')}`,
      propertyKey: PROP_KEYS.CATEGORY_RATIO,
    },
    {
      name: i18next.t('ANALYSIS.SALES.COLUMN_NAME_PERCENT_PRODUCT_MIX'),
      propertyKey: PROP_KEYS.MIX_RATIO,
    },
    {
      name: i18next.t('ANALYSIS.SALES.COLUMN_NAME_FOOD_COST_PER_UNIT_EX_TAXES'),
      propertyKey: PROP_KEYS.FOOD_COST_PER_UNIT,
    },
    {
      name: i18next.t('ANALYSIS.SALES.COLUMN_NAME_FOOD_COST_TOTAL_EX_TAXES'),
      propertyKey: PROP_KEYS.FOOD_COST_TOTAL,
    },
    {
      name: i18next.t('ANALYSIS.SALES.COLUMN_NAME_THEORETICAL_MARGIN_EX_TAXES'),
      propertyKey: PROP_KEYS.MARGIN,
    },
    {
      name: i18next.t('ANALYSIS.SALES.COLUMN_NAME_PERCENT_MARGIN'),
      propertyKey: PROP_KEYS.MARGIN_RATIO,
    },
    {
      name: i18next.t('ANALYSIS.SALES.COLUMN_NAME_PRODUCT_LOSSES_EX_TAX'),
      propertyKey: PROP_KEYS.PRODUCT_LOSSES,
    },
    {
      name: i18next.t('ANALYSIS.SALES.COLUMN_NAME_INGREDIENTS_LOSSES_EX_TAX'),
      propertyKey: PROP_KEYS.INGREDIENT_LOSSES,
    },
    {
      name: i18next.t('ANALYSIS.SALES.COLUMN_NAME_INVENTORY_SHRINKAGE_EX_TAXES'),
      propertyKey: PROP_KEYS.INVENTORY_SHRINKAGE,
    },
    {
      name: i18next.t('ANALYSIS.SALES.COLUMN_NAME_ADJUSTED_THEORETICAL_MARGIN_EX_TAXES'),
      propertyKey: PROP_KEYS.ADJUSTED_THEORETICAL_MARGIN,
    },
  ];

  return columns;
};

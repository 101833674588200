import styled from 'styled-components';

/**
 * The following components shall be re-used to maintain a consistent layout across different pages
 * They should focus on layout and spacing, and not on styling.
 * When necessary, a new component can be added.
 */

// Base full screen container (i.e: includes the NavigationBar)
export const GenericPageContainer = styled.div`
  width: 100%;
  height: 100%;
`;

// Base content container (What's below the NavigationBar)
export const GenericContentContainer = styled.div`
  width: 100%;
  height: 100%;

  padding: 16px 24px;
  overflow: auto;
`;

// Often used for setting horizontal gap between elements
export const GenericGapContainer = styled.div`
  display: flex;
  column-gap: 24px;
`;

import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import modalStyling from './utils';

import { Button, Tooltip } from '../../utils/styledLibraryComponents';

import { DeepsightComponentLoader } from '../../DeepsightComponents';

import {
  CloseModalIcon,
  ComponentContainer,
  Container,
  Footer,
  ItemCountContainer,
  ModalBackground,
  ModalContainer,
  TitleContainer,
  TitleRightPartContainer,
  TitleTextAndIconContainer,
} from './styledComponents';

const GenericModal = (props) => {
  const history = useHistory();
  const { closeGenericModal, component: Component, params } = props;

  const {
    width,
    isLoading,
    minHeight,
    maxHeight,
    footerSeparator,
    closeConfig,
    handleCloseCleanUp,
    isFullscreen,
    step,
    customPadding,
    componentOverflow = 'auto',
    applyOverflow = true,
    disableCloseOutside = false,
  } = params;

  const [modal, setModal] = useState({
    icon: '',
    type: '',
    title: '',
    tooltipText: '',
    itemsCount: '',
    actions: null,
    styling: modalStyling.default,
  });

  useEffect(() => {
    resetModal();
  }, [params]);

  useEffect(
    () =>
      history.listen(() => {
        handleCloseModal();
        closeGenericModal();
      }),
    [history],
  );

  const resetModal = () => {
    setModal({
      type: params.type,
      icon: params.icon,
      title: params.title,
      tooltipText: params.tooltipText,
      height: params.height,
      itemsCount: params.itemsCount,
      actions: params.actions,
      isConfirmationModal: false,
      styling: modalStyling[params.type],
    });
  };

  const handleCloseModal = () => {
    if (modal.isConfirmationModal) {
      resetModal();

      return;
    }

    if (!modal.isConfirmationModal && closeConfig) {
      setModal({
        type: 'warning',
        isConfirmationModal: true,
        icon: closeConfig.icon,
        title: closeConfig.title,
        styling: modalStyling['warning'],
        actions: [
          {
            key: 0,
            color: 'inpulse-outline',
            label: i18next.t('GENERAL.NO'),
            icon: '/images/inpulse/close-black-small.svg',
          },
          {
            key: 1,
            color: 'inpulse-default',
            label: i18next.t('GENERAL.YES'),
            icon: '/images/inpulse/check-white-small.svg',
            handleClick: () => (!!handleCloseCleanUp ? handleCloseCleanUp() : () => {}),
          },
        ],
      });

      return;
    }

    if (handleCloseCleanUp) {
      handleCloseCleanUp();
    }

    closeGenericModal();
  };

  const {
    icon,
    type,
    height,
    title,
    tooltipText,
    itemsCount,
    actions,
    styling,
    isConfirmationModal,
  } = modal;

  return (
    <>
      {!!type && (
        <Container>
          <ModalBackground
            onClick={() => {
              if (disableCloseOutside) {
                return;
              }

              handleCloseModal();
            }}
          />
          <ModalContainer
            height={isConfirmationModal ? 'auto' : height || ''}
            isFullscreen={isFullscreen}
            maxHeight={maxHeight}
            minHeight={isConfirmationModal ? '144px' : minHeight}
            styling={styling}
            width={width}
          >
            <TitleContainer styling={styling}>
              <TitleTextAndIconContainer>
                {icon && <img src={icon} />}
                <span>{title}</span>
                {!!tooltipText && <Tooltip text={tooltipText} displayBigger />}
                {(itemsCount || itemsCount != null) && (
                  <ItemCountContainer>{itemsCount}</ItemCountContainer>
                )}
              </TitleTextAndIconContainer>
              <TitleRightPartContainer>
                {step && (
                  <span>
                    {i18next.t('GENERAL.GENERIC_MODAL_STEP', {
                      current: step.current,
                      total: step.total,
                    })}
                  </span>
                )}
                <CloseModalIcon src={styling.closeIcon} onClick={handleCloseModal} />
              </TitleRightPartContainer>
            </TitleContainer>
            <ComponentContainer applyOverflow={applyOverflow} isLoading={isLoading}>
              {isLoading ? (
                <DeepsightComponentLoader />
              ) : (
                <div
                  style={{
                    width: '100%',
                    padding: customPadding ? customPadding : '24px',
                    overflow: applyOverflow && componentOverflow,
                    height: isFullscreen ? '100%' : '',
                  }}
                >
                  {isConfirmationModal ? closeConfig.content : <Component {...params.data} />}
                </div>
              )}
            </ComponentContainer>
            {actions && (
              <Footer
                background={styling?.footerBackgroundColor}
                footerSeparator={footerSeparator || styling.isFooterSeparator}
              >
                {actions.map((action, index) => (
                  <Button
                    {...action}
                    handleClick={() => {
                      if (action.handleClick) {
                        action.handleClick();

                        if (!action.preventClosing) {
                          closeGenericModal();
                        }

                        return;
                      }

                      handleCloseModal();
                    }}
                    key={`${index}-${action.key}`}
                  />
                ))}
              </Footer>
            )}
          </ModalContainer>
        </Container>
      )}
    </>
  );
};

export default GenericModal;

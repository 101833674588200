import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import i18next from 'i18next';
import React, { useRef, useEffect, useState } from 'react';

import { showConfirmationMessage } from '@actions/messageconfirmation';

import { Button } from '@commons/utils/styledLibraryComponents';

import { Input } from '@lib/inpulse/Input';
import { Textarea } from '@lib/inpulse/Textarea';

import { getClientInfo } from '@selectors/client';

import MultiplePictureUpload from '@orders/OrderList/components/OrderForm/components/OrderFormInfoPanel/components/MultiplePictureUpload';
import services from '@orders/OrderList/components/OrderForm/services';

import {
  ModalBackground,
  ModalContainer,
  ModalHeader,
  ModalHeaderRushOrder,
  ModalContent,
  ModalFooter,
  Section,
  Text,
  Icon,
} from './styledComponents';

const MAX_MB_FILES_UPLOAD = 50;
const MB_VALUE_IN_KB = 1048576;

export const ModalDeliveryReceipt = (props) => {
  const {
    client: { clientId },
    close,
    params,
    readOnly,
    partnerId,
    showMessage,
    picturesUrls,
    setPicturesUrls,
    receptionComment,
    receptionReference,
    setProgressBarStatus,
    handleReceptionComment,
    handleReceptionReference,
    handleDeliveryReceiptAndProgressBarModalsOpening,
    isRushOrder,
  } = props;

  const pictureSelectedRef = useRef();

  const [infoBdl, setInfoBdl] = useState({});

  const [initialPictures, setInitialPictures] = useState([]);

  const [displayDropdownActions, setDisplayDropdownActions] = useState(false);

  const [isSizeTooHeavy, setIsSizeTooHeavy] = useState(false);
  const [selectedPictures, setSelectedPictures] = useState([]);

  const isTablet = window.innerWidth <= 1280;

  const handlePictureUpload = async (selectedPictures) => {
    if (selectedPictures.length === 0) {
      return;
    }

    let formattedSelectedPictures = [];
    let fileUri = '';

    for (let index = 0; index < selectedPictures.length; index++) {
      if (selectedPictures[index].data.includes('http')) {
        formattedSelectedPictures.push(selectedPictures[index].data);
      } else {
        fileUri = selectedPictures[index].data;

        let hash = '';

        for (let hashIndex = 0; hashIndex <= 10; hashIndex++) {
          hash += selectedPictures[index].data.charCodeAt(
            Math.floor(Math.random() * selectedPictures[index].data.length - 1),
          );
        }

        const result = await services.uploadFile(
          selectedPictures[index].file,
          {
            partnerId,
            clientId,
            reference:
              get(params, !isRushOrder ? 'order.reference' : 'rushOrder.reference') + '-' + hash,
          },
          showMessage,
        );

        if (!result || result.file || !result.data.fileUri) {
          throw 'Error during upload';
        }

        fileUri = result.data.fileUri;
        formattedSelectedPictures.push(fileUri);
      }
      setProgressBarStatus({
        index: index,
        totalPicturesCount: selectedPictures.length - 1,
      });
    }
    return formattedSelectedPictures;
  };

  const loadPictures = async (selectedPictures) => {
    handleDeliveryReceiptAndProgressBarModalsOpening(true, false);
    try {
      if (selectedPictures.some((picture) => picture.data?.includes('http') !== true)) {
        const formattedSelectedPictures = await handlePictureUpload(selectedPictures);
        setPicturesUrls(formattedSelectedPictures);
        showMessage(i18next.t('ORDERS.ORDERS.FORM_PICTURE_UPLOAD_SUCCESS'), 'success');
        setProgressBarStatus({
          index: 0,
          totalPicturesCount: 0,
        });
        return;
      }
      const selectedPicturesUrls = selectedPictures.map((picture) => picture.data);
      handleDeliveryReceiptAndProgressBarModalsOpening(false, true);
      setPicturesUrls(selectedPicturesUrls);
    } catch (error) {
      showMessage(i18next.t('ORDERS.ORDERS.FORM_PICTURE_UPLOAD_ERROR'), 'error');
      handleDeliveryReceiptAndProgressBarModalsOpening(false, true);
    }
  };

  const onSelectedPictureChange = (loadedPictures, deletedPictureUrl = null) => {
    if (isEmpty(loadedPictures) && !!deletedPictureUrl) {
      // One picture has been deleted
      const filteredPictures = selectedPictures.filter(
        (picture) => picture.data !== deletedPictureUrl,
      );

      setSelectedPictures(filteredPictures);
      return;
    }

    if (!!loadedPictures && loadedPictures.length) {
      setSelectedPictures(loadedPictures);
    }
  };

  const validate = () => {
    handleReceptionComment(infoBdl.comment);

    if (!isRushOrder) {
      handleReceptionReference(infoBdl.reference);
    }

    const totalFilesSize = selectedPictures.reduce((acc, selectedPicture) => {
      if (!selectedPicture.file) {
        return acc;
      }
      return get(selectedPicture, ['file', 'size'], 0);
    }, 0);

    //Converting the size to MB
    if (totalFilesSize / MB_VALUE_IN_KB > MAX_MB_FILES_UPLOAD) {
      showMessage(i18next.t('ORDERS.ORDERS.FORM_PICTURE_UPLOAD_TOO_HEAVY_FILES'), 'error');
      return;
    }

    if (selectedPictures.length > 0) {
      loadPictures(selectedPictures);
    } else {
      setPicturesUrls([]);
    }

    close();
  };

  const closeModal = () => {
    setInfoBdl({
      comment: receptionComment,
      reference: receptionReference,
    });

    close();
  };

  useEffect(() => {
    setInfoBdl({
      comment: receptionComment,
      reference: receptionReference,
    });
  }, [receptionComment, receptionReference]);

  useEffect(() => {
    if (picturesUrls.length) {
      setInitialPictures(picturesUrls);

      const picturesData = picturesUrls.reduce((acc, pictureUrl) => {
        // If pictureUrl.data is not null, it means that the picture has base64 data (not uploaded yet)
        const loadedFileData = get(pictureUrl, 'data', null);

        if (!loadedFileData) {
          acc.push({ data: pictureUrl, file: null });
        }

        return acc;
      }, []);

      setSelectedPictures(picturesData);
    }
  }, [picturesUrls]);

  return (
    <ModalBackground isTablet={isTablet}>
      <ModalContainer
        isTablet={isTablet}
        onClick={() => {
          (displayDropdownActions || displayDropdownActions === 0) && setDisplayDropdownActions(-1);
        }}
      >
        <ModalHeader>
          {!isRushOrder ? (
            <>
              <Text title="true">{i18next.t('ORDERS.ORDERS.FORM_DELIVERY_RECEIPT_INFOS')}</Text>
              <Icon
                alt="close"
                height="14px"
                src="/images/inpulse/close-black-small.svg"
                width="14px"
                interactive
                onClick={() => closeModal()}
              />
            </>
          ) : (
            <>
              <ModalHeaderRushOrder>
                <Icon
                  alt="close"
                  height="16px"
                  src="/images/inpulse/receipt-attachment.svg"
                  width="16px"
                  isRushOrder
                  onClick={() => closeModal()}
                />
                <Text title="true">{i18next.t('ORDERS.RUSH_ORDERS.MODAL_RECEIPT_TITLE')}</Text>
              </ModalHeaderRushOrder>
              <Icon
                alt="close"
                height="14px"
                src="/images/inpulse/close-black-small.svg"
                width="14px"
                interactive
                onClick={() => closeModal()}
              />
            </>
          )}
        </ModalHeader>
        <ModalContent isTablet={isTablet} onScroll={() => setDisplayDropdownActions(false)}>
          {!isRushOrder && (
            <Section>
              <Input
                key="receptionReference"
                label={i18next.t('ORDERS.ORDERS.LIST_LABEL_DELIVERY_FORM_RECEPTION_REFERENCE')}
                placeholder={i18next.t('ORDERS.ORDERS.LIST_LABEL_DELIVERY_FORM_PLACEHOLDER')}
                readOnly={readOnly}
                type="text"
                value={infoBdl.reference || ''}
                onChange={(event) => setInfoBdl({ ...infoBdl, reference: event.target.value })}
              />
            </Section>
          )}
          <Section>
            <Textarea
              key="receptionComment"
              label={i18next.t('ORDERS.ORDERS.LIST_LABEL_COMMENT')}
              placeholder={
                !isRushOrder
                  ? i18next.t('ORDERS.ORDERS.LIST_LABEL_COMMENT_DELIVERY_PLACEHOLDER')
                  : `${i18next.t('ORDERS.ORDERS.LIST_LABEL_COMMENT')}...`
              }
              readOnly={readOnly}
              rows="4"
              value={infoBdl.comment || ''}
              onChange={(event) => setInfoBdl({ ...infoBdl, comment: event.target.value })}
            />
          </Section>
          <Section>
            <Text disabled={readOnly} style={{ marginBottom: '8px' }}>
              {i18next.t(
                !isRushOrder
                  ? 'ORDERS.ORDERS.FORM_DELIVERY_RECEIPT_PICTURE'
                  : 'ORDERS.RUSH_ORDERS.MODAL_RECEIPT_LABEL_PICTURES',
              )}{' '}
              :
            </Text>
            <MultiplePictureUpload
              displayDropdownActions={displayDropdownActions}
              initialPictures={initialPictures}
              isSizeTooHeavy={isSizeTooHeavy}
              readOnly={readOnly}
              ref={pictureSelectedRef}
              setDisplayDropdownActions={setDisplayDropdownActions}
              setIsSizeTooHeavy={setIsSizeTooHeavy}
              multipleFiles
              onSelectionChange={onSelectedPictureChange}
            />
          </Section>
        </ModalContent>
        <ModalFooter>
          <Button
            buttonCustomStyle={{ marginLeft: 24 }}
            color={'inpulse-outline'}
            handleClick={() => closeModal()}
            icon="/images/inpulse/close-black-small.svg"
            isDisabled={readOnly}
            label={i18next.t('GENERAL.CANCEL')}
          />
          <Button
            buttonCustomStyle={{ marginLeft: 24 }}
            color={'inpulse-default'}
            handleClick={() => validate()}
            icon="/images/inpulse/check-white-small.svg"
            isDisabled={readOnly}
            label={i18next.t('GENERAL.VALIDATE')}
          />
        </ModalFooter>
      </ModalContainer>
    </ModalBackground>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type, icon) => {
    dispatch(showConfirmationMessage(message, type, icon));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalDeliveryReceipt);

import { get, isEmpty } from 'lodash';

import { userHasRoleInpulse } from '@commons/utils/roles';

export const canSeeAllCatalogs = (user, client) =>
  client.hasLocalCatalogs &&
  (userHasRoleInpulse(user) || (!isEmpty(user.catalog) && user.catalog.isMasterCatalog));

export const canUpdateAssociatedSupplier = (user, supplier, client) =>
  !client.hasLocalCatalogs ||
  userHasRoleInpulse(user) ||
  get(user, 'catalog.id') === get(supplier, 'catalogId');

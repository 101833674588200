import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { useForm, useWatch } from 'react-hook-form';
import i18next from 'i18next';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';

// Actions
import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

// Form
import { buildSchema } from '@commons/GenericForm';
import { getInputs } from './utils/form';
import { yupResolver } from '@hookform/resolvers/yup';
import WhiteCardForm from '@commons/WhiteCardForm';

// Components
import { Button } from '@commons/utils/styledLibraryComponents';
import { DeepsightComponentLoader, InpulseLabel } from '@commons/DeepsightComponents';
import EmptyState from '@commons/EmptyState';
import RemainingControls from '@commons/RemainingControls';
import Text, { ENUM_FONTS } from '@commons/Text';
import WhiteCard from '@commons/WhiteCard';

// Commons
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import {
  downloadFile,
  downloadFileFromUrl,
  encodeFileNameInUrl,
  getFileNameFromUrl,
  truncateFileNameInTheMiddle,
} from '@commons/utils/fileUtils';
import { getTheme } from '@commons/utils/theme';
import {
  INVOICE_STATUS,
  INVOICE_STATUS_LABEL_CONFIG,
} from '@src/routes/invoice/invoiceControls/utils/constants';

// Selectors
import { canControlInvoices } from '@selectors/actions/orderActions';
import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';

// Services
import { upload as uploadService } from '@services/upload';
import invoiceService from '@services/invoice';

// Styles
import {
  ControlInfoContainer,
  CreditInfoContainer,
  DetailsContainer,
  Icon,
  InpulseLabelContainer,
  LoaderContainer,
  ModalBackground,
  ModalContainer,
  ModalContent,
  ModalFooter,
  ModalHeader,
  StatusContainer,
  StatusControlContainer,
  TitleContainer,
  TitleWithIcon,
} from './styledComponents';

export const DeliveryInvoiceModal = (props) => {
  const {
    // Props
    close,
    isReadOnly,
    orderId,
    storeId,
    storeTimezone,
    supplierId,
    // Dispatch methods / props
    authorizedActions,
    client: { clientId },
    currency,
    pageLoaded,
    pageLoading,
    showErrorMessage,
    showSuccessMessage,
  } = props;

  const [invoiceControl, setInvoiceControl] = useState({});
  const intervalRef = useRef(null);

  // Form states
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [inputs, setInputs] = useState(getInputs({ currency }));
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isSaveAlreadyTriggered, setIsSaveAlreadyTriggered] = useState(false);

  const [reloadingCredits, setReloadingCredits] = useState(true);

  const theme = getTheme();

  const uploadInvoice = (file) => {
    setFile(file);
    setFileName(file.name);

    const truncatedFileName = truncateFileNameInTheMiddle(file.name);
    form.setValue('fileName', truncatedFileName);
  };

  const deleteInvoice = () => {
    setFile(null);
    setFileName('');
    setInvoiceControl({
      ...invoiceControl,
      link: null,
    });
    form.setValue('fileName', '');
  };

  /**
   * Generic callback to download either the file being uploaded or the one already associated with the invoice control
   */
  const downloadInvoice = () => {
    if (file) {
      return downloadFileBeingUploaded();
    }

    if (invoiceControl.link) {
      return downloadInvoiceLink();
    }

    showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.FILE_DOWNLOAD_ERROR'));
  };

  const form = useForm({
    defaultValues: {},
    resolver: yupResolver(
      buildSchema(
        getInputs({
          invoiceControl,
          isReadOnly,
          fileBeingUploadedName: fileName,
          currency,
          uploadInvoice,
          deleteInvoice,
          downloadInvoice,
        }),
      ),
    ),
  });

  const formFields = useWatch({
    control: form.control,
  });

  const { color: invoiceStatusColor, label: invoiceStatusText } = INVOICE_STATUS_LABEL_CONFIG[
    invoiceControl.status
  ] || {
    color: 'grey',
    label: i18next.t('ORDERS.ORDERS.FORM_INVOICE_MODAL_CONTROL_PART_STATUS_UNCONTROLLED'),
  };

  // Fetch invoice info
  useEffect(() => {
    if (!orderId) {
      return;
    }

    getICLByOrderId(orderId);
  }, [orderId]);

  useEffect(() => {
    // Update file input actions
    setInputs(
      getInputs({
        invoiceControl,
        isReadOnly,
        fileBeingUploadedName: fileName,
        currency,
        uploadInvoice,
        deleteInvoice,
        downloadInvoice,
      }),
    );
  }, [invoiceControl.status, fileName]);

  useEffect(() => {
    if (invoiceControl.status === INVOICE_STATUS.PENDING && !intervalRef.current) {
      intervalRef.current = setInterval(checkInvoiceControlStatus, 30000);
    }

    if (invoiceControl.status !== INVOICE_STATUS.PENDING && intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    return () => clearInterval(intervalRef.current);
  }, [invoiceControl.status]);

  // Check whether form is dirty
  useEffect(() => {
    const isFormDirty = get(form, 'formState.isDirty', false);

    const dirtyFields = Object.keys(get(form, 'formState.dirtyFields', {}));

    const hasAtLeastOneDifferentField = dirtyFields.some(
      // Not being sensitive to type change (case on total property)
      (field) => invoiceControl[field] != formFields[field],
    );

    // Dedicated check on file since it is not detected as dirty
    const hasUploadedFile = !!file;

    if (hasUploadedFile || (isFormDirty && hasAtLeastOneDifferentField)) {
      setIsSaveDisabled(false);

      return;
    }

    setIsSaveDisabled(true);
  }, [formFields]);

  /**
   * Download the file being uploaded
   * @return {Promise<void>}
   */
  const downloadFileBeingUploaded = async () => {
    try {
      await downloadFile(file, fileName);
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.FILE_DOWNLOAD_ERROR'));
    }
  };

  /**
   * Download the invoice file stored in the CDN and associated with the invoice control
   */
  const downloadInvoiceLink = async () => {
    try {
      const fileName = getFileNameFromUrl(invoiceControl.link);

      await downloadFileFromUrl(invoiceControl.link, fileName);
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.FILE_DOWNLOAD_ERROR'));
    }
  };

  const getICLByOrderId = async (orderId) => {
    pageLoading();

    try {
      const { invoiceControlList: fetchedInvoiceControlList } =
        await invoiceService.getInvoiceControlListByOrderId(orderId);

      const invoiceControlList = fetchedInvoiceControlList || {};

      setInvoiceControl(invoiceControlList);

      setInputs(
        getInputs({
          invoiceControl,
          currency,
          isReadOnly,
          fileBeingUploadedName: fileName,
          uploadInvoice,
          downloadInvoice,
          deleteInvoice,
        }),
      );

      if (isEmpty(invoiceControlList)) {
        return;
      }

      const invoiceFileName = getFileNameFromUrl(invoiceControlList.link, true);
      setFileName(invoiceFileName);

      form.setValue('fileName', truncateFileNameInTheMiddle(invoiceFileName));
      form.setValue('total', invoiceControlList.total);
      form.setValue('number', invoiceControlList.number);
      form.setValue('date', invoiceControlList.date ? moment(invoiceControlList.date) : null);
    } catch {
      setInvoiceControl({});

      showErrorMessage(i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.FETCH_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const checkInvoiceControlStatus = async () => {
    try {
      const { invoiceControlList } = await invoiceService.getInvoiceControlListByOrderId(orderId);

      // If ICL has been deleted in the meantime, we stop the interval by resetting the state
      setInvoiceControl(invoiceControlList || {});

      // Replace values if it has not been set
      if (!formFields.date) {
        form.setValue('date', invoiceControlList.date ? moment(invoiceControlList.date) : null);
      }

      if (!formFields.total) {
        form.setValue('total', invoiceControlList.total);
      }

      if (!formFields.number) {
        form.setValue('number', invoiceControlList.number);
      }
    } catch {
      showErrorMessage(i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.FETCH_ERROR'));
    }
  };

  const validateForm = async () => {
    const fieldsValidation = await form.trigger();

    setIsSaveAlreadyTriggered(true);

    if (!fieldsValidation) {
      setIsSaveDisabled(true);

      return;
    }

    pageLoading();
    // No try & catch since save method has its own error handling
    await save();
    pageLoaded();
  };

  const save = async (isCheckingInvoice = false) => {
    let link = null;

    if (!!file) {
      const params = {
        clientId,
        keepOriginalFileName: true,
        type: 'invoice-control',
      };

      try {
        const { data } = await uploadService.uploadFile(file, params);

        link = get(data, 'fileUri', null);
      } catch {
        showErrorMessage(i18next.t('ORDERS.ORDERS.FORM_INVOICE_MODAL_IMPORT_FAILURE'));
      }
    }

    const fieldsToUpdate = {
      id: invoiceControl.id || null,
      date: formFields.date?.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      total: formFields.total,
      number: formFields.number,
      link: encodeFileNameInUrl(link) || invoiceControl.link,
    };

    try {
      const savedInvoiceControlList = await invoiceService.importInvoiceControlListFromOrder(
        clientId,
        supplierId,
        storeId,
        orderId,
        fieldsToUpdate,
      );

      if (isCheckingInvoice) {
        return savedInvoiceControlList;
      }

      showSuccessMessage(i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.UPDATE_SUCCESS'));
      closeModal();
    } catch (err) {
      if (isCheckingInvoice) {
        throw err;
      }

      showErrorMessage(i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.UPDATE_ERROR'));
    }
  };

  const startInvoiceCheck = async () => {
    try {
      let invoiceControlListId = invoiceControl.id;

      // This starts the control process, checking every 30s
      setInvoiceControl({
        ...invoiceControl,
        status: INVOICE_STATUS.PENDING,
      });

      // If save is enabled, it means the ICL has changed or never been created
      if (!isSaveDisabled) {
        setIsSaveDisabled(true);
        const savedInvoiceControlList = await save(true);
        invoiceControlListId = savedInvoiceControlList.id;
      }

      // Starts Data Job
      await invoiceService.checkInvoiceControlList(invoiceControlListId);

      // Update store available credits
      setReloadingCredits(true);
    } catch {
      showErrorMessage(i18next.t('ORDERS.ORDERS.FORM_INVOICE_MODAL_CONTROL_FAILURE'));

      // Something went wrong during the control, reset status
      setInvoiceControl({
        ...invoiceControl,
        status: INVOICE_STATUS.FAILURE,
      });
    }
  };

  const closeModal = () => {
    close();
  };

  return (
    <ModalBackground>
      <ModalContainer>
        <ModalHeader>
          <TitleWithIcon>
            <Icon alt="invoice" src="/images/invoice.svg" />
            <Text font={ENUM_FONTS.H1_INTER}>{i18next.t('ORDERS.ORDERS.FORM_INVOICE_INFOS')}</Text>
          </TitleWithIcon>

          <Icon
            alt="close"
            src="/images/inpulse/close-black-small.svg"
            interactive
            onClick={closeModal}
          />
        </ModalHeader>
        <ModalContent>
          <WhiteCardForm
            form={form}
            inputs={inputs}
            isEditionAllowed={!isReadOnly}
            shouldDisplayError={isSaveAlreadyTriggered}
            title={i18next.t('GENERAL.INFORMATIONS')}
            tooltipText={isReadOnly ? i18next.t('ORDERS.ORDERS.FORM_INVOICE_FORBIDDEN') : ''}
          />
          <WhiteCard
            maxWidth="816px"
            renderContent={
              <>
                {!fileName && (
                  <EmptyState
                    backgroundColor={theme.colors.white}
                    label={i18next.t(
                      'ORDERS.ORDERS.FORM_INVOICE_MODAL_CONTROL_PART_EMPTY_STATE_LABEL',
                    )}
                    labelColor={theme.colors.blacks.ipBlack4}
                  ></EmptyState>
                )}
                {!!fileName && (
                  <ControlInfoContainer>
                    <StatusControlContainer>
                      <StatusContainer>
                        <Text>
                          {i18next.t(
                            'ORDERS.ORDERS.FORM_INVOICE_MODAL_CONTROL_PART_INVOICE_STATUS',
                          )}
                        </Text>
                        <InpulseLabelContainer>
                          <InpulseLabel
                            color={invoiceStatusColor}
                            text={invoiceStatusText}
                            type={'tertiary'}
                          />
                          {invoiceControl.status === INVOICE_STATUS.PENDING && (
                            <LoaderContainer>
                              <DeepsightComponentLoader height={16} width={16} />
                            </LoaderContainer>
                          )}
                        </InpulseLabelContainer>
                      </StatusContainer>
                      <CreditInfoContainer>
                        <RemainingControls
                          customLabelStyle={{
                            font: theme.fonts.textBigHeight16,
                            color: theme.colors.greys.darkest,
                          }}
                          reloadingCredits={reloadingCredits}
                          setReloadingCredits={setReloadingCredits}
                          storeId={storeId}
                          timezone={storeTimezone}
                        />
                      </CreditInfoContainer>
                      <Button
                        buttonCustomStyle={{
                          height: '100%',
                          flex: '1',
                          justifyContent: 'flex-start',
                        }}
                        color={'inpulse-outline'}
                        handleClick={startInvoiceCheck}
                        icon={'/images/inpulse/bolt-black-small.svg'}
                        isDisabled={
                          !canControlInvoices(authorizedActions) ||
                          (invoiceControl.status &&
                            invoiceControl.status !== INVOICE_STATUS.UNCONTROLLED)
                        }
                        label={i18next.t(
                          'ORDERS.ORDERS.FORM_INVOICE_MODAL_CONTROL_PART_CONTROL_ACTION',
                        )}
                        noBorder
                      />
                    </StatusControlContainer>
                    {invoiceControl.status === INVOICE_STATUS.SUCCESS && (
                      <DetailsContainer>
                        <Button
                          buttonCustomStyle={{ padding: '24px 0 12px 0' }}
                          color={'inpulse-outline'}
                          handleClick={() => {
                            window.open(
                              `/invoice/invoice-controls/${invoiceControl.id}/details`,
                              '_blank',
                            );
                          }}
                          icon={'/images/inpulse/icon-open-in-new-black.svg'}
                          label={i18next.t('ORDERS.ORDERS.FORM_INVOICE_MODAL_GO_TO_DETAILS')}
                          noBorder
                        />
                      </DetailsContainer>
                    )}
                  </ControlInfoContainer>
                )}
              </>
            }
            title={
              <TitleContainer>
                <Text font={ENUM_FONTS.H2_INTER}>
                  {i18next.t('ORDERS.ORDERS.FORM_INVOICE_MODAL_CONTROL_PART_EMPTY_STATE_TITLE')}
                </Text>

                {/* TODO: Uncomment this code when the product provides the invoice add-on information link. Replace 'https://google.fr' with the good link.*/}
                {/* <LinkContainer onClick={() => window.open('https://google.fr', '_blank')}>
                  <InpulseLabel
                    color="ipGreen1"
                    style={{ cursor: 'pointer' }}
                    text={i18next.t('ORDERS.ORDERS.FORM_INVOICE_MODAL_CONTROL_PART_LEARN_MORE')}
                    type={'secondary'}
                  />
                </LinkContainer> */}
              </TitleContainer>
            }
          />
        </ModalContent>
        <ModalFooter>
          <Button
            buttonCustomStyle={{ marginLeft: 24 }}
            color={'inpulse-outline'}
            handleClick={closeModal}
            icon="/images/inpulse/close-black-small.svg"
            label={i18next.t('GENERAL.CANCEL')}
          />
          <Button
            buttonCustomStyle={{ marginLeft: 24 }}
            color={'inpulse-default'}
            handleClick={validateForm}
            icon="/images/inpulse/save-white-small.svg"
            isDisabled={isSaveDisabled}
            label={i18next.t('GENERAL.SAVE')}
          />
        </ModalFooter>
      </ModalContainer>
    </ModalBackground>
  );
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
  client: getClientInfo(state.baseReducer.user),
  authorizedActions: getAuthorizedActions(state.baseReducer.userRights, '/order/orders'),
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryInvoiceModal);

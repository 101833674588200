import i18next from 'i18next';

export const TURNOVER_FORECAST = {
  getText: () => i18next.t('FEATURE.FORECASTS.FORECASTS_TURNOVER'),
  path: '/forecast/forecast/turnover',
  getBreadCrumbText: () => i18next.t('FEATURE.FORECASTS.FORECASTS_TURNOVER_BREADCRUMB'),
};

const PRODUCT_MIX = {
  getText: () => i18next.t('FEATURE.FORECASTS.PRODUCT_MIX'),
  path: '/forecast/forecast/product-mix',
};

const POS_PRODUCTION_PLANNING = {
  getText: () => i18next.t('FEATURE.FORECASTS.POS_PRODUCTION_PLANNING'),
  path: '/forecast/forecast/production-planning',
};

const FORECAST_SUBMODULE = {
  getText: () => i18next.t('FEATURE.FORECASTS.FORECASTS'),
  icon: '/images/forecasts-bis.svg',
  children: [TURNOVER_FORECAST, PRODUCT_MIX, POS_PRODUCTION_PLANNING],
};

export const FORECAST = {
  getText: () => i18next.t('FEATURE.FORECASTS.FORECASTS'),
  path: '/forecast',
  icon: '/images/forecasts-bis.svg',
  children: [FORECAST_SUBMODULE],
};

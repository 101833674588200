import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100% - 40px);
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;

  justify-content: center;
`;

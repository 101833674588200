import i18next from 'i18next';
import React from 'react';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import AnalyticsList from '@commons/AnalyticsList';
import DisplayNumber, { formatNumber, formatNumberWithCurrency } from '@commons/DisplayNumber';
import TooltipButton from '@commons/TooltipButton';

import { getEntityUnit } from '@stocks/utils';

import { TextWithTooltipButtonContainer } from '../styledComponents';

import { METRIC_KEY } from './constants';

const formatValueString = (value, metricKey, currency = {}, unit = '') => {
  if (value == null) {
    return '--';
  }

  if (metricKey === METRIC_KEY.TURNOVER) {
    return `${formatNumberWithCurrency({
      displayCurrencyCode: true,
      withoutDecimals: true,
      shortenDigits: true,
      number: value,
      currency,
    })}`;
  }

  return `${formatNumber(value, 2)} ${getEntityUnit(unit)}`;
};

const getFormattedCurrency = (metricKey, unit) =>
  metricKey === METRIC_KEY.TURNOVER ? null : { alphabeticCode: getEntityUnit(unit) };

export const formatDataForTooltip = (data, metricKey, currency, startDate, endDate) => {
  const unit = data.unit || '';

  return [
    {
      categoryName: i18next.t('ORDERS.BY_CATEGORY.LABEL_ORDERED'),
      categoryValue: formatValueString(data.foodCost.orderTotal, metricKey, currency, unit),
      children: [
        {
          name: i18next.t('GENERAL.SUPPLIER_PLURAL'),
          value: formatValueString(data.foodCost.order, metricKey, currency, unit),
        },
        {
          name: i18next.t('ORDERS.RUSH_ORDERS.RUSH_ORDER'),
          value: formatValueString(data.foodCost.rushOrder, metricKey, currency, unit),
        },
      ],
    },
    {
      categoryName: i18next.t('STOCKS.STOCKS.TRANSFER_GRAPH_TOOLTIP'),
      categoryValue: formatValueString(data.foodCost.transferTotal, metricKey, currency, unit),
      children: [
        {
          name: i18next.t('STOCKS.CURRENT_STOCKS.TOOLTIP_TRANSFER_IN'),
          value: formatValueString(data.foodCost.transferIn, metricKey, currency, unit),
        },
        {
          name: i18next.t('STOCKS.CURRENT_STOCKS.TOOLTIP_TRANSFER_OUT'),
          value: formatValueString(data.foodCost.transferOut, metricKey, currency, unit),
        },
      ],
    },
    {
      categoryName: i18next.t('ORDERS.BY_CATEGORY.LABEL_VARIATION_STOCK'),
      categoryValue: formatValueString(data.foodCost.stockVariation, metricKey, currency, unit),
      children: [
        {
          name: startDate.format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
          value: formatValueString(data.foodCost.initialStock, metricKey, currency, unit),
        },
        {
          name: endDate.format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
          value: formatValueString(data.foodCost.finalStock, metricKey, currency, unit),
        },
      ],
    },
    {
      categoryName: i18next.t('GENERAL.TOTAL'),
      categoryValue: formatValueString(data.foodCost.realConsumption, metricKey, currency, unit),
    },
  ];
};

export const getTableViewColumns = (currency = {}, startDate, endDate, metricKey) => {
  const headers = [
    {
      id: 0,
      name: i18next.t('GENERAL.NAME'),
      propertyKey: 'name',
      bold: true,
      textDecorationChild: 'underline',
      isSortable: true,
      large: true,
    },
    {
      id: 1,
      name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_PERCENT_ACTUAL_FC'),
      propertyKey: 'realOverTurnover',
      tooltipDisplay: i18next.t('ANALYSIS.FOOD_COST.COLUMN_TOOLTIP_PERCENT_ACTUAL_FC'),
      isSortable: true,
      isNumber: true,
      hideRow: metricKey !== METRIC_KEY.TURNOVER,
      filterType: 'numeric',
      unit: '%',
      render: (item) => {
        if (item == null) {
          return <>-</>;
        }

        return (
          <>
            <DisplayNumber number={item} /> %
          </>
        );
      },
    },
    {
      id: 2,
      name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_ACTUAL_FC'),
      propertyKey: 'foodCost.realConsumption',
      tooltipDisplay: i18next.t('ANALYSIS.FOOD_COST.COLUMN_TOOLTIP_BY_CATEGORY_ACTUAL_FC'),
      isSortable: true,
      isNumber: true,
      hideRow: metricKey !== METRIC_KEY.TURNOVER,
      filterType: 'numeric',
      render: (item, row) => {
        const dataForTooltip = formatDataForTooltip(row, metricKey, currency, startDate, endDate);

        const formattedCurrency = getFormattedCurrency(metricKey, row.unit);

        return (
          <TextWithTooltipButtonContainer>
            <DisplayNumber
              currency={formattedCurrency}
              number={item}
              displayCurrencyCode
              withoutDecimals
            />
            <TooltipButton
              tooltipContent={<AnalyticsList data={dataForTooltip} title={row.name} />}
            />
          </TextWithTooltipButtonContainer>
        );
      },
    },
    {
      id: 3,
      name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_THEORETICAL_FC'),
      propertyKey: 'theoreticalConsumption',
      tooltipDisplay: i18next.t('ANALYSIS.FOOD_COST.COLUMN_TOOLTIP_THEORETICAL_FC'),
      isSortable: true,
      isNumber: true,
      hideRow: metricKey !== METRIC_KEY.TURNOVER,
      filterType: 'numeric',
      render: (item, row) => {
        const formattedCurrency = getFormattedCurrency(metricKey, row.unit);

        return (
          <DisplayNumber
            currency={formattedCurrency}
            number={item}
            displayCurrencyCode
            withoutDecimals
          />
        );
      },
    },
    {
      id: 4,
      name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_FC_DEVIATION'),
      propertyKey: 'foodCost.gapFromTheoretical',
      tooltipDisplay: i18next.t('ANALYSIS.FOOD_COST.COLUMN_TOOLTIP_FC_DEVIATION'),
      isSortable: true,
      isNumber: true,
      hideRow: metricKey !== METRIC_KEY.TURNOVER,
      filterType: 'numeric',
      render: (item, row) => {
        const formattedCurrency = getFormattedCurrency(metricKey, row.unit);

        return (
          <DisplayNumber
            currency={formattedCurrency}
            number={item}
            displayCurrencyCode
            withoutDecimals
          />
        );
      },
    },
    {
      id: 5,
      name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_PERCENT_FC_DEVIATION'),
      propertyKey: 'relativeRealFromTheorical',
      tooltipDisplay: i18next.t('ANALYSIS.FOOD_COST.COLUMN_TOOLTIP_PERCENT_FC_DEVIATION'),
      isSortable: true,
      isNumber: true,
      hideRow: metricKey !== METRIC_KEY.TURNOVER,
      filterType: 'numeric',
      render: (item) => {
        if (item == null) {
          return <>-</>;
        }

        return (
          <>
            <DisplayNumber number={item} /> %
          </>
        );
      },
    },
    {
      id: 6,
      name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_WASTES'),
      propertyKey: 'loss',
      tooltipDisplay: i18next.t('ANALYSIS.FOOD_COST.COLUMN_TOOLTIP_WASTES'),
      isSortable: true,
      isNumber: true,
      hideRow: metricKey !== METRIC_KEY.TURNOVER,
      filterType: 'numeric',
      render: (item, row) => {
        const formattedCurrency = getFormattedCurrency(metricKey, row.unit);

        return (
          <DisplayNumber
            currency={formattedCurrency}
            number={item}
            displayCurrencyCode
            withoutDecimals
          />
        );
      },
    },
    {
      id: 7,
      name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_INVENTORY_SHRINKAGE'),
      propertyKey: 'inventoryShrinkage',
      tooltipDisplay: i18next.t('ANALYSIS.FOOD_COST.COLUMN_TOOLTIP_INVENTORY_SHRINKAGE'),
      isSortable: true,
      isNumber: true,
      hideRow: metricKey !== METRIC_KEY.TURNOVER,
      filterType: 'numeric',
      render: (item, row) => {
        const formattedCurrency = getFormattedCurrency(metricKey, row.unit);

        return (
          <DisplayNumber
            currency={formattedCurrency}
            number={item}
            displayCurrencyCode
            withoutDecimals
          />
        );
      },
    },

    {
      name: '',
      propertyKey: 'action',
      isClickable: true,
      isSortable: false,
    },
  ];

  return headers;
};

export default {
  getTableViewColumns,
  formatDataForTooltip,
};

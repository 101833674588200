import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openModal, closeModal, openGenericModal, refreshGenericModal } from '@actions/modal';
import {
  showConfirmationMessage,
  showErrorMessage,
  showSuccessMessage,
} from '@actions/messageconfirmation';

import { ENUM_MODULE_NAME, ENUM_REGEX_PATHS_FEATURE } from '@commons/utils/features';
import { Label, ListView } from '@commons/utils/styledLibraryComponents';
import {
  ORDERED_CENTRAL_KITCHEN_NAV_TREE,
  ORDERED_NAV_TREE,
  getNavTreeKeyByPath,
} from '@commons/features';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { authorizedActions } from '@services/backoffice/role';
import { getAllEventSubCategories } from '@services/eventSubCategory';
import clientService from '@services/client';
import onBoardingService from '@services/backoffice/onBoarding';

import theme from '@theme';

import { Container, ListViewContainer } from './styledComponents';
import actionsUtils from './utils/actions';
import EditRoleRights from './components/EditRoleRights';
import EventSubCategoriesAssociationModal from './components/EventSubCategoriesAssociationModal';
import FeaturesAddOrRemoveAssociationModal from './components/FeaturesAddOrRemoveAssociationModal';
import modalUtils from './utils/modal';

const COLUMNS = [
  {
    id: 'name',
    name: i18next.t('GENERAL.ROLE'),
    propertyKey: 'name',
  },
  {
    id: 'associatedFeaturesCount',
    name: i18next.t('GENERAL.PAGES'),
    propertyKey: 'associatedFeaturesCount',
    render: (item) => (
      <Label
        background={theme.colors.greys.darkest}
        children={item}
        color={theme.colors.greys.darkest}
        height={'24px'}
        type={'outline'}
        width={'24px'}
      />
    ),
  },
  {
    id: 'associatedAccountsCount',
    name: i18next.t('GENERAL.USERS'),
    propertyKey: 'associatedAccountsCount',
    render: (item) => (
      <Label
        background={theme.colors.greys.darkest}
        children={item}
        color={theme.colors.greys.darkest}
        height={'24px'}
        type={'outline'}
        width={'24px'}
      />
    ),
  },
];

export const BackOfficeRolesManagement = (props) => {
  const { match, user, showSuccessMessage, showErrorMessage } = props;

  const path = _.get(match, 'path');

  const [isLoading, setIsLoading] = useState(false);
  const [clientFeatures, setClientFeatures] = useState([]);

  const [actions, setActions] = useState([]);
  const [rowActions, setRowActions] = useState([]);

  const [clientRoles, setClientRoles] = useState([]);

  /*************************/
  /** Handle Role Edition **/
  /*************************/

  const handleEditeRole = async (roleName, role) => {
    props.pageLoading();

    let shouldRefreshList = false;

    try {
      await clientService.updateRoleByRoleId(_.get(user, 'clientId'), role.id, {
        role: { name: roleName.toLowerCase() },
      });

      props.showMessage(i18next.t('BACKOFFICE.ROLES.NAME_SUCCESSFULLY_UPDATED'), 'success');

      shouldRefreshList = true;
    } catch (error) {
      props.showMessage(i18next.t('BACKOFFICE.ROLES.NAME_UPDATE_ERROR'), 'error');
    } finally {
      props.pageLoaded();

      if (shouldRefreshList) {
        await fetchClientData();
      }
    }
  };

  const onRoleNameEditionChange = (roleName, role) => {
    const alreadyExists = _.find(
      clientRoles,
      (clientRole) => clientRole.name.toLowerCase() === roleName.toLowerCase(),
    );

    const errorMessage =
      (alreadyExists && i18next.t('BACKOFFICE.ROLES.NAME_ALREADY_EXISTS')) ||
      (!roleName && i18next.t('GENERAL.REQUIRED_FILED_ERROR_MESSAGE')) ||
      '';

    const params = modalUtils.getRoleEditModalParams({
      role,
      roleName,
      errorMessage,
      handleEditeRole,
      onRoleNameEditionChange,
    });

    props.refreshGenericModal(params);
  };

  const displayModalEditRole = (role) => {
    const params = modalUtils.getRoleEditModalParams({
      role,
      handleEditeRole,
      roleName: role.name,
      onRoleNameEditionChange,
    });

    props.openGenericModal(params);
  };

  /**************************/
  /** Handle Role Creation **/
  /**************************/

  const handleCreateRole = async (name) => {
    props.pageLoading();

    let shouldRefreshList = false;

    try {
      await clientService.createClientRole(_.get(user, 'clientId'), name.toLowerCase());

      props.showMessage(i18next.t('BACKOFFICE.ROLES.CREATE_ROLE_SUCCESS'));

      shouldRefreshList = true;
    } catch (err) {
      props.showMessage(i18next.t('BACKOFFICE.ROLES.CREATE_ROLE_FAILURE'), 'error');
    } finally {
      props.pageLoaded();

      if (shouldRefreshList) {
        await fetchClientData();
      }
    }
  };

  const onRoleToCreateChange = (value) => {
    const forbiddenNames = clientRoles.map(({ name }) => name.toLowerCase());

    const params = modalUtils.getCreateRoleModalConfig({
      name: value,
      forbiddenNames,
      handleCreateRole,
      onInputChange: onRoleToCreateChange,
    });

    props.refreshGenericModal({ data: params.data, actions: params.actions });
  };

  const displayModalCreateRole = () => {
    const forbiddenNames = clientRoles.map(({ name }) => name.toLowerCase());

    const params = modalUtils.getCreateRoleModalConfig({
      name: '',
      forbiddenNames,
      handleCreateRole,
      onInputChange: onRoleToCreateChange,
    });

    props.openGenericModal(params);
  };

  /**************************/
  /** Handle Role Deletion **/
  /**************************/

  const handleDeleteRoleById = async (accountRoleId) => {
    props.pageLoading();

    let shouldRefreshList = false;

    try {
      await clientService.deleteRoleById(_.get(user, 'clientId'), accountRoleId);

      props.showMessage(i18next.t('BACKOFFICE.ROLES.DELETE_ROLE_SUCCESS'));

      shouldRefreshList = true;
    } catch (err) {
      props.showMessage(i18next.t('BACKOFFICE.ROLES.DELETE_ROLE_FAILURE'), 'error');
    } finally {
      props.pageLoaded();

      if (shouldRefreshList) {
        await fetchClientData();
      }
    }
  };

  const displayModalDeleteRole = (role) => {
    const params = modalUtils.getDeleteRoleModalConfig({
      role,
      handleDeleteRoleById,
    });

    props.openGenericModal(params);
  };

  /**************************/
  /** Handle Edit Rights **/
  /**************************/

  const handleEditRightsSave = async (selectedActions, accountRoleId) => {
    props.pageLoading();

    try {
      const featureActionsIds = selectedActions.map(({ id }) => id);

      await authorizedActions.assignFeatureActionsByAccountRoleId(accountRoleId, featureActionsIds);

      props.closeModal();

      showSuccessMessage(i18next.t('BACKOFFICE.ROLES.PAGE_EDIT_RIGHTS_SUCCESS'));
    } catch (error) {
      showErrorMessage(i18next.t('BACKOFFICE.ROLES.PAGE_EDIT_RIGHTS_FAIL'));
    } finally {
      props.pageLoaded();
    }
  };

  const displayModalEditRights = async (role) => {
    const { actions, allActions } = await authorizedActions.getAuthorizedActionsByAccountRoleId(
      role.id,
    );

    const roleAllowedActions = allActions.filter(({ id }) =>
      role.roleAllowedFeaturesActions.some((featureActions) => featureActions.id === id),
    );

    const formattedAllowedActions = roleAllowedActions.map((right) => {
      const matchingFeature = clientFeatures.find(
        (feature) => feature.path === right.lnkFeatureFeatureActionsrel.path,
      );

      const isViewRight = right.resource.endsWith(':view');

      return {
        ...right,
        module: matchingFeature ? matchingFeature.module : '-',
        name: right.translationKey ? i18next.t(right.translationKey) : right.name,
        page: right.lnkFeatureFeatureActionsrel.name,
        path: right.lnkFeatureFeatureActionsrel.path,
        isRowSelected: actions.some(({ id }) => id === right.id) || isViewRight,
        isNotSelectable: isViewRight,
      };
    });

    const params = {
      isAssociation: true,
      component: EditRoleRights,
      role,
      actions: formattedAllowedActions,
      closeModal: props.closeModal,
      handleSave: handleEditRightsSave,
    };

    props.openModal(params);
  };

  /**************************************************/
  /** Handle Role event sub categories association **/
  /**************************************************/

  const handleRoleEventSubCategoriesAssociation = async (eventSubCategories, roleId) => {
    const formattedEventSubCategories = eventSubCategories.map(
      (eventSubCategory) => eventSubCategory.id,
    );

    props.pageLoading();

    try {
      await clientService.updateEventSubCategoriesRoles(
        _.get(user, 'clientId'),
        roleId,
        formattedEventSubCategories,
      );

      props.closeModal();

      await fetchClientRoles();

      props.showMessage(i18next.t('BACKOFFICE.ROLES.ASSOCIATE_EVENTS_SUCCESS'), 'success');
    } catch (error) {
      props.showMessage(i18next.t('BACKOFFICE.ROLES.ASSOCIATE_EVENTS_ERROR'), 'error');
    } finally {
      props.pageLoaded();
    }
  };

  const displayModalEventSubCategoriesAssociation = async (role) => {
    const eventSubCategories = await getAllEventSubCategories();

    const associatedEventSubCategoryByIds = _.keyBy(
      role.associatedEventSubCategory,
      'eventSubCategoryId',
    );

    const filteredEventSubCategories = eventSubCategories.filter((eventSubCategory) => {
      if (
        role.name !== 'Inpulse' &&
        (eventSubCategory.name === 'Élection' ||
          eventSubCategory.name === 'Vacances' ||
          eventSubCategory.name === 'Événement calendaire')
      ) {
        return false;
      }

      return !associatedEventSubCategoryByIds[eventSubCategory.id];
    });

    const params = {
      component: EventSubCategoriesAssociationModal,
      role,
      eventSubCategories: filteredEventSubCategories,
      closeModal: props.closeModal,
      handleSave: (selectedEventSubCategories, roleId) =>
        handleRoleEventSubCategoriesAssociation(selectedEventSubCategories, roleId),
    };

    props.openModal(params);
  };

  /**************************************/
  /** Handle Role Features Association **/
  /**************************************/

  const handleFeaturesAssociationSave = async (selectedFeatures, roleId) => {
    const selectedFeatureClientMappingIds = selectedFeatures.map(
      (feature) => feature.featureClientMappingId,
    );

    props.pageLoading();

    try {
      await clientService.createAccountRoleFeatureClientMappings(
        _.get(user, 'clientId'),
        roleId,
        selectedFeatureClientMappingIds,
      );

      props.closeModal();

      showSuccessMessage(i18next.t('BACKOFFICE.ROLES.CREATE_PAGE_MAPPING_SUCCESS'));

      await fetchClientData();
    } catch (error) {
      showErrorMessage(i18next.t('BACKOFFICE.ROLES.CREATE_PAGE_MAPPING_ERROR'));
    } finally {
      props.pageLoaded();
    }
  };

  const displayModalFeaturesAssociation = async (role) => {
    // Do not propose BO features expect recipe debugger that could be associated to some users non-inpulse
    const filteredClientFeatures = clientFeatures.filter(
      ({ path }) =>
        !path.match(ENUM_REGEX_PATHS_FEATURE[ENUM_MODULE_NAME.BACKOFFICE]) ||
        path === '/backoffice/general/recipe-debugger',
    );

    const featuresNotMapped = filteredClientFeatures.filter(
      (clientFeature) =>
        !role.associatedFeatures.some(({ featureId }) => featureId === clientFeature.id),
    );

    const params = {
      isAssociation: true,
      component: FeaturesAddOrRemoveAssociationModal,
      title: i18next.t('ADMIN.INGREDIENTS.ASSOCIATION_MODAL_ALREADY_SET_TITLE'),
      role,
      features: featuresNotMapped,
      closeModal: props.closeModal,
      handleSave: handleFeaturesAssociationSave,
    };

    props.openModal(params);
  };

  const handleFeaturesDissociationSave = async (selectedFeatures, roleId) => {
    const selectedFeatureIds = selectedFeatures.map((feature) => feature.id);

    props.pageLoading();

    try {
      await clientService.deleteAccountRoleFeatureClientMappings(
        _.get(user, 'clientId'),
        roleId,
        selectedFeatureIds,
      );

      props.closeModal();

      showSuccessMessage(i18next.t('BACKOFFICE.ROLES.REMOVE_PAGE_MAPPING_SUCCESS'));

      await fetchClientData();
    } catch (error) {
      showErrorMessage(i18next.t('BACKOFFICE.ROLES.REMOVE_PAGE_MAPPING_ERROR'));
    } finally {
      props.pageLoaded();
    }
  };

  const displayModalFeaturesDissociation = async (role) => {
    const featuresMapped = clientFeatures.filter((clientFeature) =>
      role.associatedFeatures.some(({ featureId }) => featureId === clientFeature.id),
    );

    const params = {
      isAssociation: false,
      component: FeaturesAddOrRemoveAssociationModal,
      title: i18next.t('BACKOFFICE.ROLES.REMOVE_PAGE_ASSOCIATION'),
      role,
      features: featuresMapped,
      closeModal: props.closeModal,
      handleSave: handleFeaturesDissociationSave,
    };

    props.openModal(params);
  };

  /*******************/
  /** Fetch Methods **/
  /*******************/

  const fetchClientRoles = async () => {
    try {
      const roles = await clientService.getRolesByClientId(user.clientId, {
        withStoreEventSubCategories: true,
      });

      const formattedRoles = roles.map((role) => {
        const roleAllowedFeaturesActions = role.associatedFeatures.reduce((res, currentFeature) => {
          if (!_.isEmpty(currentFeature.actions)) {
            res.push(...currentFeature.actions);
          }

          return res;
        }, []);

        return {
          id: role.id,
          name: _.upperFirst(role.name),
          associatedFeatures: role.associatedFeatures,
          associatedFeaturesCount: role.associatedFeatures.length,
          associatedAccountsCount: role.associatedAccounts.length,
          associatedEventSubCategory: role.associatedEventSubCategory,
          hasFeatureActionsLinked: !!roleAllowedFeaturesActions.length,
          roleAllowedFeaturesActions,
        };
      });

      setClientRoles(formattedRoles);
    } catch (error) {
      props.showMessage(i18next.t('BACKOFFICE.ROLES.FETCHING_ERROR'), 'error');
    }
  };

  const fetchClientFeatures = async () => {
    try {
      const clientFeatures = await onBoardingService.getFeaturesOfClient(_.get(user, 'clientId'));

      // we can't use featureKeyByPath redux because we need Sales Point features and Central Kitchen features
      const allFeaturesKeyByPath = getNavTreeKeyByPath({
        navTree: [...ORDERED_NAV_TREE, ...ORDERED_CENTRAL_KITCHEN_NAV_TREE],
      });

      const formattedClientFeatures = clientFeatures.map((feature) => {
        const matchingFeature = allFeaturesKeyByPath[feature.path];

        return {
          ...feature,
          module: (!!matchingFeature && matchingFeature.moduleInfo.name) || feature.name,
        };
      });

      setClientFeatures(formattedClientFeatures);
    } catch (error) {
      props.showMessage(i18next.t('BACKOFFICE.ROLES.CLIENT_FEATURES_FETCH_ERROR'), 'error');
    }
  };

  const fetchClientData = async () => {
    setIsLoading(true);

    await fetchClientRoles();
    await fetchClientFeatures();

    setIsLoading(false);
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    (async function loadData() {
      await fetchClientData();
    })();
  }, [user]);

  useEffect(() => {
    if (!clientRoles) {
      return;
    }

    setActions(actionsUtils.getActions({ displayModalCreateRole }));
    setRowActions(
      actionsUtils.getRowActions({
        displayModalDeleteRole,
        displayModalEditRights,
        displayModalEditRole,
        displayModalFeaturesAssociation,
        displayModalFeaturesDissociation,
        displayModalEventSubCategoriesAssociation,
      }),
    );
  }, [clientRoles, clientFeatures]);

  return (
    <Container>
      <NavigationBreadCrumb featurePath={path} />
      <ListViewContainer>
        <ListView
          actions={actions}
          columns={COLUMNS}
          data={clientRoles}
          defaultOrderBy={'name'}
          defaultOrderType={'asc'}
          isLoading={isLoading}
          padding={'24px 24px 0px 24px'}
          placeholderShape={i18next.t('GENERAL.SEARCH')}
          rowActions={rowActions}
          disableFullSelection
          disableSelection
        />
      </ListViewContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  userRights: state.baseReducer.userRights,
});

const mapDispatchToProps = (dispatch) => ({
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  openModal: (params) => {
    dispatch(openModal(params));
  },
  closeModal: () => {
    dispatch(closeModal());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeRolesManagement);

export const INPUT_TYPES = {
  TOGGLE: 'toggle',
  CHECKBOX: 'checkbox',
  PLAIN_TEXT: 'plain-text',
  CURRENCY: 'currency',
  TEXT: 'text',
  TEXT_NUMBER: 'text-number',
  TEXT_WITH_ACTION: 'text-action',
  NUMBER: 'number',
  SINGLE_SELECT: 'single-select',
  MULTI_SELECT: 'multi-select',
  SINGLE_DATEPICKER: 'single-datepicker',
  CUSTOM_RENDER: 'custom-render',
};

import { omit } from 'lodash';
import i18next from 'i18next';

import { getPropertyNoneValue } from '@commons/constants/categoryTypes';

const formatOnChangeCallbackData = (data) =>
  data.reduce((result, current) => {
    if (!result[current.value]) {
      result[current.value] = {
        ...omit(current, 'ids'),
        associatedItems: current.ids.map((id) => ({
          id,
        })),
      };
    }
    return result;
  }, {});

const formatPropertiesToItemListSorterPayload = (properties) =>
  properties.map((category) => ({
    id: category.id,
    value: category.name || getPropertyNoneValue(),
  }));

const formatDataToItemListSorterPayload = (categories) =>
  categories.reduce((result, { name, id, associatedItems }) => {
    // ItemListSorter do not allow null, so we need to put default value
    const categoryName = name || i18next.t('GENERAL.NONE_VALUE');

    result[categoryName] = {
      associatedItems,
      originalItem: {
        id, // handy to know which id the item refers to
        value: categoryName, // handy to know when property name was changed
      },
    };

    return result;
  }, {});

export default {
  formatOnChangeCallbackData,
  formatDataToItemListSorterPayload,
  formatPropertiesToItemListSorterPayload,
};

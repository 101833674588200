import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font: ${(props) => props.theme.fonts.h1InterNonBold};
  color: ${(props) => props.theme.colors.greys.darkest};
  padding-bottom: 16px;
  border-bottom: 2px solid ${(props) => props.theme.colors.greys.light};
`;

export const HeaderContainer = styled.div`
  flex: 0 0 auto;
`;

export const ContentContainer = styled.div`
  flex: 1 1 auto;
  padding: 24px 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 24px;
  padding-bottom: 0;
  border-top: 2px solid ${(props) => props.theme.colors.greys.light};
`;

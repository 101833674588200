import { Divider, IconButton, Stack } from '@mui/material';
import { GridColumnMenuFilterItem, GridColumnMenuPinningItem } from '@mui/x-data-grid-pro';
import React from 'react';

import { COLUMNS } from './constants';

const PINNED_FIELDS = [COLUMNS.NAME, COLUMNS.CATEGORY, COLUMNS.TOTAL];

export const CustomMenuIcon = () => (
  <IconButton>
    <img src={'/images/inpulse/carret-black.svg'} style={{ width: '16px', height: '16px' }} />
  </IconButton>
);

export const CustomColumnMenu = (props) => {
  const { colDef, hideMenu } = props;

  const pinnedItemProps = {
    colDef,
    onClick: hideMenu,
  };

  return (
    <>
      {PINNED_FIELDS.includes(colDef.field) && (
        <Stack>
          <GridColumnMenuPinningItem {...pinnedItemProps} />
          <Divider />
          <GridColumnMenuFilterItem {...pinnedItemProps} />
        </Stack>
      )}
      {!PINNED_FIELDS.includes(colDef.field) && null}
    </>
  );
};

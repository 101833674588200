import i18next from 'i18next';
import React from 'react';

import { Label } from '@commons/utils/styledLibraryComponents';
import { Tags } from '@commons/Tags';
import Text from '@commons/Text';

import theme from '@theme';

const renderMappingsCount = (item) => (
  <Label
    background={theme.colors.greys.lighty}
    children={item}
    color={theme.colors.blacks.ipBlack1}
    width={'32px'}
  />
);

const getListColumns = () => [
  {
    id: 'name',
    name: i18next.t('GENERAL.NAME'),
    propertyKey: 'name',
  },
  {
    id: 'associatedSPsCount',
    name: i18next.t('ADMIN.INVENTORY_LIST_TEMPLATES.LIST_COLUMN_SUPPLIER_PRODUCTS'),
    propertyKey: 'associatedSPsCount',
    render: renderMappingsCount,
  },
  {
    id: 'associatedRecipesCount',
    name: i18next.t('ADMIN.INVENTORY_LIST_TEMPLATES.ASSOCIATED_ENTITIES_COUNT_COLUMN'),
    propertyKey: 'associatedRecipesCount',
    render: renderMappingsCount,
  },
];

const getILTSupplierProductMappingsColumns = (hasLocalCatalogs = false) => {
  const columns = [
    { id: 'name', name: i18next.t('GENERAL.NAME'), propertyKey: 'name' },
    { id: 'supplierName', name: i18next.t('GENERAL.SUPPLIER'), propertyKey: 'supplierName' },
    { id: 'category', name: i18next.t('GENERAL.CATEGORY'), propertyKey: 'category' },
    { id: 'subCategory', name: i18next.t('GENERAL.SUB_CATEGORY'), propertyKey: 'subCategory' },
  ];

  if (hasLocalCatalogs) {
    columns.splice(2, 0, {
      id: 'catalogName',
      name: i18next.t('GENERAL.CATALOG'),
      propertyKey: 'catalogName',
    });
  }

  return columns;
};

const getILTRecipeMappingsColumns = (hasMultipleBrands) => {
  const columns = [{ id: 'name', name: i18next.t('GENERAL.NAME'), propertyKey: 'name' }];

  if (hasMultipleBrands) {
    columns.push({
      id: 'brands',
      name: i18next.t('GENERAL.BRAND_PLURAL'),
      propertyKey: 'brands',
      render: (brands) => <Tags items={brands.map(({ name }) => name)} />,
    });
  }

  columns.push({
    id: 'category',
    name: i18next.t('GENERAL.CATEGORY'),
    propertyKey: 'category',
    render: (category) => <Text>{category || i18next.t('GENERAL.NONE_VALUE')}</Text>,
  });

  return columns;
};

export default {
  getListColumns,
  getILTSupplierProductMappingsColumns,
  getILTRecipeMappingsColumns,
};

import { get } from 'lodash';
import i18next from 'i18next';

import { sortArrayOfObjectsAlphabetically } from '@commons/utils/sorting';

import { forecast as forecastService } from '@services/forecast';
import { forecastSupplierProducts as forecastSupplierProductsService } from '@services/forecastSupplierProducts';
import { ingredient as ingredientService } from '@services/ingredient';
import { inventoryList as inventoryListService } from '@services/inventoryList';
import { productOrders as productOrdersService } from '@services/productOrders';
import { supplierProduct as supplierProductService } from '@services/supplierProduct';
import { supplier as supplierService } from '@services/supplier';
import { upload as uploadService } from '@services/upload';
import orderService from '@services/order';

const uploadFile = async (file, params, showMessage) => {
  try {
    return uploadService.uploadFile(file, params);
  } catch (err) {
    showMessage(i18next.t('UPLOAD_FILE_ERROR'), 'error');

    return null;
  }
};

const sendOrderByEmail = async (
  userId,
  storeId,
  supplierId,
  orderId,
  to,
  cc,
  bcc,
  body,
  userTimezone,
  withMail = true,
) => {
  try {
    await orderService.sendOrderByEmail(
      userId,
      storeId,
      supplierId,
      orderId,
      to,
      cc,
      bcc,
      body,
      userTimezone,
      withMail,
    );

    return true;
  } catch (err) {
    return false;
  }
};

const cancelOrderReception = async (orderId, showMessage) => {
  try {
    await orderService.cancelReceptionOrderById(orderId);
  } catch (err) {
    showMessage(
      "Une erreur est survenue lors de l'annulation de la reception de commande",
      'error',
    );
  }
};

/**
 * Send order to be created or updated
 *
 * @param {Object} order - All data related to the order
 * @param {Array} productOrders - All product orders (ordered, invoiced or received) related to the order
 * @param {Boolean} isEditingDate - true if there is an update of start_order_date (order delivery date)
 * @param {Array} productOrdersSPToBeUpdated - List of product orders that need to be updated (useful when we need to rewrite ordered product orders)
 * @param {String} type - Sending type (mail or partner)
 * @param {Object} sendParams - All parameters that are useful for sending this order
 *
 * @returns The order that is created or updated
 */
const patchOrder = async (
  order,
  productOrders,
  isEditingDate,
  productOrdersSPToBeUpdated,
  type,
  sendParams,
) => {
  try {
    return await orderService.patchOrder(
      order,
      productOrders,
      isEditingDate,
      productOrdersSPToBeUpdated,
      type,
      sendParams,
    );
  } catch (err) {
    throw err;
  }
};

const getOrdersByStoreId = async (storeId, showMessage) => {
  try {
    const orders = await orderService.getOrdersOfStores([storeId]);

    return orders;
  } catch (err) {
    showMessage(
      'Une erreur est survenue lors de la récupération des commandes passées sur cet établissement',
      'error',
    );

    return [];
  }
};

const getIngredientsByClientId = async (clientId, showMessage) => {
  try {
    const ingredients = await ingredientService.getIngredients(clientId);

    return ingredients;
  } catch (err) {
    showMessage('Une erreur est survenue lors de la récupération des ingrédients', 'error');

    return [];
  }
};

const getSuppliersByStoreId = async (storeId, showMessage) => {
  try {
    const suppliers = await supplierService.getSuppliersOfStore(storeId);

    return sortArrayOfObjectsAlphabetically(suppliers, 'name');
  } catch (err) {
    if (get(err, 'response.status') !== 404) {
      // 404 only means no suppliers were found
      showMessage(i18next.t('ADMIN.SUPPLIERS.FETCH_FAILURE'), 'error');
    }

    return [];
  }
};

const getProductOrdersByOrderId = async (orderId, showMessage) => {
  try {
    const result = await productOrdersService.getProductOrdersOfOrders([orderId]);

    return result;
  } catch (err) {
    showMessage('Une erreur est survenue lors de la récupération des product orders', 'error');

    return [];
  }
};

const getProductOrdersStockedByStoreIdSupplierId = async (
  storeId,
  supplierId,
  startDate,
  endDate,
  showMessage,
) => {
  try {
    const result = await productOrdersService.getStockedProductOrdersOfStoreAndSupplier(
      storeId,
      supplierId,
      startDate,
      endDate,
    );

    return result;
  } catch (err) {
    showMessage(
      'Une erreur est survenue lors de la récupération des stocks product orders',
      'error',
    );

    return [];
  }
};

const isStockValidForStoreByDate = async (storeId, date, showMessage) => {
  try {
    const result = await inventoryListService.checkStocksValid(storeId, date);

    return result.areStocksValid;
  } catch (err) {
    showMessage('Une erreur est survenue lors de la vérification des stocks', 'error');

    return false;
  }
};

const isForecastValidForStoreByDate = async (storeId, startDate, endDate, showMessage) => {
  try {
    const result = await forecastService.checkForecastsValid(storeId, startDate, endDate);

    return result.areForecastsValid;
  } catch (err) {
    showMessage('Une erreur est survenue lors de la vérification des forecasts', 'error');

    return false;
  }
};

const getSupplierProductsCustomByStoreIdAndSupplierId = async (
  supplierId,
  storeId,
  showMessage,
  startDate,
) => {
  try {
    const result = await supplierProductService.getSupplierProductsOfSupplierAndStore(
      supplierId,
      storeId,
      startDate,
    );

    return result;
  } catch (err) {
    showMessage(
      'Une erreur est survenue lors de la récupération des produits fournisseurs',
      'error',
    );

    return [];
  }
};

const getforecastSupplierProductsOfStore = async (
  storeId,
  supplierId,
  startDate,
  endDate,
  showMessage,
) => {
  try {
    const result = await forecastSupplierProductsService.getforecastSupplierProductsOfStore(
      storeId,
      supplierId,
      startDate,
      endDate,
    );

    return result;
  } catch (err) {
    showMessage(
      'Une erreur est survenue lors de la récupération des forecasts supplier products',
      'error',
    );

    return [];
  }
};

const getPreviewPurchaseOrder = async (
  order,
  productOrders,
  showErrorMessage,
  userTimezone = 'Europe/Paris',
) => {
  try {
    return orderService.purchaseOrderPreview(order, productOrders, userTimezone);
  } catch (error) {
    showErrorMessage(i18next.t('ORDERS.ORDERS.ORDER_PURCHASE_PREVIEW_GENERATION_FAILED'));
  }
};

const getPreviewCreditOrder = async (order, productOrders, showErrorMessage) => {
  try {
    const result = await orderService.creditOrderPreview(order, productOrders);

    return result;
  } catch (error) {
    showErrorMessage(i18next.t('ORDERS.ORDERS.ORDER_CREDIT_PREVIEW_GENERATION_FAILED'));
  }
};

const generateDeliveryNotePreview = async (
  order,
  productOrders,
  showErrorMessage,
  userTimezone = 'Europe/Paris',
) => {
  try {
    const result = await orderService.generateDeliveryNotePreview(
      order,
      productOrders,
      userTimezone,
    );

    return result;
  } catch {
    showErrorMessage(i18next.t('ORDERS.ORDERS.ORDER_DELIVERY_NOTE_PREVIEW_GENERATION_FAILED'));
  }
};

export default {
  uploadFile,
  patchOrder,
  sendOrderByEmail,
  getOrdersByStoreId,
  cancelOrderReception,
  getSuppliersByStoreId,
  getIngredientsByClientId,
  getProductOrdersByOrderId,
  isStockValidForStoreByDate,
  isForecastValidForStoreByDate,
  getforecastSupplierProductsOfStore,
  getProductOrdersStockedByStoreIdSupplierId,
  getSupplierProductsCustomByStoreIdAndSupplierId,
  getPreviewPurchaseOrder,
  getPreviewCreditOrder,
  generateDeliveryNotePreview,
};

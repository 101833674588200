import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { closeGenericModal } from '@actions/modal';
import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import CreateIngredientModal from '@admin/components/CreateIngredientModal';

import { CHANNEL_ID_KEY_BY_NAME, CHANNELS } from '@commons/constants/channel';
import { Dropdown, Tooltip } from '@commons/utils/styledLibraryComponents';
import { ENTITY_UNITS } from '@commons/constants/units';
import { formatNumber } from '@commons/DisplayNumber';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import { getClientInfo } from '@selectors/client';

import { getRecipeCost, getRecipesOfClient } from '@services/recipe';
import { ingredient as ingredientService } from '@services/ingredient';
import centralService from '@services/central';
import clientService from '@services/client';

import { CATEGORY_TYPES_OBJECT, getPropertyNoneValue } from '@commons/constants/categoryTypes';

import theme from '@theme';

import {
  CompositionContainer,
  Container,
  DropdownsContainer,
  ExternalRedirectionContainer,
  InformationComponentsContainer,
  InformationContainer,
  LabelContainer,
  LinkImage,
} from './styledComponents';
import IngredientDropdown from './components/IngredientDropdown';
import RecipeDropdown from './components/RecipeDropdown';

export const getCompositionTypes = () => [
  {
    value: i18next.t('GENERAL.NONE_VALUE'),
    id: 'NONE',
  },
  {
    value: i18next.t('GENERAL.RECIPE'),
    id: 'RECIPE',
  },
  {
    value: i18next.t('GENERAL.INGREDIENT'),
    id: 'INGREDIENT',
  },
];

const computeEntityCost = (unit, cost, quantity = 1) => {
  if (!cost) {
    return null;
  }

  if (unit === ENTITY_UNITS.UNIT) {
    return cost;
  }

  // The entity is already in g or mL. To get the cost we only need to multiply by quantity
  return cost * quantity;
};

const InformationComponent = ({ label, value, link, tooltipText }) => (
  <InformationContainer>
    <LabelContainer>
      <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.TEXT_MIDDLE_NORMAL}>
        {label} :
      </Text>
      {!!link && (
        <LinkImage src="/images/inpulse/open-a-new-black-small.svg" onClick={link.onClick} />
      )}
      {!!tooltipText && !!tooltipText.length && (
        <div style={{ marginTop: '-2px' }}>
          <Tooltip displayBigger={true} text={tooltipText} />
        </div>
      )}
    </LabelContainer>
    <Text color={ENUM_COLORS.BLACK} font={ENUM_FONTS.TEXT_MIDDLE_BOLD}>
      {value}
    </Text>
  </InformationContainer>
);

const ProductComposition = (props) => {
  const {
    client: { clientId, defaultChannelId },
    pageLoading,
    pageLoaded,
    showErrorMessage,
    product,
    isReadOnly,
    currency: { alphabeticCode, numberDecimals },
    composition,
    compositionError,
    setComposition,
    selectedCompoType,
    setSelectedCompoType,
    onProductChange,
    isKitchenProduct = false,
    showSuccessMessage,
    closeGenericModal,
  } = props;

  const compositionTypes = getCompositionTypes();

  const [isLoading, setIsLoading] = useState(true);

  const [recipes, setRecipes] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [showIngredientOrRecipeDropdown, setShowIngredientOrRecipeDropdown] = useState(true);

  const [openCreateIngredientModal, setOpenCreateIngredientModal] = useState(false);

  useEffect(() => {
    if (!selectedCompoType || isEmpty(selectedCompoType)) {
      return;
    }

    const actionsToExecute = {
      NONE: () => setShowIngredientOrRecipeDropdown(false),
      RECIPE: fetchRecipes,
      INGREDIENT: fetchIngredients,
    };

    actionsToExecute[selectedCompoType.id]();
  }, [selectedCompoType]);

  const getTotalCost = (composition) => {
    if (composition.isIngredient) {
      return `${formatNumber(
        computeEntityCost(composition.unit, composition.cost) || null,
        numberDecimals,
      )}`;
    }

    // Default channel is necessarily On Site or Delivery
    const costOnDefaultChannel = composition.costByChannelId[defaultChannelId];

    return composition.hasMultipleChannels
      ? `${formatNumber(
          computeEntityCost(
            composition.unit,
            composition.costByChannelId[CHANNEL_ID_KEY_BY_NAME[CHANNELS.ON_SITE]],
            composition.quantity,
          ) || null,
          numberDecimals,
        )} | ${formatNumber(
          computeEntityCost(
            composition.unit,
            composition.costByChannelId[CHANNEL_ID_KEY_BY_NAME[CHANNELS.DELIVERY]],
            composition.quantity,
          ) || null,
          numberDecimals,
        )}`
      : `${formatNumber(
          computeEntityCost(composition.unit, costOnDefaultChannel, composition.quantity) || null,
          numberDecimals,
        )}`;
  };

  const getRatio = (cost, delivery = false) => {
    const productPrice = parseFloat(delivery ? product.deliveryPriceHT : product.priceHT);

    return formatNumber(
      cost > 0 && !Number.isNaN(productPrice) && productPrice > 0
        ? (cost / productPrice) * 100
        : null,
      numberDecimals,
    );
  };

  const getTotalRatio = (composition) => {
    if (composition.isIngredient) {
      const entityCost = computeEntityCost(composition.unit, composition.cost);
      const ratio = getRatio(entityCost);

      return `${ratio}${entityCost ? '%' : ''}`;
    }

    const unitCostDefaultChannel = composition.costByChannelId[defaultChannelId];

    const costDefaultChannel = computeEntityCost(
      composition.unit,
      unitCostDefaultChannel,
      composition.quantity,
    );

    const ratioDefaultChannel = getRatio(costDefaultChannel);

    if (!composition.hasMultipleChannels && !product.deliveryPrice) {
      return `${ratioDefaultChannel}${costDefaultChannel ? '%' : ''}`;
    }

    const costOnSite = computeEntityCost(
      composition.unit,
      composition.costByChannelId[CHANNEL_ID_KEY_BY_NAME[CHANNELS.ON_SITE]],
      composition.quantity,
    );

    const ratioOnSite = getRatio(costOnSite);

    if (!product.deliveryPrice) {
      return `${ratioOnSite}${costOnSite ? '%' : ''} | - %`;
    }

    const costDelivery = computeEntityCost(
      composition.unit,
      composition.costByChannelId[CHANNEL_ID_KEY_BY_NAME[CHANNELS.DELIVERY]],
      composition.quantity,
    );

    const ratioDelivery = getRatio(costDelivery, true);

    return `${ratioOnSite}${costOnSite ? '%' : ''} | ${ratioDelivery}${costDelivery ? '%' : ''}`;
  };

  const fetchRecipes = async () => {
    if (recipes.length) {
      setShowIngredientOrRecipeDropdown(true);
      return;
    }

    try {
      const clientRecipes = isKitchenProduct
        ? await centralService.getKitchenRecipesOfClient(clientId)
        : await getRecipesOfClient(clientId);

      const activeRecipes = clientRecipes.filter((recipe) => recipe.active);

      activeRecipes.forEach((recipe) => {
        if (!!recipe.product) {
          recipe.isDisabled = true;
        }

        recipe.category = recipe.category || getPropertyNoneValue();
      });

      let formattedRecipes = activeRecipes;

      if (isKitchenProduct) {
        formattedRecipes = activeRecipes.filter(({ isKitchen }) => isKitchen);
      }

      setRecipes(formattedRecipes);
      setShowIngredientOrRecipeDropdown(true);
    } catch {
      showErrorMessage(i18next.t('ADMIN.RECIPES.COMPOSITION_FETCH_ERROR_PLURAL'));
      setShowIngredientOrRecipeDropdown(false);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchIngredients = async () => {
    if (ingredients.length) {
      setShowIngredientOrRecipeDropdown(true);
      return;
    }

    try {
      const clientIngredients = await ingredientService.getIngredients(clientId, false);

      // For Central kitchen products, we display all active ingredients of client
      const activeIngredients = clientIngredients.filter(
        ({ active, unit }) => active && (isKitchenProduct || unit === ENTITY_UNITS.UNIT),
      );

      setIngredients(activeIngredients);
      setShowIngredientOrRecipeDropdown(true);
    } catch {
      showErrorMessage(i18next.t('ADMIN.INGREDIENTS.FETCH_ERROR'));
      setShowIngredientOrRecipeDropdown(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCompositionTypeChange = (selectedType) => {
    setComposition({});
    setSelectedCompoType(selectedType);

    if (selectedType.id === 'NONE') {
      onProductChange({ ...product, entityId: null });
    }
  };

  const handleEntityChange = async (selectedEntity) => {
    pageLoading();
    try {
      const recipeCost = await getRecipeCost(selectedEntity.id, { withAllergens: false });

      selectedEntity.costByChannelId = recipeCost.costByChannelId;

      setComposition(selectedEntity);
      onProductChange({ ...product, entityId: selectedEntity.id, selectedEntity });
    } catch {
      showErrorMessage(i18next.t('ADMIN.PRODUCTS.CREATE_PRODUCT_MODAL_CALCULATE_COST_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const handleOpenEntityDetails = () => {
    if (selectedCompoType.id === 'RECIPE' && composition.isKitchen) {
      window.open(`/admin/kitchen-products/recipes/${composition.id}/details`, '_blank');
    }

    window.open(
      `/admin/products/${selectedCompoType.id === 'RECIPE' ? 'recipes' : 'ingredients'}/${
        composition.id
      }/details`,
      '_blank',
    );
  };

  const handleCallToCreateIngredient = async (ingredient) => {
    pageLoading();

    try {
      let ingredientCategoryId = ingredient.categoryId;

      if (!ingredientCategoryId) {
        const { categories } = await clientService.getCategoriesAndSubCategories(
          clientId,
          CATEGORY_TYPES_OBJECT.INGREDIENT,
        );

        const categoryNone = categories.find(({ name }) => !name);

        ingredientCategoryId = categoryNone.id;
      }

      const newlyCreatedIngredient = await ingredientService.createIngredient(clientId, {
        name: ingredient.name,
        unit: ingredient.unit,
        code: ingredient.code,
        cost: ingredient.cost,
        supplierProducts: ingredient.supplierProducts.map(({ id, isUsedForCost }) => ({
          id,
          isUsedForCost,
        })),
        allergenIds: ingredient.allergens.map((allergen) => allergen.id),
        isStrategic: ingredient.isStrategic,
        categoryId: ingredientCategoryId,
      });

      showSuccessMessage(i18next.t('ADMIN.INGREDIENTS.CREATION_SUCCESS'));

      await handleEntityChange(newlyCreatedIngredient);

      closeGenericModal();
    } catch {
      showErrorMessage(i18next.t('ADMIN.INGREDIENTS.CREATION_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  return (
    <Container compositionError={compositionError}>
      <Text font={ENUM_FONTS.H2}>{i18next.t('ADMIN.PRODUCTS.COMPOSITION')}</Text>
      <DropdownsContainer>
        <Dropdown
          customStyle={{ height: 'auto' }}
          isDisabled={isReadOnly}
          isRequired={true}
          items={
            !isKitchenProduct
              ? compositionTypes
              : compositionTypes.filter(({ id }) => id !== 'NONE')
          }
          label={`${i18next.t('ADMIN.PRODUCTS.COMPOSITION_TYPE')} :`}
          labelStyle={{ font: theme.fonts.textSmall }}
          placeholder={i18next.t('GENERAL.SEARCH')}
          selectedItem={selectedCompoType}
          shouldInterpretTooltipHTML={true}
          tooltipText={i18next.t('ADMIN.PRODUCTS.COMPOSITION_TYPE_TOOLTIP')}
          onSelectionChange={handleCompositionTypeChange}
        />
        {showIngredientOrRecipeDropdown && (
          <CompositionContainer>
            {selectedCompoType.id === 'RECIPE' ? (
              <RecipeDropdown
                composition={composition}
                handleEntityChange={handleEntityChange}
                isLoading={isLoading}
                isReadOnly={isReadOnly}
                recipes={recipes}
              />
            ) : (
              <IngredientDropdown
                composition={composition}
                handleEntityChange={handleEntityChange}
                handleIngredientCreation={() => setOpenCreateIngredientModal(true)}
                ingredients={ingredients}
                isKitchenProduct={isKitchenProduct}
                isLoading={isLoading}
                isReadOnly={isReadOnly}
              />
            )}
            {!isEmpty(composition) && (
              <ExternalRedirectionContainer onClick={handleOpenEntityDetails}>
                <LinkImage src="/images/inpulse/open-a-new-black-small.svg" />
                <Text font={ENUM_FONTS.TEXT_MIDDLE_BOLD}>
                  {selectedCompoType.id === 'RECIPE'
                    ? i18next.t('ADMIN.PRODUCTS.SEE_RECIPE')
                    : i18next.t('ADMIN.PRODUCTS.SEE_INGREDIENT')}
                </Text>
              </ExternalRedirectionContainer>
            )}
          </CompositionContainer>
        )}
      </DropdownsContainer>
      {!isEmpty(composition) && (
        <InformationComponentsContainer>
          <InformationComponent
            label={i18next.t('ADMIN.PRODUCTS.COST_EXCL_TAXES_WITH_CURRENCY', {
              alphabeticCode: alphabeticCode,
            })}
            tooltipText={
              composition.hasMultipleChannels
                ? i18next.t('ADMIN.RECIPES.DETAILS_COSTS_TOOLTIP_MULTIPLE_CHANNELS')
                : null
            }
            value={getTotalCost(composition)}
          />
          <div style={{ margin: '0px 40px' }}>
            <InformationComponent
              label={i18next.t('ADMIN.PRODUCTS.PRICE_EXCL_TAXES_WITH_CURRENCY', {
                alphabeticCode: alphabeticCode,
              })}
              tooltipText={
                composition.hasMultipleChannels && i18next.t('ADMIN.PRODUCTS.PRICE_TOOLTIP')
              }
              value={
                product.deliveryPrice
                  ? `${formatNumber(product.priceHT || null, numberDecimals)} | ${formatNumber(
                      product.deliveryPriceHT || null,
                      numberDecimals,
                    )}`
                  : formatNumber(product.priceHT || null, numberDecimals)
              }
            />
          </div>
          <InformationComponent
            label={i18next.t('ADMIN.PRODUCTS.RATIO')}
            tooltipText={i18next.t('ADMIN.RECIPES.COMPOSITION_COST_DETAIL_RATIO')}
            value={getTotalRatio(composition)}
          />
        </InformationComponentsContainer>
      )}
      {!!openCreateIngredientModal && isKitchenProduct && (
        <CreateIngredientModal
          handleCallToCreateIngredient={handleCallToCreateIngredient}
          resetIngredientCreation={() => {
            setOpenCreateIngredientModal(false);
          }}
        />
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  currency: state.baseReducer.currency,
});

const mapDispatchToProps = (dispatch) => ({
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  closeGenericModal: (params) => {
    dispatch(closeGenericModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductComposition);

import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { getNumberFormattedWithDecimals } from '@commons/DisplayNumber';
import { getTheme } from '@commons/utils/theme';
import { Label } from '@commons/utils/styledLibraryComponents';
import Text from '@commons/Text';

import { getEntityUnit } from '@stocks/utils';

import { getEntityTypesChoices } from '@productions/utils/commons';
import {
  RowContentContainer,
  Item,
  FlexDiv,
  BoxImg,
  NameContent,
  ProductionInteractionContainer,
} from '@productions/CentralKitchenProductionOperations/styledComponents';

const RecommendationRow = memo((props) => {
  const { nbColumns, entity, setDisplayProductPicture, recommendationQuantity, selectedChoiceKey } =
    props;

  const theme = getTheme();

  const entityTypesChoices = getEntityTypesChoices();

  const getPicturePlaceholder = () =>
    selectedChoiceKey === entityTypesChoices[1].key
      ? '/images/inpulse/placeholder-fruit-square.svg'
      : '/images/inpulse/recipe-photo-placeholder.svg';

  return (
    <>
      <RowContentContainer>
        <Item flex={1} nbColumns={nbColumns}>
          <FlexDiv>
            <BoxImg
              img={entity.img || getPicturePlaceholder()}
              pointerCursor={!!entity.img}
              onClick={(event) => {
                event.stopPropagation();

                if (!!entity.img && setDisplayProductPicture) {
                  setDisplayProductPicture({ state: true, displayImage: entity.img });
                }
              }}
            />
            <NameContent>
              <Text>{entity.name}</Text>
            </NameContent>
          </FlexDiv>
        </Item>

        <Item flex={1} nbColumns={nbColumns}>
          <ProductionInteractionContainer>
            <Label
              background={theme.colors.highlightedBlue}
              children={getNumberFormattedWithDecimals(recommendationQuantity, 2) || 0}
              color={theme.colors.blacks.ipBlack1}
              icon={'/images/inpulse/lightning-black-small.svg'}
              position="left"
            />
          </ProductionInteractionContainer>
        </Item>

        <Item flex={1} nbColumns={nbColumns}>
          <ProductionInteractionContainer>
            <Text>{getEntityUnit(entity.unit, false)}</Text>
          </ProductionInteractionContainer>
        </Item>
      </RowContentContainer>
    </>
  );
});

RecommendationRow.PropTypes = {
  nbColumns: PropTypes.number.isRequired,
  entity: PropTypes.object.isRequired,
  setDisplayProductPicture: PropTypes.func.isRequired,
  recommendationQuantity: PropTypes.object.isRequired,
  selectedChoiceKey: PropTypes.string.isRequired,
};

export default RecommendationRow;

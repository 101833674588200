import { connect } from 'react-redux';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';

import cashierService from '@services/cashier';

import { showErrorMessage } from '@actions/messageconfirmation';

import { DATAGRID_LOCALES, generateDates } from '@commons/utils/dataGrid';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { Dropdown } from '@commons/utils/styledLibraryComponents';
import {
  GenericContentContainer,
  GenericGapContainer,
  GenericPageContainer,
} from '@commons/Layout/styledComponents';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getUserTimezone } from '@commons/utils/date';
import { LeftRightSplitter } from '@commons/LeftRightSplitter';
import { PeriodDatePicker } from '@commons/DatePickers/PeriodDatePicker';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { usePeriodDatePickerState } from '@hooks/usePeriodDatePickerState';

import { getClientInfo } from '@selectors/client';

import { COMMON_DATAGRID_PROPS } from './datagridProps';
import { getColumns } from './getColumns';

const FtpDebug = (props) => {
  const {
    match,
    user,
    stores,
    currency,
    showErrorMessage,
    client: { storeName },
  } = props;

  const USER_TIMEZONE = getUserTimezone();
  const YESTERDAY = moment.tz(USER_TIMEZONE).subtract(1, 'day');

  /*******************
   ***** STATES *****
   *******************/

  const [isLoading, setIsLoading] = useState(false);

  const [selectedStores, setSelectedStores] = useState(stores);

  // Datagrid columns
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const periodPickerState = usePeriodDatePickerState(
    moment(YESTERDAY).subtract(1, 'month'),
    YESTERDAY,
    USER_TIMEZONE,
  );
  const [maxFutureDate, setMaxFutureDate] = useState(null);

  const translatedStoreName = getClientStoreNameTranslation(storeName, false);

  // Keep an array corresponding to the selected period
  const selectedDates = useMemo(() => {
    if (!periodPickerState.startDate || !periodPickerState.endDate) {
      return [];
    }

    return generateDates(periodPickerState.startDate, periodPickerState.endDate);
  }, [periodPickerState.startDate, periodPickerState.endDate]);

  const apiRef = useGridApiRef();
  const autoScrollDoneRef = useRef(false); // Tracks whether the scroll has happened

  /*******************
   ***** EFFECTS *****
   *******************/

  // Max selectable period is 3 months
  useEffect(() => {
    const maxEndDate = moment.tz(periodPickerState.startDate, USER_TIMEZONE).add(3, 'month');

    setMaxFutureDate(moment.min(YESTERDAY, maxEndDate));
  }, [periodPickerState.startDate]);

  // Updates the columns when the selected dates change
  useEffect(() => {
    if (!selectedDates.length) {
      return;
    }

    setColumns(getColumns(selectedDates, currency, translatedStoreName));
  }, [selectedDates]);

  // Fetch FTP Statuses on stores and period change
  useEffect(() => {
    if (!selectedStores.length || !periodPickerState.startDate || !periodPickerState.endDate) {
      return;
    }

    (async () => {
      setIsLoading(true);

      try {
        const storeIds = selectedStores.map(({ id }) => id);
        const startDate = moment(periodPickerState.startDate).format(
          DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
        );
        const endDate = moment(periodPickerState.endDate).format(
          DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
        );

        const result = await cashierService.getFtpStatusByStoreIdsAndDate(
          storeIds,
          startDate,
          endDate,
        );

        // Format data for Datagrid: [{ id: storeId, storeName: storeName, date1: { status: status, turnover: turnover }, date2: { status: status, turnover: turnover }, ... }]
        const formattedResult = Object.entries(result).map(([storeId, ftpStatusByStore]) => {
          const associatedStore = selectedStores.find(({ id }) => id === storeId);

          if (!associatedStore) {
            return { id: storeId };
          }

          const storeName = associatedStore.name;

          return ftpStatusByStore.reduce(
            (acc, ftpStatus) => {
              acc[ftpStatus.date] = {
                status: ftpStatus.status,
                turnover: ftpStatus.turnover || null,
              };
              return acc;
            },
            {
              id: storeId,
              storeName,
              store: associatedStore,
            },
          );
        });

        setRows(formattedResult);
      } catch {
        showErrorMessage(i18next.t('ADMIN.FTP_DEBUG.FETCH_FTP_STATUS_ERROR'));

        setRows([]);
      } finally {
        setIsLoading(false);
      }
    })();

    autoScrollDoneRef.current = false;
  }, [selectedStores, periodPickerState.startDate, periodPickerState.endDate]);

  // Auto-scroll to the far right when the grid finished loading
  useEffect(() => {
    const handleRenderedRowsIntervalChange = () => {
      if (apiRef.current && columns.length && !autoScrollDoneRef.current) {
        // Scroll to the far right
        apiRef.current.scrollToIndexes({
          colIndex: columns.length - 1,
        });
        autoScrollDoneRef.current = true;
      }
    };

    // Ensure the event is set up when the grid has rendered rows
    // subscribeEvent() returns the cleanup callback
    return apiRef.current.subscribeEvent(
      'renderedRowsIntervalChange',
      handleRenderedRowsIntervalChange,
    );
  }, [apiRef, columns]);

  return (
    <GenericPageContainer>
      <NavigationBreadCrumb featurePath={match.path} />
      <GenericContentContainer>
        <LeftRightSplitter
          left={
            <GenericGapContainer>
              <Dropdown
                iconSrc="/images/inpulse/pin-black-small.svg"
                isDisabled={isLoading}
                isUniqueSelection={false}
                items={stores}
                searchPlaceholder={i18next.t('GENERAL.SEARCH')}
                selectedItems={selectedStores}
                isRequired
                onSelectionChange={setSelectedStores}
              />
              <PeriodDatePicker
                disabled={isLoading}
                endDate={periodPickerState.endDate}
                focusedDateInput={periodPickerState.focusedDateInput}
                maxFutureDate={
                  !!periodPickerState.startDate && !!periodPickerState.endDate
                    ? YESTERDAY
                    : maxFutureDate
                }
                setFocusedDateInput={periodPickerState.setFocusedDateInput}
                startDate={periodPickerState.startDate}
                timezone={USER_TIMEZONE}
                onDatesChange={periodPickerState.handleSelectedDates}
              />
            </GenericGapContainer>
          }
        />
        <DataGridPro
          apiRef={apiRef}
          columns={columns}
          loading={isLoading}
          localeText={
            DATAGRID_LOCALES[user.lnkLanguageAccountrel.code].components.MuiDataGrid.defaultProps
              .localeText
          }
          rows={rows}
          {...COMMON_DATAGRID_PROPS}
        />
      </GenericContentContainer>
    </GenericPageContainer>
  );
};

const mapStateToProps = (state) => ({
  stores: state.baseReducer.activeStores,
  currency: state.baseReducer.currency,
  user: state.baseReducer.user,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FtpDebug);

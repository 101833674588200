import styled, { css } from 'styled-components';

export const RoundPicture = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export const NameCellContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  gap: 8px;
`;

export const Name = styled.div`
  font: ${({ theme }) => theme.fonts.textMiddleNormal};
  color: ${({ theme }) => theme.colors.greys.darkest};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const NameHover = styled.div`
  background: ${({ theme }) => theme.colors.white};
  font: ${({ theme }) => theme.fonts.textMiddleNormal};
  position: absolute;
  padding: 8px 8px 8px 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);

  ${(props) => {
    let offset = 0;

    if (props.offsetName) {
      offset += 50; // name column includes pictures on the left of the name
    }

    if (props.offsetCategory) {
      offset += 200; // entire name column width
    }

    return css`
      left: ${offset}px;
    `;
  }}
`;

export const RowContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  justify-content: flex-start;
  align-items: center;
`;

export const RecipeBatchProductionRowsContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const Sku = styled.div`
  font: ${({ theme }) => theme.fonts.textSmallNormal};
  color: ${({ theme }) => theme.colors.greys.darker};
`;

export const tooltipTextsAnalyticsStore = {
  dateRangePicker: 'ORDERS.BY_CATEGORY.TOOLTIP_DATE_PICKER_RANGE',
  turnover: 'ORDERS.BY_CATEGORY.TOOLTIP_TURNOVER',
  order: 'ORDERS.BY_CATEGORY.TOOLTIP_ORDER',
  theoricalConsumption: 'ORDERS.BY_CATEGORY.TOOLTIP_THEORICAL_CONSUMPTION',
  theoricalConsumptionPercentage: 'ORDERS.BY_CATEGORY.TOOLTIP_THEORICAL_CONSUMPTION_PERCENTAGE',
  materialRatio: 'ORDERS.BY_CATEGORY.TOOLTIP_THEORICAL_CONSUMPTION_PERCENTAGE',
  realConsumption: 'ORDERS.BY_CATEGORY.STORE_TOOLTIP_REAL_CONSUMPTION',
  gapFromTheoretical: 'ANALYSIS.FOOD_COST.COLUMN_TOOLTIP_FC_DEVIATION',
  relativeRealFromTheorical: 'ANALYSIS.FOOD_COST.COLUMN_TOOLTIP_PERCENT_FC_DEVIATION',
  orderOverTurnoverRatio: 'ORDERS.BY_CATEGORY.STORE_TOOLTIP_ORDER_OVER_TURNOVER_RATIO',
  grossSalary: 'ANALYSIS.MARGIN.GROSS_SALARY_COLUMN_TOOLTIP',
  grossSalaryPercentage: 'ANALYSIS.MARGIN.GROSS_SALARY_OVER_TURNOVER_PERCENTAGE_COLUMN_TOOLTIP',
  grossMargin: 'ANALYSIS.MARGIN.GROSS_MARGIN_COLUMN_TOOLTIP',
  grossMarginOverTurnoverPercentage:
    'ANALYSIS.MARGIN.GROSS_MARGIN_OVER_TURNOVER_PERCENTAGE_COLUMN_TOOLTIP',
  wastes: 'ANALYSIS.FOOD_COST.COLUMN_TOOLTIP_WASTES',
  inventoryShrinkage: 'ANALYSIS.FOOD_COST.COLUMN_TOOLTIP_INVENTORY_SHRINKAGE',
};

import { groupBy } from 'lodash';

export const DEFAULT_TIMEZONE = 'Europe/Paris';

export const getStoresMostCommonTimezone = (stores) => {
  const storesGroupedByTimezone = groupBy(stores, 'timezone');

  const mostImportantTimezone = Object.entries(storesGroupedByTimezone).reduce(
    (acc, [timezone, stores]) => {
      if (stores.length > acc.nbStore) {
        acc.nbStore = stores.length;
        acc.timezone = timezone;
      }

      return acc;
    },
    { nbStore: 0, timezone: DEFAULT_TIMEZONE },
  );

  return mostImportantTimezone;
};

export default {
  getStoresMostCommonTimezone,
  DEFAULT_TIMEZONE,
};

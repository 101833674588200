import _ from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { flattenArray } from '@commons/utils/format';
import { getUserTimezone } from '@commons/utils/date';

import { PRODUCT_ORDER_TYPE } from '@orders/OrderList/constants';

import ORDER_STATUS, { ORDER_PREPARATION_STATUS } from '../constants/status';
import services from '../services';

import getOrderEmailBody from './getOrderEmailBody';
import getOrderInfos from './getOrderInfos';

const getFormattedOrderInfos = ({
  storeId,
  params,
  source,
  orderId,
  endDate,
  startDate,
  partnerId,
  supplierId,
  receptionComment,
  orderOpeningTime,
  productsInventory,
  receptionReference,
  purchaseOrderComment,
  emailRecipients,
  emailRecipientsCopy,
  storeTimezone,
  supplierInfos,
  isDuplicatingOrder,
}) => {
  const orderInfos = getOrderInfos({
    storeId,
    params,
    orderId: isDuplicatingOrder ? null : orderId,
    endDate,
    startDate,
    partnerId,
    supplierId,
    receptionComment,
    orderOpeningTime,
    productsInventory,
    receptionReference,
    savingType: 'saveOrder',
    isEditable: source !== 'send',
    status: source === 'send' ? ORDER_STATUS.SENT : ORDER_STATUS.DRAFT,
    preparationStatus:
      source === 'send' && supplierInfos.isKitchen ? ORDER_PREPARATION_STATUS.TO_BE_PREPARED : null,
    purchaseOrderComment,
    emailRecipients,
    emailRecipientsCopy,
    storeTimezone,
  });

  orderInfos.startOrderDate = moment(startDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
  orderInfos.endOrderDate = moment(endDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

  return orderInfos;
};

export const getFormattedProductOrders = (
  productsInventory,
  productOrders,
  source,
  orderId,
  isEditingPreparationOrder = false,
  isDuplicatingOrder = false,
) => {
  const orderableSupplierProducts = flattenArray(
    productsInventory.map((category) => category.products),
  );

  const newProductOrders = [];
  const existingProductOrders = [];

  orderableSupplierProducts.forEach((item) => {
    const supplierProductPackaging = item.packagings.find((packaging) => packaging.isUsedInOrder);

    const convertedPrice = (isEditingPreparationOrder ? item.priceBDL : item.price) || 0;

    let type = source === 'send' ? PRODUCT_ORDER_TYPE.ORDERED : PRODUCT_ORDER_TYPE.DRAFT;

    if (isEditingPreparationOrder) {
      type = PRODUCT_ORDER_TYPE.INVOICED;
    }

    const formattedProductOrder = {
      orderId: isDuplicatingOrder ? null : orderId,
      price: convertedPrice,
      quantity: isEditingPreparationOrder ? item.invoiced : item.ordered,
      supplierProductId: item.id,
      type,
      supplierProductPackagingId: isEditingPreparationOrder
        ? item.receptionPackagingId
        : supplierProductPackaging.id,
    };

    const existingProductOrder = isDuplicatingOrder
      ? null
      : productOrders.find((productOrder) => productOrder.supplierProductId === item.id);

    if (existingProductOrder) {
      existingProductOrders.push({
        ...formattedProductOrder,
        id: existingProductOrder.id,
        supplierProductPackagingId: isEditingPreparationOrder
          ? item.receptionPackagingId
          : existingProductOrder.supplierProductPackagingId,
      });
    } else if (item.ordered > 0) {
      newProductOrders.push(formattedProductOrder);
    }
  });

  return existingProductOrders.concat(newProductOrders);
};

const getSendingType = (source, sendingParams, supplierInfos) => {
  const shouldSendEmail =
    source === 'send' || (source === 'save' && _.get(sendingParams, 'edi.mode') === 'multiple');

  if (!shouldSendEmail) {
    return undefined;
  }

  if (source === 'send' && supplierInfos && (!supplierInfos.ediPartner || !supplierInfos.isEdi)) {
    return 'mail';
  }

  if (source === 'send' && supplierInfos && supplierInfos.ediPartner) {
    return 'partner';
  }

  return 'edi';
};

const getSendingParams = (
  sendingType,
  {
    cc,
    params,
    orderId,
    storeId,
    startDate,
    supplierId,
    storeInfos,
    sendingParams,
    clientName,
    emailRecipients,
    emailRecipientsCopy,
  },
) => {
  const userTimezone = getUserTimezone();

  if (sendingType === 'mail') {
    return {
      storeId,
      startDate,
      supplierId,
      cc:
        typeof emailRecipientsCopy === 'string'
          ? emailRecipientsCopy.split(',')
          : emailRecipientsCopy,
      userId: _.get(params, 'user.id'),
      userTimezone,
      to: typeof emailRecipients === 'string' ? emailRecipients : emailRecipients.join(','),
      clientId: _.get(params, 'user.clientId'),
      bcc: _.get(sendingParams, 'email.order.default_bcc'),
      body: getOrderEmailBody(
        _.get(sendingParams, 'email.order.default_body'),
        params.user,
        storeInfos,
        clientName,
      ),
    };
  }

  if (sendingType === 'partner') {
    return {
      orderId,
      storeId,
      supplierId,
      userId: _.get(params, 'user.id'),
      userTimezone,
    };
  }

  if (sendingType === 'edi') {
    return {
      orderId,
      storeId,
      startDate,
      supplierId,
      status: ORDER_STATUS.DRAFT,
      clientId: _.get(params, 'user.clientId'),
      userTimezone,
    };
  }

  return {};
};

const sendOrder = async (
  {
    cc,
    params,
    source,
    orderId,
    storeId,
    sending,
    endDate,
    startDate,
    partnerId,
    storeInfos,
    supplierId,
    setOrderId,
    productOrders,
    sendingParams,
    supplierInfos,
    receptionComment,
    orderOpeningTime,
    productsInventory,
    receptionReference,
    clientName,
    purchaseOrderComment,
    emailRecipients,
    emailRecipientsCopy,
    isEditingPreparationOrder,
    isDuplicatingOrder,
  },
  onlyFormatted = false,
) => {
  const order = getFormattedOrderInfos({
    storeId,
    params,
    source,
    orderId,
    endDate,
    startDate,
    partnerId,
    supplierId,
    receptionComment,
    orderOpeningTime,
    productsInventory,
    receptionReference,
    purchaseOrderComment,
    emailRecipients,
    emailRecipientsCopy,
    storeTimezone: storeInfos.timezone,
    supplierInfos,
    isDuplicatingOrder,
  });

  const formattedProductOrders = getFormattedProductOrders(
    productsInventory,
    productOrders,
    source,
    orderId,
    isEditingPreparationOrder,
    isDuplicatingOrder,
  );

  if (!!onlyFormatted) {
    return { formattedOrder: order, formattedProductOrders };
  }

  const sendingType = getSendingType(source, sendingParams, supplierInfos);

  if (sendingType === 'mail' && _.isEmpty(_.get(sendingParams, 'email.order'))) {
    throw { type: 'send', message: 'Configuration missing to send email' };
  }

  const formattedSendingParams = getSendingParams(sendingType, {
    cc,
    params,
    orderId,
    storeId,
    startDate,
    supplierId,
    storeInfos,
    supplierInfos,
    sendingParams,
    clientName,
    emailRecipients,
    emailRecipientsCopy,
  });

  const result = await services.patchOrder(
    order,
    formattedProductOrders,
    false,
    [],
    sendingType,
    formattedSendingParams,
  );

  if (setOrderId) {
    setOrderId(result.id);
  }

  return i18next.t(
    !sendingType ? 'ORDERS.ORDERS.FORM_SAVED' : 'ORDERS.ORDERS.FORM_SUBMITTED_SUCCESSFULLY',
  );
};

export default sendOrder;

import i18next from 'i18next';

/*
  Pending: The invoice store has been detected and can be imported
  Waiting: The invoice is waiting for store detection
*/
export const FILES_STATUS_CODES = {
  WAITING: 'WAITING',
  DETECTING_STORE: 'DETECTING_STORE',
  FAILED_DETECTION: 'FAILED_DETECTION',
  PENDING: 'PENDING',
  INSUFFICIENT_CREDITS: 'INSUFFICIENT_CREDITS',
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

// Sets first letter to uppercase and the rest to lowercase
const titleCase = (string) => string[0].toUpperCase() + string.slice(1).toLowerCase();

export const GET_FILES_STATUS = (storeName) => ({
  [FILES_STATUS_CODES.WAITING]: {
    name: i18next.t('ORDERS.ORDERS.FORM_INVOICE_MODAL_CONTROL_PART_WAITING'),
    color: 'orange',
  },
  [FILES_STATUS_CODES.DETECTING_STORE]: {
    // We need to use the titleCase function because depending on the language the store name can be at the beginning of the sentence or not
    name: titleCase(
      i18next.t('ORDERS.ORDERS.FORM_INVOICE_MODAL_CONTROL_PART_DETECTING_STORE', { storeName }),
    ),
    color: 'black',
  },
  [FILES_STATUS_CODES.FAILED_DETECTION]: {
    // We need to use the titleCase function because depending on the language the store name can be at the beginning of the sentence or not
    name: titleCase(
      i18next.t('ORDERS.ORDERS.FORM_INVOICE_MODAL_CONTROL_PART_FAILED_DETECTION', {
        storeName,
      }),
    ),
    color: 'red',
  },
  [FILES_STATUS_CODES.PENDING]: {
    name: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.FILE_STATUS_PENDING'),
    color: 'orange',
  },
  [FILES_STATUS_CODES.INSUFFICIENT_CREDITS]: {
    name: i18next.t('ORDERS.ORDERS.FORM_INVOICE_MODAL_CONTROL_PART_INSUFFICIENT_CREDITS'),
    color: 'red',
  },
  [FILES_STATUS_CODES.IN_PROGRESS]: {
    name: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.FILE_STATUS_IN_PROGRESS'),
    color: 'black',
  },
  [FILES_STATUS_CODES.SUCCESS]: {
    name: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.FILE_STATUS_SUCCESS'),
    color: 'green',
  },
  [FILES_STATUS_CODES.ERROR]: {
    name: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.FILE_STATUS_ERROR'),
    color: 'red',
  },
});

/**
 * Converts bytes to kilobytes or megabytes accordingly.
 * @param {number} bytes - The number of bytes to convert.
 * @returns {string} - The formatted string in kilobytes (Ko) or megabytes (Mo).
 */
export const formatBytes = (bytes) => {
  if (bytes >= 1000 && bytes <= 999999) {
    const kilobytes = bytes / 1024;
    return `${kilobytes.toFixed(2)} ${i18next.t('GENERAL.KILOBYTES')}`;
  } else if (bytes > 999999) {
    const megabytes = bytes / (1024 * 1024);
    return `${megabytes.toFixed(2)} ${i18next.t('GENERAL.MEGABYTES')}`;
  } else {
    return `${bytes} ${i18next.t('GENERAL.BYTES')}`;
  }
};

import { connect } from 'react-redux';
import { keyBy, orderBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

// Design
import { getListColumns } from './columns';
import { HeaderContainer, Text } from './styledComponents';

// Services
import { cashierProductService } from '@services/cashierProduct';

// Commons
import { CHOICES_DROPDOWN_ACTIVE, CHOICES_DROPDOWN_MAPPED } from '@admin/utils/DropdownItems';
import { Dropdown, INPUT_WIDTH, ListView } from '@commons/utils/styledLibraryComponents';
import { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';
import { getClientInfo } from '@selectors/client';
import { showErrorMessage } from '@actions/messageconfirmation';
import { STANDARD_LISTVIEW_PADDING } from '@commons/constants/listViewProps';
import EmptyState from '@commons/EmptyState';

const renderLastSync = (lastSync) => {
  if (!lastSync) {
    return null;
  }

  const now = moment();
  const formattedDate = moment(lastSync);

  const isToday = formattedDate.isSame(now, 'day');
  const isYesterday = formattedDate.isSame(now.subtract(1, 'day'), 'day');

  const date = formattedDate.format('DD/MM');
  const time = formattedDate.format('HH:mm');

  return (
    <Text grey>
      {isToday
        ? i18next.t('ADMIN.CASHIER_PRODUCTS.LAST_SYNC_TODAY', { time })
        : isYesterday
        ? i18next.t('ADMIN.CASHIER_PRODUCTS.LAST_SYNC_YESTERDAY', { time })
        : i18next.t('ADMIN.CASHIER_PRODUCTS.LAST_SYNC_DATE_PARAM', { date, time })}
    </Text>
  );
};

const StepCashierProductAssociation = (props) => {
  const {
    cashierProducts: selectedCashierProducts,
    onCashierProductsChange,
    cashierProductsClient,
    client: { lastSync, clientId },
    product,
    showErrorMessage,
  } = props;

  const [columns] = useState(getListColumns());
  const [formattedCashierProducts, setFormattedCashierProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const selectedCashierProductsKeyById = keyBy(selectedCashierProducts, 'id');
  const activeFilterKey = CHOICES_DROPDOWN_ACTIVE()[0];
  const unmappedFilterKey = CHOICES_DROPDOWN_MAPPED()[1];
  const displayEmptyState = !formattedCashierProducts.length && !isLoading;

  useEffect(() => {
    if (!cashierProductsClient.length) {
      // Display empty state
      setIsLoading(false);
      return;
    }

    (async () => {
      try {
        setIsLoading(true);

        const scores =
          await cashierProductService.getCashierProductsWithProductMappingScoresByProductName(
            product.name,
            cashierProductsClient.map(({ name }) => name),
            clientId,
          );

        const formattedCashierProducts = cashierProductsClient.map((cashierProduct) => ({
          ...cashierProduct,
          isRowSelected: !!selectedCashierProductsKeyById[cashierProduct.id],
          similarityScore: scores[cashierProduct.name],
        }));

        const sortedCashierProducts = orderBy(formattedCashierProducts, 'similarityScore', 'desc');

        setFormattedCashierProducts(sortedCashierProducts);
      } catch {
        showErrorMessage(i18next.t('BACKOFFICE.PRODUCTS.CASHIER_PRODUCTS_RETRIEVING_ERROR'));
      } finally {
        setIsLoading(false);
      }
    })();
  }, [cashierProductsClient]);

  return displayEmptyState ? (
    <EmptyState
      label={i18next.t('ADMIN.CASHIER_PRODUCTS.ACTION_MAPPING_AUTO_TO_PRODUCT_NOT_ELIGIBLE')}
      labelColor={ENUM_COLORS.IP_BLACK_1}
      labelFont={ENUM_FONTS.H2_INTER}
      subtitle={i18next.t('ADMIN.CASHIER_PRODUCTS.ACTION_MAPPING_AUTO_TO_PRODUCT_RELAUNCH_SYNC')}
      subtitleMargin={'8px'}
    />
  ) : (
    <ListView
      columns={columns}
      data={formattedCashierProducts}
      isLoading={isLoading}
      padding={STANDARD_LISTVIEW_PADDING}
      renderEmptyState={() => <EmptyState />}
      renderFilterButton={() => (
        <HeaderContainer>
          <Dropdown
            iconSrc={'/images/inpulse/location-dmgrey-small.svg'}
            selectedItem={activeFilterKey}
            width={INPUT_WIDTH.MEDIUM}
            isDisabled
          />
          <Dropdown
            iconSrc={'/images/inpulse/location-dmgrey-small.svg'}
            selectedItem={unmappedFilterKey}
            width={INPUT_WIDTH.MEDIUM}
            isDisabled
          />
          {renderLastSync(lastSync)}
        </HeaderContainer>
      )}
      setSelectedItems={onCashierProductsChange}
      disableResetSelectionOnNavigation
      forceEnableSelection
    />
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StepCashierProductAssociation);

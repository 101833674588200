import { connect } from 'react-redux';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import MomentPropTypes from 'react-moment-proptypes';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { buildArraySchema } from '@commons/GenericForm';
import { Button, Dropdown, INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';
import { CHANNELS, CHANNEL_ID_KEY_BY_NAME } from '@commons/constants/channel';
import {
  Container,
  FooterContainer,
  FooterRightContainer,
  HeaderContainer,
  Title,
  DropdownItem,
  DropdownItemImage,
  DropdownItemMainValue,
  DropdownItemSecondaryValue,
  DropdownItemValuesContainer,
} from '@commons/TableForm/TableFormInModal/styledComponents.js';
import { DATE_DISPLAY_FORMATS, PRODUCTS_CALENDAR_INFO } from '@commons/DatePickers/constants';
import {
  FullScreenModalContent,
  FullScreenModalContentInfos,
  FullScreenModalContentSelectors,
  FullScreenModalContentSelectorsAndInfos,
} from '@commons/FullScreenModal/Content';
import { FullScreenModalHeaderButtons } from '@commons/FullScreenModal/Header';
import { GenericModalContainer } from '@commons/Modals/GenericModal/styledComponents';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getIsCentralMode } from '@commons/utils/localStorage';
import { handleAppRefresh } from '@commons/utils/refreshApp';
import { PastDayDatePicker } from '@commons/DatePickers/PastDayDatePicker';
import { TableForm } from '@commons/TableForm';
import { TableFormFooter } from '@commons/TableForm/Footer';
import DisplayNumber from '@commons/DisplayNumber';
import GenericModal from '@commons/Modals/GenericModal';

import { getCentralKitchenStores, getSalesPointStores } from '@selectors/stores';
import { getClientInfo } from '@selectors/client';

import { getChannels } from '@services/channel';
import { lossService } from '@services/loss';
import { product as productService } from '@services/product';
import { storeSupplierProductMapping } from '@services/storeSupplierProductMapping';
import { supplier as supplierService } from '@services/supplier';
import centralService from '@services/central';
import recipeService from '@services/recipe';

import { convertSPPQuantityInMasterUnit } from '@orders/utils/computePackagingToSupplierProduct';

import {
  LOSS_TYPE,
  LOSS_TYPE_DROPDOWN_PLACEHOLDER,
  LOSS_TYPE_ITEM_PLACEHOLDER,
  LOSS_TYPE_TABLE_FORM_EMPTY_STATE,
} from '../LossListView/constants';

import { CHANGE_TYPES, getUpdateStoreWarningModal } from './utils/modalConfiguration';
import { getTableFormColumnsByType } from './utils/getColumns';
import { resolveLossRowInputs } from './utils/formInputsConfiguration';

const HEADER_TITLE = {
  [LOSS_TYPE.PRODUCT]: 'LOSSES.PRODUCTS.ADD_MODAL_TITLE',
  [LOSS_TYPE.RECIPE]: 'LOSSES.RECIPES.ADD_MODAL_TITLE',
  [LOSS_TYPE.SUPPLIER_PRODUCT]: 'LOSSES.SUPPLIER_PRODUCTS.ADD_MODAL_TITLE',
};

const MATCHING_ID_FIELD = {
  [LOSS_TYPE.PRODUCT]: '__Pid',
  [LOSS_TYPE.RECIPE]: '__Rid',
  [LOSS_TYPE.SUPPLIER_PRODUCT]: '__SPid',
};

const FIELD_ARRAY_NAME = 'losses';

const LossForm = (props) => {
  const {
    // state props
    stores,
    currency,
    client: { storeName, hasMultipleChannels, defaultChannelId, clientId },
    // dispatch props
    pageLoading,
    pageLoaded,
    showSuccessMessage,
    showErrorMessage,
    params: {
      initialStore,
      initialDate,
      currentDate,
      initialOldDatesWithLosses,
      initialRecentDatesWithLosses,
      handleCloseCleanUp,
      lossType,
      getDatesWithLossesForMonth,
    },
    closeModal,
    shouldReloadApp,
  } = props;

  // ==================
  // ===== States =====
  // ==================

  const getNoneDropdownValue = () => ({
    id: -1,
    name: i18next.t('GENERAL.NONE_VALUE'),
    value: i18next.t('GENERAL.NONE_VALUE'),
  });

  const [itemList, setItemList] = useState([]);

  const [selectedStore, setSelectedStore] = useState(initialStore);
  const [currentDateWithTz, setCurrentDateWithTz] = useState(currentDate);
  const [selectedDate, setSelectedDate] = useState(initialDate);

  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const [warningModalParams, setWarningModalParams] = useState(null);

  const [lossCategories, setLossCategories] = useState([]);

  const [focusedMonth, setFocusedMonth] = useState(initialDate);

  // Channels will be used only when reporting losses for Products
  const [channels, setChannels] = useState([]);

  const [entitiesChannelsCost, setEntitiesChannelsCost] = useState({});

  const tableColumns = getTableFormColumnsByType(lossType);

  const movableColumns = tableColumns.filter((column) => !column.isFixed);

  const lossFormInputs = movableColumns.map((column) => column.input);

  const [recentDatesWithLosses, setRecentDatesWithLosses] = useState(initialOldDatesWithLosses);

  // The list of dates on which losses have been reported more than 48h ago
  const [oldDatesWithLosses, setOldDatesWithLosses] = useState(initialRecentDatesWithLosses);

  const translatedStoreName = getClientStoreNameTranslation(storeName, false);

  const lossesForm = useForm({
    mode: 'all', // To make sure that validation is run on every action
    defaultValues: {
      [FIELD_ARRAY_NAME]: [],
    },
    resolver: yupResolver(buildArraySchema(FIELD_ARRAY_NAME, lossFormInputs)),
  });

  const { fields, prepend, remove } = useFieldArray({
    control: lossesForm.control,
    name: FIELD_ARRAY_NAME,
    defaultValues: {
      [FIELD_ARRAY_NAME]: [],
    },
  });

  const formFields = useWatch({
    name: FIELD_ARRAY_NAME,
    control: lossesForm.control,
  });

  // ======================
  // ===== UseEffects =====
  // ======================

  useEffect(() => {
    const {
      formState: { errors },
    } = lossesForm;

    // Verify if rows with visible errors are valid
    const hasErrors = formFields.some((field, index) => {
      const rowErrors = _.get(errors, `${FIELD_ARRAY_NAME}[${index}]`);
      return field.hasErrors && !_.isEmpty(rowErrors);
    });

    setIsSaveDisabled(hasErrors);
  }, [formFields]);

  useEffect(() => {
    (async () => {
      try {
        const allLossCategories = await lossService.getLossCategoriesByClientIdAndLossType(
          clientId,
          lossType,
        );

        const formattedLossCategories = allLossCategories.map((category) => ({
          ...category,
          value: i18next.t(category.translationKey),
        }));

        formattedLossCategories.unshift(getNoneDropdownValue());

        setLossCategories(formattedLossCategories);
      } catch {
        showErrorMessage(i18next.t('GENERAL.FETCH_LOSS_CATEGORIES_FAILURE'));
      }

      if (lossType === LOSS_TYPE.PRODUCT) {
        try {
          const allChannels = await getChannels();

          const channelsNameTranslations = {
            [CHANNEL_ID_KEY_BY_NAME[CHANNELS.ON_SITE]]: i18next.t('ADMIN.RECIPES.ONSITE'),
            [CHANNEL_ID_KEY_BY_NAME[CHANNELS.DELIVERY]]: i18next.t('ADMIN.RECIPES.DELIVERY'),
          };

          const allChannelsWithTranslationKey = allChannels.map((channel) => ({
            ...channel,
            name: channelsNameTranslations[channel.id],
          }));

          if (!hasMultipleChannels) {
            const defaultChannel = allChannelsWithTranslationKey.find(
              ({ id }) => id === defaultChannelId,
            );

            setChannels([defaultChannel]);

            return;
          }

          setChannels(allChannelsWithTranslationKey);
        } catch {
          showErrorMessage(i18next.t('GENERAL.FETCH_CHANNELS_FAILURE'));
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (lossType === LOSS_TYPE.SUPPLIER_PRODUCT) {
      fetchData();
    }

    if (!!selectedStore) {
      setCurrentDateWithTz(moment().tz(selectedStore.timezone));

      const selectedDateFormatted = moment(
        selectedDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      );

      // we use moment of another moment because we have to compare the day and we can't use
      // isAfter method (it doesn't takes in account properly the timezone)

      const dateTodayOfSelectedStore = moment(
        moment.tz(selectedStore.timezone).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      );

      if (selectedDateFormatted.isAfter(dateTodayOfSelectedStore)) {
        setSelectedDate(moment().tz(selectedStore.timezone));
      }
    }
  }, [selectedStore, selectedDate]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!!selectedStore) {
      (async function fetchDates() {
        const dates = await getDatesWithLossesForMonth(focusedMonth, selectedStore, false);

        setOldDatesWithLosses(_.get(dates, 'oldDatesWithLosses', []));
        setRecentDatesWithLosses(_.get(dates, 'recentDatesWithLosses', []));
      })();
    }
  }, [focusedMonth, selectedStore, selectedDate]);

  // ====================
  // ===== Methods ======
  // ====================

  const fetchData = async () => {
    pageLoading();

    switch (lossType) {
      case LOSS_TYPE.PRODUCT:
        await fetchProducts();
        break;

      case LOSS_TYPE.RECIPE:
        await fetchRecipes();
        break;

      case LOSS_TYPE.SUPPLIER_PRODUCT:
        await fetchSupplierProducts();
        break;

      default:
        break;
    }

    pageLoaded();
  };

  const fetchProducts = async () => {
    try {
      const products = await productService.getProductsOfClient(clientId);

      const activeProducts = products.filter(
        ({ active, lnkEntityProductrel }) =>
          active && !!lnkEntityProductrel && !lnkEntityProductrel.isIngredient,
      );

      const formattedProducts = formatProducts(activeProducts);

      setItemList(formattedProducts);
    } catch {
      showErrorMessage(i18next.t('GENERAL.FETCH_CLIENT_PRODUCTS_FAILURE'));
    }
  };

  const fetchSupplierProducts = async () => {
    try {
      const storeSupplierProductMappings =
        await storeSupplierProductMapping.getStoreSupplierProductMappingsOfStore(selectedStore.id);
      const suppliersOfStore = await supplierService.getSuppliersOfStore(selectedStore.id);

      if (_.isEmpty(storeSupplierProductMappings)) {
        return;
      }

      const filteredStoreSupplierProductMappings = storeSupplierProductMappings.filter(
        (storeSupplierProductMapping) =>
          storeSupplierProductMapping.hasDlc &&
          _.get(
            storeSupplierProductMapping,
            'lnkSupplierproductStoresupplierproductmappingrel.active',
            false,
          ),
      );

      const formattedSPs = formatMappingsIntoSupplierProducts(
        filteredStoreSupplierProductMappings,
        suppliersOfStore,
      );

      setItemList(formattedSPs);
    } catch {
      showErrorMessage(i18next.t('GENERAL.FETCH_SUPPLIER_PRODUCTS_FAILURE'));
    }
  };

  const fetchRecipes = async () => {
    try {
      const recipes = await centralService.getKitchenRecipesOfClient(clientId, true);

      if (!recipes.length) {
        return;
      }

      const activeRecipes = recipes.filter(({ active }) => active);

      const formattedRecipes = formatRecipes(activeRecipes);

      setItemList(formattedRecipes);
    } catch {
      showErrorMessage(i18next.t('LOSSES.RECIPES.FETCH_RECIPES_FAILURE'));
    }
  };

  const getPriceFieldPath = (field) => {
    switch (lossType) {
      case LOSS_TYPE.PRODUCT:
        return `costByChannelId[${field.channel.id}]`;

      case LOSS_TYPE.RECIPE:
        return `cost`;

      case LOSS_TYPE.SUPPLIER_PRODUCT:
        return `priceByPackagingId[${field.packaging.id}]`;

      default:
        return;
    }
  };

  const computeTotalHT = () => {
    if (!formFields.length) {
      return `- ${currency.alphabeticCode}`;
    }

    const totalHt = formFields.reduce((total, field) => {
      const priceFieldPath = getPriceFieldPath(field);

      if (field.losses > 0 && _.get(field, priceFieldPath)) {
        total += _.get(field, priceFieldPath) * field.losses;
      }

      return total;
    }, 0);

    return <DisplayNumber displayCurrencyCode={true} number={totalHt} />;
  };

  const formatMappingsIntoSupplierProducts = (storeSupplierProductMappings, suppliersOfStore) => {
    const formattedSupplierProducts = storeSupplierProductMappings.map((mapping) => {
      const supplierProductRel = mapping.lnkSupplierproductStoresupplierproductmappingrel;

      const supplierKeyedById = _.keyBy(suppliersOfStore, 'id');
      const supplierName = supplierKeyedById[supplierProductRel.supplierId]
        ? supplierKeyedById[supplierProductRel.supplierId].name
        : '';

      const invoicePackaging = supplierProductRel.packagings.find(
        ({ isUsedInInvoice }) => !!isUsedInInvoice,
      );

      const priceByPackagingId = supplierProductRel.packagings.reduce((result, packaging) => {
        if (result[packaging.id] === undefined) {
          const computedPrice =
            supplierProductRel.price /
            convertSPPQuantityInMasterUnit(invoicePackaging.id, supplierProductRel.packagings);

          const convertedQuantity = convertSPPQuantityInMasterUnit(
            packaging.id,
            supplierProductRel.packagings,
          );

          result[packaging.id] = computedPrice * convertedQuantity;
        }

        return result;
      }, {});

      return {
        __SPid: supplierProductRel.id, // avoid override by react-hook-form which adds its own 'id' to track form fields
        name: supplierProductRel.name,
        img: supplierProductRel.img,
        packagings: supplierProductRel.packagings.filter((packaging) => packaging.isUsedInStock),
        price: supplierProductRel.price,
        sku: supplierProductRel.sku,
        supplierName,
        priceByPackagingId,
        renderValue: () => (
          <DropdownItem>
            <DropdownItemImage
              src={
                supplierProductRel.img
                  ? supplierProductRel.img
                  : LOSS_TYPE_ITEM_PLACEHOLDER[LOSS_TYPE.SUPPLIER_PRODUCT]
              }
            ></DropdownItemImage>
            <DropdownItemValuesContainer>
              <DropdownItemMainValue>{supplierProductRel.name}</DropdownItemMainValue>
              <DropdownItemSecondaryValue>{supplierName}</DropdownItemSecondaryValue>
            </DropdownItemValuesContainer>
          </DropdownItem>
        ),
      };
    });

    return formattedSupplierProducts;
  };

  const formatProducts = (products) => {
    const formattedProducts = products.map((product) => ({
      __Pid: product.id, // avoid override by react-hook-form which adds its own 'id' to track form fields
      name: product.name,
      img: product.img,
      costByChannelId: {},
      renderValue: () => (
        <DropdownItem>
          <DropdownItemImage
            src={product.img ? product.img : LOSS_TYPE_ITEM_PLACEHOLDER[LOSS_TYPE.PRODUCT]}
          ></DropdownItemImage>
          <DropdownItemValuesContainer>
            <DropdownItemMainValue>{product.name}</DropdownItemMainValue>
            <DropdownItemSecondaryValue>{product.category}</DropdownItemSecondaryValue>
          </DropdownItemValuesContainer>
        </DropdownItem>
      ),
    }));

    return formattedProducts;
  };

  const formatRecipes = (recipes) => {
    const formattedRecipes = recipes.map((recipe) => ({
      __Rid: recipe.id,
      name: recipe.name,
      img: recipe.img,
      unit: recipe.unit,
      quantity: recipe.quantity,
      cost: recipe.cost,
      renderValue: () => (
        <DropdownItem>
          <DropdownItemImage
            src={recipe.img || LOSS_TYPE_ITEM_PLACEHOLDER[LOSS_TYPE.RECIPE]}
          ></DropdownItemImage>
          <DropdownItemValuesContainer>
            <DropdownItemMainValue>{recipe.name}</DropdownItemMainValue>
            <DropdownItemSecondaryValue>{recipe.category}</DropdownItemSecondaryValue>
          </DropdownItemValuesContainer>
        </DropdownItem>
      ),
    }));

    return formattedRecipes;
  };

  const deleteLoss = (index) => {
    remove(index);
  };

  const _addCostByChannelToItem = async (item) => {
    if (lossType !== LOSS_TYPE.PRODUCT) {
      return item;
    }

    if (entitiesChannelsCost[item.__Pid]) {
      return { ...item, costByChannelId: entitiesChannelsCost[item.__Pid] };
    }

    prepend({
      ...item,
      lossCategoryValues: lossCategories,
      channelValues: channels,
      isLoading: true,
    });

    try {
      const mappings = await productService.getProductMappings(item.__Pid);

      const costByChannelId = {};

      for (const mapping of mappings) {
        const entity = mapping.entity;

        const entityCost = await recipeService.getRecipeCost(entity.id, { withAllergens: false });

        costByChannelId[mapping.channelId] = entityCost.costByChannelId[mapping.channelId];
      }

      setEntitiesChannelsCost({ ...entitiesChannelsCost, [item.__Pid]: costByChannelId });

      return { ...item, costByChannelId };
    } catch {
      showErrorMessage(i18next.t('LOSSES.PRODUCTS.RETRIEVE_COSTS_FAILURE'));
    } finally {
      remove(0);
    }
  };

  const _getMatchingFieldsFromItem = (item) => {
    const matchingPropertyName = MATCHING_ID_FIELD[lossType];

    const formValues = lossesForm.getValues(FIELD_ARRAY_NAME);

    return formValues.filter((formInput) => {
      const itemMatchingField = _.get(item, `[${matchingPropertyName}]`, null);
      const formInputMatchingField = _.get(formInput, `[${matchingPropertyName}]`, null);

      if (itemMatchingField !== null && formInputMatchingField !== null) {
        return itemMatchingField === formInputMatchingField;
      }

      return false;
    });
  };

  const _getAvailableChannelToItem = (item) => {
    const matchingFieldsOnCurrentEntity = _getMatchingFieldsFromItem(item);

    return channels.find(({ id }) => {
      const fieldsOnSameChannelCount = matchingFieldsOnCurrentEntity.filter(
        ({ channel }) => channel && channel.id === id,
      ).length;

      if (lossType === LOSS_TYPE.SUPPLIER_PRODUCT) {
        return fieldsOnSameChannelCount < lossCategories.length;
      }

      return fieldsOnSameChannelCount < lossCategories.length && item.costByChannelId[id] != null;
    });
  };

  const _getAvailablePackagingToItem = (item) => {
    const matchingFieldsOnCurrentEntity = _getMatchingFieldsFromItem(item);

    return item.packagings.find(({ id }) => {
      const fieldsOnSamePackagingCount = matchingFieldsOnCurrentEntity.filter(
        ({ packaging }) => packaging && packaging.id === id,
      ).length;

      return fieldsOnSamePackagingCount < lossCategories.length;
    });
  };

  const _getAvailableCategoryToItem = (item) => {
    const matchingFieldsOnCurrentEntity = _getMatchingFieldsFromItem(item);

    if (lossType === LOSS_TYPE.RECIPE) {
      return lossCategories.find(
        (lossCategory) =>
          !matchingFieldsOnCurrentEntity.some(
            (matchingItem) => _.get(matchingItem.category, 'id', null) === lossCategory.id,
          ),
      );
    }

    const matchingPropertyName = lossType === LOSS_TYPE.SUPPLIER_PRODUCT ? 'packaging' : 'channel';

    const matchingFieldsOnCurrentPackagingOrChannel = matchingFieldsOnCurrentEntity.filter(
      ({ [matchingPropertyName]: property }) =>
        property && item[matchingPropertyName] && property.id === item[matchingPropertyName].id,
    );

    return lossCategories.find((lossCategory) =>
      matchingFieldsOnCurrentPackagingOrChannel.every(
        (matchingItem) => _.get(matchingItem.category, 'id', null) !== lossCategory.id,
      ),
    );
  };

  const addLoss = async (item) => {
    const formattedItem = await _addCostByChannelToItem(item);

    if (!formattedItem) {
      return;
    }

    if (lossType === LOSS_TYPE.SUPPLIER_PRODUCT) {
      formattedItem.packaging = _getAvailablePackagingToItem(formattedItem);
    } else if (lossType === LOSS_TYPE.PRODUCT) {
      formattedItem.channel = _getAvailableChannelToItem(formattedItem);
    }

    prepend({
      ...formattedItem,
      channelValues: channels,
      lossCategoryValues: lossCategories,
      category: _getAvailableCategoryToItem(formattedItem),
    });

    lossesForm.trigger(FIELD_ARRAY_NAME); // Force validate added item to render proper icon status

    if (isSaveDisabled) {
      setIsSaveDisabled(false);
    }
  };

  const deleteAllLosses = () => {
    remove();
  };

  const submit = async () => {
    pageLoading();

    try {
      const storeId = selectedStore.id;
      const date = moment
        .tz(selectedDate, selectedStore.timezone)
        .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

      const lossesPayload = formFields.map((field) => {
        const selectedCategoryId = _.get(field, 'category.id');

        const item = {
          timestamp: date,
          quantity: field.losses,
          lossCategoryId:
            !selectedCategoryId || selectedCategoryId === getNoneDropdownValue().id
              ? null
              : selectedCategoryId,
        };

        switch (lossType) {
          case LOSS_TYPE.PRODUCT:
            item.productId = field.__Pid;
            item.channelId = _.get(field, 'channel.id');
            item.price = field.costByChannelId[item.channelId];
            break;

          case LOSS_TYPE.SUPPLIER_PRODUCT:
            item.supplierProductId = field.__SPid;
            item.supplierProductPackagingId = _.get(field, 'packaging.id');
            item.price = field.priceByPackagingId[item.supplierProductPackagingId];
            break;

          case LOSS_TYPE.RECIPE:
            item.entityId = field.__Rid;
            item.price = field.cost;
            break;

          default:
            break;
        }

        return item;
      });

      await lossService.createLosses(lossType, storeId, date, lossesPayload);

      showSuccessMessage(i18next.t('LOSSES.PRODUCTS.CREATION_SUCCESS'));

      if (!!handleCloseCleanUp) {
        handleCloseCleanUp(selectedStore, selectedDate);
      }

      closeModal();
      handleAppRefresh(shouldReloadApp);
    } catch {
      showErrorMessage(i18next.t('LOSSES.PRODUCTS.CREATION_FAILURE'));
    } finally {
      pageLoaded();
    }
  };

  const validateForm = () => {
    const errors = lossesForm.formState.errors;

    if (!_.isEmpty(errors)) {
      errors[FIELD_ARRAY_NAME].forEach((_, index) => {
        const path = `${FIELD_ARRAY_NAME}[${index}]`;

        lossesForm.setValue(path, {
          ...lossesForm.getValues(path),
          hasErrors: true, // Allows to keep track of fields on which validation has already been run
        });
      });

      setIsSaveDisabled(true);

      return;
    }

    lossesForm.handleSubmit(submit)();
  };

  const handleSelectedDateChange = (date) => {
    if (moment(date).isSame(selectedDate, 'day')) {
      return;
    }

    if (!formFields || _.isEmpty(formFields)) {
      setSelectedDate(date);
      return;
    }

    setWarningModalParams(
      getUpdateStoreWarningModal(
        translatedStoreName,
        date,
        setSelectedDate,
        deleteAllLosses,
        CHANGE_TYPES.DATE,
      ),
    );
  };

  const getTableFormDropdownItems = () =>
    itemList.map((item) => {
      const matchingFieldsOnCurrentEntity = formFields.filter((field) => {
        switch (lossType) {
          case LOSS_TYPE.PRODUCT:
            return field.__Pid === item.__Pid;

          case LOSS_TYPE.SUPPLIER_PRODUCT:
            return field.__SPid === item.__SPid;

          case LOSS_TYPE.RECIPE:
            return field.__Rid === item.__Rid;

          default:
            return;
        }
      });

      switch (lossType) {
        case LOSS_TYPE.PRODUCT:
          // Disable item when all unicity possibility have been used in the form based on lossCategory and Channels
          const nbChannels = matchingFieldsOnCurrentEntity.length
            ? Object.keys(matchingFieldsOnCurrentEntity[0].costByChannelId).length
            : channels.length;
          return {
            ...item,
            isDisabled: matchingFieldsOnCurrentEntity.length === nbChannels * lossCategories.length,
          };

        case LOSS_TYPE.SUPPLIER_PRODUCT:
          // Disable item when all unicity possibility have been used in the form based on lossCategory and Packagings
          return {
            ...item,
            isDisabled:
              matchingFieldsOnCurrentEntity.length ===
              item.packagings.length * lossCategories.length,
          };

        case LOSS_TYPE.RECIPE:
          return {
            ...item,
            isDisabled: matchingFieldsOnCurrentEntity.length === lossCategories.length,
          };

        default:
          return;
      }
    });

  const renderTableFormActions = () => (
    <TableFormFooter>
      <FooterContainer>
        <Dropdown
          iconSrc={'/images/inpulse/search-dmgrey-small.svg'}
          items={getTableFormDropdownItems()}
          placeholder={i18next.t(LOSS_TYPE_DROPDOWN_PLACEHOLDER[lossType])}
          selectedItem={null}
          width={INPUT_WIDTH.EXTRA_LARGE}
          onSelectionChange={addLoss}
        />
        <FooterRightContainer isDisabled={formFields.length < 1} onClick={deleteAllLosses}>
          {i18next.t('GENERAL.FORM_DELETE_LINES')}
        </FooterRightContainer>
      </FooterContainer>
    </TableFormFooter>
  );

  return (
    <Container>
      <HeaderContainer>
        <Title>{i18next.t(HEADER_TITLE[lossType])}</Title>
        <FullScreenModalHeaderButtons>
          <Button
            color={'inpulse-grey'}
            handleClick={() => {
              if (!!handleCloseCleanUp) {
                handleCloseCleanUp(selectedStore, selectedDate);
              }
              closeModal();
            }}
            icon={'/images/inpulse/close-white-small.svg'}
          />

          <Button
            color={'inpulse-default'}
            handleClick={validateForm}
            icon={'/images/inpulse/save-white-small.svg'}
            isDisabled={isSaveDisabled}
          />
        </FullScreenModalHeaderButtons>
      </HeaderContainer>
      <FullScreenModalContent headerHeight={'64px'}>
        <FullScreenModalContentSelectorsAndInfos>
          <FullScreenModalContentSelectors>
            <Dropdown
              iconSrc={'/images/inpulse/store-black-small.svg'}
              isRequired={true}
              isUniqueSelection={true}
              items={stores}
              selectedItem={selectedStore}
              onSelectionChange={(newSelectedStore) => {
                if (newSelectedStore.id === selectedStore.id) {
                  return;
                }

                if (!formFields || _.isEmpty(formFields)) {
                  setSelectedStore(newSelectedStore);
                  return;
                }

                setWarningModalParams(
                  getUpdateStoreWarningModal(
                    translatedStoreName,
                    newSelectedStore,
                    setSelectedStore,
                    deleteAllLosses,
                    CHANGE_TYPES.STORE,
                  ),
                );
              }}
            />
            <PastDayDatePicker
              authorizedDates={recentDatesWithLosses}
              calendarInfo={PRODUCTS_CALENDAR_INFO()}
              date={selectedDate}
              forbiddenDates={oldDatesWithLosses}
              maxFutureDate={currentDateWithTz}
              timezone={selectedStore.timezone}
              onDateChange={handleSelectedDateChange}
              onNextMonthClick={(nextMonth) => setFocusedMonth(nextMonth)}
              onPrevMonthClick={(prevMonth) => setFocusedMonth(prevMonth)}
            />
          </FullScreenModalContentSelectors>
          <FullScreenModalContentInfos
            text={computeTotalHT()}
            title={i18next.t('GENERAL.TOTAL_EX_TAX')}
          />
        </FullScreenModalContentSelectorsAndInfos>
        <TableForm
          columns={tableColumns}
          deleteFunction={deleteLoss}
          emptyState={{
            text: i18next.t(LOSS_TYPE_TABLE_FORM_EMPTY_STATE[lossType]),
            width: INPUT_WIDTH.LARGE,
          }}
          fieldArrayName={FIELD_ARRAY_NAME}
          fields={fields}
          form={lossesForm}
          inputs={lossFormInputs}
          isEditionAllowed={true}
          renderActions={renderTableFormActions}
          resolveInputs={(currentField) =>
            resolveLossRowInputs(
              currentField,
              lossFormInputs,
              lossType,
              formFields,
              hasMultipleChannels,
            )
          }
        />
        {warningModalParams && (
          <GenericModalContainer>
            <GenericModal
              closeGenericModal={() => setWarningModalParams(null)}
              component={warningModalParams.component}
              params={warningModalParams}
            />
          </GenericModalContainer>
        )}
      </FullScreenModalContent>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  stores: getIsCentralMode()
    ? getCentralKitchenStores(state.baseReducer.activeStores)
    : getSalesPointStores(state.baseReducer.activeStores),
  client: getClientInfo(state.baseReducer.user),
  currency: state.baseReducer.currency,
  shouldReloadApp: state.baseReducer.shouldReloadApp,
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

LossForm.propTypes = {
  initialStore: PropTypes.shape(), // store object (sended by LossListView)
  initialDate: MomentPropTypes.momentObj, // initial value of selectedDate (sended by LossListView)
  initialOldDatesWithLosses: MomentPropTypes.momentObj, // initial dates to be unauthorized to create Loss because already have one loss created more than 48 hours ago (red dot and not clickable in Datepicker) (sended by LossListView)
  initialRecentDatesWithLosses: MomentPropTypes.momentObj, // initial dates to be authorized to create Loss because already have one loss created more than 48 hours ago (green dot in Datepicker) (sended by LossListView)
  lossType: PropTypes.oneOf(Object.values(LOSS_TYPE)), // type of loss (sended by LossListView)
};

export default connect(mapStateToProps, mapDispatchToProps)(LossForm);

import { connect } from 'react-redux';
import { get, isEmpty, isEqual, keyBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { closeGenericModal, openGenericModal, refreshGenericModal } from '@actions/modal';

import { isUserAllowedToAccessProduction } from '@commons/utils/features';

import { product as productService } from '@services/product';

import { CHOICES_DROPDOWN_ACTIVE } from '@admin/utils/DropdownItems';

import {
  CHOICES_DROPDOWN_HIDDEN,
  CHOICES_DROPDOWN_MAPPED,
} from './stepsModals/SelectCashierProductsModal';
import { ENABLE_PRODUCT_MODAL_STEPS, getEnableProductModalConfig } from './modalConfiguration';

/**
 * This component does not directly render anything. Instead it makes use of the GenericModal via
 * the redux actions, manages their respective states and takes care of the SP activation logic.
 *
 * @param {*} props
 */
const EnableProductModal = (props) => {
  const {
    modal,
    product,
    resetEnablingProduct,
    handleCallToEnableProduct,
    openGenericModal,
    refreshGenericModal,
    closeGenericModal,
    hasUserAccessToProductionFeature,
  } = props;

  const canAccessProductionPlanning = hasUserAccessToProductionFeature;

  const [currentStep, setCurrentStep] = useState(
    ENABLE_PRODUCT_MODAL_STEPS.ASK_PRODUCT_CASHIER_PRODUCT_MAPPING,
  );
  const [includeCashierProductMappingStep, setIncludeCashierProductMappingStep] = useState(true);
  const [includeProductionPlanningStep, setIncludeProductionPlanningStep] = useState(true);

  const [selectCashierProductsData, setSelectCashierProductsData] = useState({
    statusDropdownSelectedItems: [CHOICES_DROPDOWN_ACTIVE()[0]],
    associatedDropdownSelectedItems: [CHOICES_DROPDOWN_MAPPED[1]],
    includedDropdownSelectedItems: [CHOICES_DROPDOWN_HIDDEN[1]],
    selectedCashierProductIds: [],
  });
  const [selectStoresData, setSelectStoresData] = useState([]);
  const [configureProductionData, setConfigureProductionData] = useState({
    price: product.price,
    launchDate: moment(),
    endDate: null,
    hasStock: true,
  });

  const resetModal = () => {
    resetEnablingProduct();
    closeGenericModal();
  };

  /**
   * Passed to setSelectedItems prop to the ListView managing the Stores
   * in order to properly navigate between the modal pages and keep the selection
   * */
  const handleSelectStores = (selectedStores) => {
    const selectedStoresByIds = keyBy(selectedStores, 'id');

    const formattedStores = selectStoresData.map((store) => {
      if (selectedStoresByIds[store.id]) {
        return { ...store, isRowSelected: true };
      }
      return { ...store, isRowSelected: false };
    });

    if (!isEqual(selectStoresData, formattedStores)) {
      setSelectStoresData(formattedStores);
    }
  };

  useEffect(() => {
    if (!currentStep) {
      return;
    }

    const isModalOpened = get(modal, 'GenericModalBool', false);
    const params = getEnableProductModalConfig({
      currentStep,
      setCurrentStep,
      resetModal,
      product,
      handleCallToEnableProduct,
      includeCashierProductMappingStep,
      setIncludeCashierProductMappingStep,
      selectCashierProductsData,
      setSelectCashierProductsData,
      includeProductionPlanningStep,
      setIncludeProductionPlanningStep,
      selectStoresData,
      setSelectStoresData,
      configureProductionData,
      setConfigureProductionData,
      handleSelectStores,
      canAccessProductionPlanning,
    });

    if (isModalOpened) {
      refreshGenericModal(params);
      return;
    }

    openGenericModal(params);
  }, [
    currentStep,
    includeCashierProductMappingStep,
    includeProductionPlanningStep,
    selectCashierProductsData,
    selectStoresData,
    configureProductionData,
  ]);

  return <div />;
};

/**
 * Makes the actual API call to enable the product
 * @param {*} formattedData Result from formatDataForApiCall in EnableProductModal/modalConfiguration.js
 */
export const enableProductMapCashierProductsAndAddProductionParameters = async (formattedData) => {
  const { product, cashierProductIds, storeIds, price, hasStock, launchDate, endDate } =
    formattedData;

  try {
    await productService.enableProduct(product.id);
  } catch (err) {
    throw new Error(i18next.t('ADMIN.PRODUCTS.ENABLE_PRODUCT_FAILURE_STEP_ENABLE'));
  }

  if (!isEmpty(storeIds)) {
    try {
      await productService.saveProductWithProductionParameters(
        product,
        storeIds,
        price,
        hasStock,
        launchDate,
        endDate,
      );
    } catch (err) {
      throw new Error(i18next.t('ADMIN.PRODUCTS.ENABLE_PRODUCT_FAILURE_STEP_PRODUCTION_CONFIG'));
    }
  }

  if (!isEmpty(cashierProductIds)) {
    try {
      await productService.associateCashierProductsToProduct(product.id, cashierProductIds);
    } catch (err) {
      throw new Error(i18next.t('ADMIN.PRODUCTS.ENABLE_PRODUCT_FAILURE_STEP_CASHIER_PRODUCTS'));
    }
  }
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  modal: state.modalReducer,
  hasUserAccessToProductionFeature: isUserAllowedToAccessProduction(state.baseReducer.userRights),
});

const mapDispatchToProps = (dispatch) => ({
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  closeGenericModal: () => {
    dispatch(closeGenericModal());
  },
});

EnableProductModal.propTypes = {
  product: PropTypes.object, // passed by Product.js
  resetEnablingProduct: PropTypes.func, // passed by Product.js, to reset its state
  handleCallToEnableProduct: PropTypes.func, // passed by Product.js
  openGenericModal: PropTypes.func, // passed by redux
  refreshGenericModal: PropTypes.func, // passed by redux
};

export default connect(mapStateToProps, mapDispatchToProps)(EnableProductModal);

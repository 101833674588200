import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: ${({ customWidth }) => (customWidth ? `${customWidth}px` : '100%')};
  height: 'auto';

  padding: 12px 15px 12px 15px;

  border-radius: 8px;
  background: ${(props) =>
    props.readOnly ? props.theme.colors?.greys?.light : props.theme.colors?.greys?.lightest};
  border: ${(props) =>
    props.error
      ? `1px solid ${props.theme.colors?.infoRed}`
      : `1px solid ${props.theme.colors?.greys?.light}`};

  row-gap: 8px;
  column-gap: 8px;
  display: flex;
  flex-wrap: wrap;
`;

export const Box = styled.div`
  width: 64px;
  height: 64px;

  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors?.greys?.light};

  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${(props) => props.url});
  background-size: ${(props) => (props.full ? 'cover' : 'auto')};
  background-color: ${(props) => props.theme.colors?.greys?.lighter};

  &:hover {
    cursor: pointer;
  }

  > input {
    width: inherit;
    height: inherit;

    &:hover {
      cursor: pointer;
    }
    &::-webkit-file-upload-button {
      cursor: pointer;
    }

    opacity: 0;
  }

  ${(props) => {
    if (props.disabled) {
      return css`
        background-color: ${(props) => props.theme.colors?.greys?.light};

        &:hover {
          cursor: not-allowed;
        }

        & > input:hover {
          cursor: not-allowed;
        }
      `;
    }
  }}

  ${(props) => {
    if (props.fileInput) {
      return css`
        width: 40px;
        height: 40px;

        border-radius: 50%;

        border: none;
        background-color: ${(props) =>
          props.disabled ? 'none' : props.theme.colors?.greys?.darkest};

        &:hover {
          background-color: ${(props) => (props.disabled ? 'none' : props.theme.colors?.infoGreen)};
        }

        &:active {
          background-color: ${(props) =>
            props.disabled ? 'none' : props.theme.colors?.infoDarkGreen};
        }
      `;
    }
  }}
`;

export const IconContainer = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ActionsContainer = styled.div`
  width: 240px;

  margin-top: 8px;
  margin-left: -1px;
  position: relative;

  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  background: ${(props) => props.theme.colors?.greys?.lightest};
  z-index: 16;
`;

export const OpaqueBackgroundHover = styled.div`
  z-index: 1;

  width: 63px;
  height: 63px;

  border-radius: 8px;
  background-color: #1d1d1b40;

  opacity: ${(props) => (props.isOpaque ? '1' : '0')};

  &:hover {
    opacity: 1;
  }
`;

export const Action = styled.div`
  width: 100%;

  padding: 12px;

  display: flex;
  align-items: center;

  background: ${(props) => props.theme.colors?.greys?.lightest};

  &:first-child {
    border-radius: 8px 8px 0px 0px;
  }

  &:last-child {
    border-radius: 0px 0px 8px 8px;
  }

  &:hover {
    cursor: ${(props) => (props.readOnly ? 'not-allowed' : 'pointer')};

    background: ${(props) => props.theme.colors?.greys?.lighter};
  }
  z-index: 16;
`;

export const Icon = styled.img`
  margin-right: 12px;
`;

export const Text = styled.div`
  font: ${(props) =>
    props.textSmall ? props.theme.fonts?.textSmall : props.theme.fonts?.textBigHeight16};
  color: ${(props) =>
    props.grey
      ? props.theme.colors?.greys?.light
      : props.red
      ? props.theme.colors?.infoRed
      : props.theme.colors?.greys?.darkest};
`;

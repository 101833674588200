import i18next from 'i18next';

import ConfirmationModal from '../../components/ConfirmationModal';

export const getMarkAsSentConfirmationModalParams = ({
  markOrdersAsSent,
  ordersSelection,
  suppliers,
}) => ({
  type: 'warning',
  width: '542px',
  height: 'auto',
  icon: '/images/inpulse/pen-white-small.svg',
  title: i18next.t(
    ordersSelection.length > 1
      ? 'ORDERS.ORDERS.MODAL_CHANGE_STATUS_TO_SENT_TITLE_PLURAL'
      : 'ORDERS.ORDERS.MODAL_CHANGE_STATUS_TO_SENT_TITLE',
  ),
  component: ConfirmationModal,
  data: {
    content: i18next.t(
      ordersSelection.length > 1
        ? 'ORDERS.ORDERS.MODAL_CHANGE_STATUS_TO_SENT_TEXT_PLURAL'
        : 'ORDERS.ORDERS.MODAL_CHANGE_STATUS_TO_SENT_TEXT',
    ),
  },
  actions: [
    {
      key: 0,
      color: 'inpulse-outline',
      label: i18next.t('GENERAL.CANCEL'),
      icon: '/images/inpulse/close-black-small.svg',
    },
    {
      key: 1,
      color: 'inpulse-default',
      label: i18next.t('GENERAL.CONFIRM'),
      icon: '/images/inpulse/check-white-small.svg',
      handleClick: () => markOrdersAsSent(ordersSelection, suppliers),
    },
  ],
});

export default {
  getMarkAsSentConfirmationModalParams,
};

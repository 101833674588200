import axios from '../core/http';

import { APIcaller } from './APIcaller';

import { config } from '../config';
const baseApiUrl = config.baseApiUrl;

export const getRecipesOfClient = (
  clientId,
  { computeRecipesCost = false, onlyAvailableInStocks = false } = {},
) => APIcaller.apiGet(`/admin/recipes`, { clientId, computeRecipesCost, onlyAvailableInStocks });

function getRecipeParents(id) {
  return axios
    .get(`${baseApiUrl}/admin/recipes/${id}/parents-mappings`, {
      params: {},
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export const getRecipesByCategoriesForRecipeComposition = (clientId, withoutIngredients = false) =>
  axios
    .get(baseApiUrl + '/admin/recipes/byCategories/recipeComposition', {
      params: { clientId, withoutIngredients },
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

export const getRecipe = (recipeId, shouldFetchComposition = true) =>
  APIcaller.apiGet(`/admin/recipes/${recipeId}`, { shouldFetchComposition });

export const getCompositions = (recipeId) =>
  APIcaller.apiGet(`/admin/recipes/${recipeId}/compositions`);

export const getAssociatedRecipesByIds = (
  recipeIds,
  shouldFetchComposition = false,
  shouldFetchIngredientsRecursively = false,
) =>
  APIcaller.apiPost(`/admin/recipes/list`, {
    recipeIds,
    shouldFetchComposition,
    shouldFetchIngredientsRecursively,
  });

export const getRecipeCost = (recipeId, { withAllergens = true } = {}) =>
  axios
    .get(baseApiUrl + '/admin/recipes/' + recipeId + '/cost', {
      params: { withAllergens },
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

export const create = (
  clientId,
  {
    img,
    recipePreparationSteps,
    name,
    unit,
    value,
    category,
    hasMultipleChannels,
    hasStock,
    hasLoss,
    batchProduction,
    compositions,
    brandIds,
    inventoryListTemplates,
    storageAreas,
    categoryId,
  },
) =>
  APIcaller.apiPost('/admin/recipes', {
    img,
    recipePreparationSteps,
    name,
    unit,
    value,
    clientId,
    category,
    hasMultipleChannels,
    hasStock,
    hasLoss,
    batchProduction,
    compositions,
    brandIds,
    inventoryListTemplates,
    storageAreas,
    categoryId,
  });

export const patchById = ({
  id,
  img,
  recipePreparationSteps,
  name,
  unit,
  value,
  hasMultipleChannels,
  hasStock,
  hasLoss,
  batchProduction,
  compositions,
  brandIds,
  inventoryListTemplates,
  storageAreas,
  categoryId,
}) =>
  APIcaller.apiPatch(`/admin/recipes/${id}`, {
    img,
    name,
    recipePreparationSteps,
    unit,
    value,
    hasMultipleChannels,
    hasStock,
    hasLoss,
    batchProduction,
    compositions,
    brandIds,
    inventoryListTemplates,
    storageAreas,
    categoryId,
  });

export const disableRecipes = (recipeIds) =>
  APIcaller.apiPatch('/admin/recipes/disable', { recipeIds });

export const enableRecipe = (recipeId) =>
  axios.patch(
    baseApiUrl + '/admin/recipes/enable',
    {
      recipeIds: [recipeId],
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    },
  );

export const deleteDisabledRecipes = (recipeIds) =>
  axios.delete(baseApiUrl + '/admin/recipes', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('loopbackAccessToken'),
    },
    data: {
      recipeIds,
    },
  });

export const checkForCircularRecipeMappings = (parentId, childId) =>
  APIcaller.apiGet('/admin/recipes/check/circular-mappings', { parentId, childId });

export const activateMulitpleChannelsByIds = (entityIds) =>
  APIcaller.apiPost('/admin/recipes/activate-multiple-channels', {
    entityIds,
  });

export const downloadRecipeDetails = (
  clientId,
  recipeId,
  name,
  img,
  recipePreparationSteps,
  category,
  value,
  unit,
  compositions,
) =>
  APIcaller.apiPost('/admin/recipes/download-pdf', {
    clientId,
    recipeId,
    name,
    img,
    recipePreparationSteps,
    category,
    value,
    unit,
    compositions,
  });

export const updateRecipesAvailabilities = (entityIds, hasStock, hasLoss) =>
  APIcaller.apiPatch('/admin/recipes/availability', { entityIds, hasStock, hasLoss });

export const patchCategories = (recipeIds, categoryId) =>
  APIcaller.apiPatch('/admin/recipes/categories', {
    recipeIds,
    categoryId,
  });

export default {
  getRecipesOfClient,
  getRecipeParents,
  getRecipe,
  getCompositions,
  getRecipeCost,
  create,
  patchById,
  disableRecipes,
  deleteDisabledRecipes,
  getAssociatedRecipesByIds,
  getRecipesByCategoriesForRecipeComposition,
  enableRecipe,
  checkForCircularRecipeMappings,
  activateMulitpleChannelsByIds,
  downloadRecipeDetails,
  updateRecipesAvailabilities,
  patchCategories,
};

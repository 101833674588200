import i18next from 'i18next';
import React from 'react';

import { LeftRightSplitter } from '@commons/LeftRightSplitter';
import WhiteCard from '@commons/WhiteCard';
import WysiwygEditor from '@commons/WysiwygEditor';

import ExportRecipeWithCompositionAndPreparation from '../../../components/ExportRecipeWithCompositionAndPreparation';

import { RecipePreparationStepsTitle } from './styledComponents';
import ColorPicker from './components/ColorPicker';

const RecipePreparationSteps = ({ recipe, allergens, compositions, onRecipeChange }) => {
  const toolbar = {
    options: ['inline', 'fontSize', 'fontFamily', 'list', 'colorPicker', 'emoji', 'history'],
    inline: {
      inDropdown: false,
      options: ['bold', 'italic', 'underline'],
    },
    fontSize: { className: 'wysiwyg-dropdown' },
    fontFamily: {
      className: 'wysiwyg-dropdown',
      options: ['Inter', 'Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
    },
    colorPicker: { component: ColorPicker },
  };

  return (
    <WhiteCard
      content={
        <LeftRightSplitter
          left={
            <RecipePreparationStepsTitle>
              {i18next.t('ADMIN.RECIPES.PREPARATION_STEPS')}
            </RecipePreparationStepsTitle>
          }
          right={
            <ExportRecipeWithCompositionAndPreparation
              allergens={allergens}
              compositionSettings={compositions}
              link={recipe.recipePreparationSteps}
              recipe={recipe}
              type="recipe"
              onLinkSaved={(recipePreparationSteps) =>
                onRecipeChange({
                  ...recipe,
                  recipePreparationSteps,
                })
              }
            />
          }
          width={'100%'}
        />
      }
      customStyle={{ maxWidth: '100%', width: '100%' }}
      renderContent={
        <WysiwygEditor
          content={recipe.recipePreparationSteps}
          isDisabled={false}
          setter={(value) => onRecipeChange({ ...recipe, recipePreparationSteps: value })}
          toolbar={toolbar}
          toolbarHidden={false}
          stripPastedStyles
        />
      }
    />
  );
};

export default RecipePreparationSteps;

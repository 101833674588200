import i18next from 'i18next';
import React from 'react';

import { Dropdown } from '@commons/utils/styledLibraryComponents';
import { Franco, SearchBar, ToggleButton } from '@commons/utils/styledLibraryComponents';
import { INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';
import DisplayNumber from '@commons/DisplayNumber';

import { getEntityTypesChoices } from '@stocks/utils/constants';

import { STOCK_DATA_TYPES } from '../../constants/types';

import StoreConvention from '../StoreConvention';

import { StockFormFiltersContainer, FilterContainer } from './styledComponents';

export const StockFormFilters = (props) => {
  const {
    isLoading,
    totalPrice,
    handleSearchInput,
    searchInput,
    stockType,
    stockConvention,
    selectedMetric,
    setSelectedMetric,
    supplierChoices,
    selectedSuppliers,
    setSelectedSuppliers,
  } = props;

  const entityTypesChoices = getEntityTypesChoices();

  return (
    <StockFormFiltersContainer>
      <FilterContainer>
        <ToggleButton
          choices={entityTypesChoices}
          isDisabled={isLoading}
          selectedChoice={selectedMetric}
          setSelectedChoice={setSelectedMetric}
        />
      </FilterContainer>
      {selectedMetric.key === STOCK_DATA_TYPES.SUPPLIER_PRODUCT && (
        <FilterContainer>
          <Dropdown
            iconSrc={'/images/inpulse/truck-black.svg'}
            isUniqueSelection={false}
            items={supplierChoices}
            selectedItems={selectedSuppliers}
            width={INPUT_WIDTH.MEDIUM}
            isRequired // disable validation button if no supplier is selected
            onSelectionChange={setSelectedSuppliers}
          />
        </FilterContainer>
      )}
      <FilterContainer>
        <SearchBar
          disabled={isLoading}
          maxWidth={160}
          minWidth={160}
          placeholder={i18next.t('GENERAL.SEARCH')}
          setValue={handleSearchInput}
          value={searchInput}
        />
      </FilterContainer>
      {stockConvention && (
        <FilterContainer>
          <StoreConvention stockConvention={stockConvention} stockType={stockType} />
        </FilterContainer>
      )}
      <FilterContainer>
        <Franco
          content={<DisplayNumber displayCurrencyCode={true} number={totalPrice} />}
          title={`${i18next.t('STOCKS.STOCKS.FORM_FILTERS_TOTAL_BOX_TITLE')} :`}
          width={'160px'}
        />
      </FilterContainer>
    </StockFormFiltersContainer>
  );
};

export default StockFormFilters;

import { APIcaller } from '@services/APIcaller';

const getStocksOfIngredientsByCategories = async (storeId, date, categoryIds, isStrategic) =>
  APIcaller.apiPost('/stocks/analytics/current/retrieve', {
    date,
    storeId,
    categoryIds,
    isStrategic,
  });

const getNextOrderOfEntityId = async (storeId, entityId, date) =>
  APIcaller.apiGet(`/admin/stores/${storeId}/ingredients/${entityId}/orders`, {
    date,
    storeId,
    entityId,
  });

export default {
  getNextOrderOfEntityId,
  getStocksOfIngredientsByCategories,
};

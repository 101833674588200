import { capitalize, get } from 'lodash';
import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React from 'react';

/** Actions */
import { showErrorMessage } from '@actions/messageconfirmation';

/** Commons */
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { DeepsightComponentLoader } from '@commons/DeepsightComponents';
import { DEFAULT_TIMEZONE } from '@commons/utils/timezone';
import { Tooltip } from '@commons/utils/styledLibraryComponents';
import Text, { ENUM_FONTS, ENUM_COLORS } from '@commons/Text';

/** Services */
import invoiceService from '@services/invoice';

/** Styles */
import {
  CreditInfoContainer,
  TextWithToolTipContainer,
  Credits,
  LoaderContainer,
  TooltipContainer,
} from './styledComponents';

const RemainingControls = ({
  storeId,
  timezone,
  reloadingCredits, // used to force the call to fetchCreditsDetails
  setReloadingCredits,
  customLabelStyle,

  /** Reducer props*/
  showErrorMessage,
}) => {
  const [isLoadingCredits, setIsLoadingCredits] = useState(true);
  const [creditsDetailsOfStore, setCreditsDetailsOfStore] = useState({
    invoiceVolume: 0,
    remaining: 0,
  });
  const [creditsLeft, setCreditsLeft] = useState(0);

  /** USE EFFECTS */

  useEffect(() => {
    if (!storeId) {
      setIsLoadingCredits(false);
      return;
    }

    if (reloadingCredits !== undefined && reloadingCredits === false) {
      return;
    }

    fetchCreditsDetails();
  }, [storeId, reloadingCredits]);

  /** FUNCTIONS */

  const fetchCreditsDetails = async () => {
    try {
      setIsLoadingCredits(true);

      const creditsByStore = await invoiceService.getStoresAvailableCredits([storeId]);
      const creditsDetailsOfStore = get(creditsByStore, storeId, null);

      setCreditsDetailsOfStore(creditsDetailsOfStore);
      setCreditsLeft(get(creditsDetailsOfStore, 'remaining', 0));
    } catch (error) {
      showErrorMessage(
        i18next.t('INVOICE.INVOICE_CONTROLS.FETCH_AVAILABLE_CREDITS_FOR_STORES_ERROR'),
      );
    } finally {
      setIsLoadingCredits(false);

      if (!!setReloadingCredits) {
        setReloadingCredits(false);
      }
    }
  };

  return (
    <CreditInfoContainer>
      <TextWithToolTipContainer>
        <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.TEXT_SMALL} style={customLabelStyle}>
          {`${i18next.t('INVOICE.INVOICE_CONTROLS.REMAINING_CONTROLS_THIS_MONTH')}:`}
        </Text>
        <TooltipContainer>
          <Tooltip
            className="tooltip"
            text={capitalize(
              moment.tz(timezone || DEFAULT_TIMEZONE).format(DATE_DISPLAY_FORMATS.MONTH_YEAR),
            )}
            displayBigger
          />
        </TooltipContainer>
      </TextWithToolTipContainer>

      {isLoadingCredits ? (
        <LoaderContainer>
          <DeepsightComponentLoader height={16} width={16} />
        </LoaderContainer>
      ) : (
        <Credits creditsLeft={creditsLeft}>
          <Text
            color={creditsLeft ? ENUM_COLORS.INFO_GREEN : ENUM_COLORS.INFO_ORANGE}
            font={ENUM_FONTS.TEXT_MIDDLE_NORMAL}
          >
            {`${creditsDetailsOfStore.remaining}/${creditsDetailsOfStore.invoiceVolume}`}
          </Text>
        </Credits>
      )}
    </CreditInfoContainer>
  );
};

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(null, mapDispatchToProps)(RemainingControls);

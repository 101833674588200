import { connect } from 'react-redux';
import { keyBy } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { showErrorMessage } from '@actions/messageconfirmation';

import { getTheme } from '@commons/utils/theme';
import { ListView } from '@commons/utils/styledLibraryComponents';
import { LISTVIEW_NO_TOP_PADDING } from '@commons/constants/listViewProps';
import EmptyState from '@commons/EmptyState';

import { supplierProduct as supplierProductService } from '@services/supplierProduct';
import { supplier as supplierService } from '@services/supplier';
import catalogsService from '@services/catalogs';

import paginationUtils from '@admin/suppliers/supplierProducts/utils/pagination';

import { Container } from './styledComponents';
import { getPropertyNoneValue } from '@commons/constants/categoryTypes';

const getColumns = (hasLocalCatalogs) => {
  const columns = [
    { id: 'name', name: i18next.t('GENERAL.NAME'), propertyKey: 'name' },
    { id: 'supplierName', name: i18next.t('GENERAL.SUPPLIER'), propertyKey: 'supplierName' },
    { id: 'category', name: i18next.t('GENERAL.CATEGORY'), propertyKey: 'category' },
    { id: 'subCategory', name: i18next.t('GENERAL.SUB_CATEGORY'), propertyKey: 'subCategory' },
  ];

  if (hasLocalCatalogs) {
    columns.splice(2, 0, {
      id: 'catalogName',
      name: i18next.t('GENERAL.CATALOG'),
      propertyKey: 'catalogName',
      disableSort: true, // Paginated route so we have to disable sorting
    });
  }

  return columns;
};

const SupplierProductMappingsModal = (props) => {
  const {
    clientId,
    hasLocalCatalogs,
    showErrorMessage,
    setSpToBeAdded,
    alreadyMappedSp = [],
  } = props;

  const theme = getTheme();

  const [listViewColumns] = useState(getColumns(hasLocalCatalogs));
  const [isLoadingSupplierProduct, setIsLoadingSupplierProducts] = useState(true);
  const [availableSupplierProduct, setAvailableSupplierProduct] = useState([]);

  // Custom "query params" for ListView in modals as we doesn't have url in modals
  const [search, setSearch] = useState('');
  const [orderBy, setOrderBy] = useState('name');
  const [orderType, setOrderType] = useState('asc');
  const [currentPage, setCurrentPage] = useState(0);
  const [maxPerPage, setMaxPerPage] = useState(500);

  const [supplierProductsCount, setSupplierProductsCount] = useState(0);
  const [columnPropertyKeys] = useState(listViewColumns.map(({ propertyKey }) => propertyKey));

  useEffect(() => {
    setSpToBeAdded([]);
  }, []);

  useEffect(() => {
    (async () => {
      await reloadSupplierProducts();
    })();
  }, [search, orderBy, orderType, currentPage, maxPerPage]);

  /** FUNCTIONS */
  const reloadSupplierProducts = async () => {
    setIsLoadingSupplierProducts(true);

    try {
      const clientSuppliers = await supplierService.getSuppliersOfClient(
        clientId,
        false,
        hasLocalCatalogs,
      );
      const supplierIds = clientSuppliers.map(({ id }) => id);
      const suppliersKeyById = keyBy(clientSuppliers, 'id');

      let catalogsKeyById;

      if (hasLocalCatalogs) {
        const catalogs = await catalogsService.getCatalogsByClientId(clientId);

        catalogsKeyById = keyBy(catalogs, 'id');
      }

      const spIdsToExclude = alreadyMappedSp.map(
        ({ supplierProductId, id }) => supplierProductId || id,
      );

      const listViewQueryParams = {
        order_by: orderBy,
        search: search,
        order_type: orderType,
        current_page: currentPage,
        max_per_page: maxPerPage,
      };

      const paginationPayload = paginationUtils.getPayloadPagination({
        columnPropertyKeys,
        listViewQueryParams,
      });

      const { skip, limit } = paginationPayload;

      const { totalCount, supplierProducts } = await supplierProductService.getWithPagination({
        clientId,
        supplierIds: supplierIds,
        search,
        orderBy,
        orderType,
        skip,
        limit,
        queryParams: '&active=true',
        spIdsToExclude,
      });

      const formattedAvailableSP = supplierProducts.map(
        ({ id, name, supplier, category, subCategory, price, isKitchen }) => {
          const supplierId = supplier.id;

          const catalogId = hasLocalCatalogs && suppliersKeyById[supplier.id].catalogId;
          const catalogName = hasLocalCatalogs && catalogsKeyById[catalogId].name;

          return {
            id,
            name,
            active: supplier.active,
            supplierId,
            supplierName: supplier.name,
            category: category || getPropertyNoneValue(),
            subCategory: subCategory || getPropertyNoneValue(),
            price,
            isKitchen,
            isRowSelected: false,
            catalogId,
            catalogName,
          };
        },
      );

      setAvailableSupplierProduct(formattedAvailableSP);
      setSupplierProductsCount(totalCount);
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.FETCH_ERROR'));
    } finally {
      setIsLoadingSupplierProducts(false);
    }
  };

  const handleSelection = (selectedItems) => {
    const formattedSupplierProducts = selectedItems.map(
      ({
        id,
        name,
        supplierId,
        catalogId,
        catalogName,
        supplierName,
        category,
        subCategory,
        price,
        isKitchen,
        active,
      }) => ({
        name,
        supplierId,
        active,
        catalogId,
        catalogName,
        supplierName,
        category,
        subCategory,
        price,
        supplierProductId: id,
        isKitchen,
      }),
    );

    setSpToBeAdded(formattedSupplierProducts);
  };

  return (
    <Container>
      <ListView
        columns={listViewColumns}
        countElements={supplierProductsCount}
        data={availableSupplierProduct}
        defaultCurrentPage={currentPage}
        defaultMaxPerPage={maxPerPage}
        defaultOrderBy={orderBy}
        defaultOrderType={orderType}
        defaultSearchInput={search}
        handleCurrentPageChange={(value) => setCurrentPage(value)}
        handleMaxPerPageChange={(value) => setMaxPerPage(value)}
        handleOrderByChange={(value) => setOrderBy(value)}
        handleOrderTypeChange={(value) => setOrderType(value)}
        handleSearchInputChange={(value) => setSearch(value)}
        isLoading={isLoadingSupplierProduct}
        markerConfiguration={{
          isHidden: ({ isKitchen }) => !isKitchen,
          backgroundColor: theme.colors.brand.secondary,
          icon: { src: '/images/inpulse/central-black-small.svg' },
        }}
        maxPerPageOptions={[10, 20, 50, 100, 500, 1000]}
        padding={LISTVIEW_NO_TOP_PADDING}
        renderEmptyState={() => <EmptyState />}
        setSelectedItems={(items) => handleSelection(items)}
        forceEnableSelection
        hideAllPerPageOption
        onQueryParamsChange={() => true}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SupplierProductMappingsModal);

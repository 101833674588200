import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;

  background-color: white;
`;

export const ContentContainer = styled.div`
  width: calc(100% - 64px);
  height: calc(100% - 40px);

  background: ${(props) => props.theme.colors.greys.lighter};

  > div {
    min-width: 100%;
    width: fit-content;
  }

  @media (max-width: 1200px) {
    > div:not(:first-child) > div > div {
      padding-right: 20px;
    }
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  height: 90px;

  padding: 20px 20px;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  .location-filter-input-text {
    max-width: none !important;
  }
`;

export const ListContainer = styled.div`
  width: 100%;
  height: calc(100% - 90px);

  position: relative;
`;

export const Item = styled.div`
  width: 40px;
  height: 30px;
`;

export const Label = styled.div`
  width: fit-content;
  padding: 5px 10px;
  height: 100%;
  text-align: center;
  background: ${(props) => props.orange && '#fff0cb'};
  border-radius: 4px;
`;

export const Icon = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-image: url('/images/icon-stock.svg');
  background-repeat: no-repeat;
  background-position: center;
  &:hover {
    background: #e7efff;
    background-image: url('/images/icon-stock.svg');
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 3px;
    cursor: pointer;
  }
`;

export const FilterContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  column-gap: 24px;
`;

export const StockModal = styled.div`
  min-width: 375px;
`;

export const Stock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

export const Title = styled.div`
  font-size: 16px;
  text-align: left;
  font-weight: 600;
  line-height: 16px;
  font: ${(props) => props.theme.fonts?.h2};
`;

export const StockValue = styled.div`
  display: flex;

  font-size: 24px;
  text-align: center;
  justify-content: center;
  font-weight: 600;
  line-height: 24px;
  font: ${(props) => props.theme.fonts?.h1};
`;

export const StockDate = styled.div`
  font-size: 12px;
  text-align: center;
  font-weight: 400;
  line-height: 12px;
  margin-top: 10px;
  font: ${(props) => props.theme.fonts?.textSmall};
`;

export const StockStats = styled.div`
  padding: 0 10px;
`;

/** NestedList Rows */
export const TextWithTooltipButtonContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

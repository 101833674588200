import { convertSPPQuantityInMasterUnit } from '@orders/utils/computePackagingToSupplierProduct';

export const getQuantityMasterUnit = (product) => {
  const { packagings, totByPackagingId } = product;

  return Object.keys(totByPackagingId).reduce((total, supplierProductPackagingId) => {
    const value = totByPackagingId[supplierProductPackagingId];

    if (value == null) {
      return total;
    }

    const convertedQuantity = convertSPPQuantityInMasterUnit(
      supplierProductPackagingId,
      packagings,
    );

    return total + convertedQuantity * value;
  }, 0);
};

export const getPrice = (product) => {
  const { packagings, totByPackagingId, price } = product;

  const totalInMasterUnit = getQuantityMasterUnit({ packagings, totByPackagingId });

  const invoicePackaging = packagings.find(({ isUsedInInvoice }) => isUsedInInvoice);

  if (!invoicePackaging) {
    return null;
  }

  const convertedPrice = price / convertSPPQuantityInMasterUnit(invoicePackaging.id, packagings);

  return totalInMasterUnit * convertedPrice;
};

export function getTotalPrice(supplierProducts, totByStorageAreaIdSPIdAndPackagingId = {}) {
  return supplierProducts.reduce((total, supplierProduct) => {
    const totByPackagingId =
      totByStorageAreaIdSPIdAndPackagingId[supplierProduct.id] ||
      supplierProduct.totByPackagingId ||
      {};

    return total + getPrice({ ...supplierProduct, totByPackagingId });
  }, 0);
}

export const getPriceForAllStorageAreas = (
  spByStorageAreaIds,
  totByStorageAreaIdSPIdAndPackagingId,
  recipesByStorageAreaIds,
  totByStorageAreaIdAndRecipeId,
) => {
  let totalPrice = 0;

  for (const [storageAreaId, supplierProducts] of Object.entries(spByStorageAreaIds)) {
    const priceForStorageArea = supplierProducts.reduce((total, supplierProduct) => {
      let totByPackagingId = {};

      if (
        totByStorageAreaIdSPIdAndPackagingId[storageAreaId] &&
        totByStorageAreaIdSPIdAndPackagingId[storageAreaId][supplierProduct.id]
      ) {
        totByPackagingId = totByStorageAreaIdSPIdAndPackagingId[storageAreaId][supplierProduct.id];
      }

      const price = getPrice({ ...supplierProduct, totByPackagingId });

      if (isNaN(price) || !price) {
        return total;
      }

      return total + price;
    }, 0);

    totalPrice += priceForStorageArea;
  }

  for (const [storageAreaId, recipes] of Object.entries(recipesByStorageAreaIds)) {
    const priceForStorageArea = recipes.reduce((total, recipe) => {
      let recipeTotal = 0;

      if (
        totByStorageAreaIdAndRecipeId[storageAreaId] &&
        totByStorageAreaIdAndRecipeId[storageAreaId][recipe.id]
      ) {
        recipeTotal = totByStorageAreaIdAndRecipeId[storageAreaId][recipe.id];
      }

      return total + recipeTotal * recipe.cost;
    }, 0);

    totalPrice += priceForStorageArea;
  }

  return totalPrice;
};

export default {
  getPrice,
  getTotalPrice,
  getQuantityMasterUnit,
};

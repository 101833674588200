import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import React, { Component } from 'react';

import Orders from './OrderList';

import NavigationBreadCrumb from '../../commons/Breadcrumb/NavigationBreadCrumb';

import {
  getFeaturesByPath,
  ENUM_MODULE_NAME,
  FEATURES_URL_BY_MODULE_NAME,
} from '../../commons/utils/features';
import RushOrderModulesContainer from './RushOrders';

import { getSalesPointStores } from '../../selectors/stores';

export class OrderModulesContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    const { match, userRights } = this.props;

    const path = _.get(match, 'path');

    if (path === '/order' && userRights) {
      const orderFeatures = FEATURES_URL_BY_MODULE_NAME[ENUM_MODULE_NAME.ORDER];

      const userOrderFeatures = getFeaturesByPath(userRights, ENUM_MODULE_NAME.ORDER);

      const paths = orderFeatures.map(({ path }) => path);

      const sortedMainFeatures = userOrderFeatures
        .filter((feature) => paths.includes(feature.path))
        .sort((current, next) => paths.indexOf(current.path) - paths.indexOf(next.path));

      if (sortedMainFeatures.length) {
        this.props.history.push(sortedMainFeatures[0].path);
      }
    }

    this.setState({ isLoading: false });
  }

  render() {
    if (this.state.isLoading) {
      return <div></div>;
    }

    const { match } = this.props;

    const path = _.get(match, 'path');

    return (
      <div style={{ width: '100%', height: '100%' }}>
        <NavigationBreadCrumb featurePath={path} />
        <Orders
          canOrderWithMin={this.props.canOrderWithMin}
          categoriesOrder={this.props.categoriesOrder}
          constraints={this.props.constraints}
          createOrdersXLS={this.props.createOrdersXLS}
          displayStocks={this.props.displayStocks}
          groupedOrder={this.props.groupedOrder}
          send={this.props.send}
          sendOrder={this.props.sendOrder}
          stores={this.props.stores}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  stores: getSalesPointStores(state.baseReducer.activeStores),
});

const ConnectedOrderModulesContainer = connect(
  mapStateToProps,
  null,
)(withTranslation()(OrderModulesContainer));

// ===== Module features for easy import in ClientContainer =====

import OrderAnalyticsModule from './OrderAnalyticsModulesContainer';

const OrdersModule = {
  ...OrderAnalyticsModule,
  OrderModulesContainer: ConnectedOrderModulesContainer,
  RushOrderModulesContainer,
};

export default OrdersModule;

import { isEmpty } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { getTheme } from '@commons/utils/theme';
import { SimpleTableView, Tooltip } from '@commons/utils/styledLibraryComponents';
import { TooltipIcon } from '@commons/Metrics/styledComponents';
import EmptyState from '@commons/EmptyState';
import ScrollableWhiteCard from '@commons/WhiteCard/ScrollableWhiteCard';

import { HeaderContainer, Title, TitleContainer } from './styledComponents';

const TABLE_COLUMNS = [
  { propertyKey: 'name', name: i18next.t('GENERAL.NAME') },
  { propertyKey: 'sku', name: i18next.t('GENERAL.SKU') },
  { propertyKey: 'quantity', name: i18next.t('GENERAL.QUANTITY'), type: 'number' },
  {
    propertyKey: 'turnoverExcludingTaxes',
    name: i18next.t('GENERAL.TURNOVER_EX_TAX'),
    type: 'currency',
  },
];

const TopCashierProducts = (props) => {
  const { isLoading, data } = props;

  const theme = getTheme();

  return (
    <ScrollableWhiteCard
      isLoading={isLoading}
      renderContent={
        !isEmpty(data) ? (
          <SimpleTableView columns={TABLE_COLUMNS} data={data} />
        ) : (
          <EmptyState
            backgroundColor={theme.colors.white}
            label={i18next.t('ADMIN.MAPPING_MONITORING.EMPTY_STATE_LABEL')}
          />
        )
      }
      renderHeader={
        <HeaderContainer>
          <TitleContainer>
            <Tooltip
              renderTooltipTrigger={() => (
                <TooltipIcon src={'/images/inpulse/info-dmgrey-small.svg'} />
              )}
              text={i18next.t('ADMIN.MAPPING_MONITORING.TABLE_TOOLTIP')}
              displayBigger
            />
            <Title>{i18next.t('ADMIN.MAPPING_MONITORING.TABLE_TITLE')}</Title>
          </TitleContainer>
        </HeaderContainer>
      }
    ></ScrollableWhiteCard>
  );
};

export default TopCashierProducts;

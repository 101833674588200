import React, { createContext, useEffect, useState } from 'react';

import { CHOICES_DROPDOWN_ACTIVE } from '../routes/admin/utils/DropdownItems';
import { getListViewState } from './utils';

export const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  const listViewStates = getListViewState();

  // Applied filters
  const [selectedStatus, setSelectedStatus] = useState(CHOICES_DROPDOWN_ACTIVE()[0]);

  // Advanced filters
  const [advancedFilters, setAdvancedFilters] = useState(null);

  useEffect(() => {
    listViewStates.setCurrentPage(1);
  }, [selectedStatus, advancedFilters]);

  return (
    <ProductContext.Provider
      value={{
        ...listViewStates,
        selectedStatus,
        setSelectedStatus,
        advancedFilters,
        setAdvancedFilters,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

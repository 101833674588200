import { forEach } from 'lodash';
import i18next from 'i18next';

import { convertEntityQuantityToInpulseUnit, convertUnits } from '@commons/utils/conversion';
import { formatDateWithLanguageCode } from '@commons/utils/date';
import utilsXLS from '@commons/utils/makeXLS';

const computeTotal = (currentRow, selectedDates) => {
  const total = selectedDates.reduce(
    (acc, date) => (acc = !!currentRow[date] ? acc + currentRow[date] : acc),
    null,
  );

  return total;
};

const convertEntityQuantities = (currentRow, selectedDates) => {
  const convertedQuantities = selectedDates.reduce((acc, date) => {
    if (!acc[date] && currentRow[date]) {
      acc[date] = convertEntityQuantityToInpulseUnit(currentRow[date], currentRow.unit);
    }

    return acc;
  }, {});

  return convertedQuantities;
};

const getProductionPlanningCommonsData = (selectedDates, userLanguageCode) => {
  const columns = [
    {
      name: i18next.t('GENERAL.STORE_NAME_LABEL_ESTABLISHMENT'),
      propertyKey: 'selectedStoreName',
    },
    {
      name: i18next.t('GENERAL.DATE'),
      propertyKey: 'productionPeriodStartDate',
    },
    {
      name: i18next.t('GENERAL.CATEGORY'),
      propertyKey: 'category',
      transform: (value) => value || i18next.t('GENERAL.NONE_VALUE'),
    },
  ];

  forEach(selectedDates, (currentStringDate) => {
    columns.push({
      name: formatDateWithLanguageCode(currentStringDate, userLanguageCode),
      propertyKey: currentStringDate,
      type: 'percentage',
    });
  });

  columns.push({
    name: i18next.t('GENERAL.TOTAL'),
    propertyKey: 'total',
    type: 'percentage',
  });

  return columns;
};

const getProductSheetColums = (selectedDates, userLanguageCode) => {
  const columns = getProductionPlanningCommonsData(selectedDates, userLanguageCode);

  columns.splice(3, 0, {
    name: i18next.t('GENERAL.PRODUCT'),
    propertyKey: 'name',
  });

  return columns;
};

const getRecipeSheetColums = (selectedDates, userLanguageCode) => {
  const columns = getProductionPlanningCommonsData(selectedDates, userLanguageCode);

  columns.splice(
    3,
    0,
    {
      name: i18next.t('GENERAL.RECIPE'),
      propertyKey: 'name',
    },
    {
      name: i18next.t('ADMIN.RECIPES.HAS_BATCH_PRODUCTION'),
      propertyKey: 'batchProductionQuantity',
    },
    {
      name: i18next.t('GENERAL.UNIT'),
      propertyKey: 'convertedUnit',
    },
  );

  return columns;
};

const getIngredientSheetColums = (selectedDates, userLanguageCode) => {
  const columns = getProductionPlanningCommonsData(selectedDates, userLanguageCode);

  columns.splice(
    3,
    0,
    {
      name: i18next.t('GENERAL.INGREDIENT'),
      propertyKey: 'name',
    },
    {
      name: i18next.t('GENERAL.UNIT'),
      propertyKey: 'convertedUnit',
    },
  );

  return columns;
};

export const exportProductionPlanning = (products, recipes, ingredients, metaData) => {
  const { selectedStore, selectedDates, userLanguageCode } = metaData;

  const productionPeriodStartDate = formatDateWithLanguageCode(selectedDates[0], userLanguageCode);

  const formattedProductsForExport = products.map((product) => ({
    ...product,
    selectedStoreName: selectedStore.name,
    productionPeriodStartDate,
    total: computeTotal(product, selectedDates),
  }));

  const formattedRecipesForExport = recipes.map((recipe) => ({
    ...recipe,
    selectedStoreName: selectedStore.name,
    productionPeriodStartDate,
    ...convertEntityQuantities(recipe, selectedDates),
    batchProductionQuantity: recipe.batchProduction
      ? convertEntityQuantityToInpulseUnit(recipe.quantity, recipe.unit)
      : null,
    convertedUnit: convertUnits(recipe.unit),
    total: convertEntityQuantityToInpulseUnit(computeTotal(recipe, selectedDates), recipe.unit),
  }));

  const formattedIngredientsForExport = ingredients.map((ingredient) => ({
    ...ingredient,
    selectedStoreName: selectedStore.name,
    productionPeriodStartDate,
    ...convertEntityQuantities(ingredient, selectedDates),
    convertedUnit: convertUnits(ingredient.unit),
    total: convertEntityQuantityToInpulseUnit(
      computeTotal(ingredient, selectedDates),
      ingredient.unit,
    ),
  }));

  const productSheet = utilsXLS.generateDefaultSheet(
    i18next.t('GENERAL.PRODUCT_PLURAL'),
    getProductSheetColums(selectedDates, userLanguageCode),
    formattedProductsForExport,
  );

  const recipeSheet = utilsXLS.generateDefaultSheet(
    i18next.t('GENERAL.RECIPE_PLURAL'),
    getRecipeSheetColums(selectedDates, userLanguageCode),
    formattedRecipesForExport,
  );

  const ingredientSheet = utilsXLS.generateDefaultSheet(
    i18next.t('GENERAL.INGREDIENT_PLURAL'),
    getIngredientSheetColums(selectedDates, userLanguageCode),
    formattedIngredientsForExport,
  );

  utilsXLS.makeXLS(i18next.t('FORECAST.POS_PRODUCTION_PLANNING.EXPORT_FILENAME'), [
    productSheet,
    recipeSheet,
    ingredientSheet,
  ]);
};

import { groupBy, omit, sortBy, sum } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';

import { convertEntityQuantityToInpulseUnit } from '@commons/utils/conversion';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { formatNumber } from '@commons/DisplayNumber';

import { getEntityUnit } from '../../../stocks/utils';

const getProductionQtyByEntityIdAndDate = (productionPlannings) => {
  const productionPlanningsGroupByEntityId = groupBy(productionPlannings, 'entityId');

  const productionQtyByEntityIdAndDate = {};

  for (const [entityId, prodPlannings] of Object.entries(productionPlanningsGroupByEntityId)) {
    productionQtyByEntityIdAndDate[entityId] = prodPlannings.reduce(
      (acc, { productionDate, quantity }) => {
        const formattedProductionDate = moment(productionDate).format(
          DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
        );

        if (!acc[formattedProductionDate]) {
          acc[formattedProductionDate] = quantity;
        }

        return acc;
      },
      {},
    );
  }

  return productionQtyByEntityIdAndDate;
};

/**
 * ProductionPlanning (which are quantities planned for recipes) are retrieved first
 * Then, the list of recipes and their composition is retrieved
 * Here, we compute the planned quantities for each preparation and ingredient
 */
const computePreparationAndIngredientsProduction = (
  productionQtyByEntityIdAndDate,
  recipesWithCompositions,
) => {
  const preparationsAndIngredientsQuantities = {};

  for (const entityId of Object.keys(productionQtyByEntityIdAndDate)) {
    const recipeComposition = recipesWithCompositions[entityId].composition;

    const currentRecipe = recipesWithCompositions[entityId];
    const { quantity: recipeParentQty, unit } = currentRecipe;

    const convertedRecipeParentQty = convertEntityQuantityToInpulseUnit(recipeParentQty, unit);

    for (const date of Object.keys(productionQtyByEntityIdAndDate[entityId])) {
      const recipePlannedQuantity = productionQtyByEntityIdAndDate[entityId][date];

      Object.values(recipeComposition).forEach(({ id, quantity }) => {
        if (!recipesWithCompositions[id]) {
          // Make sure it's either a preparation or an ingredient
          if (!preparationsAndIngredientsQuantities[id]) {
            preparationsAndIngredientsQuantities[id] = {};
            preparationsAndIngredientsQuantities[id][date] = 0;
          } else if (!preparationsAndIngredientsQuantities[id][date]) {
            preparationsAndIngredientsQuantities[id][date] = 0;
          }

          preparationsAndIngredientsQuantities[id][date] +=
            recipePlannedQuantity * (quantity / convertedRecipeParentQty);
        }
      });
    }
  }

  return { ...productionQtyByEntityIdAndDate, ...preparationsAndIngredientsQuantities };
};

const _getConsumptionOfEntity = (entityConsumption, unit) => {
  let formattedTotalConsumption = entityConsumption.total;

  if (formattedTotalConsumption != null) {
    formattedTotalConsumption = convertEntityQuantityToInpulseUnit(entityConsumption.total, unit);
  }

  const { dailyConsumption, cumulativeConsumption } = _computeConsumptionDetails(
    entityConsumption,
    unit,
  );

  return { consumption: formattedTotalConsumption, dailyConsumption, cumulativeConsumption };
};

const _computeConsumptionDetails = (entityConsumption, unit) => {
  const formattedUnit = getEntityUnit(unit);
  const formattedConsumptions = omit(entityConsumption, 'total');

  const details = Object.entries(formattedConsumptions).reduce(
    (acc, [date, consumption]) => {
      let formattedConsumption = consumption;

      if (formattedConsumption != null) {
        formattedConsumption = convertEntityQuantityToInpulseUnit(consumption, unit);
        acc.cumulativeTot += formattedConsumption;
      }

      acc.dailyConsumption[date] = `${formatNumber(formattedConsumption, 2)} ${formattedUnit}`;

      acc.cumulativeConsumption[date] = `${formatNumber(acc.cumulativeTot, 2)} ${formattedUnit}`;

      return acc;
    },
    // Datagrid needs an array of rows, in the inner lines we only have 2 rows and each rows are object.
    // In order to set easily this rows, we build them in the accumulator, and for each date we add the concerned consumption.
    {
      dailyConsumption: { id: '1', name: i18next.t('GENERAL.TOTAL') },
      cumulativeConsumption: {
        id: '2',
        name: i18next.t('PRODUCTION.CENTRAL_KITCHEN.CUMULATIVE_TOTAL'),
      },
      cumulativeTot: null,
    },
  );

  return details;
};

const formatRecipesProductionPlanning = (
  productionQtyByEntityIdAndDate,
  recipesWithCompositions,
  recipesWithYesterdayStockKeyById = null,
  consumptionByEntityId,
) => {
  const formattedRecipes = Object.values(recipesWithCompositions).map(
    ({ id, name, category, unit, img, active, composition }) => {
      const currentEntityProductionsByDate = productionQtyByEntityIdAndDate[id];

      const formattedRecipe = {
        id,
        name,
        category,
        unit,
        img,
        active,
        composition,
        ...(currentEntityProductionsByDate ? currentEntityProductionsByDate : {}),
        total: currentEntityProductionsByDate
          ? sum(Object.values(currentEntityProductionsByDate))
          : null,
      };

      if (!!recipesWithYesterdayStockKeyById && recipesWithYesterdayStockKeyById[id]) {
        const formattedYesterdayRealStock = recipesWithYesterdayStockKeyById[id].realStock.unit;

        const formattedYesterdayTheoricalStock =
          recipesWithYesterdayStockKeyById[id].theoricalStock.unit;

        formattedRecipe.yesterdayStock = null;
        formattedRecipe.yesterdayRealStock = formattedYesterdayRealStock;
        formattedRecipe.yesterdayTheoricalStock = formattedYesterdayTheoricalStock;

        if (formattedYesterdayRealStock != null || formattedYesterdayTheoricalStock != null) {
          const stockValue = formattedYesterdayRealStock || formattedYesterdayTheoricalStock;
          formattedRecipe.yesterdayStock = stockValue;
        }
      }

      if (!!consumptionByEntityId && consumptionByEntityId[id]) {
        const { consumption, dailyConsumption, cumulativeConsumption } = _getConsumptionOfEntity(
          consumptionByEntityId[id],
          unit,
        );

        formattedRecipe.consumption = consumption;
        formattedRecipe.innerRows = [dailyConsumption, cumulativeConsumption];
      }

      return formattedRecipe;
    },
  );

  return formattedRecipes;
};

const formatPreparationsAndIngredients = (
  productionQtyByEntityIdAndDate,
  recipesWithCompositions,
  consumptionByEntityId,
) => {
  // Helps distinguish between preparations and sold recipes
  const recipeIds = new Set(Object.keys(recipesWithCompositions));
  const uniquePreparations = new Set();
  const uniqueIngredients = new Set();

  const { preparations, ingredients } = Object.values(recipesWithCompositions).reduce(
    (acc, { composition }) => {
      Object.values(composition).forEach(
        ({ id, name, category, img, active, quantity, unit, isIngredient }) => {
          const currentEntityProductionsByDate = productionQtyByEntityIdAndDate[id];

          const formattedComponent = {
            id,
            name,
            category,
            img,
            active,
            quantity,
            unit,
            ...(currentEntityProductionsByDate ? currentEntityProductionsByDate : {}),
            total: currentEntityProductionsByDate
              ? sum(Object.values(currentEntityProductionsByDate))
              : null,
          };

          if (!!consumptionByEntityId && consumptionByEntityId[id]) {
            let formattedTotalConsumption = consumptionByEntityId[id].total;

            if (formattedTotalConsumption != null) {
              formattedTotalConsumption = convertEntityQuantityToInpulseUnit(
                consumptionByEntityId[id].total,
                unit,
              );
            }

            formattedComponent.consumption = formattedTotalConsumption;

            const consumptionDetails = _computeConsumptionDetails(consumptionByEntityId[id], unit);

            formattedComponent.innerRows = [
              consumptionDetails.dailyConsumption,
              consumptionDetails.cumulativeConsumption,
            ];
          }

          if (!isIngredient && !recipeIds.has(id) && !uniquePreparations.has(id)) {
            acc.preparations.push(formattedComponent);
            uniquePreparations.add(id);
          }

          if (isIngredient && !uniqueIngredients.has(id)) {
            acc.ingredients.push(formattedComponent);
            uniqueIngredients.add(id);
          }
        },
      );
      return acc;
    },
    { preparations: [], ingredients: [] },
  );

  return {
    formattedPreparations: sortBy(preparations, ['name']),
    formattedIngredients: sortBy(ingredients, ['name']),
  };
};

export default {
  getProductionQtyByEntityIdAndDate,
  computePreparationAndIngredientsProduction,
  formatRecipesProductionPlanning,
  formatPreparationsAndIngredients,
};

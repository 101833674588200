import 'moment/locale/fr';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import moment from 'moment';
import React, { Component } from 'react';

import { Button, Dropdown, INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';

import { Input } from '@lib/inpulse/Input';

import { ALPHABET_DROPDOWN_ITEMS, WEEKDAY_DROPDOWN_ITEMS } from '@admin/utils/DropdownItems';
import { getLimitOrderDay } from '@admin/utils/date';

import {
  BodyRow,
  Container,
  DeliveryOptionsInputContainer,
  DeliveryOptionsRow,
  FooterButtonContainer,
  InputContainer,
} from './styledComponents';

moment.locale('fr');

export class StoreSupplierProfileDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deliveryOptions: [],
      supplier: {
        name: '',
        category: '',
        code: '',
        adress: '',
        city: '',
        postCode: '',
        country: '',
        active: true,
      },
      supplierProfile: {
        name: '',
        supplierId: '',
        orderMinAmount: '',
        orderMinUnit: '',
        contactName: '',
        address: '',
        email1: '',
        email2: '',
        telephone: '',
        deliveryDays: '',
        deliveryLeadTimes: '',
        orderTimeLimit: '',
        city: '',
        country: '',
        postCode: '',
      },
    };
  }

  /**********\   DATA PROCESSING   /***********/

  componentDidMount() {
    const supplierProfile = this.props.params.supplierProfile;
    if (supplierProfile) {
      let deliveryOptions = [];
      if (supplierProfile.deliveryDays && supplierProfile.deliveryLeadTimes) {
        const deliveryDays = supplierProfile.deliveryDays.split(';');
        const deliveryLeadTimes = supplierProfile.deliveryLeadTimes.split(';');
        deliveryOptions = deliveryDays.map((deliveryDay, index) => ({
          deliveryDay: deliveryDay,
          deliveryLeadTime: deliveryLeadTimes[index],
        }));
      }
      this.setState({
        deliveryOptions,
        supplierProfile,
      });
    }
  }

  /**********\   RENDER   /***********/

  render() {
    const { currency } = this.props;

    const { deliveryOptions, supplierProfile } = this.state;
    return (
      <Container>
        <div className="medium-modal-form-header">
          <div className="medium-modal-form-header-title">
            <span style={{ marginLeft: '10px' }}>
              {i18next.t('ADMIN.STORES.CHECK_SUPPLIER_PROFILE', {
                supplierName: supplierProfile.name,
              })}
            </span>
          </div>
        </div>
        {/************* BODY *************/}
        <div
          className="medium-modal-form-main-container"
          style={{ height: 'calc(100% - 176px)', overflow: 'auto' }}
        >
          <div className="medium-modal-form-category-block">
            <div className="medium-modal-form-category-header">
              {i18next.t('ADMIN.SUPPLIERS.CREATE_SUPPLIER_GENERAL_INFO')}
            </div>
            <div className="medium-modal-form-category-body">
              <BodyRow childCount={2}>
                <div className="medium-modal-form-category-body-row">
                  <InputContainer>
                    <Input
                      label={i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_SUPPLIER')}
                      type="text"
                      value={supplierProfile.supplierName || ''}
                      readOnly
                      onChange={() => {}}
                    />
                  </InputContainer>
                  <InputContainer>
                    <Input
                      label={i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_NAME')}
                      type="text"
                      value={supplierProfile.name || ''}
                      readOnly
                      onChange={() => {}}
                    />
                  </InputContainer>
                </div>
              </BodyRow>
            </div>
          </div>
          <div className="medium-modal-form-category-block">
            <div className="medium-modal-form-category-header">
              {i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_CONTACT')}
            </div>
            <div className="medium-modal-form-category-body">
              <BodyRow childCount={3}>
                <div className="medium-modal-form-category-body-row">
                  <InputContainer>
                    <Input
                      label={i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_CONTACT_NAME')}
                      type="text"
                      value={supplierProfile.contactName || ''}
                      readOnly
                      onChange={(ev) => {}}
                    />
                  </InputContainer>
                  <InputContainer>
                    <Input
                      label={i18next.t('GENERAL.EMAIL')}
                      type="text"
                      value={supplierProfile.email1 || ''}
                      readOnly
                      onChange={(ev) => {}}
                    />
                  </InputContainer>
                  <InputContainer>
                    <Input
                      label={i18next.t('GENERAL.EMAIL_SECONDARY')}
                      type="text"
                      value={supplierProfile.email2 || ''}
                      readOnly
                      onChange={(ev) => {}}
                    />
                  </InputContainer>
                </div>
              </BodyRow>
              <BodyRow childCount={2}>
                <div className="medium-modal-form-category-body-row">
                  <InputContainer>
                    <Input
                      label={i18next.t('GENERAL.PHONE')}
                      type="text"
                      value={supplierProfile.telephone || ''}
                      readOnly
                      onChange={(ev) => {}}
                    />
                  </InputContainer>
                </div>
              </BodyRow>
            </div>
          </div>
          <div className="medium-modal-form-category-block">
            <div className="medium-modal-form-category-header">
              {i18next.t('ADMIN.SUPPLIERS.SUPPLIER_DETAIL_LOCATION')}
            </div>
            <div className="medium-modal-form-category-body">
              <BodyRow childCount={3}>
                <div className="medium-modal-form-category-body-row">
                  <InputContainer>
                    <Input
                      label={i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_ADDRESS')}
                      type="text"
                      value={supplierProfile.address || ''}
                      readOnly
                      onChange={(ev) => {}}
                    />
                  </InputContainer>
                  <InputContainer>
                    <Input
                      label={i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_POSTAL_CODE')}
                      type="text"
                      value={supplierProfile.postCode || ''}
                      readOnly
                      onChange={(ev) => {}}
                    />
                  </InputContainer>
                  <InputContainer>
                    <Input
                      label={i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_CITY')}
                      type="text"
                      value={supplierProfile.city || ''}
                      readOnly
                      onChange={(ev) => {}}
                    />
                  </InputContainer>
                </div>
              </BodyRow>
              <BodyRow childCount={1}>
                <div className="medium-modal-form-category-body-row">
                  <InputContainer>
                    <Input
                      label={i18next.t('GENERAL.COUNTRY')}
                      type="text"
                      value={supplierProfile.country || ''}
                      readOnly
                      onChange={(ev) => {}}
                    />
                  </InputContainer>
                </div>
              </BodyRow>
            </div>
          </div>
          <div className="medium-modal-form-category-block">
            <div className="medium-modal-form-category-header">
              {i18next.t('ADMIN.SUPPLIERS.ORDER_MINIMUM')}
            </div>
            <div className="medium-modal-form-category-body">
              <BodyRow childCount={2}>
                <div className="medium-modal-form-category-body-row">
                  <InputContainer>
                    <Input
                      label={`${i18next.t('ADMIN.SUPPLIERS.ORDER_MINIMUM')} (${
                        currency.alphabeticCode
                      })`}
                      type="number"
                      value={supplierProfile.orderMinAmount || ''}
                      readOnly
                      onChange={(ev) => {}}
                    />
                  </InputContainer>
                  <InputContainer>
                    <Input
                      label={i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_MINIMUM_ORDER')}
                      type="number"
                      value={supplierProfile.orderMinUnit || ''}
                      readOnly
                      onChange={(ev) => {}}
                    />
                  </InputContainer>
                </div>
              </BodyRow>
            </div>
          </div>
          <div className="medium-modal-form-category-block">
            <div className="medium-modal-form-category-header">
              {i18next.t('ADMIN.PRODUCTS.DELIVERY')}
            </div>
            <div className="medium-modal-form-category-body">
              <BodyRow childCount={1}>
                <div className="medium-modal-form-category-body-row">
                  <InputContainer width={'22%'}>
                    <Input
                      label={i18next.t('ORDERS.ORDERS.FORM_INFO_MODAL_ORDERING_TIME_DEADLINE')}
                      type="text"
                      value={supplierProfile.orderTimeLimit || ''}
                      readOnly
                      onChange={(ev) => {}}
                    />
                  </InputContainer>
                </div>
              </BodyRow>
              {deliveryOptions.map((deliveryOption, index) => {
                const selectedDay = WEEKDAY_DROPDOWN_ITEMS().find(
                  (day) => day.id === deliveryOption.deliveryDay,
                );

                const selectedLetter = ALPHABET_DROPDOWN_ITEMS.find(
                  (letter) => letter.id === deliveryOption.deliveryLeadTime,
                );

                return (
                  <DeliveryOptionsRow key={index}>
                    <div className="medium-modal-form-category-body-row">
                      <DeliveryOptionsInputContainer
                        flex="4"
                        marginLeft="3%"
                        marginTop="20px"
                        width="22%"
                      >
                        <Dropdown
                          isDisabled={true}
                          isLabelTextSmall={true}
                          label={`${i18next.t('ORDERS.ORDERS.FORM_INFO_MODAL_DAY_OF_DELIVERY')} :`}
                          selectedItem={selectedDay}
                          width={INPUT_WIDTH.FULL}
                        />
                      </DeliveryOptionsInputContainer>
                      <DeliveryOptionsInputContainer
                        display="flex"
                        flex="6"
                        marginLeft="3%"
                        width="36%"
                        inpulse
                      >
                        <DeliveryOptionsInputContainer
                          display="flex"
                          flex="3"
                          marginLeft="3%"
                          width="40%"
                        >
                          <Input
                            label={i18next.t(
                              'ADMIN.SUPPLIERS.SUPPLIER_PROFILE_DELIVERY_TIME_CHOICE',
                            )}
                            type="text"
                            value={'A'}
                            readOnly
                            onChange={() => {}}
                          />
                        </DeliveryOptionsInputContainer>
                        <DeliveryOptionsInputContainer
                          color="#A8B1C4"
                          flex="1"
                          marginLeft="6%"
                          marginRight="3%"
                          marginTop="60px"
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'end',
                              alignItems: 'center',
                              paddingBottom: '10px',
                            }}
                          >
                            {i18next.t('GENERAL.FOR')}
                          </div>
                        </DeliveryOptionsInputContainer>
                        <DeliveryOptionsInputContainer
                          flex="3"
                          marginLeft="3%"
                          marginTop="45px"
                          width="40%"
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'end',
                            }}
                          >
                            <Dropdown
                              isDisabled={true}
                              selectedItem={selectedLetter}
                              width={INPUT_WIDTH.FULL}
                            />
                          </div>
                        </DeliveryOptionsInputContainer>
                      </DeliveryOptionsInputContainer>
                      <DeliveryOptionsInputContainer flex="4" marginLeft="3%" width="22%">
                        <Input
                          label={i18next.t(
                            'ORDERS.ORDERS.FORM_INFO_MODAL_DAY_OF_DELIVERY_DEADLINE',
                          )}
                          type="text"
                          value={getLimitOrderDay(
                            deliveryOption.deliveryDay,
                            deliveryOption.deliveryLeadTime,
                          )}
                          readOnly
                          onChange={() => {}}
                        />
                      </DeliveryOptionsInputContainer>
                    </div>
                  </DeliveryOptionsRow>
                );
              })}
            </div>
          </div>
        </div>
        {/************* FOOTER BUTTONS *************/}
        <div className="medium-modal-form-footer">
          <FooterButtonContainer>
            <Button
              color={'inpulse-outline'}
              handleClick={() => this.props.closeModal()}
              icon={'/images/inpulse/close-black-small.svg'}
              label={this.props.t('GENERAL.CANCEL')}
            />
            <Button
              color={'inpulse-default'}
              handleClick={() => {
                this.props.closeModal();
                this.props.params.history.push(
                  `/admin/suppliers/${supplierProfile.supplierId}/details`,
                );
              }}
              icon={'/images/inpulse/pen-white-small.svg'}
              label={this.props.t('GENERAL.MODIFY')}
            />
          </FooterButtonContainer>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
});

export default StoreSupplierProfileDetails = connect(mapStateToProps)(
  withTranslation()(StoreSupplierProfileDetails),
);

import { get, isEmpty } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { Dropdown } from '@commons/utils/styledLibraryComponents';
import { GENERIC_MODAL_CANCEL_BUTTON } from '@commons/Modals/GenericModal/genericModalActions';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import RemainingControls from '@commons/RemainingControls';

import {
  Container,
  DropdownContainer,
  LabelRequiredCharacter,
  LabelContainer,
  ControlsContainer,
} from './styledComponents';

const Modal = (props) => {
  const {
    stores,
    suppliers,
    clientStoreName,
    selectedStore,
    selectedSupplier,
    setSelectedStore,
    setSelectedSupplier,
  } = props;

  return (
    <Container>
      <Text
        color={ENUM_COLORS.DARKER}
        font={ENUM_FONTS.TEXT_BIG_HEIGHT_24}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {i18next.t(
          'INVOICE.INVOICE_CONTROLS.ACTION_RELAUNCH_ORDER_INVOICE_ASSOCIATION_MODAL_CONTENT',
        )}
      </Text>
      <DropdownContainer>
        <LabelContainer>
          <LabelRequiredCharacter>*</LabelRequiredCharacter>
          <Text
            color={ENUM_COLORS.DARKEST}
            font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {`${i18next.t('GENERAL.SUPPLIER')} :`}
          </Text>
        </LabelContainer>
        <Dropdown
          customListStyle={{
            position: 'fixed',
            width: 'inherit',
          }}
          iconSrc={'/images/inpulse/app-black-small.svg'}
          items={suppliers}
          selectedItem={selectedSupplier}
          isRequired
          onSelectionChange={setSelectedSupplier}
        />
      </DropdownContainer>
      <DropdownContainer>
        <LabelContainer>
          <LabelRequiredCharacter>*</LabelRequiredCharacter>
          <Text
            color={ENUM_COLORS.DARKEST}
            font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {`${clientStoreName} :`}
          </Text>
        </LabelContainer>
        <Dropdown
          customListStyle={{
            position: 'fixed',
            width: 'inherit',
          }}
          iconSrc={'/images/inpulse/pin-black-small.svg'}
          items={stores}
          selectedItem={selectedStore}
          isRequired
          onSelectionChange={setSelectedStore}
        />
      </DropdownContainer>
      <ControlsContainer>
        <RemainingControls
          storeId={get(selectedStore, 'id', null)}
          timezone={get(selectedStore, 'timezone', null)}
        />
      </ControlsContainer>
    </Container>
  );
};

const getModalConfig = ({
  stores,
  invoice,
  suppliers,
  selectedStore,
  clientStoreName,
  selectedSupplier,
  setSelectedStore,
  setSelectedSupplier,
  handleCloseCleanUp,
  callback,
}) => ({
  type: 'action',
  width: '560px',
  height: '512px',
  title: i18next.t(
    'INVOICE.INVOICE_CONTROLS.ACTION_RELAUNCH_ORDER_INVOICE_ASSOCIATION_MODAL_TITLE',
  ),
  icon: '/images/inpulse/sync-black-small.svg',
  customPadding: '24px',
  handleCloseCleanUp,
  disableCloseOutside: true,
  component: Modal,
  data: {
    stores,
    clientStoreName,
    suppliers,
    selectedSupplier,
    setSelectedSupplier,
    selectedStore,
    setSelectedStore,
  },
  actions: [
    GENERIC_MODAL_CANCEL_BUTTON(),
    {
      key: 1,
      preventClosing: true,
      color: 'inpulse-default',
      handleClick: () => callback(invoice.id, selectedStore.id, selectedSupplier.id),
      isDisabled: isEmpty(selectedSupplier) || isEmpty(selectedStore),
      label: i18next.t(
        'INVOICE.INVOICE_CONTROLS.ACTION_RELAUNCH_ORDER_INVOICE_ASSOCIATION_MODAL_BUTTON_RESET',
      ),
      icon: '/images/inpulse/sync-white.svg',
    },
  ],
});

export default { getModalConfig };

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Content = styled.div`
  width: 100%;
  overflow: auto;
  position: relative;
  height: ${(props) => (props.isCurrentUserProfile ? 'calc(100% - 104px)' : '100%')};
  padding-top: ${(props) => (props.isCurrentUserProfile ? '0' : '88px')};

  background-color: ${(props) => props.theme.colors.greys.lighter};

  & > :last-child {
    margin-bottom: 24px;
  }
`;

export const Section = styled.div`
  width: 100%;

  padding: 0 32px;
  margin-top: 24px;

  display: flex;
`;

export const Action = styled.div`
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  font-weight: normal;
  transition: color 0.2s cubic-bezier(0, 0.64, 0.25, 0.99);

  font: ${(props) => props.theme.fonts?.h3};
  color: ${(props) => props.theme.colors?.greys.dark};

  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;

    color: ${(props) => props.theme.colors?.greys.darkest};
  }
`;

import i18next from 'i18next';
import moment from 'moment-timezone';

const CHECK_IF_INTEGER_REGEXP = /^\d+$/gm;

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';

import { COLUMN_TYPES } from './datagrid/constants';
import {
  renderCategoryCell,
  renderIngredientQuantityCell,
  renderInputCell,
  renderInputHeader,
  renderNameCell,
  renderRecipeQuantityCell,
  renderStandardHeader,
  renderTotalCell,
  renderEditInputCell,
} from './datagrid/customCellRenders';

const DayCellRenderByType = {
  [COLUMN_TYPES.PRODUCTS]: renderInputCell,
  [COLUMN_TYPES.RECIPES]: renderRecipeQuantityCell,
  [COLUMN_TYPES.INGREDIENTS]: renderIngredientQuantityCell,
};

// Return an array of dates as formatted strings between startDate and endDate (inclusive)
export const generateDates = (
  startDate,
  endDate,
  dateFormat = DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
) => {
  const dates = [];

  for (
    let currentDate = moment(startDate).clone();
    currentDate.isSameOrBefore(moment(endDate), 'day');
    currentDate.add(1, 'day')
  ) {
    dates.push(currentDate.format(dateFormat));
  }

  return dates;
};

// Generate columns for each day over the period of selectedDates
export const generateDateColumns = (
  type,
  selectedDates,
  datesWithSavedData,
  { editable = true } = {},
) => {
  const today = moment().format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
  const dayColumnFields = {
    type: 'number',
    minWidth: 148,
    disableReorder: true,
    filterable: false,
    renderCell: DayCellRenderByType[type],
  };

  const columns = [];

  selectedDates.forEach((date) => {
    const isCellEditable = editable && moment(date).isSameOrAfter(today);

    const hasSavedDates = datesWithSavedData.includes(date);

    columns.push({
      ...dayColumnFields,
      field: date,
      headerName: moment(date).format(DATE_DISPLAY_FORMATS.CONDENSED_DAY_DATE_MONTH),
      editable: isCellEditable,
      disableColumnMenu: true,
      ...(type === COLUMN_TYPES.PRODUCTS ? { renderEditCell: renderEditInputCell } : {}),
      renderHeader: (props) => renderInputHeader(props, hasSavedDates),
      preProcessEditCellProps: ({ props }) => {
        if (!props.value) {
          // No need to check if it's an integer when it's null
          return { ...props, error: false };
        }

        // Not using Number.isInteger() because it allows numbers like 1.0 or 1. without anything behind
        const hasError = !new RegExp(CHECK_IF_INTEGER_REGEXP).test(`${props.value}`);
        return { ...props, error: hasError };
      },
    });
  });

  return columns;
};

export const getColumns = (type, selectedDates, datesWithSavedData = []) => {
  const commonColumnsProps = {
    disableReorder: true,
    renderHeader: renderStandardHeader,
  };

  const nameColumn = {
    ...commonColumnsProps,
    field: 'name',
    headerName: i18next.t('GENERAL.NAME'),
    minWidth: 320,
  };

  const skuColumn = {
    field: 'sku',
  };

  const categoryColumn = {
    ...commonColumnsProps,
    field: 'category',
    headerName: i18next.t('GENERAL.CATEGORY'),
    minWidth: 200,
    renderCell: ({ row }) => renderCategoryCell(row.category),
  };

  const totalColumn = {
    ...commonColumnsProps,
    field: 'total',
    type: 'number',
    headerName: i18next.t('GENERAL.TOTAL'),
    minWidth: 160,
    renderCell: (props) => renderTotalCell(props, type),
  };

  if (type === COLUMN_TYPES.PRODUCTS) {
    nameColumn['renderCell'] = ({ row }) =>
      renderNameCell(row.name, COLUMN_TYPES.PRODUCTS, {
        sku: row.sku,
        withImg: true,
        imgSrc: row.img,
      });

    categoryColumn.filterable = false;

    return [
      nameColumn,
      skuColumn,
      categoryColumn,
      ...generateDateColumns(COLUMN_TYPES.PRODUCTS, selectedDates, datesWithSavedData),
      totalColumn,
    ];
  }

  nameColumn['renderCell'] = ({ row }) =>
    renderNameCell(row.name, type, { withImg: true, imgSrc: row.img });

  return [
    nameColumn,
    categoryColumn,
    ...generateDateColumns(type, selectedDates, datesWithSavedData, { editable: false }),
    totalColumn,
  ];
};

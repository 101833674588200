import i18next from 'i18next';

export const SUPPORT = {
  getText: () => i18next.t('FEATURE.HELP_CENTER.SUPPORT'),
  path: 'https://support.inpulse.ai',
  isExternalLink: true,
};

export const CHANGELOGS = {
  getText: () => i18next.t('FEATURE.HELP_CENTER.CHANGELOGS'),
  path: 'https://news.inpulse.ai',
  isExternalLink: true,
  requireSleekPlanToken: true,
};

const HELP_CENTER_SUBMODULE = {
  getText: () => i18next.t('FEATURE.HELP_CENTER.HELP_CENTER'),
  icon: '/images/help-center.svg',
  children: [SUPPORT, CHANGELOGS],
};

export const HELP_CENTER = {
  getText: () => i18next.t('FEATURE.HELP_CENTER.HELP_CENTER'),
  path: '/help-center',
  icon: '/images/help-center.svg',
  children: [HELP_CENTER_SUBMODULE],
};

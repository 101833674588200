import { APIcaller } from './APIcaller';

const getLossCategoriesByClientIdAndLossType = (clientId, lossType) =>
  APIcaller.apiGet('/loss/categories', { clientId, lossType });

const getLossCategoriesByClientId = () => APIcaller.apiGet(`/loss/categories/by-client`);

const getDatesWithLossesForStores = (type, storeIds, startDate, endDate) =>
  APIcaller.apiPost('/loss/dates', {
    type,
    storeIds,
    startDate,
    endDate,
  });

const getLossesList = (type, storeIds, startDate, endDate) =>
  APIcaller.apiPost('/loss/list', {
    type,
    storeIds,
    startDate,
    endDate,
  });

const createLosses = (type, storeId, date, losses) =>
  APIcaller.apiPost('/loss/createByBatch', {
    type,
    date,
    losses,
    storeId,
  });

const deleteLosses = (lossIds) =>
  APIcaller.apiDelete('/loss', {
    lossIds,
  });

const getLossesAnalytics = (storeIds, startDate, endDate, lossCategoryIds, ingredientCategoryIds) =>
  APIcaller.apiPost('/order/analytics/losses', {
    storeIds,
    startDate,
    endDate,
    lossCategoryIds,
    ingredientCategoryIds,
  });

export const lossService = {
  createLosses,
  getLossCategoriesByClientIdAndLossType,
  getLossCategoriesByClientId,
  getDatesWithLossesForStores,
  getLossesList,
  getLossesAnalytics,
  deleteLosses,
};

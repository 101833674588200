import { allergen as allergenService } from '@services/allergen';

/**********************/
/* Fetch Data Methods */
/**********************/

/**
 * Render the allergens form section with the different inputs that should be displayed
 *
 * @param {Function} setAllergens  - Method used to set the local state of the allergens variable
 */
export async function fetchAllergensList(setAllergens) {
  const result = await allergenService.getAllergens();

  setAllergens(result);
}

export default {
  fetchAllergensList,
};

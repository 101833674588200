const { get } = require('lodash');

const SHEET = {
  ID: 'orders', // Must match the sheet ID declared in the constant on the server
  NAME: 'Commandes',
};

// The "key" property will be the keys used for payload, must be similar to COLUMN_CONFIG keys
// definition on the server.
// "normalized key" are the headers as seen in the Excel file without diacritics and lower cased.
const COLUMN_IDS = {
  STORES: {
    key: 'stores',
    normalizedKey: 'etablissements',
  },
  SUPPLIERS: {
    key: 'suppliers',
    normalizedKey: 'fournisseurs',
  },
  START_ORDER_DATE: {
    key: 'startOrderDate',
    normalizedKey: 'date de livraison',
  },
  SKU: {
    key: 'sku',
    normalizedKey: 'sku',
    // Avoid considering SKU as number
    mappingFn: (row, normalizedKey) => get(row, normalizedKey, '').toString(),
  },
  QUANTITY: {
    key: 'quantity',
    normalizedKey: 'quantite commandee',
  },
};

// Hidden columns
const HIDDEN_COLUMN_IDS = {
  ID: 'id',
  NAME: 'name',
};

// Hidden Store config
const HIDDEN_SHEET_STORES = {
  ID: 'stores',
  NAME: 'storesSheet',
};

const HIDDEN_STORES_NORMALIZE_COLUMN_IDS = {
  ID: 'storeid',
  NAME: 'storename',
};

// Hidden Supplier config
const HIDDEN_SHEET_SUPPLIERS = {
  ID: 'suppliers',
  NAME: 'suppliersSheet',
};

const HIDDEN_SUPPLIERS_NORMALIZE_COLUMN_IDS = {
  ID: 'supplierid',
  NAME: 'suppliername',
};

module.exports = {
  SHEET,
  COLUMN_IDS,
  // Hidden sheets configuration
  HIDDEN_COLUMN_IDS,
  HIDDEN_SHEET_STORES,
  HIDDEN_STORES_NORMALIZE_COLUMN_IDS,
  HIDDEN_SHEET_SUPPLIERS,
  HIDDEN_SUPPLIERS_NORMALIZE_COLUMN_IDS,
};

import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

import { fetchPastEventError, fetchPastEventsOfStore, receivePastEvents } from '@actions/event';
import {
  fetchPastForecastsOfStore,
  receivePastForecasts,
  requestPastForecastsError,
} from '@actions/forecast';
import { fetchPastSalesOfStore, receivePastSales, requestPastSalesError } from '@actions/sale';
import { fetchPastWeather, fetchPastWeatherError, receivePastWeather } from '@actions/weather';
import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage } from '@actions/messageconfirmation';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { Dropdown, ToggleSwitch } from '@commons/utils/styledLibraryComponents';
import { getNumberFormattedWithDecimals } from '@commons/DisplayNumber/index.js';
import { PeriodDatePicker } from '@commons/DatePickers/PeriodDatePicker';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { usePeriodDatePickerState } from '@hooks/usePeriodDatePickerState';

import { getClientInfo } from '@selectors/client.js';
import { getPrevHorizon } from '@selectors/featureProps';
import { getSalesPointStores } from '@selectors/stores';

import { weatherStation as weatherStationService } from '@services/weatherStation';

import DeepsightPastErrorForecastChart from '../../Forecast/Forecasts/containers/PastErrorForecastContainer/DeepsightPastErrorForecastChart';
import Utils from '../../Forecast/Forecasts/containers/utils/UtilsFunctions';

import { CHART_LEGEND, DAYS_TO_FETCH_PAST, SELECTABLE_DAYS } from './utils';
import {
  Container,
  HeaderContainer,
  ToggleContainer,
  ToggleSwitchText,
  ChartContainer,
  ChartContent,
  TitleChartContainer,
} from './styledComponents';

const MANAGER_PREVISION = true;

const Sales = (props) => {
  const {
    match,
    stores,
    prevHorizon,
    pastWeather,
    pastEvents,
    pastSales,
    pastForecasts,
    getPastWeatherForecast,
    getPastForecastOfStore,
    getPastSalesOfStore,
    getPastEventsOfStore,
    pageLoading,
    pageLoaded,
    client: { forecastProperty, turnoverName, isForecastTurnover },
  } = props;

  const [selectedStore, setSelectedStore] = useState(_.get(stores, '[0]', {}));
  const [selectedDaysToDisplay, setSelectedDaysToDisplay] = useState(SELECTABLE_DAYS()[0]);
  const [toggleInpulseForecast, setToggleInpulseForecast] = useState(true);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [legend, setLegend] = useState([]);
  const [weatherObject, setWeatherObject] = useState(null);
  const [eventsObject, setEventsObject] = useState(null);
  const [isRetrievingData, setIsRetrievingData] = useState(true);

  const yesterday = moment.tz(selectedStore.timezone).subtract('1', 'day');

  const periodPickerState = usePeriodDatePickerState(
    moment.tz(selectedStore.timezone).subtract(7, 'days').startOf('day'),
    moment.tz(selectedStore.timezone).subtract(1, 'days').endOf('day'),
    selectedStore.timezone,
  );

  useEffect(() => {
    if (!selectedStore || !periodPickerState.startDate || !periodPickerState.endDate) {
      return;
    }

    setIsRetrievingData(true);
    getCurrentWeatherForecast();
    fetchSalesAndForecasts();
  }, [selectedStore, periodPickerState.startDate, periodPickerState.endDate]);

  useEffect(() => {
    if (!selectedStore || !pastWeather.length) {
      return;
    }

    setIsRetrievingData(true);
    updateResultObject();
  }, [pastWeather, pastSales, pastForecasts, selectedDaysToDisplay, toggleInpulseForecast]);

  useEffect(() => {
    if (!dataToDisplay.length || !pastWeather.length) {
      return;
    }

    getWeather();
    getEvents();
  }, [dataToDisplay, pastWeather]);

  useEffect(() => {
    if (isRetrievingData) {
      pageLoading();
      return;
    }

    pageLoaded();
  }, [isRetrievingData]);

  const getCurrentWeatherForecast = async () => {
    const currentdaysToFetchPast =
      prevHorizon.past > DAYS_TO_FETCH_PAST ? prevHorizon.past : DAYS_TO_FETCH_PAST;

    const storeLocation = selectedStore.locationValue;

    if (!storeLocation) {
      showErrorMessage(i18next.t('FORECAST.SALES.PAST_SALES_LOCATION_FETCH_ERROR'));
      return;
    }

    const weatherStation = await weatherStationService.getWeatherForecastStation(storeLocation);

    if (!weatherStation) {
      showErrorMessage(i18next.t('BACKOFFICE.CHECKS.FIRST_COLUMN_WEATHER_STATION_TEXT'));
      return;
    }

    getPastWeatherForecast(
      weatherStation.id,
      selectedStore.timezone,
      moment
        .tz(periodPickerState.startDate, selectedStore.timezone)
        .subtract(currentdaysToFetchPast, 'days')
        .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      moment
        .tz(periodPickerState.endDate, selectedStore.timezone)
        .add(prevHorizon.future, 'days')
        .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
    );

    getPastEventsOfStore(
      selectedStore.id,
      moment.tz(periodPickerState.startDate, selectedStore.timezone).format(),
      moment.tz(periodPickerState.endDate, selectedStore.timezone).format(),
    );
  };

  const fetchSalesAndForecasts = () => {
    const timezonedStartDate = moment
      .tz(periodPickerState.startDate, selectedStore.timezone)
      .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

    const timezonedEndDate = moment
      .tz(periodPickerState.endDate, selectedStore.timezone)
      .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

    getPastForecastOfStore(selectedStore.id, timezonedStartDate, timezonedEndDate);
    getPastSalesOfStore(selectedStore.id, timezonedStartDate, timezonedEndDate, isForecastTurnover);
  };

  const getDaysArray = (startDate, endDate, granularity) => {
    let currentDate = moment.tz(startDate, selectedStore.timezone).startOf('day');
    const formattedEndDate = moment.tz(endDate, selectedStore.timezone).endOf('day');

    let dateArray = [];
    if (!granularity.id) {
      while (currentDate.isBefore(formattedEndDate)) {
        dateArray.push(currentDate.format());
        currentDate = currentDate.add(1, 'day');
      }

      return dateArray;
    }

    while (currentDate.isBefore(formattedEndDate)) {
      if (currentDate.isoWeekday() === granularity.id) {
        dateArray.push(currentDate.format());
      }

      currentDate = currentDate.add(1, 'day');
    }

    return dateArray;
  };

  const updateResultObject = () => {
    const arrayOfDates = getDaysArray(
      periodPickerState.startDate.format(),
      periodPickerState.endDate.format(),
      selectedDaysToDisplay,
    );

    let resObj = {},
      dynamicExists = false,
      savedExists = false,
      salesExists = false;
    const formattedData = arrayOfDates.map((date) => {
      const timezonedDate = moment.tz(date, selectedStore.timezone);
      resObj = {};
      resObj.timestamp = date;
      resObj.id = date;
      resObj.sales = null;
      if (MANAGER_PREVISION) {
        resObj.saved = null;
        pastForecasts.forEach((forecast) => {
          if (
            moment.tz(forecast.timestamp, selectedStore.timezone).isSame(timezonedDate, 'day') &&
            forecast.type === 'saved'
          ) {
            savedExists = true;
            resObj.saved = isNaN(parseInt(forecast[forecastProperty], 10))
              ? null
              : getNumberFormattedWithDecimals(forecast[forecastProperty], 0);
          }
        });
      }
      if (!MANAGER_PREVISION || toggleInpulseForecast) {
        resObj.dynamic = null;
        pastForecasts.forEach((forecast) => {
          if (
            moment.tz(forecast.timestamp, selectedStore.timezone).isSame(timezonedDate, 'day') &&
            forecast.type === 'dynamic'
          ) {
            dynamicExists = true;
            resObj.dynamic = isNaN(parseInt(forecast[forecastProperty], 10))
              ? null
              : getNumberFormattedWithDecimals(forecast[forecastProperty], 0);
          }
        });
      }
      pastSales.forEach((sale) => {
        if (moment.tz(sale.timestamp, selectedStore.timezone).isSame(timezonedDate, 'day')) {
          salesExists = true;
          resObj.sales = getNumberFormattedWithDecimals(sale[forecastProperty], 0);
        }
      });
      if (!MANAGER_PREVISION) {
        resObj.error =
          resObj.dynamic !== null && resObj.sales !== null ? resObj.sales - resObj.dynamic : 0;
      } else {
        resObj.error =
          resObj.saved !== null && resObj.sales !== null ? resObj.sales - resObj.saved : 0;
      }
      return resObj;
    });

    setLegend(
      CHART_LEGEND().map((elem) => {
        if (elem.id === 0 && dynamicExists === false) {
          return {};
        } else if (elem.id === 1 && savedExists === false) {
          return {};
        } else if (elem.id === 2 && salesExists === false) {
          return {};
        } else if (elem.id === 2) {
          elem.value = turnoverName;

          return elem;
        } else {
          return elem;
        }
      }),
    );

    setDataToDisplay(formattedData);
    setIsRetrievingData(false);
  };

  const getWeather = () => {
    let weatherObject = {};

    const weatherGroupByTimestamp = _.groupBy(pastWeather, (day) =>
      moment
        .tz(day.timestamp, selectedStore.timezone)
        .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
    );

    dataToDisplay.forEach(function (day) {
      if (
        weatherGroupByTimestamp[
          moment
            .tz(day.timestamp, selectedStore.timezone)
            .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY)
        ]
      ) {
        const filteredWeather = _.head(
          weatherGroupByTimestamp[
            moment
              .tz(day.timestamp, selectedStore.timezone)
              .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY)
          ],
        );

        if (filteredWeather) {
          const temperature = parseInt(filteredWeather.tMax, 10);
          const img = _.get(
            filteredWeather,
            'lnkWeathericonWeatherdayrel.img',
            '/images/icon-weather-cloud.png',
          );

          weatherObject[day.timestamp] = {
            temperature: temperature,
            img: img,
          };
        } else {
          weatherObject[day.timestamp] = {};
        }
      }
    });

    setWeatherObject(weatherObject);
  };

  const getEvents = () => {
    let eventsObject = {};
    let events = pastEvents;

    dataToDisplay.forEach((day) => {
      let filteredEvents = events.filter(function (event) {
        let currentDate = moment
          .tz(day.timestamp, selectedStore.timezone)
          .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
        return (
          currentDate >=
            moment
              .tz(event.startDate, selectedStore.timezone)
              .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY) &&
          currentDate <=
            moment
              .tz(event.endDate, selectedStore.timezone)
              .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY)
        );
      });

      let filteredFilteredEvents = Utils.sortEvents(filteredEvents);
      eventsObject[day.timestamp] = filteredFilteredEvents.slice(
        Math.max(filteredFilteredEvents.length - 3, 0),
      ); //Max 3 events
    });
    setEventsObject(eventsObject);
  };

  return (
    <Container>
      <NavigationBreadCrumb featurePath={match.path} />

      <HeaderContainer>
        <Dropdown
          iconSrc={
            !selectedStore.length
              ? '/images/inpulse/pin-dmgrey-small.svg'
              : '/images/inpulse/pin-black-small.svg'
          }
          items={stores}
          selectedItem={selectedStore}
          isRequired
          isUniqueSelection
          onSelectionChange={setSelectedStore}
        />
        <PeriodDatePicker
          endDate={periodPickerState.endDate}
          focusedDateInput={periodPickerState.focusedDateInput}
          maxFutureDate={yesterday}
          setFocusedDateInput={periodPickerState.setFocusedDateInput}
          startDate={periodPickerState.startDate}
          timezone={selectedStore.timezone}
          onDatesChange={periodPickerState.handleSelectedDates}
        />
        <Dropdown
          items={SELECTABLE_DAYS()}
          selectedItem={selectedDaysToDisplay}
          sortBy={null}
          isRequired
          isUniqueSelection
          onSelectionChange={setSelectedDaysToDisplay}
        />
        <ToggleContainer>
          <ToggleSwitch
            checked={toggleInpulseForecast}
            handleClick={() => setToggleInpulseForecast(!toggleInpulseForecast)}
            id="fill-inpulse-reco"
          />
          <ToggleSwitchText>{i18next.t('GENERAL.INPULSE_FORECAST')}</ToggleSwitchText>
        </ToggleContainer>
      </HeaderContainer>

      <ChartContainer>
        {!isRetrievingData && (
          <ChartContent>
            <TitleChartContainer>
              {i18next.t('ANALYSIS.SALES.GRAPH_TITLE_PAST_SALES')}
            </TitleChartContainer>
            <DeepsightPastErrorForecastChart
              bar_color="green/red"
              data={dataToDisplay}
              daysToDisplay={
                periodPickerState.startDate && periodPickerState.endDate
                  ? periodPickerState.endDate.diff(periodPickerState.startDate, 'days') + 1
                  : 0
              }
              eventsObject={eventsObject}
              height={535}
              legend={legend}
              MANAGER_PREVISION={toggleInpulseForecast}
              metricName={turnoverName}
              storeTimezone={selectedStore.timezone}
              weatherObject={weatherObject}
            />
          </ChartContent>
        )}
      </ChartContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  stores: getSalesPointStores(state.baseReducer.activeStores),
  pastForecasts: state.baseReducer.pastForecasts,
  pastSales: state.baseReducer.pastSales,
  pastEvents: state.baseReducer.pastEvents,
  pastWeather: state.baseReducer.pastWeather,
  weatherStation: state.baseReducer.weatherStation,
  prevHorizon: getPrevHorizon(state.baseReducer.userRights),
  client: getClientInfo(state.baseReducer.user),
});
const mapDispatchToProps = (dispatch) => ({
  getPastWeatherForecast: (weatherStationId, storeTimezone, startDate, endDate) => {
    dispatch(fetchPastWeather(weatherStationId, storeTimezone, startDate, endDate)).then(
      (result) => {
        dispatch(receivePastWeather(result));
      },
      (error) => {
        dispatch(fetchPastWeatherError(error));
      },
    );
  },
  getPastForecastOfStore: (storeId, startDate, endDate) => {
    dispatch(fetchPastForecastsOfStore(storeId, startDate, endDate)).then(
      (result) => {
        dispatch(receivePastForecasts(result));
      },
      (error) => {
        dispatch(requestPastForecastsError(error));
      },
    );
  },
  getPastSalesOfStore: (storeId, startDate, endDate, isForecastTurnover) => {
    dispatch(fetchPastSalesOfStore(storeId, startDate, endDate, isForecastTurnover)).then(
      (result) => {
        dispatch(receivePastSales(result));
      },
      (error) => {
        dispatch(requestPastSalesError(error));
      },
    );
  },
  getPastEventsOfStore: (storeId, startDate, endDate) => {
    dispatch(fetchPastEventsOfStore(storeId, startDate, endDate)).then(
      (result) => {
        dispatch(receivePastEvents(result));
      },
      (error) => {
        dispatch(fetchPastEventError(error));
      },
    );
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Sales);

import { ChromePicker } from 'react-color';
import React, { useState, useEffect, useRef } from 'react';

import { getTheme } from '@commons/utils/theme';

import {
  ColorPickerButtonContainer,
  ColorPickerColoredSquare,
  ColorPickerContainer,
  ColorPickerModal,
} from '../styledComponents';

const ColorPicker = (props) => {
  const { onChange } = props;

  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState('#000000');

  const theme = getTheme();
  const colorPickerRef = useRef(null);

  useEffect(() => {
    onChange('color', color);
  }, [color]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
        setDisplayColorPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [colorPickerRef]);

  return (
    <ColorPickerContainer>
      <ColorPickerButtonContainer>
        <ColorPickerColoredSquare
          color={color}
          onClick={() => setDisplayColorPicker(!displayColorPicker)}
        ></ColorPickerColoredSquare>
      </ColorPickerButtonContainer>
      {displayColorPicker && (
        <ColorPickerModal ref={colorPickerRef} onClick={(event) => event.stopPropagation()}>
          <ChromePicker
            color={color}
            disableAlpha
            onChangeComplete={({ hex }) => setColor(hex || theme.colors.black)}
          />
        </ColorPickerModal>
      )}
    </ColorPickerContainer>
  );
};

export default ColorPicker;

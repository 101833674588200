import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00EA83',
    },
    secondary: {
      main: '#222321',
    },
    error: {
      main: '#EF252A',
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: 'Inter',

    h1: {
      fontSize: '24px',
      fontWeight: 700,
      color: '#222321',
    },
    h2: {
      fontSize: '20px',
      fontWeight: 700,
      color: '#222321',
    },
    h3: {
      fontSize: '16px',
      fontWeight: 700,
      color: '#222321',
    },
    subtitle1: {
      fontSize: '14px',
      fontWeight: 700,
      color: '#6D706B',
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: 500,
      color: '#6D706B',
    },
    body1: {
      fontSize: '14px',
      fontWeight: 500,
      color: '#222321',
    },
    body2: {
      fontSize: '12px',
      fontWeight: 500,
      color: '#222321',
    },
    button: {
      fontSize: '14px',
      fontWeight: 700,
      textTransform: 'none',
      color: '#222321',
    },
    caption: {
      fontSize: '12px',
      fontWeight: 500,
      color: '#6D706B',
    },
    overline: {
      fontSize: '12px',
      fontWeight: 500,
      color: '#6D706B',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'contained' && {
            backgroundColor: '#222321',
            color: '#ffffff',
            borderRadius: '24px',
            '&:hover': {
              backgroundColor: '#00EA83',
              color: '#222321',
            },
            font: 'normal 700 14px/16px inter-bold',
          }),
          ...(ownerState.variant === 'text' && {
            color: '#222321',
            '&:hover': {
              color: '#00EA83',
            },
            font: 'normal 700 14px/16px inter-bold',
          }),
        }),
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#222321',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#E3E3E2',
          backgroundColor: '#FFFFFF',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          backgroundColor: '#FFFFFF',

          '& .consumptionViaOrders': {
            color: '#222321',
          },

          '& .theorical': {
            color: '#00E7E3',
          },

          '& .real': {
            color: '#979797',
          },
        },
        toolbarContainer: {
          padding: '16px',
          borderBottom: '1px solid #E3E3E2',
          '& .MuiBadge-badge': {
            // filters badge
            minWidth: '10px',
            height: '10px',
            padding: '6px 4px',
          },
        },
        main: {
          borderRadius: 'inherit',
        },
        'pinnedColumnHeaders--left': {
          boxShadow: '2px 0px 4px -2px rgba(0,0,0,0.21)',
        },
        'pinnedColumnHeaders--right': {
          boxShadow: '-2px 0px 4px -2px rgba(0,0,0,0.21)',
          borderRight: 'none',

          '& .MuiDataGrid-columnHeader--withRightBorder': {
            borderRight: 'none',
          },
        },
        'pinnedColumns--right': {
          '& .MuiDataGrid-cell--withRightBorder': {
            borderRight: 'none',
          },
        },
        menuIconButton: {
          padding: '0px',

          '&:hover': {
            backgroundColor: '#00EA83',
          },
        },
        columnHeadersInner: {
          '& .customMenuIcon': {
            '& .MuiDataGrid-menuIcon': {
              visibility: 'visible',
            },
          },
        },
        virtualScroller: {
          scrollbarWidth: 'none',
          minHeight: '80px',
        },
        panel: {
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16);',
          borderRadius: '8px',
        },
        menu: {
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16);',
          borderRadius: '8px',
        },
        paper: {
          borderRadius: '8px',
        },
        detailPanel: {
          borderBottom: '1px solid #E3E3E2',
          overflow: 'unset',
        },
        iconButtonContainer: {
          '& .MuiBadge-root': {
            '& .MuiBadge-anchorOriginTopRightRectangular': {
              display: 'none',
            },
          },
        },
      },
    },
  },
});

export default theme;

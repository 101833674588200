import { connect } from 'react-redux';
import { get, keyBy } from 'lodash';
import Box from '@mui/material/Box';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { Button, Dropdown } from '@commons/utils/styledLibraryComponents';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { DisabledDatePicker } from '@commons/DatePickers/DisabledDatePicker';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getUserTimezone } from '@commons/utils/date';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import { getClientInfo } from '@selectors/client';
import { getSalesPointStores } from '@selectors/stores';

import { supplier as supplierService } from '@services/supplier';
import orderService from '@services/order';

import {
  InputContainer,
  Footer,
  Header,
  SummaryContainer,
  DatePickerContainer,
} from './styledComponents';

const modalSx = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 560,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
};

export const OrdersFromRecosModal = (props) => {
  const {
    handleClose,
    reloadOrders,
    // From Redux
    salesPointStores,
    client: { storeName },
    pageLoading,
    pageLoaded,
    showSuccessMessage,
    showErrorMessage,
  } = props;

  /** --- STATES --- */
  const [isConfiguring, setIsConfiguring] = useState(true);
  const [isLoadingSuppliers, setIsLoadingSuppliers] = useState(false);

  const [selectedStores, setSelectedStores] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);

  const [result, setResult] = useState({});

  const translatedStoreName = getClientStoreNameTranslation(storeName, true);

  const TOMORROW = moment.tz(getUserTimezone()).add(1, 'day');
  const salesPointStoresKeyById = keyBy(salesPointStores, 'id');

  /** --- USE EFFECTS --- */
  useEffect(() => {
    if (!selectedStores.length) {
      return;
    }

    setSelectedSupplier(null);
    setIsLoadingSuppliers(true);
    const storeIds = selectedStores.map(({ id }) => id);

    (async () => {
      try {
        /**
         * TODO: If the feature is one day enabled for other clients:
         * rework this to have a dedicated endpoint finding the common suppliers of the selected stores
         *
         * For now, the feature being only for FeelnFood, we can use the existing endpoint
         */
        const fetchedSuppliers = await supplierService.getSuppliersOfStores(storeIds, {
          withLinkedData: false,
        });

        setSuppliers(fetchedSuppliers);
      } catch {
        showErrorMessage(i18next.t('ADMIN.SUPPLIERS.FETCH_FAILURE'));
      } finally {
        setIsLoadingSuppliers(false);
      }
    })();
  }, [selectedStores]);

  /** --- FUNCTIONS --- */
  const validateOrdersFromRecos = async () => {
    const storeIds = selectedStores.map(({ id }) => id);

    try {
      const result = await orderService.validateOrdersFromRecos(
        storeIds,
        selectedSupplier.id,
        moment(TOMORROW).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        getUserTimezone(),
      );

      setResult(result);
      setIsConfiguring(false);
    } catch {
      showErrorMessage(i18next.t('ORDERS.ORDERS.VALIDATE_FAILURE'));
    }
  };

  const returnToConfiguration = () => {
    setIsConfiguring(true);
  };

  const createOrdersFromRecommendations = async () => {
    pageLoading();
    const storeIds = get(result, 'storesWithoutOrders', []);

    try {
      handleClose();

      const userTimezone = getUserTimezone();

      const { storesWithNoOrder } = await orderService.createOrdersFromRecos(
        storeIds,
        selectedSupplier.id,
        moment(TOMORROW).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        userTimezone,
      );

      if (!storesWithNoOrder.length) {
        showSuccessMessage(i18next.t('ORDERS.ORDERS.BATCH_CREATION_FROM_RECOMMENDATION_SUCCESS'));
      } else {
        showErrorMessage(
          i18next.t('ORDERS.ORDERS.BATCH_CREATION_FROM_RECOMMENDATION_NO_ORDER', {
            count: storesWithNoOrder.length,
            storeName: storesWithNoOrder.join(', '),
          }),
        );
      }
    } catch {
      showErrorMessage(i18next.t('ORDERS.ORDERS.BATCH_CREATION_FROM_RECOMMENDATION_FAILURE'));
    } finally {
      await reloadOrders();
      pageLoaded();
    }
  };

  if (isConfiguring) {
    return (
      <Box sx={modalSx}>
        <Header>
          <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.H1}>
            {i18next.t('ORDERS.ORDERS.MODAL_CREATE_FROM_RECOMMENDATION_TITLE')}
          </Text>
        </Header>
        <InputContainer>
          <Dropdown
            iconSrc="/images/inpulse/pin-black-small.svg"
            isUniqueSelection={false}
            items={salesPointStores}
            label={translatedStoreName}
            searchPlaceholder={i18next.t('GENERAL.SEARCH')}
            selectedItems={selectedStores}
            isRequired
            onSelectionChange={setSelectedStores}
          />
        </InputContainer>
        <InputContainer>
          <Dropdown
            iconSrc="/images/inpulse/pin-black-small.svg"
            isDisabled={!selectedStores.length || isLoadingSuppliers}
            items={suppliers}
            label={i18next.t('GENERAL.SUPPLIER')}
            searchPlaceholder={i18next.t('GENERAL.SEARCH')}
            selectedItem={selectedSupplier}
            isRequired
            onSelectionChange={setSelectedSupplier}
          />
        </InputContainer>
        <InputContainer>
          <DatePickerContainer>
            <Text>{i18next.t('GENERAL.DATE')}</Text>
            <DisabledDatePicker date={TOMORROW} />
          </DatePickerContainer>
        </InputContainer>
        <Footer>
          <Button
            color={'inpulse-outline'}
            handleClick={handleClose}
            icon={'/images/inpulse/close-black-small.svg'}
            label={i18next.t('GENERAL.CANCEL')}
          />
          <Button
            color={'inpulse-default'}
            handleClick={validateOrdersFromRecos}
            icon={'/images/inpulse/check-white-small.svg'}
            isDisabled={!selectedStores.length || !selectedSupplier}
            label={i18next.t('GENERAL.VALIDATE')}
          />
        </Footer>
      </Box>
    );
  }

  const numberOfOrders = get(result, 'storesWithoutOrders.length', 0);
  const displayStoresWithExistingOrders = get(result, 'storesWithExistingOrders.length', 0) > 0;

  return (
    <Box sx={modalSx}>
      <Header>
        <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.H1}>
          {i18next.t('ORDERS.ORDERS.MODAL_CREATE_FROM_RECOMMENDATION_TITLE')}
        </Text>
      </Header>

      <SummaryContainer>
        <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.H3}>
          {i18next.t('ORDERS.ORDERS.NUMBER_OF_ORDERS_FROM_RECOMMENDATION', {
            numberOfOrders,
          })}
        </Text>
        {displayStoresWithExistingOrders && (
          <>
            <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.H3}>
              {i18next.t('ORDERS.ORDERS.STORES_WITH_EXISTING_ORDERS')}
            </Text>
            {result.storesWithExistingOrders.map((storeId) => (
              <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.H3} key={storeId}>
                {salesPointStoresKeyById[storeId].name}
              </Text>
            ))}
          </>
        )}
        <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.H3}>
          {i18next.t('GENERAL.SUPPLIER')} : {selectedSupplier.name}
          <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.H3}></Text>
          {i18next.t('GENERAL.DELIVERY_DATE')} :
          {TOMORROW.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY)}
        </Text>
      </SummaryContainer>
      <Footer>
        <Button
          color={'inpulse-outline'}
          handleClick={returnToConfiguration}
          icon={'/images/inpulse/arrow-left-ip-black.svg'}
          label={i18next.t('GENERAL.BACK')}
        />
        <Button
          color={'inpulse-default'}
          handleClick={createOrdersFromRecommendations}
          icon={'/images/inpulse/check-white-small.svg'}
          isDisabled={!selectedStores.length}
          label={i18next.t('GENERAL.CONFIRM')}
        />
      </Footer>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  salesPointStores: getSalesPointStores(state.baseReducer.activeStores),
  modal: state.modalReducer,
  currency: state.baseReducer.currency,
});

const mapDispatchToProps = (dispatch) => ({
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersFromRecosModal);

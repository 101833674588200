import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { DeepsightUploadPicture } from '@commons/DeepsightComponents';

import {
  EmptyInput,
  ProductBrandsInput,
  ProductCategoryInput,
  ProductDeliveryPriceInput,
  ProductDeliveryVatInput,
  ProductDlcInput,
  ProductNameInput,
  ProductPriceInput,
  ProductSkuInput,
  ProductSubCategoryInput,
  ProductVatInput,
} from '@admin/products/products/detail/components/ProductInfo/common/inputs';
import ProductComposition, {
  getCompositionTypes,
} from '@admin/products/products/detail/components/ProductRecipeAssociation';

import { CompositionContainer, Content, Section, SectionInfo, Title } from './styledComponents';

/**
 * Render the information of the given product following the list of inputs that should be displayed
 *
 * @param {Props} props                   - The props linked to the component
 * @param {Inputs[]} listFieldsBySection  - The list of custom inputs to display
 *
 * @returns {Component}
 */
export function renderFieldsBySections(props, listFieldsBySection) {
  const { product } = props;

  return (
    <div>
      {listFieldsBySection.map((section, indexSection) => (
        <Section key={indexSection}>
          {section.map((item, indexItem) => {
            const ComponentName = item.component;

            return (
              <SectionInfo key={indexItem}>
                <ComponentName
                  {...item.props(props)}
                  label={item.name}
                  value={get(product, `${item.keyProperty}`, '')}
                />
              </SectionInfo>
            );
          })}
        </Section>
      ))}
    </div>
  );
}

/**
 * Render box containing the main inputs related to the details of product
 *
 * @param {Props} props - The props linked to the component
 *
 * @returns {Component}
 */
export function renderMainInfoProduct(props) {
  const { hasMultipleBrands } = props;

  const mainProductFieldsBySection = getMainInputsProduct(hasMultipleBrands);

  return (
    <Content>
      <Title>{i18next.t('ADMIN.PRODUCTS.CREATION_INFO')}</Title>
      {renderFieldsBySections(props, mainProductFieldsBySection)}
    </Content>
  );
}

/**
 * Render box containing the inputs related to the price of a product
 *
 * @param {Props} props - The props linked to the component
 *
 * @returns {Component}
 */
export function renderPriceInfoProduct(props) {
  const {
    currency,
    client: { hasMultipleChannels },
  } = props;
  const priceProductFieldsBySection = getPriceInputsProduct(currency, hasMultipleChannels);

  return (
    <Content>
      <Title>{i18next.t('ADMIN.PRODUCTS.CREATION_PRICE')}</Title>
      {renderFieldsBySections(props, priceProductFieldsBySection)}
    </Content>
  );
}

/**
 * Render box allowing to update a product picture
 *
 * @param {Props} props - The props linked to the component
 *
 * @returns {Component}
 */
export function renderPictureProduct(props) {
  const { product, onProductChange } = props;

  return (
    <Content className={'container-picture'}>
      <Title>{i18next.t('ADMIN.PRODUCTS.CREATION_PICTURE')}</Title>
      <DeepsightUploadPicture
        handleSelectedPhoto={(fileUri) => onProductChange({ ...product, img: fileUri })}
        pictureUrl={product.img}
        button
      />
    </Content>
  );
}

export const renderProductRecipeAssociation = (props) => {
  const { product, onProductChange } = props;

  const compositionTypes = getCompositionTypes();

  const [composition, setComposition] = useState({});
  const [selectedCompoType, setSelectedCompoType] = useState(compositionTypes[0]);

  const formattedProduct = {
    ...product,
    priceWithTaxes: product.price ? parseFloat(product.price) : null,
    deliveryPriceWithTaxes: product.deliveryPrice ? parseFloat(product.deliveryPrice) : null,
  };

  useEffect(() => {
    if (!product.selectedEntity) {
      return;
    }

    setComposition(product.selectedEntity);
    setSelectedCompoType(
      product.selectedEntity.isIngredient ? compositionTypes[2] : compositionTypes[1],
    );
  }, []);

  useEffect(() => {
    if (!(selectedCompoType.id !== 'NONE' && isEmpty(composition))) {
      onProductChange({
        ...product,
        entityId: composition.id,
        selectedEntity: composition,
        isCompositionValid: true,
      });

      return;
    }

    onProductChange({ ...product, isCompositionValid: false });
  }, [composition]);

  return (
    <CompositionContainer>
      <ProductComposition
        composition={composition}
        product={formattedProduct}
        selectedCompoType={selectedCompoType}
        setComposition={setComposition}
        setSelectedCompoType={setSelectedCompoType}
        onProductChange={onProductChange}
      />
    </CompositionContainer>
  );
};

/**
 * Get the custom list of inputs related to the definition of a product to allow in the form
 * according the client settings (hasMultipleBrands)
 *
 * @param {Boolean} hasMultipleBrands - Whether the client is configured with multiple brands
 *
 * @returns {Inputs[]} The list of custom inputs to display
 */
export function getMainInputsProduct(hasMultipleBrands) {
  if (!hasMultipleBrands) {
    return [
      [
        { ...ProductNameInput(), required: true },
        ProductCategoryInput(),
        ProductSubCategoryInput(),
      ],
      [ProductSkuInput(), ProductDlcInput(), EmptyInput],
    ];
  }

  return [
    [{ ...ProductNameInput(), required: true }, ProductBrandsInput(), ProductCategoryInput()],
    [ProductSubCategoryInput(), ProductSkuInput(), ProductDlcInput()],
  ];
}

/**
 * Get the custom list of inputs related to the product's price to allow in the form
 *
 * @returns {Inputs[]} The list of custom inputs to display
 */
export function getPriceInputsProduct(currency, hasMultipleChannels) {
  if (!hasMultipleChannels) {
    return [
      [
        {
          ...ProductPriceInput(currency),
          required: true,
        },
        { ...ProductVatInput(), required: true },
        EmptyInput,
      ],
    ];
  }

  return [
    [
      {
        ...ProductPriceInput(currency),
        required: true,
      },
      { ...ProductVatInput(), required: true },
      ProductDeliveryPriceInput(currency),
    ],
    [ProductDeliveryVatInput(), EmptyInput, EmptyInput],
  ];
}

const StepProductInfo = (props) => (
  <div>
    {renderMainInfoProduct(props)}
    {renderProductRecipeAssociation(props)}
    {renderPriceInfoProduct(props)}
    {renderPictureProduct(props)}
  </div>
);

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
});

export default connect(mapStateToProps)(StepProductInfo);

import { connect } from 'react-redux';
import { get, isEmpty, omit } from 'lodash';
import i18next from 'i18next';
import React, { useContext, useEffect, useState } from 'react';

import {
  closeGenericModal,
  openGenericModal,
  openMediumModal,
  openSmallModal,
  refreshGenericModal,
} from '@actions/modal';
import { loading, loadingSuccess } from '@actions/loading';
import {
  patchMultipleProducts,
  receivePatchMultipleProducts,
  requestPatchMultipleProductsError,
} from '@actions/product';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { Button, ListView } from '@commons/utils/styledLibraryComponents';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_SAVE_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { handleOpenedImportFileModal } from '@commons/Modals/ImportModal/utils/config';
import { IMPORT_STATE, IMPORT_TYPE, SWITCH_STEP } from '@commons/Modals/ImportModal/utils/constant';
import { isUserDeepsight, isUserManager } from '@commons/utils/roles';
import { sortArrayOfObjectsAlphabetically } from '@commons/utils/sorting';
import GeneralEmptyStateListView from '@commons/GeneralEmptyStateListView';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { CentralKitchenProductContext } from '@context/CentralKitchenProductContext';
import { ProductContext } from '@context/ProductContext';

import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';

import { product as productService } from '@services/product';
import centralService from '@services/central';
import clientService from '@services/client';

import { downloadFile, formatXlsFileToJson } from '@backoffice/utils';
import EmptyState from '@backoffice/BackOfficeStoresContainer/BackOfficeStoresManagement/components/EmptyState';

import { CHOICES_DROPDOWN_ACTIVE } from '@admin/utils/DropdownItems';
import { getIsInCentralKitchenOnlyPage } from '@admin/utils/centralKitchen';
import CentralKitchenProductExport from '@admin/KitchenProducts/KitchenProductsProducts/components/CentralKitchenProductExport';
import DeepsightFiltersButton from '@admin/components/FilterButton';

import { formatCentralKitchenProductBatchCreationFile } from './utils/formatCentralKitchenProductBatchCreationFile';
import { formatProductBatchCreationFile } from './utils/formatProductsBatchCreationFile';
import { getActions, getRowActions } from './utils/getActions';
import { getCentralKitchenProductsColumns, getColumns } from './utils/columns';
import { getCompositionLabel } from './utils/getProductExportLabels';
import { getEnableProductsModalParams } from './utils/modalConfigurations';
import { ListViewContainer } from './styledComponents';
import CreateProductModal from './components/CreateProductModal';
import DeleteProductsModal from './components/DeleteProductsModal';
import EnableProductModal, {
  enableProductMapCashierProductsAndAddProductionParameters,
} from './components/EnableProductModal';
import ProductExportModal from './components/ProductExportModal';
import utils from './detail/components/ProductInfo/utils';

export const Products = (props) => {
  const {
    user,
    authorizedActions,
    currency,
    modal,
    pageLoading,
    pageLoaded,
    showSuccessMessage,
    showErrorMessage,
    openGenericModal,
    refreshGenericModal,
    openModalExportInfo,
    closeGenericModal,
    patchMultipleProducts,
    history,
    client: { clientId, storeName },
    match: { path },
  } = props;

  /* STATES */

  // Global states
  const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');
  const isInCentralKitchenOnlyPage = getIsInCentralKitchenOnlyPage();
  const columns = isInCentralKitchenOnlyPage
    ? getCentralKitchenProductsColumns(storeName)
    : getColumns(props.client);

  const {
    selectedStatus,
    setSelectedStatus,
    advancedFilters,
    setAdvancedFilters,
    currentPage,
    maxPerPage,
    orderBy,
    orderType,
    search,
    setCurrentPage,
    setMaxPerPage,
    setOrderBy,
    setOrderType,
    setSearch,
  } = useContext(isInCentralKitchenOnlyPage ? CentralKitchenProductContext : ProductContext) || {};

  // ListView states
  const [actions, setActions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);
  const [emptyState, setEmptyState] = useState(false);
  const [rowActions, setRowActions] = useState([]);

  // Data states
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  // Filter states
  const [filteringOnActive, setFilteringOnActive] = useState(
    get(selectedStatus, 'filterValue', false),
  );
  const [filters, setFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);
  const [columnsFilterList, setColumnsFilterList] = useState([]);

  // Actions
  const [enablingProduct, setEnablingProduct] = useState(null); // when set, render EnableProductModal component
  const [currentStep, setCurrentStep] = useState(IMPORT_STATE.SELECT_FILE);
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorFile, setErrorFile] = useState(null);
  const [batchProductsToCreate, setBatchProductsToCreate] = useState(null);

  /* USE EFFECT  */

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    const actions = getActions({
      filteringOnActive,
      products,
      renderButton,
      selectedItems,
      triggerExport,
      user,
      openGenericModal,
      handleProductsDeletion,
      handleProductChangeModalBatch,
      disableProducts,
      getProductBatchCreationTemplate,
      getCentralKitchenProductBatchCreationTemplate,
      openProductsImportModal,
      handleEnableProduct,
      isInCentralKitchenOnlyPage,
      authorizedActions,
    });

    setActions(actions);

    const rowActions = getRowActions({
      filteringOnActive,
      products,
      triggerExport,
      user,
      openGenericModal,
      handleProductsDeletion,
      handleProductChangeModalBatch,
      disableProducts,
      handleEnableProduct,
      isInCentralKitchenOnlyPage,
    });

    setRowActions(rowActions);
  }, [products, selectedItems, filteredProducts, selectedStatus]);

  useEffect(() => {
    processAdvancedFilters(products);
  }, [advancedFilters, applyFilters, enablingProduct, selectedStatus]);

  useEffect(() => {
    if (!selectedFile) {
      return;
    }

    handleOpenedImportFileModal(
      {
        currentStep,
        importType: IMPORT_TYPE.PRODUCTS,
        handleSwitchStep,
        handleFileChange,
        selectedFile: selectedFile,
        exportErrorData: errorFile,
        cleanImportModal: cleanUpModalImport,
      },
      openGenericModal,
      refreshGenericModal,
      get(modal, 'GenericModalBool', false),
    );
  }, [selectedFile, currentStep]);

  /* METHODS */

  const goProductDetailsPage = (product) => {
    if (isInCentralKitchenOnlyPage) {
      history.push({
        pathname: `/admin/kitchen-products/products/${product.id}/details`,
      });

      return;
    }

    history.push({
      pathname: `/admin/products/${product.id}/details`,
    });
  };

  const triggerCreate = () => {
    const { openModal } = props;

    if (isInCentralKitchenOnlyPage) {
      history.push({
        pathname: '/admin/kitchen-products/products/create',
      });

      return;
    }

    const params = {
      component: CreateProductModal,
      history,
      clientCurrency: currency,
    };

    return openModal(params);
  };

  const triggerExport = (items) => {
    if (isInCentralKitchenOnlyPage) {
      openModalExportInfo({
        component: CentralKitchenProductExport,
        title: i18next.t('ADMIN.PRODUCTS.EXPORT'),
        products: filteredProducts,
      });

      return;
    }

    openModalExportInfo({
      component: ProductExportModal,
      title: i18next.t('ADMIN.PRODUCTS.EXPORT'),
      products: items || filteredProducts,
    });
  };

  const handleProductsDeletion = (products) => {
    closeGenericModal(); // close confirmation modal

    openModalExportInfo({
      component: DeleteProductsModal,
      products,
      title: i18next.t('ADMIN.PRODUCTS.DELETION', { count: products.length }),
      refreshPage: getProducts,
      isInCentralKitchenOnlyPage,
    });
  };

  const getProducts = async () => {
    setIsLoading(true);

    try {
      const products = isInCentralKitchenOnlyPage
        ? await centralService.getCentralKitchenProductsOfClient(clientId)
        : await productService.getProductsOfClient(clientId);

      if (!products || !products.length) {
        setProducts([]);
        setEmptyState(true);

        return;
      }

      const formattedProducts = preprocessProducts(products);

      setProducts(formattedProducts);
    } catch (err) {
      showErrorMessage(i18next.t('GENERAL.FETCH_CLIENT_PRODUCTS_FAILURE'));

      setProducts([]);
    } finally {
      setIsLoading(false);
    }
  };

  const preprocessProducts = (products) => {
    const formattedProducts = products.map((product) => {
      product.name = product.name.trim();
      product.price = product.priceWithTaxes != null ? product.priceWithTaxes : '';
      product.priceHT = utils.getPriceExcludingVAT(product) || '';
      product.category = product.category ? product.category.trim() : null;
      product.subCategory = product.subCategory ? product.subCategory.trim() : null;
      product.composition = getCompositionLabel(product.lnkEntityProductrel);
      product.nbSyncCashierProduct = product.nbCashierProductMappingCount || 0;
      product.brand = product.lnkBrandProductrel ? product.lnkBrandProductrel.name : '';
      return product;
    });

    processAdvancedFilters(formattedProducts);
    updateColumnsFilterList(formattedProducts);

    return formattedProducts;
  };

  const processAdvancedFilters = (products) => {
    if (!applyFilters) {
      return;
    }

    const filteredSelectedProducts = products.filter(
      ({ active }) => active === get(selectedStatus, 'filterValue'),
    );

    if ((!advancedFilters || !advancedFilters.length) && applyFilters) {
      setFilteredProducts(filteredSelectedProducts);
      return;
    }

    const filteredProductsListWithAdvancedFilters = advancedFilters.reduce(
      (result, { doFilter, propertyKey, value, filterType, list }) => {
        if (filterType === 'string' && value && value.length === 1 && value[0].id === 'none') {
          return doFilter(result, propertyKey, list, true);
        }
        return doFilter(result, propertyKey, value);
      },
      filteredSelectedProducts,
    );

    setFilteredProducts(filteredProductsListWithAdvancedFilters);
  };

  const updateColumnsFilterList = (products) => {
    let columnsList = columns;

    if (isInCentralKitchenOnlyPage) {
      columnsList = columns.filter(
        ({ propertyKey }) => !['name', 'composition'].includes(propertyKey),
      );
    }

    const updatedColumnsList = columnsList.reduce((result, column) => {
      if (!column.filterType) {
        return result;
      }

      // Due to embed label of associated cashierProducts do this to map to the value for filter
      if (column.id === 'cashierProducts') {
        column.propertyKey = 'nbCashierProductMappingCount';
      }

      const item = { ...column };

      if (column.filterType === 'string') {
        const choices = products.reduce((list, product, key) => {
          if (product[column.propertyKey]) {
            if (typeof product[column.propertyKey] === 'object') {
              product[column.propertyKey].forEach((elem, index) => {
                if (!list[elem]) {
                  list[elem] = {
                    id: index,
                    name: elem,
                    [column.propertyKey]: elem,
                  };
                }
              });
            } else {
              if (!list[product[column.propertyKey]]) {
                list[product[column.propertyKey]] = {
                  id: key,
                  name: product[column.propertyKey],
                  [column.propertyKey]: product[column.propertyKey],
                };
              }
            }
          }
          return list;
        }, {});

        if (!Object.keys(choices).length) {
          return result;
        }

        item.list = sortArrayOfObjectsAlphabetically(Object.values(choices), 'name');
      }

      result.push(item);

      return result;
    }, []);

    return setColumnsFilterList(updatedColumnsList);
  };

  const handleProductChangeModalBatch = (updatedProducts, updateProperties = true, field) => {
    const updatedDataModal = updatedProducts.reduce((result, current) => {
      if (!result[current.value]) {
        result[current.value] = {
          ...omit(current, 'ids'),
          associatedItems: current.ids.map((id) => ({
            id,
          })),
        };
      }
      return result;
    }, {});

    const updatedData = { data: updatedDataModal };

    if (updateProperties) {
      updatedData.properties = Object.keys(updatedDataModal).sort();
    }

    refreshGenericModal(
      {
        data: updatedData,
        actions: [
          GENERIC_MODAL_CANCEL_BUTTON(),
          {
            ...GENERIC_MODAL_SAVE_BUTTON(),
            handleClick: () =>
              updateProduct(updatedProducts, field, {
                successMessage: i18next.t(
                  field === 'category'
                    ? 'GENERAL.MODAL_CATEGORIES_UPDATE_SUCCESS'
                    : 'GENERAL.MODAL_SUBCATEGORIES_UPDATE_SUCCESS',
                ),
                errorMessage: i18next.t(
                  field === 'category'
                    ? 'GENERAL.MODAL_CATEGORIES_UPDATE_ERROR'
                    : 'GENERAL.MODAL_SUBCATEGORIES_UPDATE_ERROR',
                ),
              }),
            isDisabled: false,
          },
        ],
      },
      ['actions', 'data.data', 'data.properties'],
    );
  };

  /**
   * Takes care of the loading state and success/failure toaster for the API call
   * @param {*} formattedData Result from formatDataForApiCall in EnableProductModal/modalConfiguration.js
   */
  const handleCallToEnableProduct = async (formattedData) => {
    pageLoading();

    try {
      isInCentralKitchenOnlyPage
        ? await centralService.enableKitchenProductByIds(formattedData)
        : await enableProductMapCashierProductsAndAddProductionParameters(formattedData);

      showSuccessMessage(
        i18next.t(
          isInCentralKitchenOnlyPage
            ? 'ADMIN.PRODUCTS.ENABLE_KITCHEN_PRODUCTS_SUCCESS'
            : 'ADMIN.PRODUCTS.ENABLE_PRODUCT_SUCCESS',
          { count: isInCentralKitchenOnlyPage ? formattedData.length : 0 },
        ),
      );

      await getProducts();
    } catch (err) {
      showErrorMessage(
        i18next.t(
          isInCentralKitchenOnlyPage
            ? 'ADMIN.PRODUCTS.ENABLE_KITCHEN_PRODUCTS_ERROR'
            : 'ADMIN.PRODUCTS.ENABLE_PRODUCT_FAILURE',
          {
            step: err.message,
            count: isInCentralKitchenOnlyPage ? formattedData.length : 0,
          },
        ),
      );
    } finally {
      pageLoaded();
    }
  };

  const handleEnableProduct = (product) => {
    if (isInCentralKitchenOnlyPage) {
      const params = getEnableProductsModalParams([product], handleCallToEnableProduct);
      openGenericModal(params);
      return;
    }

    setEnablingProduct(product);
  };

  const resetEnablingProduct = () => {
    setEnablingProduct(null);
  };

  const updateProduct = async (updatedProducts, field, { successMessage, errorMessage }) => {
    if (isEmpty(updatedProducts)) {
      return;
    }

    pageLoading();

    const formattedProductsToUpdate = updatedProducts.reduce((acc, current) => {
      const fieldValue = get(current, 'value');
      for (const productId of get(current, 'ids')) {
        const productToUpdate = {
          id: productId,
        };
        productToUpdate[field] = fieldValue;
        acc.push(productToUpdate);
      }
      return acc;
    }, []);

    try {
      await patchMultipleProducts(formattedProductsToUpdate);
      showSuccessMessage(successMessage);

      await getProducts();
    } catch (e) {
      showErrorMessage(errorMessage);
    } finally {
      pageLoaded();
    }
  };

  const handleForProductState = (key) => {
    setSelectedStatus(key);
    setFilteringOnActive(get(key, 'filterValue'));
  };

  const renderButton = (actionLabel) => {
    if (!isUserManager(user)) {
      return (
        <Button
          buttonCustomStyle={{ fontWeight: 400 }}
          color={'blue'}
          fontSize={14}
          formatText={false}
          handleClick={triggerCreate}
          icon={'/images/inpulse/add-white-small.svg'}
          iconCustomStyle={{ width: '14px', height: '14px' }}
          label={actionLabel}
        />
      );
    }
  };

  const disableProducts = async (products) => {
    pageLoading();
    const productsIds = products.map(({ id }) => id);

    try {
      isInCentralKitchenOnlyPage
        ? await centralService.disableKitchenProductByIds(productsIds)
        : await productService.disableProductByIds(productsIds);

      showSuccessMessage(
        i18next.t(
          isInCentralKitchenOnlyPage
            ? 'ADMIN.PRODUCTS.DISABLE_KITCHEN_PRODUCTS_SUCCESS'
            : 'ADMIN.PRODUCTS.DISABLE_PRODUCTS_SUCCESS',
          { count: products.length },
        ),
      );

      await getProducts();
    } catch (error) {
      showErrorMessage(
        i18next.t(
          isInCentralKitchenOnlyPage
            ? 'ADMIN.PRODUCTS.DISABLE_KITCHEN_PRODUCTS_ERROR'
            : 'ADMIN.PRODUCTS.DISABLE_PRODUCTS_ERROR',
          { count: products.length },
        ),
      );
    } finally {
      pageLoaded();
    }
  };

  const getCentralKitchenProductBatchCreationTemplate = async () => {
    pageLoading();

    try {
      const template = await clientService.getCentralKitchenProductBatchCreationTemplate(clientId);

      downloadFile(
        template,
        i18next.t('ADMIN.PRODUCTS.DOWNLOAD_TEMPLATE_FOR_CENTRAL_KITCHEN_IMPORT_FILENAME'),
        false,
        false,
      );
    } catch (err) {
      showErrorMessage(i18next.t('ADMIN.PRODUCTS.DOWNLOAD_TEMPLATE_TOASTER_FAILED'));
    } finally {
      pageLoaded();
    }
  };

  const getProductBatchCreationTemplate = async () => {
    pageLoading();

    try {
      const template = await clientService.getProductBatchCreationTemplate(clientId);

      downloadFile(template, i18next.t('ADMIN.PRODUCTS.DOWNLOAD_TEMPLATE_FOR_IMPORT_FILENAME'));
    } catch (err) {
      showErrorMessage(i18next.t('ADMIN.PRODUCTS.DOWNLOAD_TEMPLATE_TOASTER_FAILED'));
    } finally {
      pageLoaded();
    }
  };

  const postBatchProductsToCreate = async () => {
    pageLoading();

    try {
      isInCentralKitchenOnlyPage
        ? await clientService.postCentralKitchenProductsBatchCreation(
            clientId,
            batchProductsToCreate,
          )
        : await clientService.postProductsBatchCreation(clientId, batchProductsToCreate);

      showSuccessMessage(i18next.t('GENERAL.IMPORT_FILE_BATCH_CREATION_TOASTER_SUCCESS'));
      cleanUpModalImport();

      await getProducts();
    } catch (error) {
      showErrorMessage(i18next.t('GENERAL.IMPORT_FILE_BATCH_CREATION_TOASTER_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const getCentralKitchenProductsBatchFileValidation = async () => {
    pageLoading();

    try {
      const centralKitchenProducts = await formatXlsFileToJson(selectedFile);

      const formattedCentralKitchenProducts =
        formatCentralKitchenProductBatchCreationFile(centralKitchenProducts);

      setBatchProductsToCreate(formattedCentralKitchenProducts);

      await clientService.postCentralKitchenProductsBatchCreationValidate(
        clientId,
        formattedCentralKitchenProducts,
      );

      setCurrentStep(IMPORT_STATE.VALIDATED_FILE);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        setErrorFile(error.response.data);
        setCurrentStep(IMPORT_STATE.ERROR_FILE);
      }
    } finally {
      pageLoaded();
    }
  };

  const getProductsBatchFileValidation = async () => {
    pageLoading();

    try {
      const products = await formatXlsFileToJson(selectedFile);

      const formattedProducts = formatProductBatchCreationFile(products);

      setBatchProductsToCreate(formattedProducts);

      await clientService.postProductsBatchCreationValidate(clientId, formattedProducts);

      setCurrentStep(IMPORT_STATE.VALIDATED_FILE);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        setErrorFile(error.response.data);
        setCurrentStep(IMPORT_STATE.ERROR_FILE);
      }
    } finally {
      pageLoaded();
    }
  };

  const handleFileValidation = async () => {
    !isInCentralKitchenOnlyPage
      ? await getProductsBatchFileValidation()
      : await getCentralKitchenProductsBatchFileValidation();
  };

  const handleSwitchStep = (changeStep) => {
    if (changeStep === SWITCH_STEP.PREVIOUS) {
      cleanUpModalImport();
      return;
    }

    if (currentStep === IMPORT_STATE.SELECT_FILE) {
      handleFileValidation();
    }

    if (currentStep === IMPORT_STATE.ERROR_FILE) {
      cleanUpModalImport();
    }

    if (currentStep === IMPORT_STATE.VALIDATED_FILE) {
      postBatchProductsToCreate();
    }
  };

  const handleFileChange = (file) => {
    setSelectedFile(file);
    openProductsImportModal(file);
  };

  const cleanUpModalImport = () => {
    setSelectedFile(null);
    setErrorFile(null);
    setCurrentStep(IMPORT_STATE.SELECT_FILE);
    setBatchProductsToCreate(null);

    closeGenericModal();
  };

  const openProductsImportModal = (file) => {
    const productCreationFile =
      (!!file || file == null) && currentStep === IMPORT_STATE.SELECT_FILE ? file : selectedFile;

    handleOpenedImportFileModal(
      {
        currentStep: currentStep,
        importType: !isInCentralKitchenOnlyPage
          ? IMPORT_TYPE.PRODUCTS
          : IMPORT_TYPE.CENTRAL_KITCHEN_PRODUCTS,
        handleSwitchStep,
        handleFileChange,
        selectedFile: productCreationFile,
        exportErrorData: errorFile,
        cleanImportModal: cleanUpModalImport,
      },
      openGenericModal,
      refreshGenericModal,
      get(modal, 'GenericModalBool', false),
    );
  };

  const renderEmptyState = (path) => (
    <>
      <NavigationBreadCrumb featurePath={path} />
      <GeneralEmptyStateListView
        icon={'/images/inpulse/product.svg'}
        renderAction={() => (
          <Button
            color="inpulse-default"
            handleClick={() => triggerCreate()}
            icon={'/images/inpulse/add-white-small.svg'}
            label={i18next.t('ADMIN.PRODUCTS.CREATE')}
          />
        )}
        subtitle={i18next.t('ADMIN.PRODUCTS.EMPTY_STATE_SUBTITLE')}
        title={i18next.t('ADMIN.PRODUCTS.EMPTY_STATE_TITLE')}
      />
    </>
  );

  return (
    <>
      <NavigationBreadCrumb featurePath={path} />
      {emptyState ? (
        renderEmptyState()
      ) : (
        <ListViewContainer>
          <ListView
            actionOnClick={(product) => goProductDetailsPage(product)}
            actions={actions}
            columns={columns}
            data={filteredProducts}
            defaultCurrentPage={currentPage}
            defaultMaxPerPage={maxPerPage}
            defaultOrderBy={orderBy}
            defaultOrderType={orderType}
            defaultSearchInput={search}
            handleCurrentPageChange={setCurrentPage}
            handleMaxPerPageChange={setMaxPerPage}
            handleOrderByChange={setOrderBy}
            handleOrderTypeChange={setOrderType}
            handleSearchInputChange={setSearch}
            isLoading={isLoading}
            languageCode={userLanguageCode}
            minActionsInActionsDropdown={1}
            padding={'24px 24px 0px 24px'}
            placeholderShape={i18next.t('GENERAL.SEARCH')}
            renderEmptyState={() => <EmptyState />}
            renderFilterButton={() => (
              <DeepsightFiltersButton
                advancedFilters={advancedFilters}
                applyFilters={applyFilters}
                columnsFilterList={columnsFilterList}
                customSingleDropDowns={[
                  {
                    id: 'product-state',
                    isDisabled: isUserManager(user) && !isUserDeepsight(user),
                    disabledIcon: '/images/inpulse/power-ip-black.svg',
                    itemSelectedIcon: '/images/inpulse/power-ip-black.svg',
                    list: CHOICES_DROPDOWN_ACTIVE(),
                    defaultSelectedItem: CHOICES_DROPDOWN_ACTIVE()[0],
                    selectedItem: selectedStatus,
                    setSelectedItem: handleForProductState,
                  },
                ]}
                filters={filters}
                isLoading={isLoading}
                readOnly={isLoading}
                setAdvancedFilters={setAdvancedFilters}
                setApplyFilters={setApplyFilters}
                setFilters={setFilters}
                textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
              />
            )}
            rowActions={rowActions}
            setSelectedItems={setSelectedItems}
          />
        </ListViewContainer>
      )}
      {!!enablingProduct && (
        <EnableProductModal
          handleCallToEnableProduct={handleCallToEnableProduct}
          product={enablingProduct}
          resetEnablingProduct={resetEnablingProduct}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  modal: state.modalReducer,
  user: state.baseReducer.user,
  client: getClientInfo(state.baseReducer.user),
  currency: state.baseReducer.currency,
  authorizedActions: getAuthorizedActions(state.baseReducer.userRights, '/admin/products/products'),
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (params) => {
    dispatch(openMediumModal(params));
  },
  openModalExportInfo: (params) => {
    dispatch(openSmallModal(params));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params, customPropertyPathsToUpdate) => {
    dispatch(refreshGenericModal(params, customPropertyPathsToUpdate));
  },
  closeGenericModal: (params) => {
    dispatch(closeGenericModal(params));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  patchMultipleProducts: (products) =>
    dispatch(patchMultipleProducts(products)).then(
      (result) => dispatch(receivePatchMultipleProducts(result)),
      (error) => {
        dispatch(requestPatchMultipleProductsError(error));
        throw 'Error';
      },
    ),
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Products);

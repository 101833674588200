import { connect } from 'react-redux';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { Button, ListView } from '@commons/utils/styledLibraryComponents';
import { mapDataForExport } from '@commons/utils/format';
import EmptyState from '@commons/EmptyState';
import GeneralEmptyStateListView from '@commons/GeneralEmptyStateListView';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';
import utilsXLS from '@commons/utils/makeXLS';

import { CHOICES_DROPDOWN_ACTIVE } from '@admin/utils/DropdownItems';
import { getUpdateStatusWarningModal } from '@admin/catalogs/utils/modalConfigurations';
import DeepsightFiltersButton from '@admin/components/FilterButton';

import catalogsService from '@services/catalogs';

import { getClientInfo } from '@selectors/client';

import { getListActions, getListRowActions } from '../../utils/actions';
import { getListColumns } from '../../utils/columns';

import { Container, ContentContainer } from './styledComponents';

const Catalogs = (props) => {
  const {
    pageLoading,
    pageLoaded,
    showErrorMessage,
    showSuccessMessage,
    openGenericModal,
    history,
    match: { path },
    client: { clientId },
  } = props;

  const [columns] = useState(getListColumns());

  const [actions, setActions] = useState([]);
  const [rowActions, setRowActions] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [selectedCatalogs, setSelectedCatalogs] = useState([]);
  const [catalogs, setCatalogs] = useState([]);
  const [filteredCatalogs, setFilteredCatalogs] = useState([]);

  const [displayEmptyStates, setDisplayEmptyStates] = useState(false);

  // Filter states
  const [filters, setFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState(CHOICES_DROPDOWN_ACTIVE()[0]);

  const handleExport = (selectedItems) => {
    pageLoading();

    try {
      const catalogsSheet = createCatalogsExportSheet(
        selectedItems.length > 0 ? selectedItems : filteredCatalogs,
      );

      utilsXLS.makeXLS(i18next.t('FEATURE.ADMIN.CATALOGS'), [catalogsSheet]);
    } catch {
      showErrorMessage(i18next.t('GENERAL.EXPORT_FAILURE'));
    } finally {
      pageLoaded();
    }
  };

  const createCatalogsExportSheet = (selectedItems) => {
    const columnSettings = getListColumns();
    const headers = columnSettings.map(({ name }) => name);

    const data = mapDataForExport(columnSettings, selectedItems, 'propertyKey');

    return {
      title: i18next.t('FEATURE.ADMIN.CATALOGS'),
      headers,
      data,
      formatToNumberColumnIndexes: { start: 2, end: 2 },
    };
  };

  const handleCreation = () => {
    history.push('/admin/catalogs/create');
  };

  const handleStatus = (selectedItems) => {
    const params = getUpdateStatusWarningModal(selectedItems, updateCatalogsStatus);

    openGenericModal(params);
  };

  const updateCatalogsStatus = async (catalogs) => {
    pageLoading();

    try {
      const updatedCatalogStatus = !catalogs[0].active;

      const catalogIds = catalogs.map(({ id }) => id);

      await catalogsService.updateCatalogStatus(clientId, updatedCatalogStatus, catalogIds);

      await getCatalogs();

      setSelectedCatalogs([]);

      showSuccessMessage(i18next.t('ADMIN.CATALOGS.UPDATE_STATUS_SUCCESS'));
    } catch {
      showErrorMessage(i18next.t('ADMIN.CATALOGS.UPDATE_STATUS_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const getCatalogs = async () => {
    pageLoading();
    setIsLoading(true);

    try {
      const fetchedCatalogs = await catalogsService.getCatalogsByClientId(clientId);

      setCatalogs(fetchedCatalogs);

      if (!fetchedCatalogs.length) {
        setDisplayEmptyStates(true);

        return;
      }

      if (applyFilters) {
        const filteredCatalogs = fetchedCatalogs.filter(
          ({ active }) => selectedStatus.filterValue === active,
        );
        setFilteredCatalogs(filteredCatalogs);
      }
    } catch {
      setCatalogs([]);

      setFilteredCatalogs([]);

      showErrorMessage(i18next.t('ADMIN.CATALOGS.FETCH_ERROR'));
    } finally {
      pageLoaded();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCatalogs();
  }, []);

  useEffect(() => {
    setActions(
      getListActions(selectedCatalogs, selectedStatus, handleExport, handleCreation, handleStatus),
    );

    setRowActions(getListRowActions(selectedStatus, handleStatus));
  }, [filteredCatalogs, selectedCatalogs, selectedStatus]);

  /******************************/
  /** HANDLE FILTERS LIST VIEW **/
  /******************************/
  useEffect(() => {
    if (!applyFilters) {
      return;
    }

    const filteredCatalogs = catalogs.filter(({ active }) => selectedStatus.filterValue === active);

    setFilteredCatalogs(filteredCatalogs);
  }, [applyFilters]);

  const goToCatalogDetailsPage = (catalog) => {
    history.push(`/admin/catalogs/${catalog.id}/details`);
  };

  return (
    <Container>
      <NavigationBreadCrumb featurePath={path} />
      <ContentContainer>
        {displayEmptyStates ? (
          <GeneralEmptyStateListView
            icon={'/images/inpulse/catalogs.svg'}
            renderAction={() => (
              <Button
                color="inpulse-default"
                handleClick={handleCreation}
                icon={'/images/inpulse/add-white-small.svg'}
                label={i18next.t('ADMIN.CATALOGS.CREATE')}
              />
            )}
            subtitle={i18next.t('ADMIN.CATALOGS.EMPTY_STATE_SUBTITLE')}
            title={i18next.t('ADMIN.CATALOGS.EMPTY_STATE_TITLE')}
          />
        ) : (
          <ListView
            actionOnClick={goToCatalogDetailsPage}
            actions={actions}
            columns={columns}
            data={filteredCatalogs}
            defaultOrderBy={'name'}
            defaultOrderType={'asc'}
            isLoading={isLoading}
            minActionsInActionsDropdown={1}
            padding={'24px 24px 0px 24px'}
            placeholderShape={i18next.t('GENERAL.SEARCH')}
            renderEmptyState={() => <EmptyState />}
            renderFilterButton={() => (
              <DeepsightFiltersButton
                applyFilters={applyFilters}
                customSingleDropDowns={[
                  {
                    id: 'catalog-state',
                    disabledIcon: '/images/inpulse/power-ip-black.svg',
                    itemSelectedIcon: '/images/inpulse/power-ip-black.svg',
                    list: CHOICES_DROPDOWN_ACTIVE(),
                    defaultSelectedItem: CHOICES_DROPDOWN_ACTIVE()[0],
                    selectedItem: selectedStatus,
                    setSelectedItem: setSelectedStatus,
                  },
                ]}
                filters={filters}
                isLoading={isLoading}
                readOnly={isLoading}
                setApplyFilters={setApplyFilters}
                setFilters={setFilters}
                textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
              />
            )}
            rowActions={rowActions}
            setSelectedItems={setSelectedCatalogs}
          />
        )}
      </ContentContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Catalogs);

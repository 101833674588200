import { connect } from 'react-redux';
import { get, keyBy, sortBy } from 'lodash';
import { pdf } from '@react-pdf/renderer';
import i18next from 'i18next';
import React from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage } from '@actions/messageconfirmation';

import { Button } from '@commons/utils/styledLibraryComponents';
import { flattenArray } from '@commons/utils/format';

import {
  canCorrectInventory,
  canDownloadInventorySheet,
  canEditInventoryDate,
} from '@selectors/actions/inventoriesStockActions';
import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';

import { getEntityUnit } from '@stocks/utils';

import { exportInventories } from '@stocks/StocksInventories/utils/exportInventories';
import { isEditionAllowed } from '@stocks/StocksInventories/common/rights';

import { convertSPPQuantityInMasterUnit } from '@orders/utils/computePackagingToSupplierProduct';

import { STOCK_DATA_TYPES } from '../../constants/types';
import utils from '../../utils';

import { Container } from './styledComponents';
import PDFInventoryRender from './rendererPdf';

const STOCK_CONVENTION_VALUES = [
  { id: 'start', name: i18next.t('STOCKS.STOCKS.FORM_HEADER_CONVENTION_START_DISPLAY') },
  { id: 'end', name: i18next.t('STOCKS.STOCKS.FORM_HEADER_CONVENTION_END_DISPLAY') },
];

export const generatePDFBlob = async (props) => {
  const {
    client: { clientPicture },
    stockConvention,
    supplierProducts,
    setIsDownloadingInventorySheet,
    pageLoading,
    pageLoaded,
    showErrorMessage,
    selectedMetric,
  } = props;

  const stockConventionFormatted = STOCK_CONVENTION_VALUES.find(
    ({ id }) => id === stockConvention,
  )?.name;

  try {
    pageLoading();
    setIsDownloadingInventorySheet(true);
    const PDFBlob = await pdf(
      <PDFInventoryRender
        {...props}
        clientPicture={clientPicture}
        stockConvention={stockConventionFormatted}
        supplierProducts={supplierProducts}
      />,
    ).toBlob();
    const PDFUrl = window.URL.createObjectURL(new File([PDFBlob], 'file'));

    const PDFLink = document.createElement('a');
    PDFLink.href = PDFUrl;
    PDFLink.setAttribute(
      'download',
      `${i18next.t(
        selectedMetric.key === STOCK_DATA_TYPES.SUPPLIER_PRODUCT
          ? 'STOCKS.STOCKS.INGREDIENTS_INVENTORY_SHEET'
          : 'STOCKS.STOCKS.RECIPES_INVENTORY_SHEET',
      )}.pdf`,
    );

    document.body.appendChild(PDFLink);
    PDFLink.click();
    PDFLink.remove();

    setIsDownloadingInventorySheet(false);
  } catch {
    showErrorMessage(i18next.t('STOCKS.STOCKS.FAILURE_EXPORT_PDF'));
  } finally {
    pageLoaded();
  }
};

const StockFormActions = (props) => {
  const {
    currency,
    client: { storeName },
    stockDate,
    reference,
    stockType,
    currentStoreName,
    inventoryListId,
    readOnly,
    isLoading,
    isModeOffline,
    isEditingDate,
    setIsEditingDate,
    authorizedActions,
    inventoryCreationDate,
    setIsCorrectingInventory,
    isCorrectingInventory,
    totByStorageAreaIdSPIdAndPackagingId,
    setInfoModalDownloadInventorySheet,
    isCentralKitchenView,
    spByStorageAreaIds,
    storageAreas,
    isValidated,
    recipesByStorageAreaIds,
    totByStorageAreaIdAndRecipeId,
  } = props;

  const stockActions = [
    {
      label: i18next.t('STOCKS.STOCKS.FORM_UPDATE_STOCK_BUTTON'),
      icon: '/images/inpulse/pen-ip-black-small.svg',
      handleClick: () => setIsCorrectingInventory(true),
      isHidden:
        isModeOffline ||
        !inventoryListId ||
        (isEditionAllowed(inventoryCreationDate) && !isValidated) ||
        (readOnly && !isCentralKitchenView && !canCorrectInventory(authorizedActions)) ||
        isCorrectingInventory ||
        isEditingDate,
    },
    {
      label: i18next.t('STOCKS.STOCKS.FORM_DOWNLOAD_INVENTORY_PDF'),
      icon: '/images/inpulse/file-download-black-thick.svg',
      handleClick: () => setInfoModalDownloadInventorySheet(props),
      isHidden:
        !!inventoryListId ||
        (!isCentralKitchenView && !canDownloadInventorySheet(authorizedActions)),
    },
    {
      label: i18next.t('STOCKS.STOCKS.FORM_UPDATE_STOCK_DATE_BUTTON'),
      icon: '/images/inpulse/pen-black-small.svg',
      handleClick: () => setIsEditingDate(true),
      isHidden:
        isModeOffline ||
        !inventoryListId ||
        (!isCentralKitchenView && !canEditInventoryDate(authorizedActions)) ||
        isEditingDate ||
        isCorrectingInventory,
    },
    {
      label: i18next.t('GENERAL.EXPORT_XLS'),
      icon: '/images/inpulse/file-download-black-thick.svg',
      handleClick: () => handleXLS(),
    },
  ];

  const processStock = () => {
    const storageAreasById = keyBy(storageAreas.supplier_product, 'id');

    let allFilteredSupplierProducts = [];

    for (const [storageAreaId, supplierProducts] of Object.entries(spByStorageAreaIds)) {
      const formattedSPs = supplierProducts.map((supplierProduct) => {
        const updatedSupplierProduct = { ...supplierProduct };

        const masterSupplierProductPackaging = supplierProduct.packagings.find(
          (packaging) => !packaging.masterSupplierProductPackagingId,
        );

        updatedSupplierProduct.reference = reference;

        updatedSupplierProduct.supplier = supplierProduct.lnkSupplierSupplierproductrel.name;

        updatedSupplierProduct.storeName = currentStoreName;

        updatedSupplierProduct.stockType = stockType;

        updatedSupplierProduct.stockDate = stockDate.format('L');

        updatedSupplierProduct.masterPackagingUnit = masterSupplierProductPackaging.unit;

        updatedSupplierProduct.storageAreaName = get(
          storageAreasById[storageAreaId],
          'name',
          i18next.t('GENERAL.NONE_VALUE'),
        );

        return updatedSupplierProduct;
      });

      formattedSPs.forEach((supplierProduct) => {
        const invoicePackaging = supplierProduct.packagings.find(
          ({ isUsedInInvoice }) => isUsedInInvoice,
        );

        const totByPackagingId =
          totByStorageAreaIdSPIdAndPackagingId[storageAreaId][supplierProduct.id] ||
          supplierProduct.totByPackagingId ||
          {};

        Object.keys(totByPackagingId).forEach((packagingId) => {
          const total = totByPackagingId[packagingId];

          if (total == null) {
            return;
          }

          const updatedTotByPackagingId = { [packagingId]: total };

          const updatedSupplierProduct = {
            ...supplierProduct,
            totByPackagingId: updatedTotByPackagingId,
          };

          const convertedPrice =
            supplierProduct.price == null || !invoicePackaging
              ? null
              : supplierProduct.price /
                convertSPPQuantityInMasterUnit(invoicePackaging.id, supplierProduct.packagings);

          const convertedQuantity = convertSPPQuantityInMasterUnit(
            packagingId,
            supplierProduct.packagings,
          );

          allFilteredSupplierProducts.push({
            ...supplierProduct,
            tot: total,
            price: convertedPrice * convertedQuantity,
            totalUnit: utils.getQuantityMasterUnit(updatedSupplierProduct),
            totalPrice:
              supplierProduct.price == null ? null : utils.getPrice(updatedSupplierProduct),
            supplierProductPackagingId: packagingId,
          });
        });
      }, []);
    }

    return allFilteredSupplierProducts
      .sort((a, b) =>
        a.storageAreaName.toLowerCase().localeCompare(b.storageAreaName.toLowerCase()),
      )
      .sort((a, b) => a.subCategory.localeCompare(b.subCategory))
      .sort((a, b) => a.supplier.localeCompare(b.supplier))
      .sort((a, b) => a.reference.localeCompare(b.reference));
  };

  const formatRecipesStock = () => {
    const storageAreasById = keyBy(storageAreas.recipe, 'id');

    let allRecipes = [];

    for (const [storageAreaId, recipes] of Object.entries(recipesByStorageAreaIds)) {
      for (const recipe of recipes) {
        const {
          id,
          name: recipeName,
          category,
          cost,
          unit: recipeUnit,
          brands: recipeBrands,
        } = recipe;

        const stockQuantity = totByStorageAreaIdAndRecipeId[storageAreaId][id];

        if (!stockQuantity) {
          continue;
        }

        const brands = recipeBrands.map(({ name }) => name).join(',');

        const storageAreaName = get(
          storageAreasById[storageAreaId],
          'name',
          i18next.t('GENERAL.NONE_VALUE'),
        );

        const recipeStockDate = stockDate.format('L');

        const unit = getEntityUnit(recipeUnit, false);

        allRecipes.push({
          name: recipeName,
          reference,
          quantity: stockQuantity,
          cost,
          totalCost: stockQuantity * cost,
          category: category || i18next.t('GENERAL.OTHER'),
          unit,
          stockDate: recipeStockDate,
          storeName: currentStoreName,
          storageAreaName,
          brands,
        });
      }
    }

    return sortBy(allRecipes, [
      (recipeInventory) => recipeInventory.storageAreaName.toLowerCase(),
      (recipeInventory) => recipeInventory.reference,
    ]);
  };

  const handleXLS = () => {
    if (stockDate) {
      let formattedStocks = processStock();

      formattedStocks = flattenArray(formattedStocks);

      const formattedRecipesStock = formatRecipesStock();

      exportInventories(
        formattedStocks,
        formattedRecipesStock,
        reference,
        storeName,
        true,
        currency,
      );
    } else {
      props.showMessage(i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_PLEASE_SELECT_A_DATE'), 'error');
    }
  };

  return (
    <Container>
      <Button
        actions={stockActions}
        color={'inpulse-outline'}
        iconDropdown={'/images/inpulse/ellipsis-black-small.svg'}
        isDisabled={isLoading}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
  client: getClientInfo(state.baseReducer.user),
  authorizedActions: getAuthorizedActions(
    state.baseReducer.userRights,
    '/stocks/inventories/stocks',
  ),
  totByStorageAreaIdSPIdAndPackagingId: state.stockFormReducer.totByStorageAreaIdSPIdAndPackagingId,
  totByStorageAreaIdAndRecipeId: state.stockFormReducer.totByStorageAreaIdAndRecipeId,
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StockFormActions);

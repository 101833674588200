import { connect } from 'react-redux';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal, refreshGenericModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { Button, ListView } from '@commons/utils/styledLibraryComponents';
import { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_CONFIRM_BUTTON,
  GENERIC_MODAL_SAVE_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { getConfirmationModal } from '@commons/Modals/ConfirmationModal';
import { LISTVIEW_NO_TOP_PADDING } from '@commons/constants/listViewProps';
import EmptyState from '@commons/EmptyState';

import { getClientInfo } from '@selectors/client';

import clientService from '@services/client';

import DeepsightFiltersButton from '@admin/components/FilterButton';

import formatUtils from '@admin/inventories/utils/formatUtils';
import SupplierProductMappingsModal from '@admin/inventories/utils/mappings.utils';

import actionsUtils from '../utils/actionsUtils';
import columnsUtils from '../utils/columnsUtils';

import { Container } from './styledComponents';

const InventoryListTemplateMappings = (props) => {
  const {
    isLoading,
    isCreation,
    existingMappings = [],
    openGenericModal,
    client: { clientId, hasLocalCatalogs },
    showSuccessMessage,
    showErrorMessage,
    inventoryListTemplateId,
    pageLoading,
    pageLoaded,
    inventoryListTemplateForm,
    refreshGenericModal,
    formFields,
    user,
    catalogs,
  } = props;

  // States ListView
  const [columns] = useState(columnsUtils.getILTSupplierProductMappingsColumns(hasLocalCatalogs));
  const [actions, setActions] = useState([]);
  const [rowActions, setRowActions] = useState([]);
  const [displayEmptyState, setDisplayEmptyState] = useState(false);

  // Filters states
  const [filters, setFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [categoriesToChoose, setCategoriesToChoose] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [subCategoriesToChoose, setSubCategoriesToChoose] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [selectedCatalogs, setSelectedCatalogs] = useState([user.catalog]);

  const [mappings, setMappings] = useState(existingMappings);
  const [filteredMappings, setFilteredMappings] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [spToBeAdded, setSpToBeAdded] = useState([]);

  const refreshFilters = (currentMappings) => {
    const filtersData = currentMappings.reduce(
      (acc, { supplierId, supplierName, active, catalogId, category, subCategory }) => {
        if (!acc.filterSuppliers.some(({ id }) => id === supplierId)) {
          acc.filterSuppliers.push({
            id: supplierId,
            name: supplierName,
            active,
            catalogId,
          });

          if (hasLocalCatalogs && selectedCatalogs.some(({ id }) => id === catalogId)) {
            acc.filterSelectedSuppliers.push({
              id: supplierId,
              name: supplierName,
              active,
              catalogId,
            });
          }
        }

        if (!acc.filterCategories.some(({ name }) => name === category)) {
          acc.filterCategories.push({ id: category, name: category });
        }

        if (!acc.filterSubCategories.some(({ name }) => name === subCategory)) {
          acc.filterSubCategories.push({ id: subCategory, name: subCategory });
        }

        return acc;
      },
      {
        filterSuppliers: [],
        filterSelectedSuppliers: [],
        filterCategories: [],
        filterSubCategories: [],
      },
    );

    setSuppliers(filtersData.filterSuppliers);
    setSelectedSuppliers(
      hasLocalCatalogs ? filtersData.filterSelectedSuppliers : filtersData.filterSuppliers,
    );

    setCategoriesToChoose(filtersData.filterCategories);
    setSelectedCategories(filtersData.filterCategories);

    setSubCategoriesToChoose(filtersData.filterSubCategories);
    setSelectedSubCategories(filtersData.filterSubCategories);
  };

  useEffect(() => {
    if (isCreation || !existingMappings.length) {
      setDisplayEmptyState(true);
      return;
    }

    setMappings(existingMappings);

    setFilteredMappings(existingMappings);
  }, [existingMappings]);

  useEffect(() => {
    if (!isLoading && !mappings.length) {
      setDisplayEmptyState(true);
      return;
    }

    setDisplayEmptyState(false);

    refreshFilters(mappings);
  }, [mappings]);

  useEffect(() => {
    if (formFields && formFields.supplierProductMappings) {
      setMappings(formFields.supplierProductMappings);
      setFilteredMappings(formFields.supplierProductMappings);
    }
  }, [formFields]);

  // Handle filters change
  useEffect(() => {
    // User has not applied the filters
    if (!applyFilters) {
      return;
    }

    const filteredData = mappings.filter(
      ({ supplierId, category, subCategory, catalogId }) =>
        (!hasLocalCatalogs || selectedCatalogs.some(({ id }) => id === catalogId)) && // catalogs selector
        selectedSuppliers.some(({ id }) => id === supplierId) && // suppliers selector
        selectedCategories.some(({ name }) => category === name) && // categories selector
        selectedSubCategories.some(({ name }) => subCategory === name), // sub-categories selector
    );

    setFilteredMappings(filteredData);
  }, [applyFilters, formFields, selectedSuppliers, selectedCatalogs]);

  useEffect(() => {
    setActions(
      actionsUtils.getInventoryListTemplateGlobalActions(
        isLoading,
        selectedItems,
        handleAddAssociation,
        handleRemoveAssociation,
      ),
    );

    setRowActions(actionsUtils.getInventoryListTemplateRowActions(handleRemoveAssociation));
  }, [filteredMappings, selectedItems, isLoading, mappings]);

  useEffect(() => {
    const params = getSupplierProductMappingsModalConfig();

    refreshGenericModal(params);
  }, [spToBeAdded]);

  /** FUNCTIONS */
  const renderNoSupplierProductMappings = () => (
    <EmptyState
      label={i18next.t('ADMIN.INVENTORY_LIST_TEMPLATES.EMPTY_STATE_MAPPINGS_LABEL')}
      labelColor={ENUM_COLORS.IP_BLACK_1}
      labelFont={ENUM_FONTS.H2_INTER}
      renderActionButton={() => (
        <Button
          color={'inpulse-default'}
          handleClick={handleAddAssociation}
          icon={'/images/inpulse/add-white-small.svg'}
          label={i18next.t('GENERAL.ADD')}
        />
      )}
      subtitle={i18next.t('ADMIN.INVENTORY_LIST_TEMPLATES.EMPTY_STATE_MAPPINGS_SUBTITLE')}
      subtitleMargin={'8px'}
    />
  );

  const getSupplierProductMappingsModalConfig = () => ({
    type: 'actionWhite',
    isFullscreen: true,
    title: i18next.t('ADMIN.INVENTORY_LIST_TEMPLATES.MAPPINGS_ASSOCIATION_MODAL_TITLE'),
    icon: '/images/inpulse/add-black-small.svg',
    customPadding: '24px 0px 0px 0px',
    component: SupplierProductMappingsModal,
    data: {
      clientId,
      setSpToBeAdded,
      hasLocalCatalogs,
      alreadyMappedSp: mappings,
      catalogs,
    },
    actions: [
      GENERIC_MODAL_CANCEL_BUTTON(),
      {
        ...GENERIC_MODAL_SAVE_BUTTON(),
        handleClick: () => {
          const formattedSPToBeAdded = spToBeAdded.map((supplierProduct) => ({
            ...supplierProduct,
            id: supplierProduct.supplierProductId,
          }));

          if (!isCreation) {
            addILTMappings(formattedSPToBeAdded);
            return;
          }

          inventoryListTemplateForm.setValue('supplierProductMappings', [
            ...mappings,
            ...formattedSPToBeAdded,
          ]);
          setMappings([...mappings, ...formattedSPToBeAdded]);
          setFilteredMappings([...mappings, ...formattedSPToBeAdded]);
        },
      },
    ],
  });

  const handleAddAssociation = () => {
    const params = getSupplierProductMappingsModalConfig();

    openGenericModal(params);
  };

  const handleRemoveAssociation = (selectedItems) => {
    const firstSP = selectedItems[0];

    const warningModalConfig = getConfirmationModal({
      title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_LIST_STORES_ACTION_REMOVE_SELECTION'),
      content: i18next.t(
        'ADMIN.INVENTORY_LIST_TEMPLATES.REMOVE_SUPPLIER_PRODUCT_MAPPINGS_WARNING',
        {
          count: selectedItems.length,
          name: firstSP.name,
        },
      ),
      icon: '/images/inpulse/warning-white-small.svg',
      actions: [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          ...GENERIC_MODAL_CONFIRM_BUTTON(),
          handleClick: () => deleteILTMappings(selectedItems),
        },
      ],
    });

    openGenericModal(warningModalConfig);
  };

  const handleILTMappingsUpdate = async (mappingsToKeep) => {
    const associatedSPIds = mappingsToKeep.map(({ id }) => id);

    pageLoading();

    try {
      await clientService.updateInventoryListTemplate(
        clientId,
        inventoryListTemplateId,
        null,
        associatedSPIds,
      );

      setMappings(mappingsToKeep);

      let filteredData = mappingsToKeep;

      if (hasLocalCatalogs) {
        filteredData = mappingsToKeep.filter(({ catalogId }) =>
          selectedCatalogs.some(({ id }) => id === catalogId),
        );
      }

      setFilteredMappings(filteredData);

      showSuccessMessage(i18next.t('GENERAL.CHANGES_SUCCESSFULLY_SAVED'));
    } catch {
      showErrorMessage(i18next.t('GENERAL.SAVING_CHANGES_FAILED'));
    } finally {
      pageLoaded();
    }
  };

  const deleteILTMappings = (selectedItems) => {
    const idsToRemoveOrAdd = selectedItems.map(({ id }) => id);
    const mappingsToKeep = mappings.filter(({ id }) => !idsToRemoveOrAdd.includes(id));

    if (isCreation) {
      inventoryListTemplateForm.setValue('supplierProductMappings', mappingsToKeep);
      setMappings(mappingsToKeep);
      setFilteredMappings(mappingsToKeep);

      return;
    }

    handleILTMappingsUpdate(mappingsToKeep);
  };

  const addILTMappings = (selectedItems) => {
    const mappingsToKeep = mappings.concat(selectedItems);

    handleILTMappingsUpdate(mappingsToKeep);
  };

  return (
    <Container>
      {displayEmptyState ? (
        renderNoSupplierProductMappings()
      ) : (
        <ListView
          actions={actions}
          columns={columns}
          data={filteredMappings}
          defaultMaxPerPage={500}
          defaultOrderBy={'name'}
          defaultOrderType={'asc'}
          isLoading={isLoading}
          maxPerPageOptions={[10, 20, 50, 100, 500, 1000]}
          minActionsInActionsDropdown={1}
          padding={LISTVIEW_NO_TOP_PADDING}
          placeholderShape={i18next.t('GENERAL.SEARCH')}
          renderEmptyState={() => <EmptyState />}
          renderFilterButton={() => (
            <DeepsightFiltersButton
              applyFilters={applyFilters}
              catalogs={catalogs}
              customMultipleDropDowns={formatUtils.computeMultipleDropdownsFilter({
                categoriesToChoose,
                selectedCategories,
                setSelectedCategories,
                subCategoriesToChoose,
                selectedSubCategories,
                setSelectedSubCategories,
              })}
              filters={filters}
              readOnly={isLoading}
              selectedCatalogs={selectedCatalogs}
              selectedSuppliers={selectedSuppliers}
              setApplyFilters={setApplyFilters}
              setFilters={setFilters}
              setSelectedCatalogs={setSelectedCatalogs}
              setSelectedSuppliers={setSelectedSuppliers}
              suppliers={suppliers}
              textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
            />
          )}
          rowActions={rowActions}
          setSelectedItems={setSelectedItems}
          hideAllPerPageOption
        />
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  activeStores: state.baseReducer.activeStores,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryListTemplateMappings);

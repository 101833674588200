const COLUMNS = {
  STORE_NAME: 'storeName',
};

// See https://mui.com/x/react-data-grid/style/#using-the-sx-prop for reference
const DATAGRID_STYLE = {
  maxHeight: 'calc(100% - 48px)',
  height: 'auto',
  minWidth: '886px',
  marginTop: '24px',
  marginBottom: '24px',
  '& .MuiDataGrid-columnHeader': {
    padding: '0px 4px',
  },
  '& .MuiDataGrid-cell': { padding: '0px 4px' },
};

// See https://mui.com/x/api/data-grid/data-grid-pro/ for reference
const DATAGRID_OTHER_PROPS = {
  autosizeOnMount: false,
  disableColumnResize: true,
  disableColumnSelector: true,
  disableDensitySelector: true,
  disableRowSelectionOnClick: true,
  hideFooter: true,
  ignoreDiacritics: true,
  showCellVerticalBorder: false,
  showColumnVerticalBorder: true,
};

const DATAGRID_INITIAL_STATE = {
  pinnedColumns: {
    left: [COLUMNS.STORE_NAME],
  },
  sorting: {
    sortModel: [{ field: COLUMNS.STORE_NAME, sort: 'asc' }],
  },
  filter: {
    filterModel: {
      items: [{ columnField: COLUMNS.STORE_NAME, operatorValue: 'contains', value: '' }],
    },
  },
};

export const COMMON_DATAGRID_PROPS = {
  initialState: DATAGRID_INITIAL_STATE,
  sx: DATAGRID_STYLE,
  ...DATAGRID_OTHER_PROPS,
};

import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { closeGenericModal, openGenericModal, refreshGenericModal } from '@actions/modal';
import { getStoresOfUser, receiveStores, requestStoresError } from '@actions/store';
import { loading, loadingSuccess } from '@actions/loading';
import { showConfirmationMessage } from '@actions/messageconfirmation';
import { updateIsSynchronizingCashierStoresStatus } from '@actions/store';

import { Button } from '@commons/utils/styledLibraryComponents';
import { cashierStoreService } from '@services/cashierStore';
import { clientStatusCheck } from '@commons/utils/clientStatusCheck';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';
import WhiteCard from '@commons/WhiteCard';

import { getClientInfo } from '@selectors/client';

import {
  getStoresExcelTemplate,
  importStoresExcelTemplate,
  validateStoresExcelTemplate,
} from '@services/backoffice/onBoarding';

import theme from '@theme';

import { downloadFile, formatXlsFileToJson } from '@backoffice/utils';
import ImportExcelFileModal from '@backoffice/components/ImportExcelFileModal';

import { formatExcelDates } from '../utils/formatExcelDates';

import { Container } from './styledComponents';

const TIMEOUT_GET_SYNC_STATUS = 5000; // in ms

const ENUM_SYNCHRO_CASHIER_PRODUCT = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  REVOKED: 'REVOKED',
};

const defaultModalConfig = {
  width: '542px',
  height: 'auto',
  component: ImportExcelFileModal,
};

export const BackOfficeStoresCreation = (props) => {
  const {
    client: { clientName, clientId, clientStatus },
    match,
    isSynchronizingCashierStores,
    updateIsSynchronizingCashierStoresStatus,
  } = props;

  const [selectedFile, setSelectedFile] = useState(null);
  const [isDownloadingExcel, setIsDownloadingExcel] = useState(false);

  const path = _.get(match, 'path');

  const syncCashierStore = async (clientId, updateIsSynchronizingCashierStoresStatus) => {
    try {
      await cashierStoreService.syncCashierStore(clientId);

      updateIsSynchronizingCashierStoresStatus(true);

      props.showMessage(i18next.t('BACKOFFICE.CASHIER_STORES.RESTART_SYNC_SUCCESS'), 'success');
    } catch (error) {
      props.showMessage(i18next.t('BACKOFFICE.CASHIER_STORES.RESTART_SYNC_ERROR'), 'error');
    }
  };

  useEffect(() => {
    if (!isSynchronizingCashierStores) {
      return;
    }

    const getClientInterval = setInterval(() => {
      (async () => {
        try {
          const syncStatus = await cashierStoreService.getCashierStoreSyncStatus(clientId);

          if (syncStatus.state === ENUM_SYNCHRO_CASHIER_PRODUCT.SUCCESS) {
            props.showMessage(
              i18next.t('BACKOFFICE.CASHIER_STORES.SYNC_STATUS_SUCCESS'),
              'success',
            );
            updateIsSynchronizingCashierStoresStatus(false);
          }
          if (
            syncStatus.state === ENUM_SYNCHRO_CASHIER_PRODUCT.FAILURE ||
            syncStatus.state === ENUM_SYNCHRO_CASHIER_PRODUCT.REVOKED
          ) {
            props.showMessage(i18next.t('BACKOFFICE.CASHIER_STORES.SYNC_STATUS_ERROR'), 'error');
            updateIsSynchronizingCashierStoresStatus(false);
          }
        } catch (error) {
          props.showMessage(i18next.t('BACKOFFICE.CASHIER_STORES.SYNC_STATUS_ERROR'), 'error');
          updateIsSynchronizingCashierStoresStatus(false);
        }
      })();
    }, TIMEOUT_GET_SYNC_STATUS);

    return () => clearInterval(getClientInterval);
  }, [isSynchronizingCashierStores]);

  const formatJson = (dataModel) =>
    Object.keys(dataModel).reduce((result, sheetName) => {
      if (sheetName === 'brands') {
        result[sheetName] = dataModel[sheetName].map((current) => ({
          id: current['id'],
          name: current['nom'],
        }));
      }

      if (sheetName === 'stores') {
        result[sheetName] = dataModel[sheetName].map((current) => ({
          name: current['nom'],
          longitude: (current['longitude'] || '').toString().trim(),
          latitude: (current['latitude'] || '').toString().trim(),
          stockConvention: current['convention de remontée de stock'],
          holidayZone: current['zone de vacances'],
          active: current['actif'],
          country: current['pays'],
          city: current['ville'],
          partnerId: (current['identifiant partenaire'] || '').toString().replace(/\s/g, ''),
          postCode: (current['code postal'] || '').toString(),
          address: current['adresse'],
          additionnalAddress: current["complément d'adresse"],
          telephone: (current['téléphone'] || '').toString(),
          closingDays: !!current['jour de fermeture']
            ? current['jour de fermeture'].toString().replace('.', ',')
            : undefined,
          timezone: current['timezone'],
          productionStockConvention: current['productionstockconvention'],
          productionLossConvention: current['productionlossconvention'],
          brand: current['marque'],
          type: current['type'],
          retailer: current['enseigne'] || '',
          location: current['localisation'] || '',
          launchDate:
            !!current['date de facturation'] && !!current['timezone']
              ? formatExcelDates(current['date de facturation'], current['timezone'])
              : null,
          invoiceControlPlan: current['forfait contrôles de factures'],
        }));
      }

      if (sheetName === 'cashierStores') {
        result[sheetName] = dataModel[sheetName].map((current) => ({
          name: current['nom'] || '',
          address: current['adresse'] || '',
          postCode: (current['code postal'] || '').toString(),
          city: current['ville'] || '',
          country: current['pays'] || '',
          partnerId: (current['partner id'] || '').toString(),
          cashierConfigName: current['connexion'] || '',
          storeName: current['store associé'] || '',
        }));
      }

      if (sheetName === 'retailers') {
        result[sheetName] = dataModel[sheetName].map((current) => ({
          id: current['id'],
          name: current['nom'] || '',
        }));
      }

      if (sheetName === 'locations') {
        result[sheetName] = dataModel[sheetName].map((current) => ({
          id: current['id'],
          name: current['nom'] || '',
          partnerId: current['identifiant partenaire'],
        }));
      }

      if (sheetName === 'client') {
        result[sheetName] = dataModel[sheetName].map((current) => ({
          hasMultipleTimezones: current['plusieurs fuseaux horaires'],
          defaultTimezone: current['fuseau horaire par defaut'],
        }));
      }

      if (sheetName === 'invoiceControlPlans') {
        result[sheetName] = dataModel[sheetName].map((current) => ({
          id: current['id'],
          name: current['nom'],
        }));
      }

      return result;
    }, {});

  const onFileChange = (file) => {
    setSelectedFile(file);

    props.refreshGenericModal(getModalModalConfig('validate-file', file));
  };

  const retrieveBlobStoresExcelTemplate = async () => {
    setIsDownloadingExcel(true);

    try {
      const response = await getStoresExcelTemplate(clientId);

      downloadFile(response, clientName);
    } catch (error) {
      props.showMessage(i18next.t('BACKOFFICE.STORES.EXCEL_RETRIEVING_ERROR'), 'error');
    } finally {
      setIsDownloadingExcel(false);
    }
  };

  const handleFileValidation = async (file) => {
    props.pageLoading();

    try {
      const dataModel = await formatXlsFileToJson(file);

      const formattedJsonDataModel = formatJson(dataModel);

      const { status, data } = await validateStoresExcelTemplate(clientId, formattedJsonDataModel);

      if (status === 'success') {
        props.refreshGenericModal(getModalModalConfig('validated-file', file));

        return;
      }

      props.refreshGenericModal(getModalModalConfig('error-file', file, data));
    } catch (error) {
      props.closeGenericModal();
      props.showMessage(i18next.t('BACKOFFICE.STORES.EXCEL_VALIDATION_ERROR'), 'error');
    } finally {
      props.pageLoaded();
    }
  };

  const handleFileImport = async (file) => {
    props.pageLoading();

    try {
      const dataModel = await formatXlsFileToJson(file);

      const formattedJsonDataModel = formatJson(dataModel);

      await importStoresExcelTemplate(clientId, formattedJsonDataModel);

      props.showMessage(i18next.t('BACKOFFICE.STORES.EXCEL_IMPORT_SUCCESS'));

      props.getStoresOfUser();
    } catch (error) {
      props.showMessage(i18next.t('BACKOFFICE.STORES.EXCEL_IMPORT_ERROR'), 'error');
    } finally {
      props.pageLoaded();

      props.closeGenericModal();
    }
  };

  const getModalModalConfig = (type, updatedFile = null, errorFile = null) => {
    if (type === 'select-file') {
      return {
        ...defaultModalConfig,
        type: 'action',
        title: i18next.t('BACKOFFICE.STORES.MODAL_IMPORT_SELECT_FILE_TITLE'),
        icon: '/images/inpulse/file-upload-black-small.svg',
        data: {
          subTitle: i18next.t('BACKOFFICE.STORES.MODAL_IMPORT_SELECT_FILE_CONTENT'),
          selectedFile,
          setSelectedFile: onFileChange,
        },
        actions: [
          {
            key: 0,
            color: 'inpulse-outline',
            label: i18next.t('GENERAL.CANCEL'),
            icon: '/images/inpulse/close-black-small.svg',
            handleClick: setSelectedFile(null),
          },
          {
            key: 1,
            isDisabled: true,
            preventClosing: true,
            color: 'inpulse-default',
            label: i18next.t('GENERAL.IMPORT'),
            icon: '/images/inpulse/file-upload-white-small.svg',
            handleClick: () => false,
          },
        ],
      };
    }

    if (type === 'validate-file') {
      return {
        ...defaultModalConfig,
        type: 'action',
        title: i18next.t('BACKOFFICE.STORES.MODAL_IMPORT_SELECT_FILE_TITLE'),
        icon: '/images/inpulse/file-upload-black-small.svg',
        data: {
          subTitle: i18next.t('BACKOFFICE.STORES.MODAL_IMPORT_SELECT_FILE_CONTENT'),
          selectedFile: updatedFile,
          setSelectedFile: onFileChange,
        },
        actions: [
          {
            key: 0,
            color: 'inpulse-outline',
            label: i18next.t('GENERAL.CANCEL'),
            icon: '/images/inpulse/close-black-small.svg',
            handleClick: setSelectedFile(null),
          },
          {
            key: 1,
            preventClosing: true,
            color: 'inpulse-default',
            label: i18next.t('GENERAL.IMPORT'),
            icon: '/images/inpulse/file-upload-white-small.svg',
            handleClick: () => handleFileValidation(updatedFile),
          },
        ],
      };
    }

    if (type === 'error-file') {
      return {
        ...defaultModalConfig,
        type: 'error',
        title: i18next.t('BACKOFFICE.STORES.MODAL_IMPORT_SELECT_FILE_TITLE_VALIDATION'),
        icon: '/images/inpulse/info-white-small.svg',
        data: {
          subTitle: i18next.t('BACKOFFICE.CLIENT.MODAL_IMPORT_SELECT_FILE_CONTENT_ERROR'),
          validatedFile: false,
          selectedFile: updatedFile,
        },
        actions: [
          {
            key: 0,
            label: 'Fichier erreur',
            color: 'inpulse-default',
            icon: '/images/inpulse/file-download-white-small.svg',
            handleClick: () => downloadFile(errorFile, clientName, true),
          },
        ],
      };
    }

    if (type === 'validated-file') {
      return {
        ...defaultModalConfig,
        type: 'success',
        title: i18next.t('BACKOFFICE.STORES.MODAL_IMPORT_SELECT_FILE_TITLE_VALIDATION'),
        icon: '/images/inpulse/check-white-small.svg',
        data: {
          validatedFile: true,
          selectedFile: updatedFile,
        },
        actions: [
          {
            key: 0,
            preventClosing: true,
            color: 'inpulse-default',
            label: i18next.t('GENERAL.IMPORT'),
            icon: '/images/inpulse/check-white-small.svg',
            handleClick: () => handleFileImport(updatedFile),
          },
        ],
      };
    }

    return defaultModalConfig;
  };

  return (
    <Container>
      <NavigationBreadCrumb featurePath={path} />
      <WhiteCard
        content={i18next.t('BACKOFFICE.STORES.CASHIER_STORES_START_SYNCHRONIZATION')}
        contentColor={theme.colors?.greys.darker}
        renderContent={
          <Button
            animation={{
              rotate: isSynchronizingCashierStores,
            }}
            buttonCustomStyle={{
              width: 'fit-content',
            }}
            color={'inpulse-default'}
            fontSize={14}
            formatText={false}
            handleClick={() => {
              syncCashierStore(clientId, updateIsSynchronizingCashierStoresStatus);
            }}
            icon={'/images/inpulse/sync-white-small.svg'}
            iconCustomStyle={{ width: '20px', height: '20px' }}
            isDisabled={isSynchronizingCashierStores}
            label={i18next.t('GENERAL.SYNC')}
          />
        }
        title={i18next.t('BACKOFFICE.STORES.CASHIER_STORES_SYNCHRONIZATION_TITLE')}
      />
      <WhiteCard
        content={i18next.t('BACKOFFICE.STORES.GENERATE_TEMPLATE_SUBTITLE')}
        renderContent={
          <Button
            animation={{ rotate: isDownloadingExcel }}
            buttonCustomStyle={{ width: 'fit-content' }}
            color={'inpulse-default'}
            handleClick={() => {
              if (isDownloadingExcel) {
                return;
              }

              retrieveBlobStoresExcelTemplate();
            }}
            icon={
              isDownloadingExcel
                ? '/images/inpulse/loader-white-small.svg'
                : '/images/inpulse/file-download-white-small.svg'
            }
            isDisabled={isDownloadingExcel}
            label={i18next.t('GENERAL.DOWNLOAD')}
          />
        }
        title={i18next.t('BACKOFFICE.STORES.GENERATE_TEMPLATE_TITLE')}
      />
      <WhiteCard
        content={i18next.t('BACKOFFICE.STORES.IMPORT_TEMPLATE_SUBTITLE')}
        renderContent={
          <Button
            buttonCustomStyle={{ width: 'fit-content' }}
            color={'inpulse-default'}
            handleClick={() => props.openGenericModal(getModalModalConfig('select-file', null))}
            icon={'/images/inpulse/file-upload-white-small.svg'}
            isDisabled={!clientStatusCheck(clientStatus, 'onboarding')}
            label={i18next.t('GENERAL.IMPORT')}
          />
        }
        title={i18next.t('BACKOFFICE.STORES.IMPORT_TEMPLATE_TITLE')}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  isSynchronizingCashierStores: state.baseReducer.isSynchronizingCashierStores,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  closeGenericModal: (params) => {
    dispatch(closeGenericModal(params));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },

  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  getStoresOfUser: () =>
    dispatch(getStoresOfUser()).then(
      (result) => {
        dispatch(receiveStores(result));
      },
      (error) => {
        dispatch(requestStoresError(error));
      },
    ),
  updateIsSynchronizingCashierStoresStatus: (isSynchronizingCashierStores) => {
    dispatch(updateIsSynchronizingCashierStoresStatus(isSynchronizingCashierStores));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeStoresCreation);

import { connect } from 'react-redux';
import { get, values } from 'lodash';

import i18next from 'i18next';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import Resizer from 'react-image-file-resizer';

import { showErrorMessage } from '@actions/messageconfirmation';

import {
  Action,
  ActionsContainer,
  Box,
  Container,
  Icon,
  OpaqueBackgroundHover,
  Text,
} from './styledComponents';

import { DisabledTooltip } from '@commons/DisabledTooltip';
import { HoverTooltip, Button } from '@commons/utils/styledLibraryComponents';

const COMPRESSED_FILE_QUALITY = 70;
const MAX_MB_FILES_UPLOAD = 10; // 10 Mo max for one picture
const MB_VALUE_IN_KB = 1048576;

export const MultiplePictureUpload = forwardRef((props, ref) => {
  const {
    initialPictures,
    multipleFiles,
    readOnly,
    displayDropdownActions,
    setDisplayDropdownActions,
    isSizeTooHeavy,
    setIsSizeTooHeavy,
    onSelectionChange,
    maxNbFiles,
    disableAddPicture,
    disabledTooltipText,
    customWidth,
    // Redux props
    showErrorMessage,
  } = props;

  const [selectedPictures, setSelectedPictures] = useState([]);

  const [dropDownTopPosition, setDropDownTopPosition] = useState(0);

  const [maxNbFilesIsReached, setMaxNbFilesIsReached] = useState(false);
  const [displayPlaceholder, setDisplayPlaceholder] = useState(false);

  const isTablet = window.innerWidth <= 1280;

  const fileInputRef = React.useRef(null);

  useEffect(() => {
    if (!initialPictures) {
      return;
    }

    const updatedSelection = initialPictures.reduce((result, url) => {
      if (url) {
        result.push({ data: url, file: null });
      }

      return result;
    }, []);

    setSelectedPictures(updatedSelection);
  }, [initialPictures]);

  useEffect(() => {
    if (
      !selectedPictures.length ||
      (!!maxNbFiles && selectedPictures.length < maxNbFiles) ||
      (multipleFiles && !maxNbFiles)
    ) {
      setDisplayPlaceholder(true);
      return;
    }

    setDisplayPlaceholder(false);
  }, [multipleFiles, selectedPictures]);

  useImperativeHandle(
    ref,
    () => ({
      getSelectedPictures() {
        return selectedPictures;
      },
    }),
    [selectedPictures],
  );

  const loadPictures = (files) =>
    new Promise((resolve, reject) => {
      const selectedPicturesDuringUpload = [...selectedPictures];

      files.forEach((file, index) => {
        let fileCopy = file;

        fileCopy.path = file.name;

        const reader = new FileReader();

        reader.onerror = reject;
        reader.onload = () => {
          if (!multipleFiles) {
            resolve([{ data: reader.result, file }]);
          }
          selectedPicturesDuringUpload.unshift({ data: reader.result, file });
          if (index === files.length - 1) {
            resolve(selectedPicturesDuringUpload);
          }
        };

        reader.readAsDataURL(fileCopy);
      });
    });

  const imageCompressor = async (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1000,
        1000,
        'JPEG',
        COMPRESSED_FILE_QUALITY,
        0,
        (uri) => {
          resolve(uri);
        },
        'file',
      );
    });

  const handlePictureUpload = async (files) => {
    if (!files) {
      return;
    }

    if (maxNbFiles && selectedPictures.length + files.length > maxNbFiles) {
      setMaxNbFilesIsReached(true);
      return;
    }

    const filesArray = values(files);
    const compressedPictures = [];

    try {
      for (let index = 0; index < files.length; index++) {
        compressedPictures.push(await imageCompressor(filesArray[index]));
      }
    } catch {
      showErrorMessage(i18next.t('ORDERS.ORDERS.FORM_COMPRESSION_ERROR'));
    }

    try {
      const loadedPictures = await loadPictures(compressedPictures);
      setSelectedPictures(loadedPictures);

      // To not trigger callback whereas state selectedPictures has not been updated yet
      if (onSelectionChange) {
        setTimeout(() => {
          onSelectionChange(loadedPictures);
        }, 250);
      }
    } catch {
      showErrorMessage(i18next.t('ORDERS.ORDERS.FORM_LOAD_PICTURES_ERROR'));
    }
  };

  const openPicture = (picture) => {
    setDisplayDropdownActions(-1);
    const w = window.open('about:blank');

    setTimeout(() => {
      w.document.body.appendChild(w.document.createElement('iframe')).src = picture;

      w.document.getElementsByTagName('iframe')[0].style.width = 'calc(100% - 8px)';
      w.document.getElementsByTagName('iframe')[0].style.height = 'calc(100% - 8px)';
    }, 0);
  };

  const deletePicture = (indexPictureToDelete, deletedPictureUrl) => {
    const updatedPictureList = selectedPictures.filter(
      (_, index) => indexPictureToDelete !== index,
    );

    setDisplayDropdownActions(-1);
    setSelectedPictures(updatedPictureList);

    // To not trigger callback whereas state selectedPictures has not been updated yet
    if (onSelectionChange) {
      setTimeout(() => {
        onSelectionChange(null, deletedPictureUrl);
      }, 250);
    }

    if (!multipleFiles && isSizeTooHeavy) {
      setIsSizeTooHeavy(false);
    }
  };

  const getDropDownTopPosition = (index) => {
    const topPosition = document.getElementById(index).getBoundingClientRect();
    setDropDownTopPosition(topPosition.top);
  };

  const checkPictureSize = () => {
    if (!setIsSizeTooHeavy || !selectedPictures.length) {
      return;
    }

    const size = get(selectedPictures, '[0].file.size', null);

    // In the case it's a picture from the initialPictures prop, we don't have the file size
    if (!size) {
      return;
    }

    // Converting the size to MB
    if (size / MB_VALUE_IN_KB > MAX_MB_FILES_UPLOAD) {
      return setIsSizeTooHeavy(true);
    }
    setIsSizeTooHeavy(false);
  };

  useEffect(() => {
    if (!selectedPictures || !selectedPictures.length) {
      return;
    }

    checkPictureSize();
  }, [selectedPictures]);

  const handleAddPictureButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <Container customWidth={customWidth} error={isSizeTooHeavy || maxNbFilesIsReached}>
        <>
          <HoverTooltip
            isTooltipDisplayed={!!disableAddPicture && !!disabledTooltipText}
            renderContent={() => <DisabledTooltip text={disabledTooltipText} />}
          >
            <Button
              buttonCustomStyle={{ paddingLeft: '0px' }}
              color={'inpulse-outline'}
              handleClick={handleAddPictureButtonClick}
              icon={'/images/inpulse/add-a-photo-black.svg'}
              isDisabled={readOnly || disableAddPicture}
              label={i18next.t('ORDERS.ORDERS.ADD_PICTURE', { count: multipleFiles ? 2 : 1 })}
              noBorder
            />
          </HoverTooltip>
          <input
            accept=".jpg,.jpeg,.png"
            id="fileInput"
            multiple={multipleFiles}
            ref={fileInputRef}
            style={{ display: 'none' }}
            type="file"
            onChange={(event) => handlePictureUpload(event.target.files)}
          />
        </>
        {selectedPictures.map((picture, index) => (
          <Box
            highlighted={displayDropdownActions === index}
            id={index}
            key={index}
            url={picture.data}
            full
            onClick={(event) => {
              event.stopPropagation();

              if (index === displayDropdownActions) {
                setDisplayDropdownActions(-1);
              } else {
                setDisplayDropdownActions(index);
              }
              getDropDownTopPosition(index);
            }}
          >
            <OpaqueBackgroundHover isOpaque={index === displayDropdownActions} />
            {displayDropdownActions === index && (
              <ActionsContainer dropDownTopPosition={dropDownTopPosition} isTablet={isTablet}>
                <Action
                  onClick={(event) => {
                    event.stopPropagation();
                    openPicture(picture.data);
                  }}
                >
                  <Icon alt="open-picture" src="/images/inpulse/open-a-new-black-small.svg" />
                  <Text>{i18next.t('GENERAL.SEE_PICTURE')}</Text>
                </Action>
                <Action
                  readOnly={readOnly}
                  onClick={(event) => {
                    event.stopPropagation();
                    !readOnly && deletePicture(index, picture.data);
                  }}
                >
                  <Icon
                    alt="delete-picture"
                    src={`/images/inpulse/delete-${readOnly ? 'lmgrey' : 'black'}-small.svg`}
                  />
                  <Text grey={readOnly}>{i18next.t('GENERAL.DELETE_PICTURE')}</Text>
                </Action>
              </ActionsContainer>
            )}
          </Box>
        ))}
        {displayPlaceholder && <Box disabled />}
      </Container>
      {isSizeTooHeavy && (
        <Text style={{ marginTop: '4px' }} red textSmall>
          {i18next.t('GENERAL.FILE_TOO_HEAVY', { maxSize: MAX_MB_FILES_UPLOAD })}
        </Text>
      )}
      {maxNbFilesIsReached && (
        <Text style={{ marginTop: '4px' }} red textSmall>
          {i18next.t('ORDERS.ORDERS.MAX_CREDIT_PICTURES_COUNT_REACHED', {
            maxPicturesCount: maxNbFiles,
          })}
        </Text>
      )}
    </>
  );
});

MultiplePictureUpload.defaultProps = {
  readOnly: false,
  multipleFiles: true,
  onSelectionChange: null,
};

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(null, mapDispatchToProps)(MultiplePictureUpload);

import styled, { css } from 'styled-components';

export const ModalBackground = styled.div`
  z-index: 1;
  position: fixed;

  background: #1d1d1d40;

  top: 0px;
  left: 0px;

  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(4px);
`;

export const ModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  background: ${(props) => props.theme.colors.greys.lighter};
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
  width: calc(100vw - 96px);
  height: calc(100vh - 96px);
  margin: 48px;
`;

export const ModalHeader = styled.div`
  padding: 24px;
  background: ${(props) => props.theme.label.text.colors.white};
  border-radius: 8px 8px 0 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleWithIcon = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  column-gap: 8px;
`;

export const ModalContent = styled.div`
  overflow: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
  padding: 24px 24px;
`;

export const ModalFooter = styled.div`
  width: 100%;
  padding: 24px;
  background-color: ${(props) => props.theme.label.text.colors.white};

  display: flex;
  justify-content: flex-end;

  border-top: solid 1px ${(props) => props.theme.colors.greys.lighty};
  border-radius: 0 0 8px 8px;
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 24px;
`;

export const Icon = styled.img`
  width: 16px;
  height: 16px;
  ${(props) =>
    props.interactive &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}
`;

export const LinkContainer = styled.div`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
export const ControlInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StatusControlContainer = styled.div`
  display: flex;

  width: 100%;
  gap: 24px;
`;

export const DetailsContainer = styled.div`
  display: flex;
  justify-content: left;
`;

export const StatusContainer = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
`;

export const CreditInfoContainer = styled.div`
  flex: 1;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const LoaderContainer = styled.div`
  width: fit-content;
`;

export const InpulseLabelContainer = styled.div`
  display: flex;
  gap: '8px';
`;

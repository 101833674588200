import { connect } from 'react-redux';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import normalizeStringValue from '@commons/utils/normalizeStringValue';

import { getClientInfo } from '@selectors/client';

import { getNewCategorySubcategoryModalParams } from '@admin/products/products/components/CreateProductModal/utils/modalConfigurations';

import { Container, FormContainer, Section, SectionInfo, Title } from './styledComponents';
import { fetchAllergensList } from './common/services';

import { CATEGORY_TYPES_OBJECT } from '@commons/constants/categoryTypes';
import {
  IngredientNameInput,
  IngredientCategoryInput,
  IngredientUnityInput,
  IngredientCostInput,
  IngredientAllergensInput,
  IngredientIsStrategicInput,
} from './common/inputs';

import clientService from '@services/client';

/**
 * Get the custom list of inputs to allow in the form according the client settings
 *
 * @returns {Inputs[]} The list of custom inputs to display
 */
export function getCustomListInputs() {
  return [
    [IngredientNameInput, IngredientCategoryInput, IngredientAllergensInput],
    [IngredientUnityInput, IngredientCostInput, IngredientIsStrategicInput],
  ];
}

/**
 * Render the ingredient form section with the different inputs that should be displayed
 *
 * @param {Props} props                         - The props sent to method
 * @param {Ingredient} props.Ingredient         - The ingredient's data that should be displayed
 * @param {Function} props.onIngredientChange   - Method used to set the local state of the ingredient variable
 */
export function renderForm(props) {
  const { ingredient } = props;

  const ingredientFieldsFormBySection = getCustomListInputs();

  return (
    <div>
      {ingredientFieldsFormBySection.map((section, indexSection) => (
        <Section key={indexSection}>
          {section.map((item, indexItem) => {
            const ComponentName = item.component;
            const inputProps = item.props(props);

            return (
              <SectionInfo key={indexItem}>
                <ComponentName
                  {...inputProps}
                  value={ingredient[item.keyProperty] != null ? ingredient[item.keyProperty] : ''}
                />
              </SectionInfo>
            );
          })}
        </Section>
      ))}
    </div>
  );
}

/*************************/
/* Main Component Method */
/*************************/

export function IngredientInfoSection(props) {
  const {
    ingredient,
    onIngredientChange,
    client: { clientId },
    isReadOnly,
    isChildCreation,
    isCostInputDisabled,
    currency,
    openGenericModal,
    showErrorMessage,
    showSuccessMessage,
    pageLoading,
    pageLoaded,
  } = props;

  const [ingredientsCategories, setIngredientsCategories] = useState([]);
  const [availableAllergens, setAvailableAllergens] = useState([]);

  const [openModalAddNewItemDropdown, setOpenModalAddNewItemDropdown] = useState(false);
  const [itemDropdownPropertyName, setItemDropdownPropertyName] = useState('');

  const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  const { t } = useTranslation();

  const getCategories = async (id) => {
    const { categories } = await clientService.getCategoriesAndSubCategories(
      id,
      CATEGORY_TYPES_OBJECT.INGREDIENT,
    );

    const formattedCategories = categories.map((category) => ({
      ...category,
      name: category.name || i18next.t('GENERAL.NONE_VALUE'),
    }));

    setIngredientsCategories(sortBy(formattedCategories, 'name'));
  };

  useEffect(() => {
    if (!clientId) {
      return;
    }

    (async function loadData() {
      try {
        await Promise.all([getCategories(clientId), fetchAllergensList(setAvailableAllergens)]);
      } catch (err) {
        showErrorMessage(i18next.t('ADMIN.INGREDIENTS.ERROR_FETCH_INGREDIENT_DETAIL'));
      }
    })();
  }, [clientId]);

  useEffect(() => {
    if (openModalAddNewItemDropdown) {
      const modalCreateNewCategoryParams = getNewCategorySubcategoryModalParams(
        inputValue,
        errorMessage,
        closeCleanUp,
        handleInputChange,
        itemDropdownPropertyName,
        handleSaveNewItemDropdown,
      );

      openGenericModal(modalCreateNewCategoryParams);
    }
  }, [inputValue, openModalAddNewItemDropdown]);

  const handleInputChange = (value) => {
    setInputValue(value);

    if (!value) {
      setErrorMessage(i18next.t('GENERAL.REQUIRED_FILED_ERROR_MESSAGE'));

      return;
    }

    const alreadyExists = ingredientsCategories.some(
      ({ name }) => normalizeStringValue(name) === normalizeStringValue(value),
    );

    if (alreadyExists) {
      setErrorMessage(i18next.t('GENERAL.MODAL_CATEGORY_NAME_ALREADY_USED'));

      return;
    }

    setErrorMessage(null);
  };

  const closeCleanUp = () => {
    setErrorMessage(null);
    setInputValue('');

    setOpenModalAddNewItemDropdown(false);
  };

  const handleNewCategoryCreation = async () => {
    try {
      pageLoading();

      const createdCategory = await clientService.createCategory(
        clientId,
        inputValue.trim(),
        CATEGORY_TYPES_OBJECT.INGREDIENT,
      );

      const updatedCategories = sortBy([...ingredientsCategories, createdCategory], 'name');

      setIngredientsCategories(updatedCategories);

      onIngredientChange({
        ...ingredient,
        category: createdCategory.name,
        categoryId: createdCategory.id,
      });

      showSuccessMessage(i18next.t('ADMIN.RECIPES.CATEGORY_CREATION_SUCCESS'));
    } catch {
      const categoryNone = ingredientsCategories.find(
        ({ name }) => name === i18next.t('GENERAL.NONE_VALUE'),
      );

      onIngredientChange({
        ...ingredient,
        category: categoryNone.name,
        categoryId: categoryNone.id,
      });

      showErrorMessage(i18next.t('ADMIN.RECIPES.CATEGORY_CREATION_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const handleSaveNewItemDropdown = async () => {
    await handleNewCategoryCreation();

    setOpenModalAddNewItemDropdown(false);
    setInputValue('');
    setErrorMessage(null);
  };

  return (
    <Container>
      <Title>{t('ADMIN.INGREDIENTS.INFO_TITLE')}</Title>
      <FormContainer>
        {renderForm({
          ingredient,
          onIngredientChange,
          ingredientsCategories,
          availableAllergens,
          isChildCreation,
          setOpenModalAddNewItemDropdown,
          setItemDropdownPropertyName,
          isReadOnly,
          isCostInputDisabled,
          currency,
        })}
      </FormContainer>
    </Container>
  );
}

IngredientInfoSection.propTypes = {
  ingredient: PropTypes.object,
  onIngredientChange: PropTypes.func,
  isChildCreation: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  currency: state.baseReducer.currency,
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(IngredientInfoSection);

const { get } = require('lodash');
const moment = require('moment-timezone');

const { DATE_DISPLAY_FORMATS } = require('@commons/DatePickers/constants');

const SHEET = {
  id: 'supplierProducts',
  name: 'Ingrédients_fournisseurs',
};

const COLUMN_IDS = {
  ID: { key: 'id', normalizedKey: 'id' },
  NAME: { key: 'name', normalizedKey: 'nom' },
  SKU: {
    key: 'sku',
    normalizedKey: 'sku',
    // Avoid considering SKU as number
    mappingFn: (row, normalizedKey) => get(row, normalizedKey, '').toString(),
  },
  EAN: {
    key: 'ean',
    normalizedKey: 'code ean 13',
    mappingFn: (row, normalizedKey) => get(row, normalizedKey, '').toString() || null,
  },
  SUPPLIER: { key: 'supplierName', normalizedKey: 'fournisseur' },
  CATEGORY: { key: 'category', normalizedKey: 'categorie' },
  SUB_CATEGORY: { key: 'subCategory', normalizedKey: 'sous-categorie' },
  PRICE: { key: 'price', normalizedKey: 'prix ht' },
  VAT_RATE: { key: 'vatRate', normalizedKey: 'tva (%)' },
  INVOICE_PACKAGING: {
    key: 'invoicePackagingName',
    normalizedKey: 'conditionnement de facturation',
  },
  SCHEDULED_PRICE_VALUE: {
    key: 'scheduledPriceValue',
    normalizedKey: 'prix ht a programmer',
    mappingFn: (row, normalizedKey) => get(row, normalizedKey, null),
  },
  SCHEDULED_PRICE_DATE: {
    key: 'scheduledPriceStartDate',
    normalizedKey: 'date de changement de prix',
    mappingFn: (row, normalizedKey) => {
      const scheduledStartDate = get(row, normalizedKey, null);
      const isScheduledStartDateValid = moment(
        scheduledStartDate,
        [DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR, DATE_DISPLAY_FORMATS.DASHED_DAY_MONTH_YEAR],
        true,
      ).isValid();

      // Adding one hour because xlsx parsing remove approximately 30 minutes from a date and there's no real fix for it
      // except this quickfix, see issue: https://github.com/SheetJS/sheetjs/issues/1565
      // We do not take timezone into account so that the timezone stays at DD-MM-YYYY-00-00-00
      return isScheduledStartDateValid
        ? moment(scheduledStartDate, [
            DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR,
            DATE_DISPLAY_FORMATS.DASHED_DAY_MONTH_YEAR,
          ])
            .utc(true)
            .add(1, 'h')
            .startOf('day')
            .format()
        : null;
    },
  },
  LOSS: {
    key: 'loss',
    normalizedKey: 'pertes (%)',
  },
  PRICE_EDITABLE_AT_RECEPTION: {
    key: 'priceEditableAtReception',
    normalizedKey: 'prix modifiable a reception',
    mappingFn: (row, normalizedKey) => get(row, normalizedKey) === 'OUI',
  },
  ENTITY: {
    key: 'associatedIngredient',
    normalizedKey: 'ingredient associe',
  },
  PACKAGING_NAME_1: { key: 'packagingName1', normalizedKey: 'conditionnement 1' },
  PACKAGING_UNIT_1: {
    key: 'packagingUnit1',
    normalizedKey: 'unite conditionnement 1',
  },
  PACKAGING_QUANTITY_1: {
    key: 'packagingQuantity1',
    normalizedKey: 'quantite conditionnement 1',
  },
  PACKAGING_NAME_2: { key: 'packagingName2', normalizedKey: 'conditionnement 2' },
  PACKAGING_UNIT_2: {
    key: 'packagingUnit2',
    normalizedKey: 'unite conditionnement 2',
  },
  PACKAGING_QUANTITY_2: {
    key: 'packagingQuantity2',
    normalizedKey: 'quantite conditionnement 2',
  },
  PACKAGING_NAME_3: { key: 'packagingName3', normalizedKey: 'conditionnement 3' },
  PACKAGING_UNIT_3: {
    key: 'packagingUnit3',
    normalizedKey: 'unite conditionnement 3',
  },
  PACKAGING_QUANTITY_3: {
    key: 'packagingQuantity3',
    normalizedKey: 'quantite conditionnement 3',
  },
  MANUFACTURER_NAME: { key: 'manufacturerName', normalizedKey: 'nom du fabricant' },
  PACKAGE_WEIGHT: { key: 'packagingWeight', normalizedKey: 'poids du colis (kg)' },
  CURRENCY: { key: 'currency', normalizedKey: 'devise' },
};

module.exports = {
  SHEET,
  COLUMN_IDS,
};

import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getUserTimezone } from '@commons/utils/date';
import { handleAppRefresh } from '@commons/utils/refreshApp';

import { canEditTransfer } from '@selectors/actions/transferActions';
import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';
import { getSalesPointStores } from '@selectors/stores';

import inventoryTransferListService from '@services/inventoryTransferList';
import storeService from '@services/store';

import {
  buildSupplierProductFromExistingInventory,
  getInventoryPayload,
} from '@stocks/StocksInventories/components/StockForm/utils/formatSupplierProducts';
import { isEditionAllowed } from '@stocks/StocksInventories/common/rights';

import { exportTransferContentInFullScreenModal } from '../../utils/exports/exportContent';
import { STORE_LINKAGE_TYPES } from '../../utils/index';

import { Container } from './styledComponents';
import { getPropertyNoneValue } from '@commons/constants/categoryTypes';
import EmptyState from './components/EmptyState';
import TransferFormContent from './components/TransferFormContent';
import TransferFormHeader from './components/Header';

const TransferForm = (props) => {
  const {
    closeModal,
    pageLoading,
    pageLoaded,
    showErrorMessage,
    showSuccessMessage,
    params: { fetchTransfers, inventoryTransferList = {} },
    stores,
    client: { clientId, storeName },
    currency,
    authorizedActions,
    shouldReloadApp,
  } = props;

  const [transferList, setTransferList] = useState(null);
  const [isSaveAvailable, setIsSaveAvailable] = useState(false);
  const [selectedSupplierProducts, setSelectedSupplierProducts] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isCreation, setIsCreation] = useState(true);
  const [userStoreLinkageType, setUserStoreLinkageType] = useState('');
  const [isUpdatingDate, setIsUpdatingDate] = useState(false);
  const [isEditionAvailable, setIsEditionAvailable] = useState(false);
  const [clientStores, setClientStores] = useState([]);

  const [showTransferFormContent, setShowTransferFormContent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const translatedStoreNameSingular = getClientStoreNameTranslation(storeName);
  const translatedStoreNamePlural = getClientStoreNameTranslation(storeName, true);

  const userTimezone = getUserTimezone();

  useEffect(() => {
    (async function loadData() {
      setIsLoading(true);
      await fetchClientStores();

      if (_.isEmpty(inventoryTransferList)) {
        setIsLoading(false);
        return;
      }

      setIsCreation(!inventoryTransferList.id);

      const senderStore = await storeService.getById(inventoryTransferList.senderStoreId);

      setTransferList({
        ...inventoryTransferList,
        sentAt: moment.tz(inventoryTransferList.sentAt, senderStore.timezone),
        updatedAt: moment.tz(inventoryTransferList.updatedAt, userTimezone),
      });

      handleSupplierProductsFormattingBeforeEditing(inventoryTransferList.inventories);
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (_.isEmpty(transferList)) {
      return;
    }

    setIsCreation(!transferList.id);

    setShowTransferFormContent(
      !!(transferList.senderStoreId && transferList.recipientStoreId && transferList.sentAt),
    );

    const storesIds = stores.map((store) => store.id);

    const isLinkedToSender = _.includes(storesIds, transferList.senderStoreId);
    const isLinkedToRecipient = _.includes(storesIds, transferList.recipientStoreId);

    if (isLinkedToSender && isLinkedToRecipient) {
      setUserStoreLinkageType(STORE_LINKAGE_TYPES.BOTH);
      return;
    }

    if (isLinkedToSender) {
      setUserStoreLinkageType(STORE_LINKAGE_TYPES.SENDER);
      return;
    }

    if (isLinkedToRecipient) {
      setUserStoreLinkageType(STORE_LINKAGE_TYPES.RECIPIENT);
      return;
    }
  }, [transferList]);

  useEffect(() => {
    if (_.isEmpty(transferList)) {
      return;
    }

    if (
      !isCreation &&
      (transferList.hasBeenReceived ||
        userStoreLinkageType === STORE_LINKAGE_TYPES.RECIPIENT ||
        !isEditionAllowed(transferList.createdAt) ||
        (isEditionAllowed(transferList.createdAt) && !canEditTransfer(authorizedActions)))
    ) {
      setIsEditionAvailable(false);
      return;
    }

    setIsEditionAvailable(true);
  }, [transferList, isCreation, userStoreLinkageType]);

  const fetchClientStores = async () => {
    try {
      const currentClientStores = await storeService.getStoresOfClient(clientId);
      setClientStores(currentClientStores);
    } catch (err) {
      showErrorMessage(
        i18next.t('GENERAL.STORES_CLIENT_FETCH_FAILURE', {
          storeName: translatedStoreNamePlural,
        }),
      );
    }
  };

  const handleSave = async (isValidating = false) => {
    if (!isValidating) {
      pageLoading();
    }

    const inventoryTransferListToSave = {
      ...transferList,
      totalPrice,
      hasBeenReceived: !!transferList.hasBeenReceived,
    };

    const formattedSupplierProducts = getInventoryPayload(selectedSupplierProducts, {
      isTransfer: true,
    });

    try {
      const { id } = await inventoryTransferListService.upsertInventoryTransfer(
        inventoryTransferListToSave,
        formattedSupplierProducts,
        userTimezone,
      );

      if (!isValidating) {
        showSuccessMessage(
          i18next.t(
            isCreation ? 'STOCKS.TRANSFERS.CREATION_SUCCESS' : 'STOCKS.TRANSFERS.EDIT_SUCCESS',
          ),
        );
        handleAppRefresh(shouldReloadApp);

        closeModal();
        fetchTransfers();
      }

      return id;
    } catch (err) {
      showErrorMessage(
        i18next.t(isCreation ? 'STOCKS.TRANSFERS.CREATION_ERROR' : 'STOCKS.TRANSFERS.EDIT_ERROR'),
      );
    } finally {
      if (!isValidating) {
        pageLoaded();
      }
    }
  };

  const handleTransferExportContent = async () => {
    const inventoryTransferListToExport = {
      ...transferList,
      sentAt: _.get(transferList, 'sentAt').format(),
      senderStore: _.find(clientStores, ['id', transferList.senderStoreId]),
      recipientStore: _.find(clientStores, ['id', transferList.recipientStoreId]),
      totalPrice,
      hasBeenReceived: !!transferList.hasBeenReceived,
    };
    const inventories = getInventoryPayload(selectedSupplierProducts, { isTransfer: true });

    const formattedInventories = inventories.map((inventory) => {
      const supplierProduct = selectedSupplierProducts.find(
        ({ id }) => id === inventory.supplierProductId,
      );

      return {
        ...inventory,
        lnkSupplierproductInventoryrel: {
          ...supplierProduct,
          category: supplierProduct.category || getPropertyNoneValue(),
          subCategory: supplierProduct.subCategory || getPropertyNoneValue(),
        },
      };
    });

    await exportTransferContentInFullScreenModal(
      inventoryTransferListToExport,
      formattedInventories,
      clientId,
      translatedStoreNameSingular,
      showErrorMessage,
      currency,
    );
  };

  const handleValidation = async () => {
    pageLoading();

    try {
      const inventoryTransferListId = await handleSave(true);

      await inventoryTransferListService.validateInventoryTransferList(
        inventoryTransferListId,
        userTimezone,
      );

      showSuccessMessage(i18next.t('STOCKS.TRANSFERS.VALIDATION_SUCCESS'));

      closeModal();
      fetchTransfers();
      handleAppRefresh(shouldReloadApp);
    } catch (error) {
      showErrorMessage(i18next.t('STOCKS.TRANSFERS.VALIDATION_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const handleSupplierProductsFormattingBeforeEditing = (inventories) => {
    const alreadyAddedSupplierProducts = [];

    const formattedInventories = [];

    const supplierProducts = inventories.reduce((result, inventory) => {
      if (!_.includes(alreadyAddedSupplierProducts, inventory.supplierProductId)) {
        alreadyAddedSupplierProducts.push(inventory.supplierProductId);
        result.push(inventory.lnkSupplierproductInventoryrel);
      }

      formattedInventories.push({
        ...inventory,
        id: inventory.supplierProductId,
        packagings: inventory.lnkSupplierproductInventoryrel.packagings,
      });

      return result;
    }, []);

    const formattedSupplierProducts = supplierProducts.map((supplierProduct) =>
      buildSupplierProductFromExistingInventory(supplierProduct, formattedInventories),
    );

    setSelectedSupplierProducts(formattedSupplierProducts);
  };

  return (
    <Container>
      <TransferFormHeader
        closeModal={closeModal}
        handleSave={handleSave}
        handleValidation={handleValidation}
        isCreation={isCreation}
        isEditionAvailable={isEditionAvailable}
        isSaveAvailable={isSaveAvailable}
        isUpdatingDate={isUpdatingDate}
        selectedSupplierProducts={selectedSupplierProducts}
        setTransferList={setTransferList}
        totalPrice={totalPrice}
        transferList={transferList}
        userStoreLinkageType={userStoreLinkageType}
      />
      {!isLoading && (
        <>
          {showTransferFormContent ? (
            <TransferFormContent
              handleTransferExportContent={handleTransferExportContent}
              isCreation={isCreation}
              isEditionAvailable={isEditionAvailable}
              isUpdatingDate={isUpdatingDate}
              selectedSupplierProducts={selectedSupplierProducts}
              senderStore={_.find(clientStores, ['id', transferList.senderStoreId], {})}
              setIsEditionAvailable={setIsEditionAvailable}
              setIsSaveAvailable={setIsSaveAvailable}
              setIsUpdatingDate={setIsUpdatingDate}
              setSelectedSupplierProducts={setSelectedSupplierProducts}
              setTotalPrice={setTotalPrice}
              totalPrice={totalPrice}
              transferList={transferList}
              userStoreLinkageType={userStoreLinkageType}
            />
          ) : (
            <EmptyState
              icon={'/images/inpulse-icon-no-data.svg'}
              subtitle={i18next.t('STOCKS.TRANSFERS.FORM_EMPTY_STATE_TITLE')}
            />
          )}
        </>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
  stores: getSalesPointStores(state.baseReducer.activeStores),
  client: getClientInfo(state.baseReducer.user),
  shouldReloadApp: state.baseReducer.shouldReloadApp,
  authorizedActions: getAuthorizedActions(
    state.baseReducer.userRights,
    '/stocks/inventories/transfer',
  ),
  hasNewVersion: state.baseReducer.hasNewVersion,
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TransferForm);

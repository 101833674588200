import _ from 'lodash';
import i18next from 'i18next';
import React from 'react';

import {
  Dropdown,
  INPUT_WIDTH,
  SearchBar,
  ToggleButton,
} from '@commons/utils/styledLibraryComponents';

import { getEntityTypesChoices } from '@stocks/utils/constants';

import { FilterContainer } from '../Filters/styledComponents';

import { Container, LoadingInfoPanelRow, LoadingRow, LoadingContent } from './styledComponents';

const LOADING_ROW_ITEM = 11;

const LoadingState = () => {
  const entityTypesChoices = getEntityTypesChoices();

  const renderInfoPanel = () => (
    <LoadingInfoPanelRow>
      <FilterContainer>
        <ToggleButton choices={entityTypesChoices} isDisabled={true} />
      </FilterContainer>
      <FilterContainer>
        <Dropdown
          isDisabled={true}
          placeholder={i18next.t('GENERAL.CHOOSE_PLACEHOLDER')}
          selectedItems={[]}
          width={INPUT_WIDTH.MEDIUM}
        />
      </FilterContainer>
      <FilterContainer>
        <SearchBar disabled={true} maxWidth={160} minWidth={160} setValue={() => false} />
      </FilterContainer>
      <FilterContainer></FilterContainer>
      <FilterContainer>
        <LoadingRow key={`convention`} width={'160px'}>
          <LoadingContent />
        </LoadingRow>
      </FilterContainer>
      <FilterContainer>
        <LoadingRow key={`total`} width={'160px'}>
          <LoadingContent />
        </LoadingRow>
      </FilterContainer>
    </LoadingInfoPanelRow>
  );

  return (
    <Container>
      {renderInfoPanel()}
      {_.times(LOADING_ROW_ITEM, (index) => (
        <LoadingRow key={`loading-item-${index}`} showMargin>
          <LoadingContent />
        </LoadingRow>
      ))}
    </Container>
  );
};

export default LoadingState;

import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import i18next from 'i18next';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';

import {
  canReceiveOrderAndSendCreditRequest,
  canValidateCreditRequestWithoutMail,
} from '@selectors/actions/orderActions';
import { showErrorMessage } from '@actions/messageconfirmation';

import { Button, Tooltip } from '@commons/utils/styledLibraryComponents';
import { DeepsightComponentLoader } from '@commons/DeepsightComponents';
import { getTheme } from '@commons/utils/theme';
import InputEmailChip from '@lib/inpulse/InputEmailChip';
import WhiteCard from '@commons/WhiteCard';
import WysiwygEditor from '@commons/WysiwygEditor';

import { MAX_CREDIT_ORDER_PICTURE_PER_ORDER } from '@orders/OrderList/constants';

import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';

import {
  ContainerTextArea,
  EmailContainer,
  OrderCartContainer,
  SubTitleWhiteCard,
  WhiteCardContainer,
} from '../../styledComponents';
import MultiplePictureUpload from '../OrderFormInfoPanel/components/MultiplePictureUpload';

import { ButtonsContainer, Title } from './styledComponents';

/**
 * When doing the reception of an order, if any anomalies are entered, the form shall first display
 * a summary of the credit request, along with emails/comment/picture inputs to set for the credit request
 * @param {*} props
 */
const OrderCreditForm = (props) => {
  const {
    htmlPreview,
    creditRequestData,
    setCreditRequestData,
    handleSaveDelivery,
    productsInventory,
    handleDeletePOCreditPicture,
    setAllCreditPicturesCount,
    disableAddCreditPicture,
    // from redux
    authorizedActions,
  } = props;

  const theme = getTheme();

  const { recipientEmails, copyEmails, comment } = creditRequestData;

  const pictureSelectedRef = useRef();
  const [displayPictureDropdown, setDisplayPictureDropdown] = useState(false);
  const [initialPictures, setInitialPictures] = useState([]);
  const [isSizeTooHeavy, setIsSizeTooHeavy] = useState(false);

  const readyToSend = !isEmpty(recipientEmails);

  useEffect(() => {
    const allPictures = [];

    if (creditRequestData.creditOrderPictureUrls) {
      // Initialize order related credit pictures
      creditRequestData.creditOrderPictureUrls.forEach((picture) => {
        const loadedFileUrl = get(picture, 'data', null);

        if (loadedFileUrl) {
          allPictures.push(loadedFileUrl);
          return;
        }

        allPictures.push(picture);
      });
    }

    // Initialize POs related credit pictures
    productsInventory.forEach((categoryData) => {
      const { products } = categoryData;

      products.forEach((product) => {
        const { creditOrderPictureUrl } = product;

        const loadedFileUrl = get(creditOrderPictureUrl, 'data', null);

        if (loadedFileUrl) {
          allPictures.push(loadedFileUrl);
          return;
        }

        if (!!creditOrderPictureUrl) {
          allPictures.push(creditOrderPictureUrl);
        }
      });
    });

    setInitialPictures(allPictures);
    setAllCreditPicturesCount(allPictures.length);
  }, [creditRequestData.creditOrderPictureUrls, productsInventory]);

  const onDataChange = (key, value) => {
    setCreditRequestData({ ...creditRequestData, [key]: value });
  };

  const onSelectedPictureChange = async (loadedPictures, deletedPictureUrl = null) => {
    // Only can load order related pictures here
    if (!!loadedPictures && loadedPictures.length) {
      const newPicturesCount = loadedPictures.length - initialPictures.length;
      const picturesToAdd = loadedPictures.slice(0, newPicturesCount);

      setCreditRequestData({
        ...creditRequestData,
        creditOrderPictureUrls: [...creditRequestData.creditOrderPictureUrls, ...picturesToAdd],
      });

      return;
    }

    if (isEmpty(loadedPictures) && !!deletedPictureUrl) {
      const { creditOrderPictureUrls } = creditRequestData;

      // Handle POs picture deletion
      let categoryToUpdate = null;
      let productIdToUpdate = null;

      productsInventory.forEach((categoryData) => {
        const { products } = categoryData;

        products.forEach((product) => {
          const { creditOrderPictureUrl } = product;

          const fileUrl = get(creditOrderPictureUrl, 'data', null) || creditOrderPictureUrl;

          if (fileUrl === deletedPictureUrl) {
            categoryToUpdate = categoryData.name;
            productIdToUpdate = product.id;
          }
        });
      });

      if (!!categoryToUpdate && !!productIdToUpdate) {
        handleDeletePOCreditPicture(productIdToUpdate, categoryToUpdate);

        return;
      }

      // Handle order picture deletion
      const updatedCreditOrderPictureUrls = creditOrderPictureUrls.filter((picture) => {
        const fileUrl = get(picture, 'data', null) || picture;

        return !!fileUrl && deletedPictureUrl !== fileUrl;
      });

      setCreditRequestData({
        ...creditRequestData,
        creditOrderPictureUrls: updatedCreditOrderPictureUrls,
      });
    }
  };

  const handleSendCreditRequest = (withMail = true) => {
    handleSaveDelivery(false, withMail);
  };

  const customWithCardStyle = { border: `1px solid ${theme.colors.greys.light}` };

  return (
    <OrderCartContainer>
      <WhiteCardContainer width={'65%'}>
        <WhiteCard
          customStyle={customWithCardStyle}
          maxWidth={'inherit'}
          renderContent={
            <div>
              <Title>{i18next.t('ORDERS.ORDERS.CREDIT_TITLE')}</Title>
              {!!htmlPreview ? <div>{parse(htmlPreview)}</div> : <DeepsightComponentLoader />}
            </div>
          }
          width={'100%'}
        ></WhiteCard>
      </WhiteCardContainer>

      <WhiteCardContainer width={'35%'}>
        <WhiteCard
          customStyle={customWithCardStyle}
          renderContent={
            <>
              <Title>{i18next.t('ORDERS.ORDERS.CREDIT_EMAIL_TITLE')}</Title>
              <EmailContainer>
                <InputEmailChip
                  emails={recipientEmails}
                  isDisabled={!canReceiveOrderAndSendCreditRequest(authorizedActions)}
                  placeholder={i18next.t('ORDERS.ORDERS.CART_RECIPIENTS')}
                  required={true}
                  setEmails={(emails) => onDataChange('recipientEmails', emails)}
                  title={`${i18next.t('ORDERS.ORDERS.CART_RECIPIENTS')} :`}
                  width={'100%'}
                />
              </EmailContainer>
              <EmailContainer>
                <InputEmailChip
                  emails={copyEmails}
                  isDisabled={!canReceiveOrderAndSendCreditRequest(authorizedActions)}
                  placeholder={i18next.t('ORDERS.ORDERS.CART_RECIPIENTS_COPIES')}
                  setEmails={(emails) => onDataChange('copyEmails', emails)}
                  title={`${i18next.t('ORDERS.ORDERS.CART_RECIPIENTS_COPIES')} :`}
                  width={'100%'}
                />
              </EmailContainer>
              <ContainerTextArea>
                <SubTitleWhiteCard>{`${i18next.t(
                  'ORDERS.ORDERS.CART_COMMENT',
                )} :`}</SubTitleWhiteCard>
                <div>
                  <WysiwygEditor
                    content={comment}
                    customWrapperStyle={{ minHeight: '120px' }}
                    isDisabled={!canReceiveOrderAndSendCreditRequest(authorizedActions)}
                    setter={(value) => onDataChange('comment', value)}
                  />
                </div>
              </ContainerTextArea>
              <div>
                <SubTitleWhiteCard>
                  {i18next.t('ORDERS.ORDERS.LIST_LABEL_ANOMALY_PROOF', { count: 2 })} :
                  <Tooltip
                    dataHtml={true}
                    text={i18next.t('ORDERS.ORDERS.LIST_LABEL_ANOMALY_PROOF_TOOLTIP', { count: 2 })}
                  />
                </SubTitleWhiteCard>
                <MultiplePictureUpload
                  disableAddPicture={disableAddCreditPicture}
                  disabledTooltipText={i18next.t(
                    'ORDERS.ORDERS.MAX_CREDIT_PICTURES_COUNT_REACHED_TOOLTIP',
                    {
                      maxPicturesCount: MAX_CREDIT_ORDER_PICTURE_PER_ORDER,
                    },
                  )}
                  displayDropdownActions={displayPictureDropdown}
                  initialPictures={initialPictures}
                  isSizeTooHeavy={isSizeTooHeavy}
                  maxNbFiles={MAX_CREDIT_ORDER_PICTURE_PER_ORDER}
                  ref={pictureSelectedRef}
                  setDisplayDropdownActions={setDisplayPictureDropdown}
                  setIsSizeTooHeavy={setIsSizeTooHeavy}
                  multipleFiles
                  onSelectionChange={onSelectedPictureChange}
                />
              </div>
              <ButtonsContainer>
                <Button
                  color={'inpulse-default'}
                  handleClick={() => handleSendCreditRequest(true)}
                  icon={'/images/inpulse/send-white-small.svg'}
                  isDisabled={
                    !readyToSend || !canReceiveOrderAndSendCreditRequest(authorizedActions)
                  }
                  label={i18next.t('GENERAL.SEND')}
                />
                <Button
                  color={'inpulse-outline'}
                  handleClick={() => handleSendCreditRequest(false)}
                  icon={'/images/inpulse/check-black-small.svg'}
                  isDisabled={!canValidateCreditRequestWithoutMail(authorizedActions)}
                  label={i18next.t('GENERAL.VALIDATE_WITHOUT_SENDING')}
                />
              </ButtonsContainer>
            </>
          }
          width={'100%'}
        />
      </WhiteCardContainer>
    </OrderCartContainer>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  client: getClientInfo(state.baseReducer.user),
  authorizedActions: getAuthorizedActions(state.baseReducer.userRights, '/order/orders'),
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderCreditForm);

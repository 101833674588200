import { chain, difference, groupBy, isEmpty, keyBy, pickBy, sumBy } from 'lodash';
import { connect } from 'react-redux';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

import { DATE_DISPLAY_FORMATS } from '../../../commons/DatePickers/constants';
import { Dropdown } from '../../../commons/utils/styledLibraryComponents';
import { getClientStoreNameTranslation } from '../../../commons/utils/translations';
import { getUserTimezone, isSameOrBeforeDay } from '../../../commons/utils/date';
import { LoadingContainer } from '../../../commons/Metrics/styledComponents';
import { POSITIVE_VARIATION, STATS_DATA_TYPE } from '../../../commons/Metrics/components/Stats';
import { ratio } from '../../../commons/utils/math';
import Breadcrumb from '../../../commons/Breadcrumb';
import Metrics from '../../../commons/Metrics';
import PeriodWeekPicker from '../../../commons/DatePickers/PeriodWeekPicker';

import dashboardRepository from '../../../storage/repositories/dashboardRepository';
import dashboardService from '../../../services/dashboard';

import { getClientInfo } from '../../../selectors/client';
import { ITEM_DROPDOWN_NONE_VALUE } from '../../admin/utils/DropdownItems';
import { showErrorMessage } from '../../../actions/messageconfirmation';

import {
  Container,
  ContentContainer,
  GraphContainer,
  MetricsContainer,
  PageContainer,
  RowContainer,
  SelectorContainer,
} from './styledComponents';
import ComponentLoader from '../../../commons/DeepsightComponents/ComponentLoader';
import RealConsumptionLossProgressBar from './components/RealConsumptionLossProgressBar';
import TopFlopProductsTable from './components/TopFlopProductsTable';
import TurnoverRealConsumptionGraph from './components/TurnoverRealConsumptionGraph';

const METRICS_CURRENCY_KEYS = {
  turnoverWithoutTaxes: 'turnoverWithoutTaxes',
  realConsumption: 'realConsumption',
  theoreticalConsumption: 'theoreticalConsumption',
  marginWithoutTaxes: 'marginWithoutTaxes',
};

const CREATED_AT_LIMIT_DATE = 7;

const STORE_THRESHOLD = 50;
const STORE_THRESHOLD_INITIAL_DELTA_WEEK = 4;
const DEFAULT_MAX_DELTA_WEEK = 12;

export const FoodCostDashboard = (props) => {
  const {
    client: { useTtc, hasMultipleBrands, storeName, creationDate },
    currency,
    stores: storesOfClient,
  } = props;

  const currencyCode = currency.alphabeticCode;
  const translatedStoreName = getClientStoreNameTranslation(storeName, true).toLowerCase();

  const storesKeyById = keyBy(storesOfClient, 'id');

  /* STATE */

  // Selectors
  const [stores, setStores] = useState([]);
  const [storesBySelectedBrands, setStoresBySelectedBrands] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedStores, setSelectedStores] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // Metrics
  const [isDashboardAnalyticsLoading, setIsDashboardAnalyticsLoading] = useState(true);
  const [isPreviousYearDataLoading, setIsPreviousYearDataLoading] = useState(true);
  const [allStoresTurnoverExcludingTaxes, setAllStoresTurnoverExcludingTaxes] = useState([]);
  const [allStoresRealConsumption, setAllStoresRealConsumption] = useState([]);
  const [allStoreTheoreticalConsumption, setAllStoreTheoreticalConsumption] = useState([]);
  const [allStoresMarginExcludingTaxes, setAllStoresMarginExcludingTaxes] = useState([]);

  // Store Ranking Data
  const [storeRanking, setStoreRanking] = useState([]);

  // Turnover and Real consumption Graph
  const [graphData, setGraphData] = useState([]);

  // Miscellaneous
  const createdAtThreshold = moment().subtract(CREATED_AT_LIMIT_DATE, 'days');
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const userTimezone = getUserTimezone();

  /* USE EFFECT */

  useEffect(() => {
    if (!storesOfClient || !storesOfClient.length) {
      return;
    }

    fetchSelectorsData();

    // Handle window resizing and message display if window width is too small
    window.addEventListener('resize', () => setScreenWidth(window.innerWidth));

    return () => {
      window.removeEventListener('resize', () => setScreenWidth(window.innerWidth));
    };
  }, [storesOfClient]);

  useEffect(() => {
    if (isEmpty(selectedStores) || !startDate || !endDate) {
      return;
    }

    (async () => {
      const selectedStoreIds = selectedStores.map(({ id }) => id);

      const formattedStartDate = startDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

      // Set the date to start of week since this is the date used for comparison when checking for local data
      // In the API the data is systematically grouped by beginning of week, so it does not matter
      const formattedEndDate = endDate
        .startOf('week')
        .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

      const [startDateLastYear, endDateLastYear] = getLastYearDates(startDate, endDate);
      const formattedStartDateLastYear = startDateLastYear.format(
        DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
      );
      const formattedEndDateLastYear = endDateLastYear.format(
        DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
      );

      try {
        setIsDashboardAnalyticsLoading(true);
        setIsPreviousYearDataLoading(true);

        const [localData, localDataLastYear] = await Promise.all([
          fetchLocalData(selectedStoreIds, formattedStartDate, formattedEndDate),
          fetchLocalData(selectedStoreIds, formattedStartDateLastYear, formattedEndDateLastYear),
        ]);

        if (localData && localDataLastYear) {
          aggregateMetrics(localData);
          aggregateMetricsLastYear(localDataLastYear);
          aggregateTurnoverAndRealConsumptionEvolution(localData);
          aggregateRealConsumptionAndLossRatio(localData);
          return;
        }

        // Deliberately wait for response before fetching previous year data to render metrics and graph immediately
        await fetchDashboardAnalytics(selectedStoreIds, formattedStartDate, formattedEndDate);

        await fetchLastYearAnalytics(
          selectedStoreIds,
          formattedStartDateLastYear,
          formattedEndDateLastYear,
        );
      } catch {
        showErrorMessage(i18next.t('DASHBOARD.FOODCOST.GETTING_DASHBOARD_DATA_ERROR'));
      } finally {
        setIsDashboardAnalyticsLoading(false);
        setIsPreviousYearDataLoading(false);
      }
    })();
  }, [selectedStores, startDate, endDate]);

  useEffect(() => {
    const selectedBrandIds = selectedBrands.map(({ id }) => id);
    const isNoneValueSelected = selectedBrandIds.includes(ITEM_DROPDOWN_NONE_VALUE().id);

    // Either the Store has a matching brand or the Store has no brand and the None value is selected
    const storesBySelectedBrands = stores.filter(
      ({ brandId }) =>
        selectedBrandIds.includes(brandId) || (isNoneValueSelected && brandId === null),
    );

    setSelectedStores(storesBySelectedBrands);
    setStoresBySelectedBrands(storesBySelectedBrands);
  }, [selectedBrands]);

  /* METHODS */
  const fetchSelectorsData = () => {
    try {
      const activeStores = storesOfClient.filter(({ active, isKitchen }) => active && !isKitchen);

      // By default, all stores and brands are selected
      setStores(activeStores);

      const distinctAssociatedBrands = getDistinctBrands(activeStores);

      setBrands(distinctAssociatedBrands);
      setSelectedBrands(distinctAssociatedBrands);
    } catch {
      showErrorMessage(
        i18next.t('BACKOFFICE.STORES.FETCHING_ERROR', { storeName: translatedStoreName }),
      );
    }
  };

  const fetchLastYearAnalytics = async (storeIds, startDate, endDate) => {
    const data = await dashboardService.getDashboardAnalytics(
      storeIds,
      startDate,
      endDate,
      userTimezone,
    );

    aggregateMetricsLastYear(data);
    setIsPreviousYearDataLoading(false);
    storeLocalMetrics(data);
  };

  const fetchDashboardAnalytics = async (storeIds, startDate, endDate) => {
    const data = await dashboardService.getDashboardAnalytics(
      storeIds,
      startDate,
      endDate,
      userTimezone,
    );

    aggregateMetrics(data);
    aggregateTurnoverAndRealConsumptionEvolution(data);
    aggregateRealConsumptionAndLossRatio(data);

    setIsDashboardAnalyticsLoading(false);
    storeLocalMetrics(data);
  };

  /**
   * Aggregate metrics for all stores and set state
   *
   * @param {object[]} data
   */
  const aggregateMetrics = (data) => {
    // Aggregate currencies
    const aggregate = aggregateCurrencyData(data);

    // Aggregate percentages
    const aggregateRealConsumptionPercentage =
      ratio(
        aggregate[METRICS_CURRENCY_KEYS.realConsumption],
        aggregate[METRICS_CURRENCY_KEYS.turnoverWithoutTaxes],
      ) * 100;

    const aggregateTheoreticalConsumptionPercentage =
      ratio(
        aggregate[METRICS_CURRENCY_KEYS.theoreticalConsumption],
        aggregate[METRICS_CURRENCY_KEYS.turnoverWithoutTaxes],
      ) * 100;

    const allStoresTurnoverExcludingTaxes = {
      value: aggregate[METRICS_CURRENCY_KEYS.turnoverWithoutTaxes],
      type: STATS_DATA_TYPE.CURRENCY,
      positiveVariation: POSITIVE_VARIATION.INCREASE,
      variationType: STATS_DATA_TYPE.PERCENT,
    };

    const allStoresMarginExcludingTaxes = {
      value: aggregate[METRICS_CURRENCY_KEYS.marginWithoutTaxes],
      type: STATS_DATA_TYPE.CURRENCY,
      positiveVariation: POSITIVE_VARIATION.INCREASE,
      variationType: STATS_DATA_TYPE.PERCENT,
    };

    const allStoresRealConsumptionPercentage = {
      value: aggregateRealConsumptionPercentage,
      type: STATS_DATA_TYPE.PERCENT,
      positiveVariation: POSITIVE_VARIATION.DECREASE,
      variationType: STATS_DATA_TYPE.PERCENT,
    };

    const allStoresRealConsumption = {
      value: aggregate[METRICS_CURRENCY_KEYS.realConsumption],
      type: STATS_DATA_TYPE.CURRENCY,
      positiveVariation: POSITIVE_VARIATION.DECREASE,
      variationType: STATS_DATA_TYPE.PERCENT,
    };

    const allStoresTheoreticalConsumptionPercentage = {
      value: aggregateTheoreticalConsumptionPercentage,
      type: STATS_DATA_TYPE.PERCENT,
      positiveVariation: POSITIVE_VARIATION.DECREASE,
      variationType: STATS_DATA_TYPE.PERCENT,
    };

    const allStoresTheoreticalConsumption = {
      value: aggregate[METRICS_CURRENCY_KEYS.theoreticalConsumption],
      type: STATS_DATA_TYPE.CURRENCY,
      positiveVariation: POSITIVE_VARIATION.DECREASE,
      variationType: STATS_DATA_TYPE.PERCENT,
    };

    setAllStoresTurnoverExcludingTaxes([allStoresTurnoverExcludingTaxes]);

    setAllStoresMarginExcludingTaxes([allStoresMarginExcludingTaxes]);

    setAllStoresRealConsumption([allStoresRealConsumptionPercentage, allStoresRealConsumption]);

    setAllStoreTheoreticalConsumption([
      allStoresTheoreticalConsumptionPercentage,
      allStoresTheoreticalConsumption,
    ]);
  };

  const aggregateMetricsLastYear = (data) => {
    // Aggregate data
    const aggregateLastYear = aggregateCurrencyData(data);

    const aggregateRealConsumptionPercentageLastYear =
      ratio(
        aggregateLastYear[METRICS_CURRENCY_KEYS.realConsumption],
        aggregateLastYear[METRICS_CURRENCY_KEYS.turnoverWithoutTaxes],
      ) * 100;

    const aggregateTheoreticalConsumptionPercentageLastYear =
      ratio(
        aggregateLastYear[METRICS_CURRENCY_KEYS.theoreticalConsumption],
        aggregateLastYear[METRICS_CURRENCY_KEYS.turnoverWithoutTaxes],
      ) * 100;

    // Set old values
    setAllStoresTurnoverExcludingTaxes(([metric]) => [
      {
        ...metric,
        oldValue: aggregateLastYear[METRICS_CURRENCY_KEYS.turnoverWithoutTaxes],
      },
    ]);

    setAllStoresMarginExcludingTaxes(([metric]) => [
      {
        ...metric,
        oldValue: aggregateLastYear[METRICS_CURRENCY_KEYS.marginWithoutTaxes],
      },
    ]);

    setAllStoresRealConsumption(([metricPercentage, metricCurrency]) => [
      {
        ...metricPercentage,
        oldValue: aggregateRealConsumptionPercentageLastYear,
      },
      {
        ...metricCurrency,
        oldValue: aggregateLastYear[METRICS_CURRENCY_KEYS.realConsumption],
      },
    ]);

    setAllStoreTheoreticalConsumption(([metricPercentage, metricCurrency]) => [
      {
        ...metricPercentage,
        oldValue: aggregateTheoreticalConsumptionPercentageLastYear,
      },
      {
        ...metricCurrency,
        oldValue: aggregateLastYear[METRICS_CURRENCY_KEYS.theoreticalConsumption],
      },
    ]);
  };

  const storeLocalMetrics = async (data) => {
    const formattedData = data.map(
      ({
        storeId,
        weekDate,
        turnoverWithoutTaxes,
        realConsumption,
        theoreticalConsumption,
        marginWithoutTaxes,
        loss,
      }) => ({
        storeId,
        weekDate,
        createdAt: moment().format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        data: {
          turnoverWithoutTaxes,
          realConsumption,
          theoreticalConsumption,
          marginWithoutTaxes,
          loss,
        },
      }),
    );

    try {
      await dashboardRepository.createMany(formattedData);
    } catch {
      // Silently ignore error
    }
  };

  /**
   * Aggregate turnover without taxes and real consumption percentage for all given Stores
   * @param data
   */
  const aggregateTurnoverAndRealConsumptionEvolution = (data) => {
    const aggregatedData = [];

    const dataGroupedByDate = groupBy(data, 'weekDate');

    // For safety measures, we filter values with dates that are between startDate and endDate
    const formattedDataGroupedByDate = pickBy(
      dataGroupedByDate,
      (value, date) =>
        moment(date).isSameOrAfter(startDate) && moment(date).isSameOrBefore(endDate),
    );

    for (const [date, allData] of Object.entries(formattedDataGroupedByDate)) {
      const totalTurnoverWithoutTaxes = sumBy(allData, 'turnoverWithoutTaxes');
      const totalRealConsumption = sumBy(allData, 'realConsumption');
      const totalMarginWithoutTaxes = sumBy(allData, 'marginWithoutTaxes');

      const totalRealConsumptionPercentage =
        ratio(totalRealConsumption, totalTurnoverWithoutTaxes) * 100;

      const totalMarginWithoutTaxesPercentage =
        ratio(totalMarginWithoutTaxes, totalTurnoverWithoutTaxes) * 100;

      aggregatedData.push({
        date,
        turnoverWithoutTaxes: totalTurnoverWithoutTaxes,
        realConsumption: totalRealConsumption,
        realConsumptionPercentage: totalRealConsumptionPercentage,
        marginWithoutTaxes: totalMarginWithoutTaxes,
        marginWithoutTaxesPercentage: totalMarginWithoutTaxesPercentage,
      });
    }

    setGraphData(aggregatedData);
  };

  /**
   * Aggregate real consumption ratio and loss ratio by storeId
   * @param data
   */
  const aggregateRealConsumptionAndLossRatio = (data) => {
    const aggregatedData = [];

    const dataGroupedByStoreId = groupBy(data, 'storeId');

    for (const [storeId, allData] of Object.entries(dataGroupedByStoreId)) {
      const totalTurnoverWithoutTaxes = sumBy(allData, 'turnoverWithoutTaxes');
      const totalRealConsumption = sumBy(allData, 'realConsumption');
      const totalMargin = sumBy(allData, 'marginWithoutTaxes');
      const totalLoss = sumBy(allData, 'loss');

      const totalRealConsumptionRatio =
        ratio(totalRealConsumption, totalTurnoverWithoutTaxes) * 100;

      const totalMarginRatio = ratio(totalMargin, totalTurnoverWithoutTaxes) * 100;

      const totalLossRatio = ratio(totalLoss, totalTurnoverWithoutTaxes) * 100;

      aggregatedData.push({
        storeId,
        turnoverExcludingTaxes: totalTurnoverWithoutTaxes,
        realConsumption: totalRealConsumption,
        realConsumptionRatio: totalRealConsumptionRatio,
        marginExcludingTaxes: totalMargin,
        marginExcludingTaxesRatio: totalMarginRatio,
        lossExcludingTaxes: totalLoss,
        lossRatio: totalLossRatio,
      });
    }

    setStoreRanking(aggregatedData);
  };

  /**
   * Returns brands associated to an active store
   * @param stores
   * @returns {({name: string, id: number, value: string}|any)[]}
   */
  const getDistinctBrands = (stores) => {
    const distinctAssociatedBrands = chain(stores)
      .keyBy('brandId')
      .filter(({ brandId }) => brandId !== null)
      .map('lnkBrandStorerel')
      .value();

    return [ITEM_DROPDOWN_NONE_VALUE(), ...distinctAssociatedBrands];
  };

  const fetchLocalData = async (storeIds, startDate, endDate) => {
    const localData = await dashboardRepository.findByStoreIdsStartDateEndDate(
      storeIds,
      startDate,
      endDate,
    );

    const formattedLocalData = localData.map(({ storeId, createdAt, weekDate, data }) => ({
      storeId,
      createdAt,
      weekDate,
      ...data,
    }));

    const isLocalDataValid = checkLocalData(formattedLocalData, storeIds, startDate, endDate);

    if (isLocalDataValid) {
      return formattedLocalData;
    }

    // Delete existing new data since it is no longer valid
    const keys = localData.map(({ id }) => id);
    await dashboardRepository.deleteMany(keys);
  };

  const checkLocalData = (localData, storeIds, startDate, endDate) => {
    // If some stores are not in local db, we fetch
    const localStoreIds = [...new Set(localData.map(({ storeId }) => storeId))];
    const areSelectedStoreAllInLocal = checkStores(storeIds, localStoreIds);

    if (!areSelectedStoreAllInLocal) {
      return false;
    }

    // If the start, end dates or some weeks in between are not in local db, we fetch
    const localWeekDates = [...new Set(localData.map(({ weekDate }) => weekDate))];
    const areAllDatesInLocal = checkStartAndEndDate(startDate, endDate, localWeekDates);

    if (!areAllDatesInLocal) {
      return false;
    }

    // If one of the entry is too old, we fetch
    const isLocalDataExpired = checkCreatedAt(localData, createdAtThreshold);

    return !isLocalDataExpired;
  };

  /**
   * Check if stores and locally stored stores are different
   *
   * @param {string[]} storeIds
   * @param {string[]} localStoreIds
   *
   * @returns {boolean}
   */
  const checkStores = (storeIds, localStoreIds) => difference(storeIds, localStoreIds).length === 0;

  /**
   * Checks if all dates (start, end and weeks in between) are in local db
   *
   * @param {string} startDate
   * @param {string} endDate
   * @param {string[]} localDates
   *
   * @returns {boolean}
   */
  const checkStartAndEndDate = (startDate, endDate, localDates) => {
    const areAllSelectedWeeksInLocal =
      localDates.includes(startDate) && localDates.includes(endDate);

    const weeksBetweenStartAndEndDate = localDates.filter((date) =>
      moment(date).isBetween(startDate, endDate, 'weeks', '[]'),
    );

    const allWeeksBetweenStartAndEndDateExist =
      weeksBetweenStartAndEndDate.length === moment(endDate).diff(startDate, 'weeks') + 1;

    return areAllSelectedWeeksInLocal && allWeeksBetweenStartAndEndDateExist;
  };

  /**
   * Checks if one the entries has expired (older than threshold)
   *
   * @param {object[]} localData - createdAt is a string
   * @param {object} threshold - moment object
   *
   * @returns {Boolean}
   */
  const checkCreatedAt = (localData, threshold) =>
    localData.some(({ createdAt }) => isSameOrBeforeDay(createdAt, threshold));

  /**
   * Get the last year dates at the beginning of the week
   *
   * @param {object} startDate - moment date
   * @param {object} endDate - moment date
   *
   * @returns {[object,object]}
   */
  const getLastYearDates = (startDate, endDate) => {
    const startDateLastYear = startDate.clone().subtract(1, 'year').startOf('week');

    const endDateLastYear = endDate.clone().subtract(1, 'year').startOf('week');

    return [startDateLastYear, endDateLastYear];
  };

  const aggregateCurrencyData = (data) =>
    data.reduce(
      (
        acc,
        { turnoverWithoutTaxes, realConsumption, theoreticalConsumption, marginWithoutTaxes, loss },
      ) => {
        if (turnoverWithoutTaxes !== null) {
          acc.turnoverWithoutTaxes += turnoverWithoutTaxes;
        }

        if (realConsumption !== null) {
          acc.realConsumption += realConsumption;
        }

        if (theoreticalConsumption !== null) {
          acc.theoreticalConsumption += theoreticalConsumption;
        }

        if (marginWithoutTaxes !== null) {
          acc.marginWithoutTaxes += marginWithoutTaxes;
        }

        if (loss !== null) {
          acc.loss += loss;
        }

        return acc;
      },
      {
        turnoverWithoutTaxes: null,
        realConsumption: null,
        theoreticalConsumption: null,
        marginWithoutTaxes: null,
        loss: null,
      },
    );

  return (
    <PageContainer>
      <Breadcrumb paths={[i18next.t('FEATURE.DASHBOARD.DASHBOARD')]} />
      <Container>
        <SelectorContainer>
          <PeriodWeekPicker
            endDate={endDate}
            initialDeltaWeek={
              stores.length >= STORE_THRESHOLD ? STORE_THRESHOLD_INITIAL_DELTA_WEEK : null
            }
            maxDate={moment().subtract(1, 'weeks')}
            maxDeltaWeek={DEFAULT_MAX_DELTA_WEEK}
            minDate={moment(creationDate)}
            startDate={startDate}
            onDateChange={(newStartDate, newEndDate) => {
              setStartDate(newStartDate);
              setEndDate(newEndDate);
            }}
          />
          {hasMultipleBrands && (
            <Dropdown
              iconSrc={'/images/inpulse/bookmark-black.svg'}
              isUniqueSelection={false}
              items={brands}
              searchPlaceholder={i18next.t('GENERAL.SEARCH')}
              selectedItems={selectedBrands}
              isRequired
              onSelectionChange={setSelectedBrands}
            />
          )}
          <Dropdown
            iconSrc={'/images/store-black.svg'}
            isUniqueSelection={false}
            items={storesBySelectedBrands}
            searchPlaceholder={i18next.t('GENERAL.SEARCH')}
            selectedItems={selectedStores}
            isRequired
            onSelectionChange={setSelectedStores}
          />
        </SelectorContainer>
        <ContentContainer>
          <RowContainer>
            <MetricsContainer>
              <Metrics
                className="ca-ht"
                data={allStoresTurnoverExcludingTaxes}
                isLoading={isDashboardAnalyticsLoading}
                isLoadingPreviousData={isPreviousYearDataLoading}
                title={i18next.t('GENERAL.METRIC_TURNOVER_EX_TAX', {
                  currencyCode,
                })}
                tooltipText={i18next.t('DASHBOARD.FOODCOST.CA_HT_TOOLTIP', {
                  currencyCode,
                })}
              />
              <Metrics
                className="margin-ht"
                data={allStoresMarginExcludingTaxes}
                isLoading={isDashboardAnalyticsLoading}
                isLoadingPreviousData={isPreviousYearDataLoading}
                title={i18next.t('GENERAL.METRIC_MARGIN_EX_TAX', {
                  currencyCode,
                })}
                tooltipText={i18next.t('DASHBOARD.FOODCOST.MARGIN_HT_TOOLTIP', {
                  currencyCode,
                })}
              />
              <Metrics
                className="real-food-cost"
                data={allStoresRealConsumption}
                isLoading={isDashboardAnalyticsLoading}
                isLoadingPreviousData={isPreviousYearDataLoading}
                title={i18next.t('DASHBOARD.FOODCOST.REAL_FOOD_COST_HT_TITLE', {
                  currencyCode,
                })}
                tooltipText={i18next.t('DASHBOARD.FOODCOST.REAL_FOOD_COST_HT_TOOLTIP', {
                  currencyCode,
                })}
              />
              <Metrics
                className="theorical-food-cost"
                data={allStoreTheoreticalConsumption}
                isLoading={isDashboardAnalyticsLoading}
                isLoadingPreviousData={isPreviousYearDataLoading}
                title={i18next.t('DASHBOARD.FOODCOST.THEORETICAL_FOOD_COST_HT_TITLE', {
                  currencyCode,
                })}
                tooltipText={i18next.t('DASHBOARD.FOODCOST.THEORETICAL_FOOD_COST_HT_TOOLTIP', {
                  currencyCode,
                })}
              />
            </MetricsContainer>
            <GraphContainer>
              {isDashboardAnalyticsLoading && (
                <LoadingContainer>
                  <ComponentLoader height={16} width={16} />
                </LoadingContainer>
              )}
              {!isDashboardAnalyticsLoading && (
                <TurnoverRealConsumptionGraph
                  currency={currency}
                  data={graphData}
                  screenWidth={screenWidth}
                />
              )}
            </GraphContainer>
          </RowContainer>
          <RowContainer>
            <TopFlopProductsTable
              endDate={endDate}
              startDate={startDate}
              stores={selectedStores}
              useTtc={useTtc}
            />
            <RealConsumptionLossProgressBar
              isLoading={isDashboardAnalyticsLoading}
              storeRanking={storeRanking}
              storesKeyById={storesKeyById}
            />
          </RowContainer>
        </ContentContainer>
      </Container>
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  client: getClientInfo(state.baseReducer.user),
  currency: state.baseReducer.currency,
  stores: state.baseReducer.stores,
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FoodCostDashboard);

import { sortBy } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { ITEM_DROPDOWN_NONE_VALUE } from '@admin/utils/DropdownItems';
import { STORE_INPUTS } from '@admin/stores/components/StoreInformations/utils/formInputsConfiguration';

import { INPUT_TYPES } from '@commons/constants/inputType';
import RemainingControls from '@commons/RemainingControls';

const getStoreInvoiceControlSettings = ({ storeCompany, invoiceControlPlans, storeId }) => {
  const inputs = [
    {
      dropdownValue: [
        { ...ITEM_DROPDOWN_NONE_VALUE(), value: i18next.t('GENERAL.SELECT_NONE_MASCULINE') },
        ...invoiceControlPlans,
      ],
      hasTooltip: true,
      icon: 'none',
      emptyIcon: 'none',
      isDropdown: true,
      dropdownMatchingProperty: 'id',
      sortBy: (itemsList) => sortBy(itemsList, 'monthlyQuantity'),
      isRequired: false,
      placeholder: i18next.t('GENERAL.CHOOSE_PLACEHOLDER'),
      label: i18next.t('BACKOFFICE.STORES.PLAN'),
      propertyKey: STORE_INPUTS.PLAN,
      type: 'dropdown',
    },
    {
      type: INPUT_TYPES.PLAIN_TEXT,
      propertyKey: STORE_INPUTS.PLAN,
      customRender: () => <RemainingControls storeId={storeId} timezone={storeCompany.timezone} />,
    },
  ];

  return {
    title: i18next.t('BACKOFFICE.STORES.INVOICE_CONTROLS'),
    inputs: inputs.reduce((acc, input) => {
      acc.push({ ...input, value: storeCompany[input.propertyKey] });
      return acc;
    }, []),
  };
};

export default getStoreInvoiceControlSettings;

import moment from 'moment-timezone';

import { enUS, frFR, viVN, zhCN, gridStringOrNumberComparator } from '@mui/x-data-grid-pro';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';

export const DATAGRID_LOCALES = {
  cn: zhCN,
  en: enUS,
  fr: frFR,
  vi: viVN,
};

// Return an array of dates as formatted strings between startDate and endDate (inclusive)
export const generateDates = (
  startDate,
  endDate,
  dateFormat = DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
) => {
  const dates = [];

  for (
    let currentDate = moment(startDate).clone();
    currentDate.isSameOrBefore(moment(endDate), 'day');
    currentDate.add(1, 'day')
  ) {
    dates.push(currentDate.format(dateFormat));
  }

  return dates;
};

/**
 * Custom sorter function for sorting values in a grid column.
 *
 * @param {any} valueA - The first value to compare.
 * @param {any} valueB - The second value to compare.
 * @param {object} cellParams1 - Additional parameters for the first cell.
 * @param {object} cellParams2 - Additional parameters for the second cell.
 * @returns {number} - The comparison result (-1, 0, or 1).
 */
export const customSorter = (valueA, valueB, cellParams1, cellParams2) => {
  if (!valueA || !valueB) {
    if (valueA === valueB) {
      return 0;
    } else {
      if (!valueA) {
        return 1;
      }

      return -1;
    }
  } else {
    return gridStringOrNumberComparator(
      valueA.toLowerCase(),
      valueB.toLowerCase(),
      cellParams1,
      cellParams2,
    );
  }
};

import { cloneDeep, keyBy } from 'lodash';
import i18next from 'i18next';

import { computeTypesOfPackaging, convertPriceToPackagingById } from '@commons/utils/packagings';

import { PRODUCT_ORDER_TYPE } from '@orders/OrderList/constants';

import ORDER_STATUS from '../constants/status';

const _computeAnomalies = (
  orderStatus,
  supplierProductByCategories,
  receivedProductOrdersKeyBySPId,
) => {
  if (orderStatus >= ORDER_STATUS.SENT) {
    return supplierProductByCategories;
  }

  return supplierProductByCategories.map((category) => {
    category.products.forEach((supplierProduct) => {
      // Add to anomalie instead of anomalie cause JS doesn't want to assign receptionAnomaly with = or Object.assign()
      if (receivedProductOrdersKeyBySPId[supplierProduct.id]) {
        supplierProduct.anomalie =
          receivedProductOrdersKeyBySPId[supplierProduct.id].receptionAnomaly;
      }
    });
    return category;
  });
};

const _sortByCategories = (supplierProductByCategories, params) => {
  const categories = [...supplierProductByCategories];
  const categoriesOrders = params.categoriesOrders || [];

  categories.sort((a, b) => {
    a = a.name;
    b = b.name;

    let aValue =
      categoriesOrders.indexOf(a) !== -1
        ? categoriesOrders.length - categoriesOrders.indexOf(a)
        : -1;

    let bValue =
      categoriesOrders.indexOf(b) !== -1
        ? categoriesOrders.length - categoriesOrders.indexOf(b)
        : -1;

    if (a === 'AUTRES') {
      aValue = -2;
    } else if (b === 'AUTRES') {
      bValue = -2;
    }

    if (aValue === -1 && bValue === -1) {
      return a.localeCompare(b);
    }

    return bValue - aValue;
  });

  return categories.reduce(
    (result, category) => {
      category.products = category.products.sort((a, b) => a.name.localeCompare(b.name));

      if (category.name === '') {
        category.name = i18next.t('PRODUCTION.PRODUCTION.OTHER_CATEGORY');
      }

      category.total = category.products.reduce(
        (acc, { price, ordered }) => acc + price * ordered,
        0,
      );

      result.orderTotal += category.total;
      result.sortedSupplierProductsByCategories.push(category);

      return result;
    },
    {
      orderTotal: 0,
      sortedSupplierProductsByCategories: [],
    },
  );
};

const _computeProductOrders = (orderStatus, isDuplicatingOrder, productOrders) => {
  if (orderStatus === ORDER_STATUS.CREATION && !isDuplicatingOrder) {
    return {
      filteredProductOrders: [],
      receivedProductOrdersKeyBySPId: {},
      invoicedProductOrdersKeyBySPId: {},
      orderedProductOrdersKeyBySupplierId: {},
    };
  }

  const orderedProductOrdersKeyBySupplierId = keyBy(
    productOrders.filter((productOrder) => productOrder.type === PRODUCT_ORDER_TYPE.ORDERED),
    'lnkSupplierproductProductorderrel.id',
  );

  const receivedProductOrdersKeyBySPId = keyBy(
    productOrders.filter(({ type }) => type === PRODUCT_ORDER_TYPE.RECEIVED),
    'supplierProductId',
  );

  const invoicedProductOrdersKeyBySPId = keyBy(
    productOrders.filter(({ type }) => type === PRODUCT_ORDER_TYPE.INVOICED),
    'supplierProductId',
  );

  const filteredProductOrders = productOrders.reduce((result, productOrder) => {
    // If duplicating order, the form will behave like a creation,
    // but will have product orders from duplicated order
    if (
      isDuplicatingOrder &&
      [PRODUCT_ORDER_TYPE.DRAFT, PRODUCT_ORDER_TYPE.ORDERED].includes(productOrder.type)
    ) {
      result.push(productOrder);
      return result;
    }

    if (orderStatus === ORDER_STATUS.DRAFT && productOrder.type === PRODUCT_ORDER_TYPE.DRAFT) {
      result.push(productOrder);
    }

    if (
      orderStatus > ORDER_STATUS.DRAFT &&
      (productOrder.type === PRODUCT_ORDER_TYPE.ORDERED ||
        (productOrder.type === PRODUCT_ORDER_TYPE.RECEIVED &&
          !orderedProductOrdersKeyBySupplierId[productOrder.supplierProductId]))
    ) {
      result.push(productOrder);
    }

    return result;
  }, []);

  return {
    filteredProductOrders,
    receivedProductOrdersKeyBySPId,
    invoicedProductOrdersKeyBySPId,
    orderedProductOrdersKeyBySupplierId,
  };
};

const _computeSupplierProductWithProductOrderData = ({
  orderStatus,
  isEditable,
  supplierProduct,
  filteredProductOrdersBySPId,
  receivedProductOrdersKeyBySPId,
  invoicedProductOrdersKeyBySPId,
  orderedProductOrdersKeyBySupplierId,
  preparationStatus,
  isDuplicatingOrder,
}) => {
  const updatedSupplierProduct = cloneDeep(supplierProduct);

  // Make sure to know when a reference has been added after order
  updatedSupplierProduct.addedAfterOrdered =
    orderStatus !== ORDER_STATUS.CREATION &&
    orderStatus !== ORDER_STATUS.DRAFT &&
    !orderedProductOrdersKeyBySupplierId[updatedSupplierProduct.id];

  const { packagingUsedInOrder, packagingUsedInInvoice } = computeTypesOfPackaging(
    false,
    updatedSupplierProduct.packagings,
  );

  // Convert SP.price (based on packaging.isUsedInOrder) to order packaging
  updatedSupplierProduct.price = convertPriceToPackagingById(
    updatedSupplierProduct.price,
    packagingUsedInInvoice.id,
    packagingUsedInOrder.id,
    updatedSupplierProduct.packagings,
  );

  const matchingProductOrder = filteredProductOrdersBySPId[updatedSupplierProduct.id];

  /**********************/
  /* DUPLICATING ORDER  */
  /**********************/

  if (isDuplicatingOrder && !matchingProductOrder) {
    updatedSupplierProduct.ordered = 0;
    updatedSupplierProduct.saveOrdered = 0;

    return updatedSupplierProduct;
  }

  /*************************************/
  /* CREATING ORDER - ONLY USE SP DATA */
  /*************************************/

  if (!isDuplicatingOrder && (orderStatus === ORDER_STATUS.CREATION || !matchingProductOrder)) {
    updatedSupplierProduct.ordered = 0;
    updatedSupplierProduct.saveOrdered = 0;

    return updatedSupplierProduct;
  }

  /***************************************************/
  /* FILL IN ORDER INPUTS (quantity BDC & price BDC) */
  /***************************************************/

  if (updatedSupplierProduct.addedAfterOrdered) {
    // Makes no sense trying to set ordered quantity since it's an added reference
    updatedSupplierProduct.ordered = 0;
    updatedSupplierProduct.saveOrdered = 0;
  } else {
    updatedSupplierProduct.ordered = matchingProductOrder.quantity;
    updatedSupplierProduct.saveOrdered = matchingProductOrder.quantity;
  }

  // The price when order was made (and not the current price of the SP)
  if (!isEditable) {
    updatedSupplierProduct.price = matchingProductOrder.price;
  }

  if (orderStatus === ORDER_STATUS.DRAFT) {
    return updatedSupplierProduct;
  }

  /*****************************************/
  /* OPENING SENT ORDER TO START RECEPTION */
  /*****************************************/

  // Set default values to fill reception form inputs with ordered data
  if (orderStatus === ORDER_STATUS.SENT && !updatedSupplierProduct.addedAfterOrdered) {
    // Add default reception packaging id from order PO
    updatedSupplierProduct.receptionPackagingId =
      matchingProductOrder.lnkSupplierproductProductorderrel.packagingId;

    updatedSupplierProduct.received = updatedSupplierProduct.ordered;
    updatedSupplierProduct.invoiced = updatedSupplierProduct.ordered;
    updatedSupplierProduct.priceBDL = updatedSupplierProduct.price;

    if (preparationStatus != null && invoicedProductOrdersKeyBySPId[updatedSupplierProduct.id]) {
      const invoicedQuantity = invoicedProductOrdersKeyBySPId[updatedSupplierProduct.id].quantity;

      updatedSupplierProduct.invoiced = invoicedQuantity;
      updatedSupplierProduct.received = invoicedQuantity;
      updatedSupplierProduct.receptionPackagingId =
        invoicedProductOrdersKeyBySPId[updatedSupplierProduct.id].supplierProductPackagingId;
    }

    if (preparationStatus != null && receivedProductOrdersKeyBySPId[updatedSupplierProduct.id]) {
      updatedSupplierProduct.received =
        receivedProductOrdersKeyBySPId[updatedSupplierProduct.id].quantity;
      updatedSupplierProduct.priceBDL =
        receivedProductOrdersKeyBySPId[updatedSupplierProduct.id].price;
    }

    return updatedSupplierProduct;
  }

  /*********************************/
  /* RECEPTION MADE OR IN PROGRESS */
  /*********************************/

  // Keep track of SP that have been "treated" in reception process
  updatedSupplierProduct.checked = matchingProductOrder.checked;

  // Set default values for received and invoiced sections
  updatedSupplierProduct.received = 0;
  updatedSupplierProduct.invoiced = null; // set to null to easily check when to use updatedSupplierProduct.ordered for older order

  // Fill in invoiced info with ProductOrder.type === 'invoiced'
  if (invoicedProductOrdersKeyBySPId[updatedSupplierProduct.id]) {
    const { price, priceBDL, quantity } = invoicedProductOrdersKeyBySPId[updatedSupplierProduct.id];

    updatedSupplierProduct.invoiced = quantity;
    updatedSupplierProduct.priceBDL = priceBDL != null ? priceBDL : price;
  }

  // Fill in received info with ProductOrder.type === 'received'
  if (receivedProductOrdersKeyBySPId[updatedSupplierProduct.id]) {
    const { quantity, receptionAnomaly, supplierProductPackagingId, creditOrderPictureUrl } =
      receivedProductOrdersKeyBySPId[updatedSupplierProduct.id];

    updatedSupplierProduct.received = quantity;
    updatedSupplierProduct.receptionAnomaly = receptionAnomaly;
    updatedSupplierProduct.creditOrderPictureUrl = creditOrderPictureUrl;

    if (updatedSupplierProduct.addedAfterOrdered) {
      if (!orderedProductOrdersKeyBySupplierId[updatedSupplierProduct.id]) {
        // Setting what packaging was used when this reference was added at reception
        updatedSupplierProduct.receptionPackagingId =
          receivedProductOrdersKeyBySPId[updatedSupplierProduct.id].supplierProductPackagingId;

        // In added reference case, updatedSupplierProduct.price is expressed in reception packaging
        // Whereas it should in order packaging
        updatedSupplierProduct.price = convertPriceToPackagingById(
          updatedSupplierProduct.price,
          updatedSupplierProduct.receptionPackagingId,
          packagingUsedInOrder.id,
          updatedSupplierProduct.packagings,
        );

        return updatedSupplierProduct;
      }
    }

    // Keep track of the SPP.id used in reception as it could be different
    updatedSupplierProduct.receptionPackagingId = supplierProductPackagingId;
  }

  return updatedSupplierProduct;
};

const _buildSupplierProductsByCategories = ({
  isEditable,
  orderStatus,
  supplierProducts,
  filteredProductOrdersBySPId,
  orderedProductOrdersKeyBySupplierId,
  receivedProductOrdersKeyBySPId,
  invoicedProductOrdersKeyBySPId,
  preparationStatus,
  isDuplicatingOrder,
}) => {
  const supplierProductsKeyByCategories = {};

  for (const supplierProduct of supplierProducts) {
    const computedSupplierProduct = _computeSupplierProductWithProductOrderData({
      orderStatus,
      isEditable,
      supplierProduct,
      filteredProductOrdersBySPId,
      orderedProductOrdersKeyBySupplierId,
      receivedProductOrdersKeyBySPId,
      invoicedProductOrdersKeyBySPId,
      preparationStatus,
      isDuplicatingOrder,
    });

    // Do not keep SP if not is draft, not ordered or not a added reference
    if (
      orderStatus > ORDER_STATUS.DRAFT &&
      !filteredProductOrdersBySPId[computedSupplierProduct.id]
    ) {
      continue;
    }

    const formattedSubCategoryName = (computedSupplierProduct.subCategory || '').toLowerCase();

    if (!supplierProductsKeyByCategories[formattedSubCategoryName]) {
      supplierProductsKeyByCategories[formattedSubCategoryName] = {
        name: computedSupplierProduct.subCategory || i18next.t('GENERAL.OTHER'),
        products: [computedSupplierProduct],
      };

      continue;
    }

    supplierProductsKeyByCategories[formattedSubCategoryName].products.push(
      computedSupplierProduct,
    );
  }

  return Object.values(supplierProductsKeyByCategories);
};

const generateDataContainer = (
  params,
  supplierProducts,
  productOrders,
  orderStatus,
  isEditable,
  preparationStatus,
  isDuplicatingOrder,
) => {
  const {
    filteredProductOrders,
    receivedProductOrdersKeyBySPId,
    invoicedProductOrdersKeyBySPId,
    orderedProductOrdersKeyBySupplierId,
  } = _computeProductOrders(orderStatus, isDuplicatingOrder, productOrders);

  const filteredProductOrdersBySPId = keyBy(filteredProductOrders, 'supplierProductId');

  const supplierProductsByCategories = _buildSupplierProductsByCategories({
    isEditable,
    orderStatus,
    supplierProducts,
    filteredProductOrdersBySPId,
    orderedProductOrdersKeyBySupplierId,
    receivedProductOrdersKeyBySPId,
    invoicedProductOrdersKeyBySPId,
    preparationStatus,
    isDuplicatingOrder,
  });

  const { orderTotal, sortedSupplierProductsByCategories } = _sortByCategories(
    supplierProductsByCategories,
    params,
  );

  const categoriesWithAnomalies = _computeAnomalies(
    orderStatus,
    sortedSupplierProductsByCategories,
    receivedProductOrdersKeyBySPId,
  );

  return {
    initialOrderTotal: orderTotal,
    formattedCategories: categoriesWithAnomalies,
    isOrderConfirmable: false, // challenge the need of this value
  };
};

export default generateDataContainer;

import { connect } from 'react-redux';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { Dropdown } from '@commons/utils/styledLibraryComponents';
import { ENUM_COLORS } from '@commons/Text';
import { GenericGapContainer, GenericPageContainer } from '@commons/Layout/styledComponents';
import { getUserTimezone } from '@commons/utils/date';
import { LeftRightSplitter } from '@commons/LeftRightSplitter';
import { PeriodDatePicker } from '@commons/DatePickers/PeriodDatePicker';
import { showErrorMessage } from '@actions/messageconfirmation';
import { usePeriodDatePickerState } from '@hooks/usePeriodDatePickerState';
import cashierService from '@services/cashier';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import {
  ContentContainer,
  MetricsContainer,
  LastRowContainer,
  PageContainer,
} from './styledComponents';

import { STATS_DATA_TYPE } from './components/Metrics';
import Metrics from './components/Metrics';
import TopCashierProducts from './components/TopCashierProducts';

const MappingMonitoring = (props) => {
  const { match, stores, currency, showErrorMessage } = props;

  const USER_TIMEZONE = getUserTimezone();
  const YESTERDAY = moment.tz(USER_TIMEZONE).subtract(1, 'day');
  const currencyCode = currency.alphabeticCode;

  /*******************
   ***** STATES *****
   *******************/

  const [isLoading, setIsLoading] = useState(false);

  const [selectedStores, setSelectedStores] = useState(stores);
  const [turnoverMetric, setTurnoverMetric] = useState(0);
  const [turnoverFromProductMixMetric, setTurnoverFromProductMixMetric] = useState(0);
  const [percentageDifferenceMetric, setPercentageDifferenceMetric] = useState(null);
  const [topProducts, setTopProducts] = useState([]);

  const periodPickerState = usePeriodDatePickerState(
    moment(YESTERDAY).subtract(13, 'days'),
    YESTERDAY,
    USER_TIMEZONE,
  );
  const [maxFutureDate, setMaxFutureDate] = useState(null);

  /*******************
   ***** EFFECTS *****
   *******************/

  // Max selectable period is 1 months
  useEffect(() => {
    const maxEndDate = moment.tz(periodPickerState.startDate, USER_TIMEZONE).add(1, 'month');

    setMaxFutureDate(moment.min(YESTERDAY, maxEndDate));
  }, [periodPickerState.startDate]);

  // Fetch metrics on stores and period change
  useEffect(() => {
    if (!selectedStores.length || !periodPickerState.startDate || !periodPickerState.endDate) {
      return;
    }

    (async () => {
      setIsLoading(true);

      try {
        const storeIds = selectedStores.map(({ id }) => id);
        const startDate = moment(periodPickerState.startDate).format(
          DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
        );
        const endDate = moment(periodPickerState.endDate).format(
          DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
        );

        const {
          turnoverExcludingTaxes,
          turnoverExcludingTaxesFromProductMix,
          topUnmappedProducts,
        } = await cashierService.getMappingMonitoringByStoreIdsAndDate(
          storeIds,
          startDate,
          endDate,
        );

        const formattedPercentageDifference = !turnoverExcludingTaxes
          ? null
          : 100 -
            100 *
              (Math.min(turnoverExcludingTaxesFromProductMix, turnoverExcludingTaxes) /
                turnoverExcludingTaxes);

        setTurnoverMetric(turnoverExcludingTaxes);
        setTurnoverFromProductMixMetric(
          Math.min(turnoverExcludingTaxesFromProductMix, turnoverExcludingTaxes),
        );

        setPercentageDifferenceMetric(formattedPercentageDifference);

        setTopProducts(topUnmappedProducts);
      } catch {
        showErrorMessage(i18next.t('ADMIN.FTP_DEBUG.FETCH_FTP_STATUS_ERROR'));

        setTurnoverMetric(0);
        setTurnoverFromProductMixMetric(0);
        setPercentageDifferenceMetric(null);
        setTopProducts([]);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [selectedStores, periodPickerState.startDate, periodPickerState.endDate]);

  return (
    <GenericPageContainer>
      <NavigationBreadCrumb featurePath={match.path} />
      <PageContainer>
        <LeftRightSplitter
          left={
            <GenericGapContainer>
              <Dropdown
                iconSrc="/images/inpulse/pin-black-small.svg"
                isDisabled={isLoading}
                isUniqueSelection={false}
                items={stores}
                searchPlaceholder={i18next.t('GENERAL.SEARCH')}
                selectedItems={selectedStores}
                isRequired
                onSelectionChange={setSelectedStores}
              />
              <PeriodDatePicker
                disabled={isLoading}
                endDate={periodPickerState.endDate}
                focusedDateInput={periodPickerState.focusedDateInput}
                maxFutureDate={
                  !!periodPickerState.startDate && !!periodPickerState.endDate
                    ? YESTERDAY
                    : maxFutureDate
                }
                setFocusedDateInput={periodPickerState.setFocusedDateInput}
                startDate={periodPickerState.startDate}
                timezone={USER_TIMEZONE}
                onDatesChange={periodPickerState.handleSelectedDates}
              />
            </GenericGapContainer>
          }
        />
        <ContentContainer>
          <div>
            <MetricsContainer>
              <Metrics
                color={ENUM_COLORS.DARKEST}
                currency={currency}
                customStyle={{
                  flex: 1,
                  'min-width': '144px',
                  height: '96px',
                }}
                data={{
                  value: turnoverMetric,
                  type: STATS_DATA_TYPE.CURRENCY,
                }}
                isLoading={isLoading}
                isLoadingPreviousData={isLoading}
                title={i18next.t('ADMIN.MAPPING_MONITORING.METRIC_NAME_TURNOVER_TAX_EXCLUDED', {
                  currencyCode,
                })}
              />
              <Metrics
                color={ENUM_COLORS.DARKEST}
                currency={currency}
                customStyle={{
                  flex: 1,
                  'min-width': '144px',
                  height: '96px',
                }}
                data={{
                  value: turnoverFromProductMixMetric,
                  type: STATS_DATA_TYPE.CURRENCY,
                }}
                isLoading={isLoading}
                title={i18next.t('ADMIN.MAPPING_MONITORING.METRIC_NAME_TOTAL_MIX_TAX_EXCLUDED', {
                  currencyCode,
                })}
              />
              <Metrics
                color={
                  percentageDifferenceMetric < 5
                    ? ENUM_COLORS.INFO_GREEN
                    : percentageDifferenceMetric < 10
                    ? ENUM_COLORS.INFO_ORANGE
                    : ENUM_COLORS.INFO_RED
                }
                currency={currency}
                customStyle={{
                  flex: 1,
                  'min-width': '144px',
                  height: '96px',
                }}
                data={{
                  value: percentageDifferenceMetric,
                  type: STATS_DATA_TYPE.PERCENT,
                }}
                isLoading={isLoading}
                title={i18next.t('ADMIN.MAPPING_MONITORING.METRIC_NAME_MISSING_MIX', {
                  currencyCode,
                })}
              />
            </MetricsContainer>
          </div>
          <LastRowContainer>
            <TopCashierProducts
              currency={currency}
              data={topProducts}
              isLoading={isLoading}
              title={i18next.t('GENERAL.METRIC_MARGIN_EX_TAX', {
                currencyCode,
              })}
              tooltipText={i18next.t('DASHBOARD.FOODCOST.MARGIN_HT_TOOLTIP', {
                currencyCode,
              })}
            />
          </LastRowContainer>
        </ContentContainer>
      </PageContainer>
    </GenericPageContainer>
  );
};

const mapStateToProps = (state) => ({
  stores: state.baseReducer.activeStores,
  currency: state.baseReducer.currency,
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MappingMonitoring);

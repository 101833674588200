import React from 'react';

import { getTheme } from '@commons/utils/theme';

/**
 * Simple component to manually add an asterisk to a text / label.
 * Used to indicate that a field is required.
 * Can be directly included in the children of a <Text> component.
 *
 * ex:
 *  <Text>
 *   <Asterisk />
 *   {i18next.t(...)}
 *  </Text>
 *
 * @returns a <span> element with an asterisk.
 */

export const Asterisk = () => {
  const theme = getTheme();

  return <span style={{ marginRight: '2px', color: theme.colors.infoRed }}>*</span>;
};

import { connect } from 'react-redux';
import { get, orderBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

import { ANALYTICS_TYPE } from '@commons/constants/analyticsType';
import { Button, NestedList } from '@commons/utils/styledLibraryComponents';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { getUserTimezone } from '@commons/utils/date';
import { PeriodDatePicker } from '@commons/DatePickers/PeriodDatePicker';
import DeepsightFiltersButton from '@commons/DeepsightAnalyticsHeaders/components/DeepsightFiltersButton';
import EmptyState from '@commons/EmptyState';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showConfirmationMessage } from '@actions/messageconfirmation';

import { usePeriodDatePickerState } from '@hooks/usePeriodDatePickerState';

import { getSalesPointStores } from '@selectors/stores';

import orderService from '@services/order';

import { fetchInventoryDateOfStoreByDates } from '@orders/OrderAnalyticsModulesContainer/OrderAnalyticsCategory/utils/fetch';

import {
  Container,
  ContentContainer,
  HeaderContainer,
  Filter,
  FilterContainer,
  NestedListContainer,
} from './styledComponents';

import { exportAnalytics } from './utils/exportUtils';
import { getColumns } from './utils/getColumns';

export const MarginAnalyticsByStore = (props) => {
  const {
    match: { path },
    user,
    stores,
    pageLoading,
    pageLoaded,
    showMessage,
    showErrorMessage,
    currency,
  } = props;

  const userTimezone = getUserTimezone();
  const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');
  const yesterday = moment.tz(userTimezone).subtract(1, 'days').endOf('day');

  const periodPickerState = usePeriodDatePickerState(
    moment.tz(userTimezone).subtract(1, 'month').startOf('day'),
    yesterday,
    userTimezone,
  );

  const [focusedMonth, setFocusedMonth] = useState(
    moment
      .tz(userTimezone)
      .subtract(1, 'month')
      .startOf('month')
      .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
  );

  const [analyticsData, setAnalyticsData] = useState([]);
  const [isRetrievingAnalytics, setIsRetrievingAnalytics] = useState(true);
  const [selectedStores, setSelectedStores] = useState(stores);
  const [renderEmptyState, setRenderEmptyState] = useState(false);
  const [totalRow, setTotalRow] = useState([]);
  const [filteredAnalytics, setFilteredAnalytics] = useState([]);
  const [columns, setColumns] = useState([]);

  // Filters states
  const [filters, setFilters] = useState(null);
  const [advancedFilters, setAdvancedFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);
  const [datesWithInventory, setDatesWithInventory] = useState({});

  const getAnalyticsData = async () => {
    pageLoading();
    setIsRetrievingAnalytics(true);

    const storeIds = selectedStores.map(({ id }) => id);

    try {
      const fetchedData = await orderService.getOrdersAnalyticsByStore(
        storeIds,
        moment(periodPickerState.startDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        moment(periodPickerState.endDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        true,
      );

      const formattedData = orderBy(
        fetchedData.data.map((item, index) => {
          const grossMargin = item.turnover - item.foodCost.realConsumption - item.grossSalary;

          const grossSalaryPercentage =
            item.turnover && item.grossSalary ? (item.grossSalary / item.turnover) * 100 : null;

          const materialRatio = item.turnover
            ? (item.theoricalConsumption / item.turnover) * 100
            : null;

          const orderOverTurnoverRatio = item.turnover
            ? (item.foodCost.realConsumption / item.turnover) * 100
            : null;

          return {
            ...item,
            id: index,
            materialRatio,
            orderOverTurnoverRatio,
            grossMargin,
            grossSalaryPercentage,
            grossMarginOverTurnoverPercentage:
              item.turnover !== null ? (grossMargin / item.turnover) * 100 : null,
          };
        }),
        'storeName',
      );

      const totalData = fetchedData.totalData;
      const totalGrossMargin =
        totalData.turnover - totalData.foodCost.realConsumption - totalData.grossSalary;

      const grossSalaryPercentage = totalData.turnover
        ? (totalData.grossSalary / totalData.turnover) * 100
        : null;

      const orderOverTurnoverRatio = totalData.turnover
        ? (totalData.foodCost.realConsumption / totalData.turnover) * 100
        : null;

      const materialRatio = totalData.turnover
        ? (totalData.theoricalConsumption / totalData.turnover) * 100
        : null;

      const totalRow = [
        {
          ...totalData,
          store: {
            id: 'total',
            name: i18next.t('ORDERS.BY_CATEGORY.LABEL_TOTAL'),
          },
          materialRatio,
          orderOverTurnoverRatio,
          grossSalaryPercentage,
          grossMargin: totalGrossMargin,
          grossMarginOverTurnoverPercentage: totalData.turnover
            ? (totalGrossMargin / totalData.turnover) * 100
            : null,
        },
      ];

      setTotalRow(totalRow);

      setAnalyticsData(formattedData);
    } catch {
      showErrorMessage(i18next.t('ORDERS.BY_CATEGORY.FETCH_DATA_FAILURE'));
      setAnalyticsData([]);
    } finally {
      setIsRetrievingAnalytics(false);
      pageLoaded();
    }
  };

  const getDatesWithInventory = async () => {
    const storeIds = selectedStores.map(({ id }) => id);

    try {
      const startDate = moment
        .tz(focusedMonth, userTimezone)
        .subtract(1, 'month')
        .startOf('month')
        .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
      const endDate = moment
        .tz(focusedMonth, userTimezone)
        .add(1, 'month')
        .endOf('month')
        .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

      const formattedDates = await fetchInventoryDateOfStoreByDates(
        storeIds,
        startDate,
        endDate,
        userTimezone,
        showMessage,
      );

      formattedDates.startDate = formattedDates.startDate.map((date) =>
        moment.tz(date, userTimezone),
      );
      formattedDates.endDate = formattedDates.endDate.map((date) => moment.tz(date, userTimezone));

      setDatesWithInventory(formattedDates);
    } catch {
      showErrorMessage(i18next.t('ORDERS.BY_CATEGORY.FETCH_DATA_FAILURE'));
    }
  };

  const triggerExport = () => {
    exportAnalytics(
      filteredAnalytics,
      periodPickerState.startDate,
      periodPickerState.endDate,
      currency,
    );
  };

  useEffect(() => {
    if (!periodPickerState.startDate || !periodPickerState.endDate || !selectedStores.length) {
      return;
    }

    setColumns(getColumns(currency, periodPickerState.startDate, periodPickerState.endDate));

    getAnalyticsData();
  }, [periodPickerState.startDate, periodPickerState.endDate, selectedStores]);

  useEffect(() => {
    if (selectedStores.length !== 1) {
      return;
    }

    getDatesWithInventory();
  }, [selectedStores, focusedMonth]);

  // Handle filters
  useEffect(() => {
    if (!analyticsData) {
      setFilteredAnalytics([]);
      return;
    }

    if (!applyFilters) {
      setFilteredAnalytics(analyticsData);
      return;
    }

    if (!advancedFilters || !advancedFilters.length) {
      setFilteredAnalytics(analyticsData);

      return;
    }

    let filteredData = analyticsData.filter(({ id }) => id !== 'total');

    advancedFilters.forEach(({ propertyKey, doFilter, value }) => {
      filteredData = doFilter(filteredData, propertyKey, value);
    });

    setFilteredAnalytics(filteredData);
  }, [analyticsData, advancedFilters, applyFilters]);

  useEffect(() => {
    if (!filteredAnalytics) {
      return;
    }

    setRenderEmptyState(!filteredAnalytics.length && !isRetrievingAnalytics);
  }, [filteredAnalytics, isRetrievingAnalytics]);

  return (
    <Container>
      <NavigationBreadCrumb featurePath={path} />
      <ContentContainer>
        <HeaderContainer>
          <FilterContainer>
            <Filter>
              <DeepsightFiltersButton
                advancedFilters={advancedFilters}
                analyticsType={ANALYTICS_TYPE.ORDER}
                columnsFilterList={columns.filter((column) => column.filterType === 'numeric')}
                disableExportButton={!filteredAnalytics || !filteredAnalytics.length}
                filters={filters}
                readOnly={isRetrievingAnalytics}
                selectedStores={selectedStores}
                setAdvancedFilters={setAdvancedFilters}
                setApplyFilters={setApplyFilters}
                setFilters={setFilters}
                setSelectedStores={setSelectedStores}
                stores={stores}
                textFilterButton={i18next.t('GENERAL.ANALYTICS_BUTTON')}
                exportButtonPositionLeft
              />
            </Filter>
            <PeriodDatePicker
              authorizedDates={
                // focusedDateInput is either 'startDate' or 'endDate'
                !!periodPickerState.focusedDateInput &&
                datesWithInventory[periodPickerState.focusedDateInput]
              }
              disabled={isRetrievingAnalytics}
              endDate={periodPickerState.endDate}
              focusedDateInput={periodPickerState.focusedDateInput}
              maxFutureDate={yesterday}
              setFocusedDateInput={periodPickerState.setFocusedDateInput}
              startDate={periodPickerState.startDate}
              onDatesChange={periodPickerState.handleSelectedDates}
              onNextMonthClick={(nextMonth) => setFocusedMonth(nextMonth)}
              onPrevMonthClick={(prevMonth) => setFocusedMonth(prevMonth)}
            />
          </FilterContainer>
          <Button
            color={'inpulse-default'}
            handleClick={triggerExport}
            icon={'/images/inpulse/file-download-white-small.svg'}
            isDisabled={isRetrievingAnalytics}
            label={i18next.t('GENERAL.EXPORT')}
          />
        </HeaderContainer>

        <NestedListContainer>
          {renderEmptyState && (
            <div style={{ verticalAlign: 'middle', height: 'calc(100vh - 225px)' }}>
              <EmptyState
                iconHeight={'160px'}
                iconWidth={'160px'}
                label={i18next.t('ORDERS.BY_STORE.EMPTY_STATE_LABEL')}
              />
            </div>
          )}
          {!renderEmptyState && (
            <NestedList
              data={filteredAnalytics}
              defaultOrderBy={'store.name'}
              fixedRowsData={totalRow}
              headers={columns}
              isExpandable={false}
              isLoading={isRetrievingAnalytics}
              labelEmptyState={i18next.t('GENERAL.NO_RESULT')}
              languageCode={userLanguageCode}
              minWidth={'978px'}
            />
          )}
        </NestedListContainer>
      </ContentContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  stores: getSalesPointStores(state.baseReducer.activeStores),
  currency: state.baseReducer.currency,
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MarginAnalyticsByStore);

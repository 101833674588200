import { APIcaller } from './APIcaller';

const getInvoiceControlList = (clientId, supplierIds) =>
  APIcaller.apiPost(`/invoice-control/list`, { clientId, supplierIds });

const exportInvoiceControlsDetails = (invoiceControlListIds) =>
  APIcaller.apiPost(`/invoice-control/export-details`, { invoiceControlListIds });

const getAvailableCredits = (clientId) =>
  APIcaller.apiGet(`/invoice-control/credits?clientId=${clientId}`);

const getStoresAvailableCredits = (storeIds) =>
  APIcaller.apiPost(`/invoice-control/credits`, { storeIds });

const importInvoiceControlLists = (clientId, invoiceControlLists) =>
  APIcaller.apiPost(`/invoice-control/import`, { clientId, invoiceControlLists });

const getById = (id) => APIcaller.apiGet(`/invoice-control/${id}`);

const getOcerizationById = (id) => APIcaller.apiGet(`/invoice-control/${id}/export-ocerization`);

const getInvoiceControlListByOrderId = (orderId) =>
  APIcaller.apiGet(`/invoice-control/orders/${orderId}`);

const analyseAndExportInvoiceControlListDetails = (id) =>
  APIcaller.apiGet(`/invoice-control/${id}/export-details`);

const updateById = (id, fields) => APIcaller.apiPut(`/invoice-control/${id}`, { fields });

const resetOrderInvoiceAssociation = (id, storeId, supplierId) =>
  APIcaller.apiPost(`/invoice-control/${id}/reset`, { storeId, supplierId });

const deleteById = (id) => APIcaller.apiDelete(`/invoice-control/${id}`);

const getOrdersByInvoiceControlListId = (id) => APIcaller.apiGet(`/invoice-control/${id}/orders`);

const getAvailableOrdersToAssociate = (storeId, supplierId) =>
  APIcaller.apiGet(`/invoice-control/available-orders?storeId=${storeId}&supplierId=${supplierId}`);

const deleteOrderByInvoiceControlListId = (id, orderId) =>
  APIcaller.apiDelete(`/invoice-control/${id}/orders/${orderId}`);

const addOrdersByInvoiceControlListId = (id, orderIds) =>
  APIcaller.apiPost(`/invoice-control/${id}/orders`, { orderIds });

const importInvoiceControlListFromOrder = (
  clientId,
  supplierId,
  storeId,
  orderId,
  invoiceControlList,
) =>
  APIcaller.apiPost(`/invoice-control/import-from-order`, {
    clientId,
    supplierId,
    storeId,
    orderId,
    invoiceUrl: invoiceControlList.link,
    invoiceControlListId: invoiceControlList.id,
    invoiceNumber: invoiceControlList.number,
    invoiceDate: invoiceControlList.date,
    invoiceTotal: invoiceControlList.total,
  });

const checkInvoiceControlList = (invoiceControlListId) =>
  APIcaller.apiPost(`/invoice-control/check`, { invoiceControlListId });

const getInvoiceControlPlans = () => APIcaller.apiGet(`/invoice-control/plans`);

const automaticStoresDetection = (clientId, storeIds, supplierId, invoiceUrls) =>
  APIcaller.apiPost(`/invoice-control/detect-stores`, {
    clientId,
    storeIds,
    supplierId,
    invoiceUrls,
  });

const getInvoiceControlListByIds = (invoiceControlListIds) =>
  APIcaller.apiPost(`/invoice-control/get-by-ids`, { invoiceControlListIds });

export default {
  getById,
  updateById,
  deleteById,
  getInvoiceControlListByOrderId,
  getOcerizationById,
  analyseAndExportInvoiceControlListDetails,
  getAvailableCredits,
  getStoresAvailableCredits,
  getInvoiceControlList,
  exportInvoiceControlsDetails,
  importInvoiceControlLists,
  getOrdersByInvoiceControlListId,
  addOrdersByInvoiceControlListId,
  deleteOrderByInvoiceControlListId,
  getAvailableOrdersToAssociate,
  resetOrderInvoiceAssociation,
  importInvoiceControlListFromOrder,
  checkInvoiceControlList,
  getInvoiceControlPlans,
  automaticStoresDetection,
  getInvoiceControlListByIds,
};

import styled from 'styled-components';

export const RecipePreparationStepsTitle = styled.div`
  display: flex;
  align-self: center;

  font: ${(props) => props.theme.fonts.h2};
`;

export const ColorPickerContainer = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const ColorPickerButtonContainer = styled.div`
  width: 26px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px;
  border-radius: 2px;
  // #F1F1F1 is a color from wysiwig editor we don't have it on theme
  border: 1px solid #f1f1f1;
`;

export const ColorPickerColoredSquare = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  border: '1px solid ${(props) => props.theme.colors.black}';
  background-color: ${(props) => props.color};
`;

export const ColorPickerModal = styled.div`
  position: absolute;
  z-index: 2;
  top: 28px;
  left: 5px;
`;

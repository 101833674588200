import i18next from 'i18next';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS } from '../DatePickers/constants';
import { USER_LANGUAGE_CODE } from '@commons/constants/country';

export const getUserTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

const userTimezone = getUserTimezone();

export const ORDER_IS_EDITING_DATE_MAX_DATE_IN_PAST = 3;

const getWeekDays = () =>
  [...Array(7).keys()].map((dayIndex) => ({
    id: dayIndex,
    name: moment
      .tz(getUserTimezone())
      .startOf('week')
      .isoWeekday(dayIndex + 1)
      .format('dddd'),
  }));

export const isToday = (timestamp, timezone = userTimezone) =>
  moment.tz(timezone).diff(timestamp, 'days') === 0;

export const isAfter = (startDate, endDate, timezone = userTimezone) =>
  moment.tz(startDate, timezone).isAfter(moment.tz(endDate, timezone), 'day');

export const isSameDay = (startDate, endDate, timezone = userTimezone) =>
  moment.tz(startDate, timezone).isSame(moment.tz(endDate, timezone), 'day');

export const isSameDateWithDifferentTimezone = (
  startDate,
  endDate,
  startDateTimezone = userTimezone,
  endDateTimezone = userTimezone,
) => {
  const timezonedStartDate = moment.tz(startDate, startDateTimezone);
  const timezonedEndDate = moment.tz(endDate, endDateTimezone);

  return (
    timezonedStartDate.day() === timezonedEndDate.day() &&
    timezonedStartDate.date() === timezonedEndDate.date() &&
    timezonedStartDate.month() === timezonedEndDate.month()
  );
};

export const isSameOrBeforeDay = (startDate, endDate, timezone = userTimezone) =>
  moment.tz(startDate, timezone).isSameOrBefore(moment.tz(endDate, timezone), 'day');

export const isSameOrAfterDay = (startDate, endDate, timezone = userTimezone) =>
  moment.tz(startDate, timezone).isSameOrAfter(moment.tz(endDate, timezone), 'day');

export const isBetweenDays = (date, startDate, endDate, timezone = userTimezone) =>
  isSameOrAfterDay(date, startDate, timezone) && isSameOrBeforeDay(date, endDate, timezone);

export const isOutsideRange = (
  day,
  startDate,
  orderDatesInPast,
  isEditingDeliveryDate,
  timezone = userTimezone,
  exceptionalNoDeliveryDates = [],
) => {
  const clonedDay = day.clone().format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
  const calendarDay = moment.tz(clonedDay, timezone);

  const formattedCalendarDay = moment.tz(calendarDay, timezone).startOf('day').format();

  if (
    (isEditingDeliveryDate && orderDatesInPast.includes(formattedCalendarDay)) ||
    exceptionalNoDeliveryDates.includes(formattedCalendarDay)
  ) {
    return true;
  }

  if (isEditingDeliveryDate) {
    return moment
      .tz(timezone)
      .subtract(ORDER_IS_EDITING_DATE_MAX_DATE_IN_PAST, 'months')
      .startOf('day')
      .isSameOrAfter(calendarDay.startOf('day'));
  }

  if (startDate) {
    return moment.tz(startDate, timezone).startOf('day').isAfter(calendarDay.startOf('day'));
  }
  return moment.tz(timezone).startOf('day').isSameOrAfter(calendarDay.startOf('day'));
};

export const getLimitOrderDay = (deliveryDay, leadTime) => {
  let limitOrderWeekDay = (deliveryDay - leadTime) % 7;
  let nbWeek = Math.floor(leadTime / 7);
  if (limitOrderWeekDay < 0) limitOrderWeekDay += 7;
  if (deliveryDay - limitOrderWeekDay < 0) nbWeek++;

  return `${getWeekDays()[limitOrderWeekDay]['name']} ${
    nbWeek > 0 ? `(${i18next.t('GENERAL.WEEK_ABBREVIATION')}-${nbWeek})` : ''
  }`;
};

export const getWeekDayString = (weekDay) => getWeekDays()[weekDay]['name'];

export const getNbDaysBetweenDays = (startDay, endDay) => {
  if (startDay === endDay) return 7;
  return (endDay - startDay + 7) % 7;
};

export const convertDateToCountDownText = (date) => {
  const currentTime = moment.tz(getUserTimezone());

  const eventTime = moment.tz(date, getUserTimezone());

  const duration = moment.duration(eventTime.diff(currentTime));

  let text = '';

  const hoursLeft = duration.hours();

  if (hoursLeft) {
    text += `${hoursLeft} ${i18next.t('GENERAL.HOURS')} `;
  }

  const minutesLeft = duration.minutes();

  if (minutesLeft) {
    text += `${minutesLeft} ${i18next.t('GENERAL.MINUTES')} `;
  }

  const secondsLeft = duration.seconds();

  if (secondsLeft) {
    text += `${duration.seconds()} ${i18next.t('GENERAL.SECONDS')}`;
  }

  // When time left does not make any sense
  if ((!hoursLeft && !minutesLeft && secondsLeft <= 0) || duration.asMilliseconds() <= 0) {
    return i18next.t('GENERAL.SHORTLY');
  }

  return text;
};

/**
 * Returns either a moment object, or a formatted string representing the day before of a store
 * @param {Object} store a Store object
 * @param {null | string} format how the moment object should be formatted.
 * @returns {<Moment> | string} moment object or string.
 */
export const getPreviousDayOfStore = (store, format = null) => {
  const previousDayOfStore = moment.tz(store.timezone).subtract(1, 'day');

  if (format) {
    return previousDayOfStore.format(format);
  }
  return previousDayOfStore;
};

export const formatDateWithLanguageCode = (date, userLanguageCode) =>
  moment(date).format(
    userLanguageCode === USER_LANGUAGE_CODE.EN
      ? DATE_DISPLAY_FORMATS.SLASHED_MONTH_DAY_YEAR
      : DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR,
  );

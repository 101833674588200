import { get, isEmpty } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';

import { CONSUMPTION_COMPUTATION_TYPES } from '@commons/constants/centralConstants';
import { DEFAULT_TIMEZONE } from '@commons/utils/timezone';
import { Dropdown, INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';
import { ExportButton } from '@commons/ExportButton';
import { PeriodDatePicker } from '@commons/DatePickers/PeriodDatePicker';
import WhiteCard from '@commons/WhiteCard';

import {
  HeaderContentContainer,
  HeaderProductionTitle,
  LabelContainer,
  LabelText,
  Content,
} from '../styledComponents';

const Header = (props) => {
  const {
    canEditProductionPlanning,
    centralKitchens,
    clientStoreNameTranslation,
    consumptionPeriodPickerState,
    handleExport,
    isLoading,
    productionPeriodPickerState,
    selectedCentralKitchen,
    selectedStores,
    setSelectedCentralKitchen,
    setSelectedStores,
    stores,
    prevHorizon: { future: prevMaxFutureDay },
  } = props;

  const centralKitchenTimezone = get(selectedCentralKitchen, 'timezone', DEFAULT_TIMEZONE);
  const [productionMaxFutureDate, setProductionMaxFutureDate] = useState(null);

  const consumptionComputation = get(selectedCentralKitchen, 'consumptionComputation');
  const consumptionMaxPastDate =
    consumptionComputation === CONSUMPTION_COMPUTATION_TYPES.PRODUCT_MIX ? moment() : null;

  useEffect(() => {
    if (productionPeriodPickerState.focusedDateInput === 'startDate') {
      setProductionMaxFutureDate(null);
      return;
    }

    setProductionMaxFutureDate(productionPeriodPickerState.startDate.clone().add(2, 'weeks'));
  }, [productionPeriodPickerState.focusedDateInput]);

  return (
    <WhiteCard
      content={
        <HeaderContentContainer>
          <Content>
            <HeaderProductionTitle>
              {i18next.t('FEATURE.OPERATIONS.PRODUCTION_BREADCRUMB')}
            </HeaderProductionTitle>
            <div>
              <LabelContainer>
                <LabelText>
                  {`${i18next.t('PRODUCTION.CENTRAL_KITCHEN.PLANNING_PRODUCTION_HUB')} :`}
                </LabelText>
              </LabelContainer>
              <Dropdown
                iconSrc={'/images/inpulse/hub-black-small.svg'}
                isDisabled={centralKitchens.length === 1}
                items={centralKitchens}
                searchPlaceholder={i18next.t('GENERAL.SEARCH')}
                selectedItem={selectedCentralKitchen}
                width={INPUT_WIDTH.EXTRA_LARGE}
                isRequired
                onSelectionChange={setSelectedCentralKitchen}
              />
            </div>

            <div>
              <LabelContainer>
                <LabelText>
                  {`${i18next.t('PRODUCTION.CENTRAL_KITCHEN.PLANNING_PRODUCTION_RANGE')} :`}
                </LabelText>
              </LabelContainer>
              <PeriodDatePicker
                customStyle={{ width: INPUT_WIDTH.EXTRA_LARGE }}
                disabled={false}
                endDate={productionPeriodPickerState.endDate}
                focusedDateInput={productionPeriodPickerState.focusedDateInput}
                maxFutureDate={productionMaxFutureDate}
                setFocusedDateInput={productionPeriodPickerState.setFocusedDateInput}
                startDate={productionPeriodPickerState.startDate}
                timezone={centralKitchenTimezone}
                onDatesChange={productionPeriodPickerState.handleSelectedDates}
              />
            </div>
          </Content>
          <Content>
            <HeaderProductionTitle>
              {i18next.t('PRODUCTION.CENTRAL_KITCHEN.PLANNING_CONSUMPTION_CALCULATION')}
            </HeaderProductionTitle>
            <div>
              <LabelContainer>
                <LabelText>{`${clientStoreNameTranslation} :`}</LabelText>
              </LabelContainer>
              <Dropdown
                iconSrc={'/images/inpulse/store-black-small.svg'}
                isDisabled={isLoading || !canEditProductionPlanning}
                isUniqueSelection={false}
                items={stores}
                searchPlaceholder={i18next.t('GENERAL.SEARCH')}
                selectedItems={selectedStores}
                width={INPUT_WIDTH.EXTRA_LARGE}
                onSelectionChange={setSelectedStores}
              />
            </div>

            <div>
              <LabelContainer>
                <LabelText>{`${i18next.t(
                  'PRODUCTION.CENTRAL_KITCHEN.PLANNING_CONSUMPTION_RANGE',
                )} :`}</LabelText>
              </LabelContainer>
              <PeriodDatePicker
                customStyle={{ width: INPUT_WIDTH.EXTRA_LARGE }}
                disabled={!canEditProductionPlanning || isEmpty(selectedStores)}
                endDate={consumptionPeriodPickerState.endDate}
                focusedDateInput={consumptionPeriodPickerState.focusedDateInput}
                maxFutureDate={
                  consumptionComputation === CONSUMPTION_COMPUTATION_TYPES.PRODUCT_MIX
                    ? moment().add(prevMaxFutureDay, 'days')
                    : null
                }
                maxPastDate={consumptionMaxPastDate}
                setFocusedDateInput={consumptionPeriodPickerState.setFocusedDateInput}
                startDate={consumptionPeriodPickerState.startDate}
                timezone={centralKitchenTimezone}
                onDatesChange={consumptionPeriodPickerState.handleSelectedDates}
              />
            </div>
          </Content>
          <Content>
            <HeaderProductionTitle>{i18next.t('GENERAL.EXPORT')}</HeaderProductionTitle>
            <div>
              <ExportButton
                customStyle={{ border: 'none', padding: 'inherit', height: 'auto' }}
                handleClick={handleExport}
              />
            </div>
          </Content>
        </HeaderContentContainer>
      }
      customStyle={{ height: '240px', width: '100%', maxWidth: '100%', minWidth: '886px' }}
    />
  );
};

export default Header;
